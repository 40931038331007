import React from "react";
import { useSelector } from "react-redux";
import CustomerAccountDetailsForm from "./forms/customerAccountDetailsForm";

const CustomerAccountDetails = ({ t }) => {
  const selectedRow = useSelector(state => state.customerAccount.selectedRow);
  return <CustomerAccountDetailsForm data={selectedRow} t={t} />;
};

export default CustomerAccountDetails;
