import React from "react";
import { Button } from "../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const SalespointButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="u-margin-right-small" style={{ display: "flex" }}>
        {" "}
        <Button
          btnStyle="primary"
          label={t("salespoint.BtnSalesPointGroupChange")}
          size="medium"
          classes="btn--footer"
        />
        <Button
          btnStyle="primary"
          label={t("salespoint.BtnAdd")}
          size="medium"
          classes="btn--footer"
        />
      </div>
      <Button
        btnStyle="green"
        label={t("salespoint.BtnSave")}
        size="medium"
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default SalespointButtons;
