export const TOGGLE_CUSTOMER_DEPOSIT_TABLE = () => ({
  type: "TOGGLE_CUSTOMER_DEPOSIT_TABLE"
});

export const TOGGLE_CUSTOMER_DEPOSIT_EXPANDER_VIEW = () => ({
  type: "TOGGLE_CUSTOMER_DEPOSIT_EXPANDER_VIEW"
});

export const TOGGLE_CUSTOMER_DEPOSIT_SEARCH = () => ({
  type: "TOGGLE_CUSTOMER_DEPOSIT_SEARCH"
});
