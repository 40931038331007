export const SELECT_PARTNER_ROW = payload => ({
  type: "SELECT_PARTNER_ROW"
});

export const TOGGLE_PARTNER_SEARCH = () => ({
  type: "TOGGLE_PARTNER_SEARCH"
});

export const TOGGLE_PARTNER_TABLE = () => ({
  type: "TOGGLE_PARTNER_TABLE"
});

export const TOGGLE_PARTNER_EXPANDER_VIEW = () => ({
  type: "TOGGLE_PARTNER_EXPANDER_VIEW"
});

export const LOAD_PARTNER_SEARCH_DATA_START = () => ({
  type: "LOAD_PARTNER_SEARCH_DATA_START"
});
