import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../common/table";

class BrokerClaimsTable extends Component {
  state = {
    height: 250,
    data: [],
    selected: {},
    selectAll: 0
  };

  getColumns = () => {
    const { t } = this.props;
    const BrokerInvoiceTableTrans = "broker.claims.";
    const columns = [
      {
        Header: t(BrokerInvoiceTableTrans + "AddToInvoice"),
        accessor: "addToInvoice"
      },

      {
        Header: t(BrokerInvoiceTableTrans + "Customer"),
        accessor: "customer" // String-based value accessors!
      },
      {
        Header: t(BrokerInvoiceTableTrans + "BrokerClaimDate"),
        accessor: "brokerClaimDate"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "BrokerClaimType"),
        accessor: "brokerClaimType"
      },

      {
        Header: t(BrokerInvoiceTableTrans + "Sum"),
        accessor: "brokerSum"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "BrokerClaimNo"),
        accessor: "brokerClaimNo"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "AdditionalInfo"),
        accessor: "additionalInfo"
      }
    ];

    return columns;
  };

  render() {
    const tableSpecs = { height: 300 };
    return (
      <Table
        columns={this.getColumns()}
        tableSpecs={tableSpecs}
        data={[]}
        onRowSelect={this.props.onCustomerSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCustomerSelect: payload => {
      const action = { type: "CUSTOMERSELECT", payload: payload };
      dispatch(action);
    },
    onCustomerRowSelect: payload => {
      const action = { type: "CUSTROWSELECT", payload: payload };
      dispatch(action);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrokerClaimsTable);
