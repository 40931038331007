import React from "react";
import { useTranslation } from "react-i18next";
import { Section, SectionBody, SectionHead } from "../../common/section";
import BrokerInvoicesTabs from "./brokerInvoicesTabs";
import BrokerInvoicesTable from "./brokerInvoicesTable";

const BrokerInvoicesSection = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionHead>
        <h1 className="heading__primary">
          {t("broker.invoices.BrokerInvoices")}
        </h1>
      </SectionHead>
      <SectionBody>
        <BrokerInvoicesTable t={t} />
        <div className="u-margin-bottom-tiny" />
        <BrokerInvoicesTabs t={t} />
      </SectionBody>
    </Section>
  );
};

export default BrokerInvoicesSection;
