import React from "react";
import { useDispatch } from "react-redux";
import Table from "../../common/table";
import { SELECT_CONTRACT_FACTORING_DEBTOR_ROW } from "../actions/contractActions";
import useContractState from "../hooks/useContractState";
const ContractFactoringDebtorsTable = ({ t }) => {
  const dispatch = useDispatch();
  const selectedRow = {};
  const { contractData: data } = useContractState();
  const getColumns = () => {
    const columns = [
      {
        id: "factoringDebtor",
        Header: t("factoring.debtor.Header"),
        accessor: d => (d.company ? d.company[0].name : "")
      }
    ];
    return columns;
  };

  const onRowSelect = payload => {
    console.log(payload);
    dispatch(SELECT_CONTRACT_FACTORING_DEBTOR_ROW(payload));
  };

  return (
    <Table
      data={data || []}
      columns={getColumns()}
      height={200}
      selectedRow={selectedRow}
      onRowSelect={onRowSelect}
    />
  );
};

export default ContractFactoringDebtorsTable;
