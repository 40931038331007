const createTabReducer = (mainName = "", name = "") => {
  const initialState = {
    data: {},
    table: {
      height: 200
    },
    expanded: false,
    selectedItem: {}
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `SET_${mainName}_${name}_TABLE_HEIGHT`: {
        return {
          ...state,
          table: { ...state.table, height: action.payload }
        };
      }
      case `SELECT_${mainName}_${name}_ROW`: {
        return {
          ...state,
          selectedItem: action.payload
        };
      }
      case `TOGGLE_${mainName}_${name}_EXPANDER`: {
        return {
          ...state,
          expanded: !state.expanded
        }
      }
      case `CLEAR_${mainName}_${name}_SELECTED_ITEM`: {
        return {
          ...state,
          selectedItem: {}
        }
      }
      default:
        return state;
    }
  };
};

export default createTabReducer;
