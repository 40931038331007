import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../common/buttons/button";

const VatDocumentsButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        label={t("vat.documents.BtnUpdateSellerData")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnMergePDF")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnRequest")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnArchive")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnRemoveFromApplication")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnAdd")}
        btnStyle="primary"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnDelete")}
        btnStyle="danger"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnSave")}
        btnStyle="green"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.BtnCSV")}
        btnStyle="lime"
        size="small"
        classes="btn--footer"
      />
      <Button
        label={t("vat.documents.InsertFromFile")}
        btnStyle="lime"
        size="small"
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default VatDocumentsButtons;
