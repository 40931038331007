import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import CustomerInvoiceSearchVerticalForm from "../../search/forms/customerInvoiceSearchVerticalForm";
import useCustomerInvoiceActions from "../../hooks/useCustomerInvoiceActions";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { customerInvoices } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useAppClassifications from "../../../../layout/hooks/useAppClassifications";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CUSTOMER_INVOICES } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";

const CustomerInvoiceSearchSection = () => {
  const { searchQuery } = useSearchState(customerInvoices);
  const {
    countries,
    customerInvoicesStatusHistoryTypes
  } = useAppClassifications();
  const { searchOpen } = useLayoutState(customerInvoices);
  const { isLoading } = useDataState(customerInvoices);
  const { onSubmitCustomerInvoiceQuery } = useCustomerInvoiceActions();
  const { handleSearchToggle } = useLayoutActionDispatchers(CUSTOMER_INVOICES);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <CustomerInvoiceSearchVerticalForm
        ref={formRef}
        query={searchQuery}
        countryList={countries}
        invoiceStatusTypes={customerInvoicesStatusHistoryTypes}
        handleSearch={onSubmitCustomerInvoiceQuery}
      />
    </SearchSection>
  );
};

export default CustomerInvoiceSearchSection;
