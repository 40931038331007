import React from "react";
import _ from "lodash";
import { Button } from "../../../../common/buttons/button";
import { useTranslation } from "react-i18next";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";

const CustomerAccountBtnGroup = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(customerAccount);

  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        classes="btn--footer"
        label={t("customerAccount.BtnAdd")}
      />
      <Button
        btnStyle="danger"
        size="small"
        classes="btn--footer"
        disabled={_.isEmpty(selectedItem)}
        label={t("customerAccount.BtnDelete")}
      />

      <Button
        btnStyle="green"
        size="small"
        disabled={_.isEmpty(selectedItem)}
        classes="btn--footer"
        label={t("customerAccount.BtnSave")}
      />
      <Button
        btnStyle="lime"
        size="small"
        classes="btn--footer"
        disabled={_.isEmpty(selectedItem)}
        label={t("customerAccount.BtnCSV")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedItem)}
        classes="btn--footer"
        label={t("customerAccount.BtnSetToFactoringAccount")}
      />
    </React.Fragment>
  );
};

export default CustomerAccountBtnGroup;
