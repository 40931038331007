import {extractQueryParameters} from "../../../../../services/query";
import axios from "axios";
import {authorizationHeaders} from "../../../../../services/queries";
import {toast} from "react-toastify";
import {
  LOAD_DATA_ERROR,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  SUBMIT_QUERY
} from "../../../../common/redux/actions/search/searchActions";
import {VAT_DOCUMENTS} from "../../../../common/redux/types/viewTypes";

export const vatDocumentsSearchQuery = async payload => {
  try{
    const queryParameters = extractQueryParameters(payload);
    const data = await axios.post(
      "/graphql",
      {
        query: `query{
            vatDocuments(${queryParameters})
            {
                id
                createdOn
                sellerCityStreetHouse
                sellerName
                customer{name}
                useForFastVatRefund
                isActiveCl
                channelCl
                invoiceLocation
              customer{name}
              country{code}
              documentDate
              documentNo
              sellerName
              sellerVatPayerNo
              documentNetto
              documentVat
              documentCurrencyCl
              incomeFromTaxOffice
              payoutToCustomer
              vatApplication{applicationNo}
              isSuspiciousCl
              useForFastVatRefund
            }
          }`
      },
      authorizationHeaders()
    );
    return data;}
    catch (error) {
        toast.error(error.message);

    }
};

export const getVatDocumentsData = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(VAT_DOCUMENTS, payload));
    dispatch(LOAD_DATA_START(VAT_DOCUMENTS));
    vatDocumentsSearchQuery(payload)
      .then(d =>  dispatch(LOAD_DATA_SUCCESS(VAT_DOCUMENTS, d.data.data.vatDocuments)))
      .catch(err => {dispatch(LOAD_DATA_ERROR(VAT_DOCUMENTS, err.message)); toast.error(err.message)});
  };
};
