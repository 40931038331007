import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SearchSection } from "../../section";
import CustomerVerticalSearchForm from "../../../customer/customers/search/forms/customerVerticalSearchForm";

const CustomerViewPlaceholderSearchSection = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  return (
    <SearchSection isOpen={true} formRef={formRef}>
      <CustomerVerticalSearchForm
        t={t}
        handleSearch={() => {}}
        query={{}}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default CustomerViewPlaceholderSearchSection;
