import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import SalespointVerticalSearchForm from "../../search/forms/salespointVerticalSearchForm";
import useSalespointLayoutState from "../../hooks/layout/useSalespointLayoutState";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { SALESPOINT } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { salespoint } from "../../../common/redux/types/selectorTypes";

const SalespointSearchSection = () => {
  const formRef = useRef();
  const { handleSearchToggle } = useLayoutActionDispatchers(SALESPOINT);
  const { isLoading } = useDataState(salespoint);
  const { searchOpen } = useSalespointLayoutState();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <SalespointVerticalSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default SalespointSearchSection;
