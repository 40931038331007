import axios from "axios";
import {toast} from "react-toastify";
import Cookie from 'js-cookie';
import {useHistory} from 'react-router-dom';
import {authorizationHeaders} from "../../../services/queries";
import {Logout} from "../logout";

export const TOGGLE_LOGIN_MODAL_OPEN = () => ({
  type: "TOGGLE_LOGIN_MODAL_OPEN"
});

export const TOGGLE_LOGIN_MODAL_CLOSE = () => ({
  type: "TOGGLE_LOGIN_MODAL_CLOSE"
});

export const USER_LOGIN_START = payload => ({
  type: "USER_LOGIN_START"
});

export const USER_LOGIN_SUCCESS = payload => ({
  type: "USER_LOGIN_SUCCESS",
  payload: payload
});

export const USER_LOGIN_ERROR = payload => ({
  type: "USER_LOGIN_ERROR",
  payload: payload
});

export const USER_LOGOUT_START = () => ({
  type: "USER_LOGOUT_START"
});

export const USER_LOGOUT_SUCCESS = () => ({
  type: "USER_LOGOUT_SUCCESS"
});

export const USER_LOGIN_SET_TOKEN = payload => ({
  type: "USER_LOGIN_SET_TOKEN",
  payload: payload
});

export const GET_USER_DATA_START = payload => ({
  type: "GET_USER_DATA_START",
  payload: payload
});

export const GET_USER_DATA_SUCCESS = payload => ({
  type: "GET_USER_DATA_SUCCESS",
  payload: payload
});

export const GET_USER_DATA_ERROR = payload => ({
  type: "GET_USER_DATA_ERROR",
  payload: payload
});

export const GET_USER_PERMISSIONS_START = () => ({
  type: "GET_USER_PERMISSIONS_START"
});

export const GET_USER_PERMISSIONS_SUCCESS = payload => ({
  type: "GET_USER_PERMISSIONS_SUCCESS",
  payload: payload
});

export const GET_USER_PERMISSIONS_ERROR = payload => ({
  type: "GET_USER_PERMISSIONS_ERROR",
  payload: payload
});

export const MUTATE_USER_START = () => ({
  type: "MUTATE_USER_START"
});

export const MUTATE_USER_SUCCESS = payload => ({
  type: "MUTATE_USER_SUCCESS",
  payload: payload
});

export const MUTATE_USER_ERROR = payload => ({
  type: "MUTATE_USER_ERROR",
  payload: payload
});

export const CLEAR_AUTH_ERROR = () => ({
  type: "CLEAR_AUTH_ERROR"
});

export const mutateUser = payload => {
  return dispatch => {
    dispatch(MUTATE_USER_START());
    UserMutation(payload)
      .then(d => {
        dispatch(MUTATE_USER_SUCCESS(d));
      })
      .catch(err => dispatch(MUTATE_USER_ERROR(err)));
  };
};

export const getUserPermissions = id => {
  return dispatch => {
    dispatch(GET_USER_PERMISSIONS_START());
    getUserPermissionsQuery(id)
      .then(d => dispatch(GET_USER_PERMISSIONS_SUCCESS(d)))
      .catch(err => dispatch(GET_USER_PERMISSIONS_ERROR(err.message)));
  };
};


export const getUserData = id => {
  return async dispatch => {
    dispatch(GET_USER_DATA_START());
    await getUserDataQuery(id)
      .then(d => {
        dispatch(GET_USER_DATA_SUCCESS(d.data.data.userAccounts[0]));
        dispatch(TOGGLE_LOGIN_MODAL_CLOSE());

      })
      .catch(err => {dispatch(GET_USER_DATA_ERROR(err.message));
      });

    dispatch(GET_USER_PERMISSIONS_START());
    getUserPermissionsQuery(id)
      .then(d => dispatch(GET_USER_PERMISSIONS_SUCCESS(d)))
      .catch(err => dispatch(GET_USER_PERMISSIONS_ERROR(err.message)));
  };
};

const UserMutation = async payload => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
    mutation{
      updateUserAccountMutation(input:{id:${payload.id} firstName:"${payload.firstName}" lastName:"${payload.lastName}" languageCl: "${payload.languageCl}"})
    }
    `
      },
      authorizationHeaders()
    );
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const userLogin = payload => {
  return dispatch => {
    dispatch(USER_LOGIN_START());
    UserLoginQuery(payload)
      .then(data => {
        Cookie.set("_session", data.data.data.login);
        dispatch(USER_LOGIN_SUCCESS(data.data.data.login));
        dispatch(USER_LOGIN_SET_TOKEN(data.data.data.login));
      })
      // .then(a => {
      //   dispatch(TOGGLE_LOGIN_MODAL_CLOSE());
      // })
      .catch(error => {
        dispatch(USER_LOGIN_ERROR("Invalid credentials"));
      });
  };
};

export const UserLoginQuery = async payload => {
  try {
    const data = await axios.post("/graphql/login", {
      query: `
    query {
      login(username:"${payload.username}",password:"${payload.password}")
     }
  `
    });
    return data;
  } catch (err) {
    alert("An error occured during login");
    return null;
  }
};

export const getUserPermissionsQuery = async id => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
        userPermissionsFromUser(id: ${id}){
          key
        }
        
        }
      `
      },
      authorizationHeaders()
    );
    return data.data.data.userPermissionsFromUser;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getUsers = () => {
  const data = axios.post(
    "/graphql",
    {
      query: `
        query{
            userAccounts(count:50){
              firstName
              lastName
              languageCl
              installationType
              
            }
           }
        `
    },
    authorizationHeaders()
  );
  return data;
};

export const getUserDataQuery = async id => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
    query{userAccounts(id: ${id}){
      id
      lastName
      firstName
      validFrom
      languageCl
      username
      role
      assignedRoles{name}
      
    }}`
      },
      authorizationHeaders()
    );
    return data;
  } catch (err) {
    toast.error("Error loading USER data");
    return {};
  }
};
