import React from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FormBody,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { mutateUser } from "../../../auth/actions/authActions";

const PreferencesAccountForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitUserMutation = values => {
    dispatch(mutateUser(values));
    window.location.reload();
  };

  const user = useSelector(state => state.auth.user);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: user ? user.id : "",
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
        languageCl: user ? user.languageCl : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        submitUserMutation(values);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <InputField
            type="text"
            id="firstName"
            label={t("preferences.FirstName")}
            value={values.firstName}
            onChange={handleChange}
            classes="form__group"
          />
          <InputField
            type="text"
            id="lastName"
            label={t("preferences.LastName")}
            value={values.lastName}
            onChange={handleChange}
            classes="form__group"
          />
          <SelectInputField
            type="text"
            id="languageCl"
            options={[
              { name: "", value: "" },
              {
                name: t("classifications.LANGUAGE.EN-GB"),
                value: "LANGUAGE.EN-GB"
              },
              {
                name: t("classifications.LANGUAGE.ET-EE"),
                value: "LANGUAGE.ET-EE"
              }
            ]}
            label={t("preferences.languageCl")}
            onChange={handleChange}
            value={values.languageCl}
            classes="form__group"
          />
          <button
            className="btn btn--primary u-margin-top-tiny"
            type="submit"
            onClick={handleSubmit}
          >
            {t("preferences.Save")}
          </button>
        </FormBody>
      )}
    </Formik>
  );
};

export default PreferencesAccountForm;
