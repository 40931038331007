import React, { useRef } from "react";

export const ToggleSwitch = ({ value }) => {
  return (
    <label className="toggleSwitch__switch">
      <input type="checkbox" className="toggleSwitch__input" checked={value} />
      <span className="toggleSwitch__slider toggleSwitch__slider--round" />
    </label>
  );
};

export const ToggleSwitchWLabel = ({
  value,
  label,
  handleOnChange,
  optionValue,
  classes
}) => {
  const inputRef = useRef();

  return (
    <div className={"toggleSwitch " + classes || ""}>
      <label
        htmlFor="toggleSwitch_checkBox"
        className="toggleSwitch--small__label"
      >
        {label}
      </label>
      <label className="toggleSwitch--small__switch">
        <input
          ref={inputRef}
          type="checkbox"
          id="toggleSwitch_checkbox"
          className="toggleSwitch--small__input"
          checked={value}
          onChange={e => handleOnChange(e.target.checked)}
        />
        <span className="toggleSwitch--small__slider toggleSwitch--small__slider--round" />
      </label>
    </div>
  );
};
