import React from "react";
import View from "../../common/view";
import CustomerDepositMainSection from "./layout/main/customerDepositMainSection";
import CustomerDepositSearchSection from "./layout/search/customerDepositSearchSection";

const CustomerDepositView = () => {
  return (
    <View
      MainSection={CustomerDepositMainSection}
      SearchSection={CustomerDepositSearchSection}
    />
  );
};

export default CustomerDepositView;
