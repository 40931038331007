import React from "react";
import View from "../../common/view";
import VatDocumentsMainSection from "./layout/main/vatDocumentsMainSection";
import VatDocumentsSearchSection from "./layout/search/vatDocumentsSearchSection";

const VatDocumentsView = () => {
  return (
    <View
      MainSection={VatDocumentsMainSection}
      SearchSection={VatDocumentsSearchSection}
    />
  );
};

export default VatDocumentsView;
