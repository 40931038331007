import React, { useEffect, useState, forwardRef } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../../common/form/form";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import { CUSTOMER_ACCOUNT } from "../../../../common/redux/types/viewTypes";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";

const CustomerAccountVerticalSearchForm = forwardRef(
  ({ handleSearch, query, t, classificationsLoading }, ref) => {
    const classifications = useSelector(state => state.layout.classifications);
    const { selectedAutocompleteValue } = useSearchState(customerAccount);
    const [dropdowns, setDropdowns] = useState({ entry_source_types: [] });
    useEffect(() => {
      if (!classificationsLoading) {
        const entrySourceTypes = [
          { name: "", value: "" },
          ...classifications.entry_source_types.map(item => {
            return Object.assign(
              {},
              {
                name: t(
                  `classifications.ENTRY_SOURCE_TYPE.${item.name.split(".")[1]}`
                ),
                value: item.value
              }
            );
          })
        ];
        setDropdowns(
          Object.assign({}, { entry_source_types: entrySourceTypes })
        );
      }
    }, [classificationsLoading, classifications, t]);

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          client: selectedAutocompleteValue || "",
          period_start: query.period_start || "",
          period_end: query.period_end || "",
          transaction_entry: query.transactionEntry || "",
          has_entry_balance: query.hasEntryBalance ? query.hasEntryBalance : "",
          money_received: query.isMoneyReceived ? query.isMoneyReceived : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSearch,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="customerAccountSearchCustomer"
              name="client"
              value={values.client}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("customerAccount.details.Client")}
              autocomplete="off"
              data-lpignore="true"
              actionType={CUSTOMER_ACCOUNT}
              selectorType={customerAccount}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerAccountSearchPeriodStart"
              name="period_start"
              value={values.period_start}
              handleChange={setFieldValue}
              label={t("customerAccount.PeriodStart")}
            />
            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerAccountSearchPeriodEnd"
              name="period_end"
              value={values.period_end}
              handleChange={setFieldValue}
              label={t("customerAccount.PeriodEnd")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              options={
                dropdowns.entry_source_types.length
                  ? dropdowns.entry_source_types
                  : []
              }
              id="customerAccountSearchTransactionEntry"
              name="transaction_entry"
              value={values.transaction_entry}
              onChange={handleChange}
              label={t("customerAccount.TransactionEntry")}
            />

            <SelectInputField
              type="text"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: true },
                { name: t("common.ReplyNo"), value: false }
              ]}
              classes="form__group-stack"
              id="customerAccountSearchHasEntryBalance"
              name="has_entry_balance"
              value={values.hasEntryBalance}
              onChange={handleChange}
              label={t("customerAccount.FilterHasEntryBalance")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customerAccountSearchIsMoneyReceived"
              name="money_received"
              value={values.money_received}
              onChange={handleChange}
              label={t("customerAccount.IsMoneyReceived")}
            />
            <InputField
              type="number"
              classes="form__group-stack"
              id="count"
              value={values.count}
              onChange={handleChange}
              label={t("customerAccount.ResultsNumber")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default CustomerAccountVerticalSearchForm;
