import _ from "lodash";

export const getCustomerFilters = (payload, t) => {
  if (!_.isEmpty(payload)) {
    if (payload.isActive !== "") {
      return Object.assign(
        {},
        {
          ...payload,
          is_active: payload.is_active === "true"
            ? t("common.ReplyYes")
            : t("common.ReplyNo")
        }
      );
    }
  }
  return payload;
};
