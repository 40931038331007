import React from "react";
import View from "../../common/view";
import CustomerInvoiceMainSection from "./layout/main/customerInvoiceMainSection";
import CustomerInvoiceSearchSection from "./layout/search/customerInvoiceSearchSection";

const CustomerInvoiceView = () => {
  return (
    <View
      MainSection={CustomerInvoiceMainSection}
      SearchSection={CustomerInvoiceSearchSection}
    ></View>
  );
};

export default CustomerInvoiceView;
