import React from "react";
import Table from "../common/table";
import { useTranslation } from "react-i18next";
import useDataState from "../common/redux/hooks/useDataState";
import { discount } from "../common/redux/types/selectorTypes";
import useTableActionDispatchers from "../common/redux/actions/table/hooks/useTableActionDispatchers";
import useTableState from "../common/redux/hooks/useTableState";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { DISCOUNT } from "../common/redux/types/viewTypes";
import { TableResultData } from "../common/tableFooter";

const DiscountTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(discount);
  const { handleRowSelect } = useTableActionDispatchers(DISCOUNT);
  const { height } = useTableState(discount);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(DISCOUNT);

  const DiscountTrans = "discount.";
  const columns = [
    {
      id: "addToInvoice",
      Header: t(DiscountTrans + "AddToInvoice"),
      accessor: d =>
        d.addToInvoice ? t("common.ReplyYes") : t("common.ReplyNo"),
      maxWidth: 95
    },
    {
      id: "customer",
      Header: t(DiscountTrans + "CustomerName"),
      accessor: d => (d.customer ? d.customer[0].name : "")
    },
    {
      id: "serviceProvider",
      Header: t(DiscountTrans + "ServiceProviderName"),
      accessor: d => (d.serviceProvider ? d.serviceProvider[0].name : "")
    },
    {
      id: "basedOnInvoice",
      Header: t(DiscountTrans + "InvoiceNo"),
      accessor: d => (d.basedOnInvoice ? d.basedOnInvoice[0].invoiceNo : "")
    },
    {
      Header: t(DiscountTrans + "DiscountPeriod"),
      accessor: "discountPeriod"
    },
    {
      id: "country",
      Header: t(DiscountTrans + "CountryName"),
      accessor: d =>
        d.customer ? d.customer[0].legalAddress[0].country[0].code : ""
    },
    {
      id: "partnerSalesPoint",
      Header: t(DiscountTrans + "SalesPointName"),
      accessor: d =>
        d.partnerSalesPoint ? d.partnerSalesPoint[0].defaultName : ""
    },
    {
      id: "product",
      Header: t(DiscountTrans + "ProductDefaultName"),
      accessor: d => (d.product ? d.product[0].defaultName : "")
    },
    {
      id: "reseller",
      Header: t(DiscountTrans + "ResellerName"),
      accessor: d => d.reseller || ""
    },
    {
      Header: t(DiscountTrans + "Quantity"),
      accessor: "quantity"
    },
    {
      Header: t(DiscountTrans + "UnitDiscount"),
      accessor: "unitDiscount"
    },
    {
      Header: t(DiscountTrans + "Netto"),
      accessor: "netto",
      maxWidth: 50
    },
    {
      Header: t(DiscountTrans + "Vat"),
      accessor: "vat"
    },
    {
      id: "issuedForInvoice",
      Header: t(DiscountTrans + "IssuedForInvoice"),
      accessor: d => (d.issuedForInvoice ? d.issuedForInvoice[0].invoiceNo : "")
    }
  ];
  return (
    <React.Fragment>
      <Table
        columns={columns}
        isLoading={isLoading}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        data={items || []}
        height={height}
        classes="u-margin-bottom-small"
      />
      <TableResultData count={items.length} />
    </React.Fragment>
  );
};

export default DiscountTable;
