import React from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../common/table";
import {TableResultData} from "../../../common/tableFooter";

const VatDocumentsApplicationPaymentDataTable = () => {
  const { t } = useTranslation();
  const tableSpecs = { height: 200 };
  const getColumns = () => {
    const columns = [
      {
        id: "country",
        Header: t("vat.documents.Country"),
        flexGrow: 1
      },

      {
        Header: t("vat.documents.VatOrig"),
        accessor: "vatOrig",
        flexBasis: "20%"
      },
      {
        Header: t("vat.documents.VatEur"),
        accessor: "vatEur",
        flexBasis: "20%"
      },

      {
        Header: t("vat.documents.Unpaid"),
        accessor: "unpaid"
      },
      {
        Header: t("vat.documents.MinQuarter"),
        accessor: "minQuarter"
      },
      {
        Header: t("vat.documents.MinYear"),
        accessor: "minYear"
      }
    ];
    return columns;
  };

  return (
    <React.Fragment>
      <Table
        columns={getColumns()}
        data={[]}
        isLoading={false}
        onRowSelect={() => {}}
        selectedRow={{}}
        tableSpecs={tableSpecs}
      />
      <TableResultData count={0} />
    </React.Fragment>
  );
};

export default VatDocumentsApplicationPaymentDataTable;
