export const TOGGLE_CUSTOMER_ACCOUNT_TABLE = () => ({
  type: "TOGGLE_CUSTOMER_ACCOUNT_TABLE"
});

export const TOGGLE_CUSTOMER_ACCOUNT_EXPANDER_VIEW = () => ({
  type: "TOGGLE_CUSTOMER_ACCOUNT_EXPANDER_VIEW"
});
export const TOGGLE_CUSTOMER_ACCOUNT_SEARCH = () => ({
  type: "TOGGLE_CUSTOMER_ACCOUNT_SEARCH"
});
export const SET_CUSTOMER_ACCOUNT_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMER_ACCOUNT_TABLE_HEIGHT",
  payload: payload
});
