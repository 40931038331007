import React from "react";
import View from "../common/view";
import CardMainSection from "./layout/main/cardMainSection";
import CardSearchSection from "./layout/search/cardSearchSection";

const CardView = () => {
  return (
    <View MainSection={CardMainSection} SearchSection={CardSearchSection} />
  );
};
export default CardView;
