import {useSelector} from "react-redux";

const useTableState = selectorName => {
  const selectedRowsState = useSelector(
    state => state[selectorName].table.selectedRowsState
  );

  const height = useSelector(state => state[selectorName].table.height);

  return { selectedRowsState, height };
};

export default useTableState;
