import { useSelector } from "react-redux";

const useAppClassifications = () => {
  const customerInvoicesStatusHistoryTypes = useSelector(
    state => state.layout.classifications.customer_invoice_status_types
  );
  const countries = useSelector(
    state => state.layout.classifications.countries
  );

  const cardTypes = useSelector(state => state.layout.classifications.card_types);
  const cardStatuses = useSelector(state => state.layout.classifications.card_status);

  return { customerInvoicesStatusHistoryTypes, countries, cardTypes, cardStatuses };
};

export default useAppClassifications;
