import React from "react";
import View from "../common/view";
import TasksMainSection from "./layout/main/tasksMainSection";
import TasksSearchSection from "./layout/search/tasksSearchSection";

const TasksView = () => {
  return (
    <View MainSection={TasksMainSection} SearchSection={TasksSearchSection} />
  );
};

export default TasksView;
