import React, { useState } from "react";
import Table from "../common/table";
import { Loading } from "../common/loading";
import { list } from "postcss";
import { toast } from "react-toastify";

const TaskList = () => {
  const [listOpen, setListOpen] = useState(false);
  const data = [
    {
      id: "1",
      name: "Kliendi arve genereerimine",
      createdOn: "16.01.2020 15:12:23",
      endsOn: "16.01.2020 16:21:15",
      progress: "100%",
      remove: "remove callback"
    }
  ];

  const selectedRow = { id: "1" };

  const columns = [
    {
      Header: "Nimi",
      width: 200,
      accessor: d => d.name || ""
    },
    {
      Header: "Käivitamise aeg",
      accessor: d => d.createdOn || ""
    },
    {
      Header: "Lõpetamise aeg",
      accessor: d => d.endsOn || ""
    },
    {
      Header: "Progress",
      width: 70,
      accessor: d => d.progress || "",
      cellAlign: "center"
    },
    {
      Header: "Eemalda",
      width: 110,
      accessor: d => d.remove || ""
    }
  ];

  return (
    // <div className="tasks__container">
    //   <div className="tasks__task-list">
    //     <h3 style={{ marginBottom: "0.5rem" }}>
    //       Aktiivsete taskide nimekiri (Viimane tund)
    //     </h3>
    //
    //     <Table
    //       columns={columns}
    //       data={data}
    //       height={200}
    //       selectedRow={selectedRow}
    //       loadingComponent={Loading}
    //     />
    //   </div>
    // </div>

    <div className="tasks" tabIndex="0" onBlur={() => setListOpen(false)}>
      <div
        className="tasks__main-item"
        onClick={() => setListOpen(!listOpen)}
        style={{ backgroundColor: listOpen ? "#222222" : "" }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/icons/list.svg"}
          className="tasks__main-item-icon"
          alt="task_icon"
        />
        <p>Töötavad taskid puuduvad</p>
      </div>
      <div
        className="tasks__task-list"
        style={{
          height: listOpen ? "10rem" : 0,
          opacity: listOpen ? 1 : 0,
          display: listOpen ? "inline-block" : "none"
        }}
      >
        {/*<h3 style={{ marginBottom: "0.5rem" }}>*/}
        {/*  Aktiivsete taskide nimekiri (Viimane tund)*/}
        {/*</h3>*/}

        {/*<Table*/}
        {/*  columns={columns}*/}
        {/*  data={data}*/}
        {/*  height={200}*/}
        {/*  selectedRow={selectedRow}*/}
        {/*  loadingComponent={Loading}*/}
        {/*/>*/}
        <table>
          <thead>
            <tr>
              <th>Nimi</th>
              <th>Käivitamise aeg</th>
              <th>Lõpetamise aeg</th>
              <th>Progress</th>
              <th>Eemalda</th>
            </tr>
          </thead>
          <tbody>
            <tr
              onClick={() =>
                toast.success("Task 'Kliendi arvete genereerimine' completed")
              }
            >
              <td>Kliendi arve genereerimine</td>
              <td>16.01.2020 13:23:52</td>
              <td>16.01.2020 13:59:22</td>
              <td>100%</td>
              <td>X</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskList;
