import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../common/table";
import { SELECT_FACTORING_DEBTOR_ACC_CLEARING_ROW } from "../actions/factoringDebtorAccountActions";

const FactoringDebtorAccClearingTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRowMain = useSelector(
    state => state.factoringDebtorAccount.data.selectedItem
  );

  const handleRowSelect = payload => {
    dispatch(SELECT_FACTORING_DEBTOR_ACC_CLEARING_ROW(payload));
  };

  const selectedRow = useSelector(
    state => state.factoringDebtorAccount.tabs.clearing.selectedItem
  );
  const getColumns = () => {
    const columns = [
      {
        Header: t("customerAccount.clearing.ClearingDocumentNo"),
        accessor: "customer_clearing_document_id"
      },
      {
        Header: t("customerAccount.clearing.AdditionalInfo"),
        accessor: "additional_info"
      },
      {
        Header: t("customerAccount.clearing.Time"),
        accessor: "entry_time"
      },
      {
        Header: t("customerAccount.clearing.Amount"),
        accessor: "sum"
      },
      {
        Header: t("customerAccount.clearing.EntryOriginalAmount"),
        accessor: "balance"
      },
      {
        Header: t("customerAccount.clearing.EntryDescription"),
        accessor: "description"
      }
    ];
    return columns;
  };

  return (
    <Table
      data={selectedRowMain.relatedCustomerClearingDocumentItems || []}
      columns={getColumns()}
      onRowSelect={handleRowSelect}
      selectedRow={selectedRow}
      height={200}
    />
  );
};
export default FactoringDebtorAccClearingTable;
