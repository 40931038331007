import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "../../../common/buttons/button";
import {
  FormBody,
  InputField,
  FormCol,
  FormFlexItem,
  TextareaInputField,
  SelectInputField
} from "../../../common/form/form";
import FactoringDebtorContactPersonsTable from "./factoringDebtorContactPersonsTable";

const FactoringDebtorContactPersonsForm = () => {
  const { t } = useTranslation();
  const data = useSelector(
    state => state.factoringDebtor.tabs.contactInformation.selectedItem
  );
  const languages = useSelector(
    state => state.layout.classifications.languages
  );
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: data.name || "",
          contactPersonTypeCl: data.contactPersonTypeCl || "",
          languageCl: data.languageCl || "",
          personalCode: data.personalCode || "",
          isSignatory: data.hasSignatureRight ? data.hasSignatureRight : "",
          phone: data.phone || "",
          email: data.email || "",
          additionalInfo: data.additionalInfo || "",
          documentNo: data.documentNo || "",
          identificationDate: data.identificationDate || "",
          documentIssueDate: data.documentIssueDate || "",
          documentExpireDate: data.documentExpireDate || "",
          isPrimary: data.isPrimary ? data.isPrimary : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol className="u-margin-right-small">
              <FactoringDebtorContactPersonsTable />
            </FormCol>
            <FormCol>
              <h4 className="heading__quarternary">
                {t("customer.customerContactData.HeaderInformation")}
              </h4>
              <InputField
                type="text"
                id="name"
                classes="form__group"
                value={values.name}
                label={t("customer.customerContactData.PersonName")}
                onChange={handleChange}
              />
              <FormFlexItem direction="row">
                <InputField
                  type="text"
                  id="contactPersonTypeCl"
                  classes="form__group"
                  value={
                    values.contactPersonTypeCl
                      ? t(
                          `classifications.CONTACT_PERSON_TYPE.${
                            values.contactPersonTypeCl.split(".")[1]
                          }`
                        )
                      : ""
                  }
                  label={t("customer.customerContactData.Position")}
                  onChange={handleChange}
                />
                <SelectInputField
                  type="text"
                  id="personLanguage"
                  classes="form__group u-margin-left-small"
                  options={[
                    { name: "", value: "" },
                    ...languages.map(item => {
                      return Object.assign(
                        {},
                        {
                          name: t(
                            `classifications.LANGUAGE.${
                              item.name.split(".")[1]
                            }`
                          ),
                          value: item.value
                        }
                      );
                    })
                  ]}
                  value={values.languageCl}
                  label={t("customer.customerContactData.PersonLanguage")}
                  onChange={handleChange}
                />
              </FormFlexItem>
              <FormFlexItem>
                <InputField
                  type="text"
                  id="personalCode"
                  classes="form__group"
                  value={values.personalCode}
                  label={t("customer.customerContactData.PersonPersonalCode")}
                  onChange={handleChange}
                />
                <SelectInputField
                  id="signatory"
                  options={[
                    { name: "", value: "" },
                    { name: t("common.ReplyYes"), value: true },
                    { name: t("common.ReplyNo"), value: false }
                  ]}
                  classes="form__group u-margin-left-small"
                  value={values.isSignatory}
                  label={t("customer.customerContactData.PersonSignatory")}
                  onChange={handleChange}
                />
              </FormFlexItem>
              <InputField
                type="text"
                id="phone"
                classes="form__group"
                value={values.phone}
                label={t("customer.customerContactData.PersonPhone")}
                onChange={handleChange}
              />
              <InputField
                type="text"
                id="email"
                classes="form__group"
                value={values.email}
                label={t("customer.customerContactData.PersonEmail")}
                onChange={handleChange}
              />
              <TextareaInputField
                id="additionalInfo"
                value={values.additionalInfo}
                onChange={handleChange}
                classes="form__group"
                label={t("customer.customerContactData.PersonAdditionalInfo")}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quarternary">
                {t("customer.customerContactData.Identifying")}
              </h4>
              <InputField
                type="text"
                id="documentNo"
                value={values.documentNo}
                classes="form__group"
                onChange={handleChange}
                label={t("customer.customerContactData.DocumentNo")}
              />
              <InputField
                type="date"
                id="identificationDate"
                classes="form__group"
                value={values.identificationDate}
                onChange={handleChange}
                label={t("customer.customerContactData.IdentificationDate")}
              />
              <InputField
                type="date"
                id="documentIssueDate"
                classes="form__group"
                value={values.documentIssueDate}
                onChange={handleChange}
                label={t("customer.customerContactData.IssueDate")}
              />
              <InputField
                type="date"
                id="documentExpireDate"
                value={values.documentExpireDate}
                classes="form__group"
                onChange={handleChange}
                label={t("customer.customerContactData.ExpireDate")}
              />
              <Button
                btnStyle="primary"
                size="medium"
                classes="btn--footer"
                label={t("customer.customerContactData.BtnOpenIdentification")}
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorContactPersonsForm;
