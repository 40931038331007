import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../common/table";

class BrokerInvoicesTable extends Component {
  state = {
    height: 250,
    data: [
      {
        customer: "PORT ONE",
        addToInvoice: "yes",
        regon: "2224141223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "2 381.13"
      },
      {
        customer: "KOBE VEDU OY",
        addToInvoice: "yes",
        regon: "2224141223",
        country: "Estonia",
        p1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "2 381.13",
        debt: "1 423"
      },
      {
        customer: "VK TRANS",
        addToInvoice: "yes",
        regon: "22241545223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "2 381.13"
      },
      {
        customer: "KALEV TERA REISID",
        addToInvoice: "yes",
        regon: "2224141223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "2 221.13",
        debt: "5 293.22"
      },
      {
        customer: "NARVA JK",
        addToInvoice: "yes",
        regon: "2224141223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "381.13"
      },
      {
        customer: "LUKTOMA UAB",
        addToInvoice: "yes",
        regon: "2224141223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "1 324.13"
      },
      {
        customer: "POLTRANS",
        addToInvoice: "yes",
        regon: "22241323223",
        country: "Estonia",
        P1cntr: "1",
        otherCntr: "0",
        validFrom: "25.06.19",
        validThru: "25.06.22",
        balance: "2 381.13",
        debt: "10 239.23"
      }
    ],
    selected: {},
    selectAll: 0
  };

  getColumns = () => {
    const { t } = this.props;
    const BrokerInvoiceTableTrans = "broker.invoices.";
    const columns = [
      {
        Header: t(BrokerInvoiceTableTrans + "AddToInvoice"),
        accessor: "addToInvoice"
      },

      {
        Header: t(BrokerInvoiceTableTrans + "Customer"),
        accessor: "customer" // String-based value accessors!
      },
      {
        Header: t(BrokerInvoiceTableTrans + "InvoiceNo"),
        accessor: "invoice_no"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "InvoiceDate"),
        accessor: "invoice_date"
      },

      {
        Header: t(BrokerInvoiceTableTrans + "InvoiceType"),
        accessor: "invoice_type"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "Partner"),
        accessor: "partner"
      },

      {
        Header: t(BrokerInvoiceTableTrans + "FilterReseller"),
        accessor: "reseller"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "Total"),
        accessor: "total"
      },
      {
        Header: t(BrokerInvoiceTableTrans + "CustomerInvoiceNo"),
        accessor: "customer_invoice_no"
      }
    ];

    return columns;
  };

  render() {
    const tableSpecs = { height: 300 };
    return (
      <Table
        columns={this.getColumns()}
        tableSpecs={tableSpecs}
        data={[]}
        onRowSelect={this.props.onCustomerSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCustomerSelect: payload => {
      const action = { type: "CUSTOMERSELECT", payload: payload };
      dispatch(action);
    },
    onCustomerRowSelect: payload => {
      const action = { type: "CUSTROWSELECT", payload: payload };
      dispatch(action);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerInvoicesTable);
