import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../common/section";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useCardConsts from "../../hooks/useCardConsts";
import useCardActions from "../../hooks/useCardActions";
import CardButtons from "./cardButtons";
import CardTable from "../../cardTable";
import useDataState from "../../../common/redux/hooks/useDataState";
import { card } from "../../../common/redux/types/selectorTypes";
import useSearchState from "../../../common/redux/hooks/useSearchState";
import useLayoutState from "../../../common/redux/hooks/useLayoutState";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CARD } from "../../../common/redux/types/viewTypes";
import useCardFilters from "../../hooks/useCardFilters";

const CardMainSection = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const {
    handleTableToggle,
    handleSearchToggle,
    handleExpandersToggle
  } = useLayoutActionDispatchers(CARD);
  const { selectedItem } = useDataState(card);
  const { searchQuery } = useSearchState(card);
  const { filterLabels, tabs } = useCardConsts();
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(card);
  const { handleSearch, handleRowSelect } = useCardActions();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("card.Card")}
        getFilters={useCardFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={CardTable}
        ButtonsComponent={CardButtons}
        shortcutsEnabled={false}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default CardMainSection;
