import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import ContractViewDetailsForm from "../details/forms/contractViewDetailsForm";
import useContractState from "./useContractState";
import ContractViewStatusHistoryTable from "../status_history/contractViewStatusHistoryTable";
import ContractFactoringBankAccountTypesForm
  from "../factoring_bank_account_types/contractFactoringBankAccountTypesTable";
import ContractViewFactoringBankAccount from "../factoring_bank_account/contractViewFactoringBankAccount";
import FactoringDebtorsForm from "../factoring_debtors/factoringDebtorsForm";
import ContractViewShowProductCostTable from "../showProductCost/contractSectionShowProductCostTable";
import useDataState from "../../common/redux/hooks/useDataState";
import {
  bankAccountTypes,
  contract,
  details,
  factoringBankAccount,
  factoringDebtors,
  productCost,
  statusHistory
} from "../../common/redux/types/selectorTypes";
import {
  BANK_ACCOUNT_TYPES,
  CONTRACT,
  DETAILS,
  FACTORING_BANK_ACCOUNT,
  FACTORING_DEBTORS,
  PRODUCT_COST,
  STATUS_HISTORY
} from "../../common/redux/types/viewTypes";

const useContractConsts = () => {
  const { t } = useTranslation();
  const {
    resellers,
    contractTypes,
    contractStatuses,
    serviceProviders,
  } = useContractState();

  const {selectedItem} = useDataState(contract);

  const filterLabels = {
    resultCount: t("contract.FilterRowCount"),
    customerName: t("contract.FilterContractCustomer"),
    customerNo: t("contract.FilterCustomerNo"),
    contractType: t("contract.FilterContractType"),
    contractNo: t("contract.FilterContractNo"),
    contractStatus: t("contract.FilterContractStatus"),
    contractExpiresFrom: t("contract.FilterContractExpiresFrom"),
    contractExpiresUntil: t("contract.FilterContractExpiresUntil"),
    contractReseller: t("contract.FilterContractReseller"),
    contractServiceProvicer: t("contract.FilterContractServiceProvicer"),
    customerCountry: t("contract.FilterCustomerCountry"),
    salesagent: t("contract.FilterSalesAgent"),
    contractIsActive: t("contract.FilterIsActive")
  };

  const tabs = useMemo(
    () => [
      {
        label: t("contract.ContractDetailData"),
        component: (
          <ContractViewDetailsForm
            t={t}
            data={selectedItem}
            resellers={resellers}
            contractTypes={contractTypes}
            contractStatus={contractStatuses}
            serviceProviders={serviceProviders}
          />
        ),
        viewSelector: contract,
        tabSelector: details,
        viewType: CONTRACT,
        tabType: DETAILS

      },
      {
        label: t("contract.ContractStatusHistory"),
        component: (
          <ContractViewStatusHistoryTable
            t={t}
            data={selectedItem.contractStatusHistory || []}
          />
        ),
        viewSelector: contract,
        tabSelector: statusHistory,
        viewType: CONTRACT,
        tabType: STATUS_HISTORY
      },
      {
        label: t("contract.ContractShowProductCost"),
        component: <ContractViewShowProductCostTable data={selectedItem.productCostOverview}/>,
        viewSelector: contract,
        tabSelector: productCost,
        viewType: CONTRACT,
        tabType: PRODUCT_COST
      },
      {
        label: t("contract.ContractVatBankAccountData"),
        component: <ContractFactoringBankAccountTypesForm t={t} />,
        viewSelector: contract,
        tabSelector: bankAccountTypes,
        viewType: CONTRACT,
        tabType: BANK_ACCOUNT_TYPES
      },
      {
        label: t("contract.ContractFactoringBankAccount"),
        component: <ContractViewFactoringBankAccount />,
        viewSelector: contract,
        tabSelector: factoringBankAccount,
        viewType: CONTRACT,
        tabType: FACTORING_BANK_ACCOUNT
      },
      {
        label: t("contract.ContractFactoringDebtor"),
        component: <FactoringDebtorsForm />,
        viewSelector: contract,
        tabSelector: factoringDebtors,
        viewType: CONTRACT,
        tabType: FACTORING_DEBTORS
      }
    ],
    [
      contractStatuses,
      contractTypes,
      resellers,
      selectedItem,
      serviceProviders,
      t
    ]
  );

  return { filterLabels, tabs };
};

export default useContractConsts;
