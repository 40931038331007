import {useCallback} from 'react';
import {useDispatch} from "react-redux";
import {CLEAR_TAB_SELECTED_ITEM} from "./tabActions";


const useTabActionDispatchers = (viewType, tabType) => {
    const dispatch = useDispatch();


    const handleTabSelectedItemClear = useCallback(() => {
        dispatch(CLEAR_TAB_SELECTED_ITEM(viewType, tabType))
    }, [dispatch, viewType, tabType]);


    return {handleTabSelectedItemClear}
};


export default useTabActionDispatchers;