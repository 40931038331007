import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import useServiceLayoutState from "../../hooks/layout/useServiceLayoutState";
import ServiceSearchVerticalForm from "../../search/forms/serviceSearchVerticalForm";
import { useTranslation } from "react-i18next";
import useServiceState from "../../hooks/useServiceState";
import useServiceActions from "../../hooks/useServiceActions";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import {SERVICE} from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import {service} from "../../../common/redux/types/selectorTypes";

const ServiceSearchSection = () => {
  const { searchOpen } = useServiceLayoutState();
  const { searchQuery } = useServiceState();
  const {isLoading} = useDataState(service);
  const {handleSearchToggle} = useLayoutActionDispatchers(SERVICE);
  const { handleSearch } = useServiceActions();
  const { t } = useTranslation();
  const formRef = useRef();
  return (
    <SearchSection isOpen={searchOpen} formRef={formRef} onSearchToggle={handleSearchToggle} dataLoading={isLoading}>
      <ServiceSearchVerticalForm
        ref={formRef}
        t={t}
        query={searchQuery}
        handleSearch={handleSearch}
      />
    </SearchSection>
  );
};

export default ServiceSearchSection;
