import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";

const FactoringDebtorCommentsTable = () => {
  const selectedRow = useSelector(
    state => state.factoringDebtor.tabs.comments.selectedItem
  );
  const { t } = useTranslation();

  const getColumns = () => {
    const columns = [
      {
        Header: t("customer.customerComments.HeaderModifiedOn"),
        accessor: "lastModified"
      },
      {
        Header: t("customer.customerComments.HeaderModifiedByUser"),
        accessor: "modifier"
      },
      {
        Header: t("customer.customerComments.HeaderComment"),
        accesor: "comment"
      }
    ];

    return columns;
  };

  return (
    <Table
      data={[]}
      columns={getColumns()}
      selectedRow={selectedRow}
      height={200}
    />
  );
};
export default FactoringDebtorCommentsTable;
