import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useTableState from "../common/redux/hooks/useTableState";
import { customers } from "../common/redux/types/selectorTypes";
import useDataState from "../common/redux/hooks/useDataState";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { CUSTOMERS } from "../common/redux/types/viewTypes";
import useTableActionDispatchers from "../common/redux/actions/table/hooks/useTableActionDispatchers";
import useDataActionsDispatchers from "../common/redux/actions/data/hooks/useDataActionsDispatchers";
import Table from "../common/table";
import { Loading } from "../common/loading";
import { TableResultData } from "../common/tableFooter";

const TasksTable = ({
  removeItem,
  onRowSelect,
  onToggleRow,
  tableRef,
  tableVisible
}) => {
  const { t } = useTranslation();
  const { height } = useTableState(customers);
  const { items, selectedItem, selectedRows, isLoading } = useDataState(
    customers
  );

  const mockData = [
    {
      id: 0,
      name: "Mock genereerimine",
      initTime: "18.01.2020 11:52:23",
      terminationTime: "18.01.2020 12:01:12",
      result: "Edukas",
      status: "Lõpetatud",
      user: "Test Kasutaja",
      color: "#30bb30"
    },
    {
      id: 1,
      name: "Mock genereerimine",
      initTime: "18.01.2020 11:52:23",
      terminationTime: "18.01.2020 12:01:12",
      result: "Viga",
      status: "Lõpetatud",
      user: "Test Kasutaja",
      color: "red"
    }
  ];

  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CUSTOMERS);
  const { handleRowSelect } = useTableActionDispatchers(CUSTOMERS);
  const { selectedRowsState } = useTableState(customers);
  const onSetSelectedRowsState = useCallback(payload => {}, []);

  const { handleSetSelectedRowsState } = useDataActionsDispatchers(CUSTOMERS);

  const TranslationNS = "tasks.";

  const columns = useMemo(
    () => [
      {
        Header: t(TranslationNS + "FilterName"),
        width: 100,
        accessor: d => d.name || ""
      },

      {
        Header: t(TranslationNS + "InitTime"),
        accessor: "initTime"
      },
      {
        accessor: "terminationTime",
        Header: t(TranslationNS + "TerminationTime"),
        width: 85
      },
      {
        accessor: "result",
        Header: t(TranslationNS + "Result"),
        width: 80
      },

      {
        Header: t(TranslationNS + "Status"),
        width: 80,
        accessor: "status"
      },
      {
        accessor: "user",
        Header: t(TranslationNS + "User"),
        width: 80
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onSetSelectedRowsState]
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={mockData}
        height={height}
        setTableHeight={handleTableHeightSet}
        setUserTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRows={selectedRows}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={handleSetSelectedRowsState}
        tableRef={tableRef}
        onToggleRow={onToggleRow}
      />
      <TableResultData
        count={items.length}
        enabled={false}
        selectedRows={selectedRows}
        onRowSelect={onRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={removeItem}
        tableRef={tableRef}
      />
    </React.Fragment>
  );
};

export default TasksTable;
