import {useSelector} from "react-redux";

const useTabState = (viewName, tabName) => {
  const data = useSelector(state => state[viewName].tabs[tabName].data);
  const table = useSelector(state => state[viewName].tabs[tabName].table);
  const expanded = useSelector(state => state[viewName].tabs[tabName].expanded);
  const selectedItem = useSelector(
    state => state[viewName].tabs[tabName].selectedItem
  );

  return { data, table, selectedItem, expanded };
};

export default useTabState;
