import React, { useMemo } from "react";
import { card, statusHistory } from "../../common/redux/types/selectorTypes";
import { CARD, STATUS_HISTORY } from "../../common/redux/types/viewTypes";
import Table from "../../common/table";
import useTabTableActionDispatchers from "../../common/redux/actions/table/useTabTableActionDispatchers";
import useTabState from "../../common/redux/hooks/useTabState";

const CardStatusHistoryTable = ({ data, t }) => {
  const { table, selectedItem } = useTabState(card, statusHistory);
  const { handleRowSelect } = useTabTableActionDispatchers(
    CARD,
    STATUS_HISTORY
  );
  const columns = useMemo(
    () => [
      {
        id: "statusCl",
        Header: t("card.CardStatusHistory"),
        accessor: d => t(`classifications.${d.statusCl}`) || "",
        width: 500
      },

      {
        id: "createdOn",
        Header: "Date",
        accessor: d => d.createdOn || ""
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        data={data.cardChipStatusHistory || []}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        height={table.height}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default CardStatusHistoryTable;
