import React from "react";

export const Loading = ({ loading }) => {
  return loading ? (
    <div className="loading">
      <img
        className="loading__spinner"
        src={process.env.PUBLIC_URL + "/assets/spinners/tail-spin.svg"}
        alt="loading_icon"
      />
    </div>
  ) : null;
};

export const TablePlaceholder = ({ label }) => {
  return (
    <div className="loading__table-placeholder">
      <h2 className="heading__secondary">{label}</h2>
    </div>
  );
};

export const TableLoading = () => {
  return (
    <div className="loading__table">
      <img
        className="loading__table--spinner"
        src={process.env.PUBLIC_URL + "/assets/icons/loading_spinner.svg"}
        alt="page_loading_icon"
      ></img>
    </div>
  );
};

export const PageLoading = () => {
  return (
    <div className="loading">
      <img
        className="loading__spinner"
        style={{ height: 100, width: 200 }}
        src={process.env.PUBLIC_URL + "/assets/icons/loading_spinner.svg"}
        alt="page_loading_icon"
      ></img>
    </div>
  );
};
