import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import usePartnerLayoutState from "../../hooks/layout/usePartnerLayoutState";
import PartnerVerticalSearchForm from "../../search/forms/partnerVerticalSearchForm";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { PARTNER } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { partner } from "../../../common/redux/types/selectorTypes";

const PartnerSearchSection = () => {
  const { searchOpen } = usePartnerLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(PARTNER);
  const { isLoading } = useDataState(partner);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      dataLoading={isLoading}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
    >
      <PartnerVerticalSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default PartnerSearchSection;
