import React, {
  useState,
  useEffect,
  useMemo,
  Suspense,
  useCallback
} from "react";
import { useSelector } from "react-redux";
import LanguageSelect from "./languageSelect";
import { LogoutBtn } from "./logoutBtn";
import { UserRoleFallback } from "./components/userRole";
import UserInstallation from "./components/userInstallation";
import DatabaseUpCheck from "./components/databaseUpCheck";
import TaskList from "../../tasks/TaskList";

const UserRole = React.lazy(() => import("./components/userRole"));
const NotificationBar = () => {
  const [date, setDate] = useState("");
  const userAuthenticated = useSelector(state => state.auth.userAuthenticated);
  const userToken = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const installation = useSelector(state => state.layout.installation);

  const getCurrentDate = useMemo(
    () => () => {
      let date, day, month, year, full;
      date = new Date();
      day = getTwoDigitDateFormat(date.getDate());

      month = getTwoDigitDateFormat(date.getMonth() + 1);
      year = date.getFullYear();
      full = day + "/" + month + "/" + year;
      setDate(full);
    },
    []
  );

  const getRegionFromUrl = useCallback(() => {
    let region = "";
    if (window.location.hostname.endsWith(".ee")) {
      region = "EE";
    } else if (window.location.hostname.endsWith(".pl")) {
      region = "PL";
    } else {
      region = "DEV";
    }

    return region;
  }, []);

  useEffect(() => {
    getCurrentDate();
  }, [getCurrentDate]);

  useEffect(() => {
    const installTitle = installation.length
      ? ` - ${installation[0].value}`
      : "";
    document.title = "PAKK" + installTitle;
  }, [installation]);

  function getTwoDigitDateFormat(monthOrDate) {
    return monthOrDate < 10 ? "0" + monthOrDate : "" + monthOrDate;
  }

  return (
    <div className="notification-bar">
      <div className="notification-bar__left-col">
        <div className="notification-bar__brand">
          PAKK
          <span className="notification-bar__region">
            -{getRegionFromUrl()}
          </span>
        </div>
        <div className="notification-bar__version">
          v{process.env.REACT_APP_VERSION}-beta{" "}
          <Suspense fallback={<div>LOADING</div>}>
            <div className="u-margin-left-tiny">
              <UserInstallation
                installation={installation.length ? installation[0].value : ""}
              />
            </div>
          </Suspense>
        </div>
        <Suspense fallback={<UserRoleFallback />}>
          <UserRole role={user ? user.role : ""} />
        </Suspense>
        <TaskList />
      </div>

      <div className="notification-bar__end-col">
        {/*<DatabaseUpCheck />*/}

        {userAuthenticated ? <LogoutBtn /> : ""}
        <LanguageSelect />
        <div className="notification-bar__time-date">{date}</div>
      </div>
    </div>
  );
};

export default NotificationBar;
