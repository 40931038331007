import React, { useRef } from "react";
import {
  SectionMain,
  SectionHeader,
  SectionBody
} from "../../../../common/section";
import _ from "lodash";
import useFactoringDebtorLayoutActions from "../../hooks/layout/useFactoringDebtorLayoutActions";
import { useTranslation } from "react-i18next";
import useFactoringDebtorState from "../../hooks/useFactoringDebtorState";
import useFactoringDebtorConsts from "../../hooks/useFactoringDebtorConsts";
import useFactoringDebtorActions from "../../hooks/useFactoringDebtorActions";
import FactoringDebtorTable from "../../factoringDebtorTable";
import FactoringDebtorButtons from "./factoringDebtorButtons";
import useFactoringDebtorUtils from "../../hooks/useFactoringDebtorUtils";
import useFactoringDebtorLayoutState from "../../hooks/layout/useFactoringDebtorLayoutState";

const FactoringDebtorMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleExpandersToggle,
    handleTableToggle
  } = useFactoringDebtorLayoutActions();
  const { filterLabels, tabs } = useFactoringDebtorConsts();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useFactoringDebtorLayoutState();
  const { handleSearch, handleRowSelect } = useFactoringDebtorActions();
  const { searchQuery, selectedRow } = useFactoringDebtorState();
  const { getFilters } = useFactoringDebtorUtils();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("factoring.debtor.Header")}
        getFilters={getFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        searchOpen={searchOpen}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
      />

      <SectionBody
        TableComponent={FactoringDebtorTable}
        ButtonsComponent={FactoringDebtorButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        selectedItem={!_.isEmpty(selectedRow) ? selectedRow.name : ""}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default FactoringDebtorMainSection;
