import { useSelector } from "react-redux";

const useTransactionsState = () => {
  const searchQuery = useSelector(state => state.transactions.search.query);
  const selectedRow = useSelector(
    state => state.transactions.data.selectedItem
  );

  return { searchQuery, selectedRow };
};

export default useTransactionsState;
