import React, { Component } from "react";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../common/form/form";
import { Formik } from "formik";

class SalespointSectionDetailsForm extends Component {
  render() {
    const { t } = this.props;
    const SalespointDetailsTrans = "salespoint.details.";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <InputField
                type="text"
                label={t(SalespointDetailsTrans + "SalesPointNo")}
                classes="form__group"
                onChange={handleChange}
                id="salespointNo"
              />
              <InputField
                type="text"
                label={t(SalespointDetailsTrans + "Name")}
                classes="form__group"
                onChange={handleChange}
                id="salespointName"
              />
              <SelectInputField
                label={t(SalespointDetailsTrans + "SalesPointPartner")}
                classes="form__group"
                options={["YX Norge AS", "PORT ONE"]}
                onChange={handleChange}
                id="salespointPartner"
              />
              <SelectInputField
                label={t(SalespointDetailsTrans + "Country")}
                classes="form__group"
                options={["Norway", "Estonia"]}
                onChange={handleChange}
                id="salespointCountry"
              />
              <InputField
                type="text"
                label={t(SalespointDetailsTrans + "Phone")}
                classes="form__group"
                onChange={handleChange}
                id="salespointPhone"
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    );
  }
}
export default SalespointSectionDetailsForm;
