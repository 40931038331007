import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import useCardLayoutState from "../../hooks/layout/useCardLayoutState";
import CardVerticalSearchForm from "../../search/cardVerticalSearchForm";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CARD } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { card } from "../../../common/redux/types/selectorTypes";

const CardSearchSection = () => {
  const { searchOpen } = useCardLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(CARD);
  const { isLoading } = useDataState(card);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <CardVerticalSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default CardSearchSection;
