import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import { SELECT_FACTORING_DEBTOR_CREDIT_LIMIT_HISTORY_ROW } from "../actions/factoringDebtorActions";

const FactoringDebtorCreditLimitHistoryTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRowSelect = payload => {
    dispatch(SELECT_FACTORING_DEBTOR_CREDIT_LIMIT_HISTORY_ROW(payload));
  };

  const data = useSelector(
    state => state.factoringDebtor.data.selectedItem.customerCreditLimitLog
  );
  const selectedRow = useSelector(
    state => state.factoringDebtor.tabs.creditLimitHistory.selectedItem
  );
  const getColumns = () => {
    const columns = [
      {
        Header: t("factoring.debtor.CreditLimit"),
        accessor: "creditLimit"
      },
      {
        Header: t("customerAccount.EntryDate"),
        accessor: "createdOn"
      }
    ];
    return columns;
  };

  return (
    <Table
      data={data || []}
      onRowSelect={handleRowSelect}
      columns={getColumns()}
      selectedRow={selectedRow}
      height={200}
    />
  );
};
export default FactoringDebtorCreditLimitHistoryTable;
