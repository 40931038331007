import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import useTransactionsLayoutState from "../../hooks/layout/useTransactionsLayoutState";
import TransactionsVerticalSearchFrom from "../../search/forms/transactionsVerticalSearchForm";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { TRANSACTIONS } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { transactions } from "../../../common/redux/types/selectorTypes";

const TransactionsSearchSection = () => {
  const { searchOpen } = useTransactionsLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(TRANSACTIONS);
  const { isLoading } = useDataState(transactions);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <TransactionsVerticalSearchFrom ref={formRef} />
    </SearchSection>
  );
};

export default TransactionsSearchSection;
