import axios from "axios";
import { authorizationHeaders } from "../../../services/queries";
import { mutateClassifications } from "../../../services/arrayFunctions";
import { toast } from "react-toastify";

export const NAV_ITEM_TOGGLE = "NAVITEMTOGGLE";

export const LANGCHANGE = "LANGCHANGE";

export const DATABASE_CONNECTION_OK = () => ({
  type: "DATABASE_CONNECTION_OK",
});

export const DATABASE_CONNECTION_FAIL = () => ({
  type: "DATABASE_CONNECTION_FAIL"
});

export const RESIZE = payload => ({
  type: "RESIZE",
  payload: payload
});

export const SELECT_NAV_ITEM = payload => ({
  type: "SELECT_NAV_ITEM",
  payload: payload
});

export const SELECT_NAVMAIN_ITEM = payload => ({
  type: "SELECT_NAVMAIN_ITEM",
  payload: payload
});

export const TOGGLE_NAV_GROUP_SECTIONS = () => ({
  type: "TOGGLE_NAV_GROUP_SECTIONS"
});

export const TOGGLE_NAV_GROUP_HIDDEN = () => ({
  type: "TOGGLE_NAV_GROUP_HIDDEN"
});

export const TOGGLE_NAV_GROUP_ADMIN = () => ({
  type: "TOGGLE_NAV_GROUP_ADMIN"
});

export const LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_START = () => ({
  type: "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_START"
});

export const LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_SUCCESS = payload => ({
  type: "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_SUCCESS",
  payload: payload
});

export const SET_INSTALLATION_PERMISSIONS = payload => ({
  type: "SET_INSTALLATION_PERMISSIONS",
  payload: payload
});

export const LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_ERROR = payload => ({
  type: "LOAD_TRANSACTION_ENTRY_SOURCE_TYPE_CLASSIFICATIONS_ERROR",
  payload: payload
});

export const LOAD_COUNTRIES_LIST_START = () => ({
  type: "LOAD_COUNTRIES_LIST_START"
});

export const LOAD_COUNTRIES_LIST_SUCCESS = payload => ({
  type: "LOAD_COUNTRIES_LIST_SUCCESS",
  payload: payload
});

export const LOAD_COUNTRIES_LIST_ERROR = payload => ({
  type: "LOAD_COUNTRIES_LIST_ERROR",
  payload: payload
});

export const LOAD_CONTRACT_TYPE_CLASSIFICATIONS_START = () => ({
  type: "LOAD_CONTRACT_TYPE_CLASSIFICATIONS_START"
});

export const LOAD_CONTRACT_TYPE_CLASSIFICATIONS_SUCCESS = payload => ({
  type: "LOAD_CONTRACT_TYPE_CLASSIFICATIONS_SUCCESS",
  payload: payload
});

export const LOAD_CONTRACT_TYPE_CLASSIFICATIONS_ERROR = payload => ({
  type: "LOAD_CONTRACT_TYPE_CLASSIFICATIONS_ERROR",
  payload: payload
});

export const LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFIFACTIONS_START = () => ({
  type: "LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFICATIONS_START"
});

export const LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFIFACTIONS_SUCCESS = payload => ({
  type: "LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFICATIONS_SUCCESS",
  payload: payload
});

export const LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFIFACTIONS_ERROR = payload => ({
  type: "LOAD_CUSTOMER_INVOICE_STATUS_CLASSIFICATIONS_ERROR",
  payload: payload
});

export const LOAD_CLASSIFICATIONS_START = () => ({
  type: "LOAD_CLASSIFICATIONS_START"
});

export const LOAD_CLASSIFICATIONS_SUCCESS = payload => ({
  type: "LOAD_CLASSIFICATIONS_SUCCESS",
  payload: payload
});

export const LOAD_CLASSIFICATIONS_ERROR = payload => ({
  type: "LOAD_CLASSIFICATIONS_ERROR",
  payload: payload
});

export const LOAD_INSTALLATION_DATA_START = () => ({
  type: "LOAD_INSTALLATION_DATA_START"
});

export const LOAD_INSTALLATION_DATA_SUCCESS = payload => ({
  type: "LOAD_INSTALLATION_DATA_SUCCESS",
  payload: payload
});

export const LOAD_INSTALLATION_DATA_ERROR = payload => ({
  type: "LOAD_INSTALLATION_DATA_ERROR",
  payload: payload
});

export const SET_NAV_ITEMS_STATE = payload => ({
  type: "SET_NAV_ITEMS_STATE",
  payload: payload
});

export const getInstallationData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      pakkInstallation{
        key
        value
      }
    }`
      },
      authorizationHeaders()
    );
    return data.data.data.pakkInstallation;
  } catch (err) {
    toast.error("Error loading INSTALLATION data");
    return [];
  }
};

export const getInstallationPermissions = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
        installationPermissions{
          id
        }
        
        }
      `
      },
      authorizationHeaders()
    );
    return data.data.data.installationPermissions;
  } catch (err) {
    console.log(err.message);
  }
};

export const getClassificationsData = async () => {
  const classifications = {};
  const [
    entry_source_types,
    customer_invoice_status_types,
    contract_status_types,
    contract_types,
    langugages,
      card_types,
      card_status,
    countryList
  ] = await Promise.all([
    classificationQuery("ENTRY_SOURCE_TYPE"),
    classificationQuery("CUSTOMER_INVOICE_STATUS"),
    classificationQuery("CONTRACT_STATUS"),
    classificationQuery("CONTRACT_TYPE"),
    classificationQuery("LANGUAGE"),
    classificationQuery("CARD_TYPE"),
    classificationQuery("CARD_STATUS"),
    getCountriesList()
  ]);

  classifications.error = {};
  classifications.entry_source_types = mutateClassifications(
    entry_source_types
  );
  classifications.customer_invoice_status_types = mutateClassifications(
    customer_invoice_status_types
  );
  classifications.contract_status_types = mutateClassifications(
    contract_status_types
  );
  classifications.contract_types = mutateClassifications(contract_types);
  classifications.languages = mutateClassifications(langugages);
  classifications.card_types = mutateClassifications(card_types);
  classifications.card_status = mutateClassifications(card_status);
  classifications.countries = countryList;

  return classifications;
};

export const getInstallation = () => {
  return dispatch => {
    dispatch(LOAD_INSTALLATION_DATA_START());
    getInstallationData().then(d =>
      dispatch(LOAD_INSTALLATION_DATA_SUCCESS(d))
    );
  };
};

export const getClassifications = () => {
  return dispatch => {
    dispatch(LOAD_CLASSIFICATIONS_START());
    getClassificationsData()
      .then(d => dispatch(LOAD_CLASSIFICATIONS_SUCCESS(d)))
      .catch(err => {
        dispatch(LOAD_CLASSIFICATIONS_ERROR(err));
      });
  };
};

export const classificationQuery = async payload => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      classifications(type:${payload}){
    attribute
  }
  }`
      },
      authorizationHeaders()
    );
    return data.data.data.classifications;
  } catch (err) {
    toast.error(`Error loading classification ${payload}`);
  }
};

const getCountriesList = async () => {
  const data = await axios.post(
    "/graphql",
    {
      query: `query {
      countries {
        code
        id
      }
    }`
    },
    authorizationHeaders()
  );
  if (!data.data.errors) {
    return data.data.data.countries;
  } else {
    return [{ code: "" }];
  }
};
