import React from "react";
import View from "../../common/view";
import FactoringDebtorOverdueInvoicesMainSection from "./layout/main/factoringDebtorOverdueInvoicesMainSection";
import FactoringDebtorOverdueInvoicesSearchSection from "./layout/search/factoringDebtorOverdueInvoicesSearchSection";

const FactoringDebtorOverdueInvoicesView = () => {
  return (
    <View
      MainSection={FactoringDebtorOverdueInvoicesMainSection}
      SearchSection={FactoringDebtorOverdueInvoicesSearchSection}
    />
  );
};

export default FactoringDebtorOverdueInvoicesView;
