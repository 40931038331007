import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  contractQuery,
  SELECT_CONTRACT_ROW,
  TOGGLE_CONTRACT_ROW
} from "../actions/contractActions";

const useContractActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(
    payload => {
      dispatch(contractQuery(payload));
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_CONTRACT_ROW(payload));
    },
    [dispatch]
  );

  const handleRowToggle = useCallback(
    payload => {
      dispatch(TOGGLE_CONTRACT_ROW(payload));
    },
    [dispatch]
  );

  return {
    handleSearch,
    handleRowSelect,
    handleRowToggle
  };
};

export default useContractActions;
