import  { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_SERVICE_TABLE,
  TOGGLE_SERVICE_SEARCH,
  TOGGLE_SERVICE_EXPANDERS
} from "../../actions/serviceActions";

const useServiceLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_TABLE());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_SEARCH());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_SERVICE_EXPANDERS());
  }, [dispatch]);

  return { handleTableToggle, handleSearchToggle, handleExpandersToggle };
};

export default useServiceLayoutActions;
