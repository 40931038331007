import React from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  DisplayField,
  FormCol,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import useVatDocumentsState from "../../hooks/useVatDocumentsState";
import { useTranslation } from "react-i18next";

const VatDocumentsFormGeneral = () => {
  const { t } = useTranslation();
  const { selectedRow: data } = useVatDocumentsState();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <FormFlexWrapper>
            <FormCol>
              <InputField
                id="vatDocumentsDetailsCountry"
                name="country"
                label={t("vat.documents.Country")}
                value={
                  values.country ? t(`countries.${values.country[0].code}`) : ""
                }
                onChange={handleChange}
                classes="form__group"
              />

              <InputField
                id="vatDocumentsDetailsCustomer"
                name="customer"
                label={t("vat.documents.Customer")}
                value={values.customer ? values.customer[0].name : ""}
                onChange={handleChange}
                classes="form__group"
              />
              <InputField
                id="vatDocumentsDetailsDocumentNetto"
                name="documentNetto"
                label={t("vat.documents.DocumentNetto")}
                value={values.documentNetto}
                onChange={handleChange}
                classes="form__group"
              />
              <SelectInputField
                id="vatDocumentsDetailsChannel"
                name="channel"
                label={t("vat.documents.Channel")}
                classes="form__group"
                options={[]}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <SelectInputField
                id="vatDocumentsDetailsSeller"
                name="seller"
                label={t("vat.documents.SellerName")}
                classes="form__group"
                options={[]}
                onChange={handleChange}
              />
              <DateInputField
                id="vatDocumentsDetailsDocumentDate"
                name="documentDate"
                label={t("vat.documents.DocumentDate")}
                value={values.documentDate}
                onChange={setFieldValue}
                classes="form__group"
                placement="right"
              />
              <InputField
                id="vatDocumentsDetailsDocumentVat"
                name="documentNetto"
                label={t("vat.documents.DocumentVat")}
                value={values.documentVat}
                onChange={handleChange}
                classes="form__group"
              />
              <BoolSelectInputField
                id="vatDocumentsDetailsIsFastRefund"
                name="useForFastVatRefund"
                label={t("vat.documents.IsFastReturn")}
                classes="form__group"
                value={values.useForFastVatRefund}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <BoolSelectInputField
                id="vatDocumentsDetailsIsSuspiciousCl"
                name="isSuspiciousCl"
                label={t("vat.documents.IsSuspicious")}
                classes="form__group"
                isCl={true}
                value={values.isSuspiciousCl}
                onChange={handleChange}
              />
              <InputField
                id="vatDocumentsDetailsDocumentNo"
                name="documentNo"
                label={t("vat.documents.DocumentNo")}
                value={values.documentNo}
                onChange={handleChange}
                classes="form__group"
              />
              <SelectInputField
                id="vatDocumentsDetailsDocumentCurrency"
                name="documentCurrency"
                label={t("vat.documents.DocumentCurrency")}
                classes="form__group"
                value={values.documentCurrencyCl}
                options={[
                  { name: "", value: "" },
                  { name: "CZK", value: "CURRENCY.CZK" },
                  { name: "EUR", value: "CURRENCY.EUR" },
                  { name: "PLN", value: "CURRENCY.PLN" }
                ]}
                onChange={handleChange}
              />
              <InputField
                id="vatDocumentsDetailsDocumentVatInEur"
                name="documentVatInEur"
                label={t("vat.documents.DocumentVatEur")}
                value={values.documentVatInEur}
                onChange={handleChange}
                classes="form__group form__group--display"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <DisplayField
                id="vatDocumentsDetailsSellerAddress"
                label={t("vat.documents.SellerAddress")}
                value={values.sellerCityStreetHouse || "-"}
                classes="form__group form__group--display"
              />
              <DisplayField
                id="vatDocumentsDetailsValidP1FastReturn"
                label={t("vat.documents.ValidP1FastReturn")}
                value={values.validP1FastReturn || "-"}
                classes="form__group form__group--display"
              />
              <DisplayField
                id="vatDocumentsDetailsSellerVatNo"
                label={t("vat.documents.SellerVatNo")}
                value={values.sellerVatPayerNo || "-"}
                classes="form__group form__group--display"
              />
              <DisplayField
                id="vatDocumentsDetailsEntryTime"
                label={t("vat.documents.EntryTime")}
                value={values.entryTime || "-"}
                classes="form__group form__group--display"
              />
            </FormCol>
          </FormFlexWrapper>

          <InputField
            id="vatDocumentsDetailsInvoice"
            name="invoice"
            label={t("vat.documents.Invoice")}
            value={values.invoice}
            classes="form__group"
            onChange={handleChange}
          />
        </form>
      )}
    </Formik>
  );
};

export default VatDocumentsFormGeneral;
