import React from "react";
import { useDispatch } from "react-redux";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { SET_CUSTOMER_MISC_VAT_TABLE_HEIGHT } from "../actions/customersActions";
import useDataState from "../../../common/redux/hooks/useDataState";
import { customers } from "../../../common/redux/types/selectorTypes";

const CustomerVatInfoTable = ({ t }) => {
  const dispatch = useDispatch();
  const { selectedItem } = useDataState(customers);
  const { table } = useTabState(customers, "vatInfo");

  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMER_MISC_VAT_TABLE_HEIGHT(payload));
  };
  const CustomerVatDataTrans = "customer.customerVatData.";
  const columns = [
    {
      id: "country",
      Header: t(CustomerVatDataTrans + "CountryName"),
      accessor: d => (d.country ? t("countries." + d.country[0].code) : "")
    },

    {
      id: "number",
      Header: t(CustomerVatDataTrans + "VatNumber"),
      accessor: d => d.number
    }
  ];

  return (
    <Table
      height={table.height}
      setTableHeight={onSetTableHeight}
      onRowSelect={() => {}}
      columns={columns}
      data={selectedItem.customerVatPayer || []}
      selectedRow={{}}
    />
  );
};

export default CustomerVatInfoTable;
