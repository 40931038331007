import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import { SELECT_FACTORING_DEBTOR_CUST_INVOICE_SETTINGS_ROW } from "../actions/factoringDebtorActions";

const FactoringDebtorClientInvoiceSettingsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRowSelect = payload => {
    dispatch(SELECT_FACTORING_DEBTOR_CUST_INVOICE_SETTINGS_ROW(payload));
  };

  const selectedRow = useSelector(
    state => state.factoringDebtor.tabs.clientAndInvoiceSettings.selectedItem
  );
  const data = useSelector(
    state => state.factoringDebtor.data.selectedItem.customerBankAccounts
  );
  const getColumns = () => {
    const columns = [
      {
        Header: t("customer.customerInvoiceSettings.BankAccount"),
        accessor: "bankAccount"
      },
      {
        id: "isPrimary",
        Header: t("customer.customerInvoiceSettings.Primary"),
        accessor: d => {
          return d.isPrimary ? "✓" : "";
        }
      }
    ];
    return columns;
  };

  return (
    <Table
      data={data || []}
      columns={getColumns()}
      onRowSelect={handleRowSelect}
      height={200}
      selectedRow={selectedRow}
    />
  );
};
export default FactoringDebtorClientInvoiceSettingsTable;
