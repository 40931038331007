import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class LanguageSelect extends Component {
  state = {
    isOpen: false,
    imgLoaded: false
  };

  getLanguageFlag = i18n => {
    const language = i18n.language;
    const prefix = "/assets/icons/lang/";
    if (language === "et") {
      return prefix + "estonia.svg";
    } else if (language === "en") {
      return prefix + "english.svg";
    }
    return undefined;
  };

  imgLoaded = async () => {
    await this.setState({ imgLoaded: true });
  };

  render() {
    const { i18n } = this.props;

    return (
      <div
        className="lang-select"
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        tabIndex="0"
        onBlur={() => this.setState({ isOpen: false })}
      >
        <img
          src={process.env.PUBLIC_URL + this.getLanguageFlag(i18n)}
          onLoad={this.imgLoaded}
          className="lang-select__icon"
          alt="language_flag"
        />

        {this.state.isOpen ? (
          <div className="lang-select__dropdown">
            <form onSubmit={e => e.preventDefault()}>
              <div className="lang-select__options">
                <div
                  className="lang-select__options-option"
                  onClick={() => {
                    i18n.changeLanguage("en");
                    this.props.onLangChange("en");
                    localStorage.setItem("lang", "en");
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/lang/english.svg"
                    }
                    className="lang-select__icon"
                    alt="uk_flag"
                  />
                </div>
                <div
                  className="lang-select__options-option"
                  onClick={() => {
                    i18n.changeLanguage("et");
                    this.props.onLangChange("et");
                    localStorage.setItem("lang", "et");
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/lang/estonia.svg"
                    }
                    className="lang-select__icon"
                    alt="estonian_flag"
                  />
                </div>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.layout.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLangChange: payload => {
      const action = { type: "LANGCHANGE", payload: payload };
      dispatch(action);
    }
  };
}

const LangSelect = withTranslation()(LanguageSelect);

export default connect(mapStateToProps, mapDispatchToProps)(LangSelect);
