import React, { useRef } from "react";
import {
  SectionMain,
  SectionHeader,
  SectionBody
} from "../../../common/section";
import { useTranslation } from "react-i18next";
import SalespointTable from "../../salespointTable";
import SalespointButtons from "./salespointButtons";
import useSalespointLayoutState from "../../hooks/layout/useSalespointLayoutState";
import useSalespointConsts from "../../hooks/useSalespointConsts";
import useSalespointState from "../../hooks/useSalespointState";
import useSalespointLayoutActions from "../../hooks/layout/useSalespointLayoutActions";
import useSalespointActions from "../../hooks/useSalespointActions";

const SalespointMainSection = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const {
    searchOpen,
    tableVisible,
    expandersVisible
  } = useSalespointLayoutState();
  const { tabs, filterLabels } = useSalespointConsts();
  const { searchQuery, selectedRow } = useSalespointState();
  const {
    handleTableToggle,
    handleExpandersToggle,
    handleSearchToggle
  } = useSalespointLayoutActions();
  const { handleSearch, handleRowSelect } = useSalespointActions();

  return (
    <SectionMain toggleSearch={() => {}}>
      <SectionHeader
        sectionHeading={t("salespoint.Header")}
        getFilters={() => {}}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={SalespointTable}
        ButtonsComponent={SalespointButtons}
        shortcutsEnabled={true}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default SalespointMainSection;
