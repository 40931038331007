import React, { Component } from "react";
import { Formik } from "formik";

export default class BrokerInvoicesDetailsForm extends Component {
  render() {
    const { t } = this.props;
    const BrokerInvoiceDetailsTrans = "broker.invoices.details.";
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{}}
          /*validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}*/
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => (
            <form className="form form--flex">
              <div className="form-col">
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "AddToInvoice")}</label>
                  <input type="checkbox" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Customer")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Total")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Product")}</label>
                  <select>
                    <option>Teemaks</option>
                  </select>
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "IsEmailSent")}</label>
                  <select>
                    <option>yes</option>
                    <option>no</option>
                  </select>
                </div>
              </div>
              <div className="form-col u-margin-left-small">
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "InvoiceNo")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Partner")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>
                    {t(BrokerInvoiceDetailsTrans + "CustomerInvoiceNo")}
                  </label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Quantity")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>
                    {t(BrokerInvoiceDetailsTrans + "TotalInDocCurrency")}
                  </label>
                  <input type="text" />
                </div>
              </div>
              <div className="form-col form-col--small u-margin-left-small">
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "InvoiceDate")}</label>
                  <input type="text" />
                </div>
                <div className="form__group">
                  <label>{t(BrokerInvoiceDetailsTrans + "Reseller")}</label>
                  <select>
                    <option>SWED EE</option>
                  </select>
                </div>
              </div>
              <div className="form-col u-margin-left-small">
                <div className="form__block form__group form__block--multiline">
                  <label>
                    {t(BrokerInvoiceDetailsTrans + "AdditionalInfo")}
                  </label>
                  <textarea type="text" name="additionalInfo" />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
