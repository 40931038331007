import {useSelector} from "react-redux";

const useVatApplicationsState = () => {
  const data = useSelector(state => state.vatApplications.data.items);
  const selectedRow = useSelector(
    state => state.vatApplications.data.selectedItem
  );
  const searchQuery = useSelector(state => state.vatApplications.search.query);

  return { selectedRow, searchQuery, data };
};

export default useVatApplicationsState;
