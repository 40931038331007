import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  FormFlexItem,
  FormFlexWrapper,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { Button } from "../../../common/buttons/button";

const TransactionsSectionDetailsForm = ({ t }) => {
  const TransactionsDetailsTrans = "transactions.details.";
  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormFlexItem flexBasis={"65%"} direction="column">
              <h3 className="heading__tertiary">Transaction data</h3>
              <FormFlexWrapper>
                <FormCol>
                  <SelectInputField
                    label="Add to invoice"
                    id="addToInvoice"
                    classes="form__group"
                    options={[
                      { name: "", value: "" },
                      { name: t("common.ReplyYes"), value: "true" },
                      { name: t("common.ReplyNo"), value: "false" }
                    ]}
                    onChange={handleChange}
                  />
                  <InputField
                    type="date"
                    label={t(TransactionsDetailsTrans + "TransactionDay")}
                    classes="form__group"
                    id="transactionDay"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(TransactionsDetailsTrans + "TransactionCardNo")}
                    classes="form__group"
                    id="cardNo"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(
                      TransactionsDetailsTrans + "TransactionSalesPointName"
                    )}
                    classes="form__group"
                    id="salespoint"
                    onChange={handleChange}
                  />
                  <FormFlexWrapper classes="form--space-between form--flex-center">
                    <InputField
                      type="text"
                      label={t(TransactionsDetailsTrans + "TransactionNo2")}
                      classes="form__group"
                      id="transactionNo"
                      onChange={handleChange}
                    />
                    <Button
                      classes="u-margin-left-tiny"
                      btnStyle="primary"
                      label="Generate"
                      size="small"
                    />
                  </FormFlexWrapper>

                  <InputField
                    type="text"
                    label={t(TransactionsDetailsTrans + "TransactionProduct")}
                    classes="form__group"
                    id="transactionProduct"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(TransactionsDetailsTrans + "TransactionQuantity")}
                    classes="form__group"
                    id="transactionQuantity"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(
                      TransactionsDetailsTrans + "TransactionAmountStation"
                    )}
                    classes="form__group"
                    id="transactionTotalAmountStation"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(TransactionsDetailsTrans + "TransactionAmountOwn")}
                    classes="form__group"
                    id="transactionTotalAmountOwn"
                    onChange={handleChange}
                  />
                  <InputField
                    type="text"
                    label={t(
                      TransactionsDetailsTrans + "TransactionSalePriceEUR"
                    )}
                    classes="form__group"
                    id="transactionSalePriceEUR"
                    onChange={handleChange}
                  />
                </FormCol>
                <FormCol className="u-margin-left-small">
                  <FormFlexWrapper direction="column">
                    <InputField
                      type="text"
                      label={t(TransactionsDetailsTrans + "TransactionTime2")}
                      classes="form__group"
                      id="transactionTime"
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      label={t(
                        TransactionsDetailsTrans + "TransactionCurrency"
                      )}
                      classes="form__group"
                      id="transactionCurrency"
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      label={t(
                        TransactionsDetailsTrans + "TransactionUnitPriceStation"
                      )}
                      classes="form__group"
                      id="transactionUnitPrice"
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      label={t(
                        TransactionsDetailsTrans + "TransactionUnitPriceOwn"
                      )}
                      classes="form__group"
                      id="transactionUnitOwnPrice"
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      label={t(
                        TransactionsDetailsTrans + "TransactionVatPercentage"
                      )}
                      classes="form__group"
                      id="transactionVatPercentage"
                      onChange={handleChange}
                    />
                    <div className="form__group">
                      <label>
                        {t(
                          TransactionsDetailsTrans + "TransactionUnitDiscount"
                        )}
                      </label>
                      <p>0</p>
                    </div>
                    <div className="form__group">
                      <label>
                        {t(
                          TransactionsDetailsTrans +
                            "UnitPriceWithDiscountWOVat"
                        )}
                      </label>
                      <p>0</p>
                    </div>
                    <div className="form__group">
                      <label>
                        {t(
                          TransactionsDetailsTrans + "TransactionUnitPriceWOVat"
                        )}
                      </label>
                      <p>0</p>
                    </div>
                    <div className="form__group">
                      <label>
                        {t(
                          TransactionsDetailsTrans +
                            "TransactionUnitPriceOwnWOVat"
                        )}
                      </label>
                      <p>0</p>
                    </div>
                  </FormFlexWrapper>
                </FormCol>
              </FormFlexWrapper>
            </FormFlexItem>
            <FormFlexItem
              flexBasis={"35%"}
              direction="column"
              classes="u-margin-left-small"
            >
              <h3 className="heading__tertiary">Additional info</h3>
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionCardCustomerName"
                )}
                id="customer"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(TransactionsDetailsTrans + "TransactionCardCarRegNo")}
                id="carRegNo"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(TransactionsDetailsTrans + "TransactionCountryName")}
                id="country"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionServiceProviderName"
                )}
                id="serviceProvider"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionSalesPointPartnerName"
                )}
                id="salespointPartner"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(TransactionsDetailsTrans + "TransactionResellerName")}
                id="reseller"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(TransactionsDetailsTrans + "TransactionEntryDate")}
                id="entryDate"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionCustomerInvoiceNo"
                )}
                id="customerInvoiceNo"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionPartnerInvoiceNo"
                )}
                id="partnerInvoiceNo"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(
                  TransactionsDetailsTrans + "TransactionBrokerInvoiceNo"
                )}
                id="brokerInvoiceNo"
                classes="form__group"
                onChange={handleChange}
              />
            </FormFlexItem>
          </FormBody>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default TransactionsSectionDetailsForm;
