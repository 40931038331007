import React from "react";
import {
  FormBody,
  InputField,
  FormCol,
  FormFlexWrapper,
  SelectInputField
} from "../../../common/form/form";
import { Formik } from "formik";

const FactoringInvoicesSectionDetailsForm = ({ t, data }) => {
  const FactoringInvoiceDetailsTrans = "factoring.invoices.details.";
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer: data.customer ? data.customer[0].name : "",
        factoringDebtor: data.factoringDebtor
          ? data.factoringDebtor[0].name
          : "",
        invoiceNo: data.invoiceNo ? data.invoiceNo : "",
        invoiceDate: data.invoiceDate ? data.invoiceDate : "",
        total: data.total ? data.total : "",
        annexNo: data.contract ? data.contract[0].contractNo : "",
        isSummaryInvoice:
          typeof data.isSummaryInvoice === "boolean"
            ? data.isSummaryInvoice
            : "",
        dueDate: data.dueDate ? data.dueDate : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "Customer")}
                classes="form__group"
                value={values.customer}
                onChange={handleChange}
                id="customer"
              />
              <InputField
                type="date"
                label={t(FactoringInvoiceDetailsTrans + "InvoiceDate")}
                classes="form__group"
                value={values.invoiceDate}
                onChange={handleChange}
                id="invoiceDate"
              />
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "ContractAnnexNo")}
                classes="form__group"
                value={values.annexNo}
                onChange={handleChange}
                id="contractAnnexNo"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "FactoringDebtor")}
                classes="form__group"
                value={values.factoringDebtor}
                onChange={handleChange}
                id="factoringDebtor"
              />
              <InputField
                type="date"
                label={t(FactoringInvoiceDetailsTrans + "DueDate")}
                classes="form__group"
                value={values.dueDate}
                onChange={handleChange}
                id="dueDate"
              />
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "EntryDate")}
                classes="form__group"
                onChange={handleChange}
                id="entryDate"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "InvoiceNo")}
                classes="form__group"
                value={values.invoiceNo}
                onChange={handleChange}
                id="invoiceNo"
              />
              <InputField
                type="text"
                label={t(FactoringInvoiceDetailsTrans + "Total")}
                classes="form__group"
                value={values.total}
                onChange={handleChange}
                id="total"
              />
              <SelectInputField
                label={t(FactoringInvoiceDetailsTrans + "SummaryInvoice")}
                options={[
                  { name: "", value: "" },
                  { name: t("common.ReplyYes"), value: "true" },
                  { name: t("common.ReplyNo"), value: "false" }
                ]}
                classes="form__group"
                id="isSummaryInvoice"
                name="isSummaryInvoice"
                onChange={handleChange}
                value={values.isSummaryInvoice}
              ></SelectInputField>
            </FormCol>
          </FormFlexWrapper>
        </FormBody>
      )}
    </Formik>
  );
};
export default FactoringInvoicesSectionDetailsForm;
