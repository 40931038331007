export const CUSTOMERS = "CUSTOMERS";
export const CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT";
export const CUSTOMER_INVOICES = "CUSTOMER_INVOICES";
export const CUSTOMER_DEPOSIT = "CUSTOMER_DEPOSIT";
export const CONTRACT = "CONTRACT";
export const SERVICE = "SERVICE";
export const FACTORING_INVOICES = "FACTORING_INVOICES";
export const FACTORING_DEBTOR = "FACTORING_DEBTOR";
export const FACTORING_DEBTOR_ACCOUNT = "FACTORING_DEBTOR_ACCOUNT";
export const FACTORING_DEBTOR_OVERDUE_INVOICES = "FACTORING_DEBTOR_OVERDUE_INVOICES";
export const VAT_DOCUMENTS = "VAT_DOCUMENTS";
export const VAT_APPLICATIONS = "VAT_APPLICATIONS";
export const CARD = "CARD";
export const PARTNER = "PARTNER";
export const SALESPOINT = "SALESPOINT";
export const TRANSACTIONS = "TRANSACTIONS";
export const DISCOUNT = "DISCOUNT";
export const TASKS = "TASKS";

export const DETAILS = "DETAILS";
export const COMMENTS = "COMMENTS";
export const CONTACT_INFORMATION = "CONTACT_INFORMATION";
export const ADDRESSES = "ADDRESSES";
export const CREDIT_LIMIT_HISTORY = "CREDIT_LIMIT_HISTORY";
export const CLEARING = "CLEARING";
export const SERVICES = "SERVICES";
export const APPLICATION_PAYMENT_INFO = "APPLICATION_PAYMENT_INFO";
export const INVOICE_SETTINGS = "INVOICE_SETTINGS";
export const VAT_INFO = "VAT_INFO";
export const CONTRACT_OVERVIEW = "CONTRACT_OVERVIEW";
export const CLIENT_NO_EU = "CLIENT_NO_EU";
export const RECEIVED_MAIL = "RECEIVED_MAIL";
export const PAYMENT_REGISTRATION = "PAYMENT_REGISTRATION";
export const STATUS_HISTORY = "STATUS_HISTORY";
export const PAYMENT_HISTORY = "PAYMENT_HISTORY";
export const LIMITS = "LIMITS";
export const PRODUCT_COST = "PRODUCT_COST";
export const BANK_ACCOUNT_TYPES = "BANK_ACCOUNT_TYPES";
export const FACTORING_BANK_ACCOUNT = "FACTORING_BANK_ACCOUNT";
export const FACTORING_DEBTORS = "FACTORING_DEBTORS";
export const PARTNER_AND_INVOICE_SETTINGS = "PARTNER_AND_INVOICE_SETTINGS";
export const CONTRACT_DATA = "CONTRACT_DATA";
export const PRODUCT_PRICE_CONDITIONS = "PRODUCT_PRICE_CONDITIONS";
export const CLIENT_AND_INVOICE_SETTINGS = "CLIENT_AND_INVOICE_SETTINGS";
export const QUERY = "QUERY";
export const TASK_LOG = "TASK_LOG";
