import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import useVatDocumentsLayoutState from "../../hooks/layout/useVatDocumentsLayoutState";
import VatDocumentsVerticalSearchForm from "../../search/forms/vatDocumentsVerticalSearchForm";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { VAT_DOCUMENTS } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { vatDocuments } from "../../../../common/redux/types/selectorTypes";

const VatDocumentsSearchSection = () => {
  const { searchOpen } = useVatDocumentsLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(VAT_DOCUMENTS);
  const { isLoading } = useDataState(vatDocuments);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      dataLoading={isLoading}
      onSearchToggle={handleSearchToggle}
    >
      <VatDocumentsVerticalSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default VatDocumentsSearchSection;
