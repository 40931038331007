import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../services/queries";

export const TOGGLEFACTORINGDEBTORSEARCH = () => ({
  type: "TOGGLEFACTORINGDEBTORSEARCH"
});

export const TOGGLE_FACTORING_DEBTOR_TABLE = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_TABLE"
});

export const TOGGLE_FACTORING_DEBTOR_EXPANDERS = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_EXPANDER_VIEW"
});

export const TOGGLE_FACTORING_DEBTOR_SEARCH = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_SEARCH"
});

export const SELECT_FACTORING_DEBTOR_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_ROW",
  payload: payload
});

export const SELECT_FACTORING_DEBTOR_CONTACT_PERSON_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_CONTACT_PERSON_ROW",
  payload: payload
});

export const SELECT_FACTORING_DEBTOR_CUST_INVOICE_SETTINGS_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_CUST_INVOICE_SETTINGS_ROW",
  payload: payload
});

export const SELECT_FACTORING_DEBTOR_CREDIT_LIMIT_HISTORY_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_CREDIT_LIMIT_HISTORY_ROW",
  payload: payload
});

export const SUMBIT_FACTORING_DEBTOR_QUERY = payload => ({
  type: "SUBMIT_FACTORING_DEBTOR_QUERY",
  payload: payload
});

export const LOAD_FACTORING_DEBTOR_DATA_START = () => ({
  type: "LOAD_FACTORING_DEBTOR_DATA_START"
});

export const LOAD_FACTORING_DEBTOR_DATA_SUCCESS = payload => ({
  type: "LOAD_FACTORING_DEBTOR_DATA_SUCCESS",
  payload: payload
});

export const LOAD_FACTORING_DEBTOR_DATA_ERROR = payload => ({
  type: "LOAD_FACTORING_DEBTOR_DATA_ERROR",
  payload: payload
});

export const SET_FACTORING_DEBTOR_TABLE_HEIGHT = payload => ({
  type: "SET_FACTORING_DEBTOR_TABLE_HEIGHT",
  payload: payload
});

export const getFactoringDebtorData = payload => {
  return dispatch => {
    dispatch(SUMBIT_FACTORING_DEBTOR_QUERY(payload));
    dispatch(LOAD_FACTORING_DEBTOR_DATA_START());
    factoringDebtorSearchQuery(payload)
      .then(d => dispatch(LOAD_FACTORING_DEBTOR_DATA_SUCCESS(d)))
      .catch(error => {
        toast.error(error.message);
        dispatch(LOAD_FACTORING_DEBTOR_DATA_ERROR(error));
      });
  };
};

export const factoringDebtorSearchQuery = async payload => {
  const queryData = getFactoringDebtorQueryData(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
    query{
      companies(${queryData}, type: FACTORING_DEBTOR){
        remainingCreditLimit

        id
        name
        debt
        creditLimit
        balance
        customerContactPerson {      
          name
          contactPersonTypeCl
          languageCl
          personalCode
          phone
          email
          additionalInfo
          
          documentNo
          identificationDate
          documentIssueDate
          documentExpireDate
        }
        legalAddress {      
          country {        
            code        
          }
          city {      
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        postalAddress {
          country {        
            code        
          }
          city {
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        boardMemberAddress {      
          country {        
            code        
          }
          city {
            name
          }
          street {
            name
          }
          houseOrApartment
          zipCode
        }
        regNo
        createdOn
        
        customerContactPerson{
          name,
          id,
          personalCode,
          isPrimary,
          hasSignatureRight,
          contactPersonTypeCl,
          additionalInfo
        }

        customerComments{
          id,
          comment,
          createdOn,
          createdByUserId,
          customerId
          
        }
        customerVatPayer {
          country {
            code
          }
          number
        }
        customerExcisePayer {
          country {
            code
          }
          number
        }
        customerBankAccounts{bankAccount isPrimary id}

        customerCreditLimitLog{id createdOn creditLimit}

        contracts(type: "K"){
          guarantorPerson {
            name
          }
          validThru
          amountGuaranteed
        }
    paymentTermInDays,
    penaltyPercent,
    idsPaymentTermInDays,
    automaticPenaltyFrom,
    invoiceEmail,
    isDiscountCl,
    businessAreaCode,
    isSendingOriginalInvoiceAllowedCl,
    invoiceLanguageCl,
    invoiceCurrencyCl,
    isYxInNokCl,
    polskaVatRegionCode

        isActiveCl
        generalFax
        generalEmail
        generalPhone
        additionalInfo
        
      }
     }
      `
    },
    authorizationHeaders()
  );
  return data.data.data.companies;
};

const getFactoringDebtorQueryData = ({
  resultCount,
  name,
  id,
  regno,
  vatNo,
  generalPhone,
  generalEmail,
  salesAgent
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : ""}${
    name ? `name: "${name}"` : ""
  } ${id ? "id: " + id : ""} ${regno ? "reg_no: " + regno : ""} ${
    vatNo ? "vat_no: " + vatNo : ""
  } ${generalPhone ? `general_phone: "${generalPhone}"` : ""} ${
    generalEmail ? `email: "${generalEmail}"` : ""
  } ${salesAgent ? `sales_agent: "${salesAgent}"` : ""}`;
  return queryData;
};
