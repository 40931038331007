import React from "react";
import { CustomForm } from "../../../../common/form/form";

const VatDocumentsServicesForm = () => {
  const initialTest = {
    name: "SERVICES",
    age: "25"
  };

  return (
    <CustomForm initialValues={initialTest}>
      {values => (
        <div>
          {values.name}, {values.age}
        </div>
      )}
    </CustomForm>
  );
};

export default VatDocumentsServicesForm;
