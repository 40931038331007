import React, { Component } from "react";
import {
  FormBody,
  FormCol,
  FormGroupBlockMultiline,
  InputField
} from "../../../../common/form/form";

class BrokerClaimsDetailsForm extends Component {
  render() {
    return (
      <FormBody>
        <FormBody flex={"true"}>
          <FormCol>
            <InputField
              type="text"
              id="brokerCustomer"
              classes="form__group"
              label="Customer"
            />
            <InputField
              type="text"
              id="brokerSum"
              classes="form__group"
              label="Sum"
            />
          </FormCol>
          <FormCol className="u-margin-left-medium">
            <InputField
              type="text"
              id="brokerClaimType"
              classes="form__group"
              label="Claim type"
            />
            <InputField
              type="text"
              id="brokerClaimNumber"
              classes="form__group"
              label="Claim number"
            />
          </FormCol>
          <FormCol className="u-margin-left-medium">
            <InputField
              type="date"
              id="brokerClaimDate"
              classes="form__group"
              label="Claim date"
            />

            <InputField
              type="text"
              id="brokerInvoiceNo"
              classes="form__group"
              label="Invoice number"
            />
          </FormCol>
          <InputField
            type="checkbox"
            id="brokerAddToInvoice"
            classes="form__group u-margin-left-medium"
            label="Add to invoice"
          />
        </FormBody>
        <FormGroupBlockMultiline>
          <label>Additional info</label>
          <textarea type="text" name="brokerAdditionalInfo" />
        </FormGroupBlockMultiline>
      </FormBody>
    );
  }
}
export default BrokerClaimsDetailsForm;
