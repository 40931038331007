import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormCol,
  FormFlexItem,
  FormFlexWrapper,
  InputField,
  TextareaInputField
} from "../../../../common/form/form";
import VatDocumentsApplicationPaymentDataTable from "../vatDocumentsApplicationPaymentDataTable";

const VatDocumentsApplicationPaymentDataForm = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <FormFlexWrapper>
              <FormCol className="u-margin-right-small" flexBasis="50%">
                <FormFlexItem flexGrow="0" flexShrink="0">
                  <FormCol>
                    <InputField
                      id="vatDocumentsApplicationPaymentDataApplicationNo"
                      name="application_no"
                      label={t("vat.documents.FilterVatApplicationNo")}
                      value={values.application_no}
                      onChange={handleChange}
                      classes="form__group"
                    />
                    <InputField
                      id="vatDocumentsApplicationPaymentDataPayoutToCustomer"
                      name="payoutToCustomer"
                      label={t("vat.applications.details.PayoutToCustomer")}
                      value={values.payoutToCustomer}
                      onChange={handleChange}
                      classes="form__group"
                    />
                    <InputField
                      id="vatDocumentsApplicationPaymentDataIncomeFromTaxOffice"
                      name="incomeFromTaxOffice"
                      label={t("vat.documents.IncomeFromTaxOffice")}
                      value={values.incomeFromTaxOffice}
                      onChange={handleChange}
                      classes="form__group"
                    />
                  </FormCol>
                  <FormCol className="u-margin-left-small">
                    <InputField
                      id="vatDocumentsApplicationPaymentDataApplicationDate"
                      name="applicationDate"
                      label={t("vat.documents.ApplicationDate")}
                      value={values.applicationDate}
                      onChange={handleChange}
                      classes="form__group"
                    />
                    <InputField
                      id="vatDocumentsApplicationPaymentDataPayoutToCustomerInEur"
                      name="payoutToCustomerInEur"
                      label={t("vat.documents.PayoutToCustomerInEUR")}
                      value={values.payoutToCustomerInEur}
                      onChange={handleChange}
                      classes="form__group"
                    />
                    <InputField
                      id="vatDocumentsApplicationPaymentDataIncomeFromTaxOfficeInEur"
                      name="incomeFromTaxOfficeInEur"
                      label={t(
                        "vat.applications.details.IncomeFromTaxOfficeEur"
                      )}
                      value={values.IncomeFromTaxOfficeInEur}
                      onChange={handleChange}
                      classes="form__group"
                    />
                  </FormCol>
                </FormFlexItem>
                <TextareaInputField
                  type="text"
                  id="vatDocumentsApplicationPaymentDataAdditionalInfo"
                  name="additionalInfo"
                  label={t("vat.applications.details.AdditionalInfo")}
                  value={values.additionalInfo}
                  onChange={handleChange}
                  style={{ height: "100%" }}
                  classes="form__block form__group form__block--multiline"
                  textareaClasses="u-border-radius-3px"
                />
              </FormCol>
              <VatDocumentsApplicationPaymentDataTable />
            </FormFlexWrapper>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default VatDocumentsApplicationPaymentDataForm;
