import React, { Component } from "react";
import { Formik } from "formik";
import { FormBody, FormCol, InputField } from "../../common/form/form";

class PartnerSectionContractDataForm extends Component {
  render() {
    const { t } = this.props;
    const PartnerContractDataTrans = "partner.contractData.";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <InputField
                type="text"
                id="partnerContractDataContractNo"
                classes="form__group"
                label={t(PartnerContractDataTrans + "ContractNo")}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                id="partnerContractDataContractValidFrom"
                classes="form__group"
                label={t(PartnerContractDataTrans + "ContractValidFrom")}
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    );
  }
}
export default PartnerSectionContractDataForm;
