import {useSelector} from "react-redux";

const useVatApplicationsLayoutState = () => {
  const searchOpen = useSelector(
    state => state.vatApplications.layout.search.isOpen
  );
  const tableVisible = useSelector(
    state => state.vatApplications.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.vatApplications.layout.expanders.visible
  );
  const isLoading = useSelector(state => state.vatApplications.data.isLoading);

  return { searchOpen, tableVisible, expandersVisible, isLoading };
};

export default useVatApplicationsLayoutState;
