import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {SELECT_TAB_TABLE_ROW, SET_TAB_TABLE_HEIGHT} from "./commonTableActions";

const useTabTableActionDispatchers = (viewType, tabType) => {
  const dispatch = useDispatch();

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_TAB_TABLE_ROW(viewType, tabType, payload));
    },
    [dispatch, tabType, viewType]
  );

  const handleTableHeightSet = useCallback(payload => {
    dispatch(SET_TAB_TABLE_HEIGHT(viewType, tabType, payload))
  }, [dispatch, tabType, viewType]);

  return { handleRowSelect, handleTableHeightSet };
};

export default useTabTableActionDispatchers;
