import React, { useMemo } from "react";
import CustomerInvoiceDetails from "../details/customerInvoiceDetails";
import { useTranslation } from "react-i18next";
import CustomerInvoiceStatusTable from "../status_history/customerInvoiceStatusTable";
import CustomerInvoicePaymentHistoryTable from "../payment_history/customerInvoicePaymentHistoryTable";
import useDataState from "../../../common/redux/hooks/useDataState";
import {customerInvoices, details, paymentHistory, statusHistory} from "../../../common/redux/types/selectorTypes";
import {CUSTOMER_INVOICES, DETAILS, PAYMENT_HISTORY, STATUS_HISTORY} from "../../../common/redux/types/viewTypes";

const useCustomerInvoiceConsts = () => {
  const { selectedItem } = useDataState(customerInvoices);
  const {
    customerInvoiceStatusHistory,
    customerInvoicePaymentHistory
  } = selectedItem;
  const { t } = useTranslation();

  const filterLabels = {
    count: t("customerInvoice.ResultsNo"),
    customer: t("customerInvoice.CustomerName"),
    invoice_no: t("customerInvoice.InvoiceNo"),
    invoice_status: t("customerInvoice.InvoiceStatus"),
    issued_country: t("customerInvoice.FilterIssuedCountry"),
    date_from: t("customerInvoice.DateFrom"),
    date_to: t("customerInvoice.DateTo"),
    is_it_paid: t("customerInvoice.FilterIsPaaid"),
    is_virtual: t("customerInvoice.FilterIsVirtual"),
    reseller: t("customerInvoice.ResellerName"),
    dueDateUntil: t("customerInvoice.DueDateUntil"),
    customer_country: t("customerInvoice.FilterCustomerCountry"),
    salesAgent: t("customerInvoice.SalesAgent")
  };

  const customerInvoiceTabs = useMemo(
    () => [
      {
        label: t("customerInvoice.CustomerInvoiceDetailData"),
        component: (
          <CustomerInvoiceDetails
            data={selectedItem ? selectedItem : []}
            t={t}
          />
        ),
        viewSelector: customerInvoices,
        tabSelector: details,
        viewType: CUSTOMER_INVOICES,
        tabType: DETAILS
      },
      {
        label: t("customerInvoice.CustomerInvoiceStatusHistory"),
        component: (
          <CustomerInvoiceStatusTable
            t={t}
            data={customerInvoiceStatusHistory}
          />
        ),
        viewSelector: customerInvoices,
        tabSelector: statusHistory,
        viewType: CUSTOMER_INVOICES,
        tabType: STATUS_HISTORY
      },
      {
        label: t("customerInvoice.CustomerInvoicePaymentHistory"),
        // eslint-disable-next-line react/jsx-pascal-case
        component: (
          <CustomerInvoicePaymentHistoryTable
            t={t}
            data={customerInvoicePaymentHistory}
          />
        ),
        viewSelector: customerInvoices,
        tabSelector: paymentHistory,
        viewType: CUSTOMER_INVOICES,
        tabType: PAYMENT_HISTORY
      }
    ],
    [
      t,
      selectedItem,
      customerInvoiceStatusHistory,
      customerInvoicePaymentHistory
    ]
  );

  return { customerInvoiceTabs, filterLabels };
};

export default useCustomerInvoiceConsts;
