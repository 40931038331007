import React, { useMemo } from "react";
import Table from "../common/table";
import { TableResultData } from "../common/tableFooter";
import { useTranslation } from "react-i18next";
import useTableActionDispatchers from "../common/redux/actions/table/hooks/useTableActionDispatchers";
import { card } from "../common/redux/types/selectorTypes";
import useTableState from "../common/redux/hooks/useTableState";
import useDataState from "../common/redux/hooks/useDataState";
import { CARD } from "../common/redux/types/viewTypes";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";

const CardTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(card);
  const { handleRowSelect } = useTableActionDispatchers(CARD);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CARD);
  const { height } = useTableState(card);
  const columns = useMemo(
    () => [
      {
        id: "isActiveCl",
        Header: t("card.FilterIsActive"),
        accessor: d => (d.isActiveCl ? t(d.isActiveCl) : ""),
        width: 80
      },
      {
        id: "customer",
        Header: t("card.CustomerName"),
        accessor: d => (d.customer ? d.customer[0].name : "")
      },
      {
        id: "cardType",
        Header: t("card.CardTypeDescription"),
        accessor: d =>
          d.cardType ? t(`classifications.${d.cardType[0].cardTypeCl}`) : ""
      },
      {
        id: "vehicleRegistrationNo",
        Header: t("card.CarRegNo"),
        accessor: d => d.vehicleRegistrationNo || ""
      },
      {
        id: "magneticStripeNo",
        Header: t("card.MagnetStripNo"),
        accessor: d => d.magneticStripeNo || ""
      },
      {
        id: "magneticStripeCurrentStatusCl",
        Header: t("card.MagnetStripCurrentStatus"),
        accessor: d =>
          d.magneticStripeCurrentStatusCl
            ? t(`classifications.${d.magneticStripeCurrentStatusCl}`)
            : ""
      },
      {
        Header: t("card.ValidFrom"),
        accessor: "validFrom"
      },
      {
        Header: t("card.ValidThru"),
        accessor: "validThru"
      }
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        data={items || []}
        isLoading={isLoading}
        selectedRow={selectedItem}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        height={height}
        columns={columns}
      />
      <TableResultData count={items.length} />
    </React.Fragment>
  );
};

export default CardTable;
