import React from "react";
import View from "../../common/view";
import VatApplicationsMainSection from "./layout/main/vatApplicationsMainSection";
import VatApplicationsSearchSection from "./layout/search/vatApplicationsSearchSection";

const VatApplicationsSection = () => {
  return (
    <View
      MainSection={VatApplicationsMainSection}
      SearchSection={VatApplicationsSearchSection}
    />
  );
};

export default VatApplicationsSection;
