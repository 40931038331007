import axios from "axios";
import { extractQueryParameters } from "../../../../services/query";
import { authorizationHeaders } from "../../../../services/queries";

export const fetchDiscountData = async payload => {
  const queryParameters = extractQueryParameters(payload);
  console.log(queryParameters);
  const data = await axios.post(
    "/graphql",
    {
      query: `query{
            customerDiscounts(${queryParameters})
            {
              id
              addToInvoice
              customer{name
                  legalAddress{country{code}}}
              serviceProvider{name}
              basedOnInvoice{invoiceNo}
              partnerSalesPoint{defaultName}
              product{defaultName}
              reseller{name}
              quantity
              unitDiscount
              netto
              vat
              issuedForInvoice{invoiceNo}
              
              isActiveCl
              createdOn
            
            }
          }
         
          `
    },
    authorizationHeaders()
  );
  return data;
};
