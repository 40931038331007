import { useSelector } from "react-redux";

const useFactoringDebtorAccountState = () => {
  const selectedRow = useSelector(
    state => state.factoringDebtorAccount.selectedRow
  );
  const searchQuery = useSelector(
    state => state.factoringDebtorAccount.search.query
  );
  const searchOpen = useSelector(
    state => state.factoringDebtorAccount.search.isOpen
  );
  const tableVisible = useSelector(
    state => state.factoringDebtorAccount.table.visible
  );
  const expandersVisible = useSelector(
    state => state.factoringDebtorAccount.expander_view
  );

  return {
    selectedRow,
    searchQuery,
    searchOpen,
    tableVisible,
    expandersVisible
  };
};

export default useFactoringDebtorAccountState;
