import { useSelector } from "react-redux";

const useCustomerInvoiceState = () => {
  const statusHistoryData = useSelector(
    state =>
      state.customerInvoices.data.selectedItem.customerInvoiceStatusHistory
  );
  const paymentHistoryData = useSelector(
    state =>
      state.customerInvoices.data.selectedItem.customerInvoicePaymentHistory
  );
  const countryList = useSelector(
    state => state.layout.classifications.countries
  );
  const invoiceStatusTypes = useSelector(
    state => state.layout.classifications.customer_invoice_status_types
  );

  return {
    countryList,
    invoiceStatusTypes,
    statusHistoryData,
    paymentHistoryData
  };
};

export default useCustomerInvoiceState;
