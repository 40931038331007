import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import useFactoringDebtorLayoutState from "../../hooks/layout/useFactoringDebtorLayoutState";
import FactoringDebtorVerticalSearchForm from "../../search/forms/factoringDebtorVerticalSearchForm";
import { useTranslation } from "react-i18next";
import useFactoringDebtorActions from "../../hooks/useFactoringDebtorActions";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { FACTORING_DEBTOR } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { factoringDebtor } from "../../../../common/redux/types/selectorTypes";

const FactoringDebtorSearchSection = () => {
  const { t } = useTranslation();
  const { searchOpen } = useFactoringDebtorLayoutState();
  const { handleSearch } = useFactoringDebtorActions();
  const { isLoading } = useDataState(factoringDebtor);
  const { handleSearchToggle } = useLayoutActionDispatchers(FACTORING_DEBTOR);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <FactoringDebtorVerticalSearchForm
        t={t}
        handleSearch={handleSearch}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default FactoringDebtorSearchSection;
