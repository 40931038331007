import React from "react";
import Table from "../../common/table";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { TableResultData } from "../../common/tableFooter";
import { VAT_DOCUMENTS } from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import { vatDocuments } from "../../common/redux/types/selectorTypes";
import useDataState from "../../common/redux/hooks/useDataState";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";

const VatDocumentsTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem, isLoading } = useDataState(vatDocuments);
  const { handleRowSelect } = useTableActionDispatchers(VAT_DOCUMENTS);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    VAT_DOCUMENTS
  );
  const { height } = useTableState(vatDocuments);
  const VatDocumentsTableTrans = "vat.documents.";

  console.log("ITEMS", items);
  const columns = [
    {
      id: "customer",
      Header: t(VatDocumentsTableTrans + "Customer"),
      accessor: d => (d.customer ? d.customer[0].name : "")
    },

    {
      id: "country",
      Header: t(VatDocumentsTableTrans + "Country"),
      accessor: d => (d.country ? t(`countries.${d.country[0].code}`) : "")
    },
    {
      Header: t(VatDocumentsTableTrans + "DocumentDate"),
      accessor: "documentDate"
    },
    {
      Header: t(VatDocumentsTableTrans + "DocumentNo"),
      accessor: "documentNo"
    },

    {
      Header: t(VatDocumentsTableTrans + "FilterSeller"),
      accessor: "sellerName"
    },
    {
      Header: t(VatDocumentsTableTrans + "DocumentNetto"),
      accessor: "documentNetto"
    },
    {
      Header: t(VatDocumentsTableTrans + "DocumentVat"),
      accessor: "documentVat"
    },

    {
      Header: t(VatDocumentsTableTrans + "DocumentCurrency"),
      accessor: "documentCurrencyCl"
    },
    {
      id: "incomeFromTaxOffice",
      Header: t(VatDocumentsTableTrans + "IncomeFromTaxOffice"),
      accessor: d =>
        !_.isEmpty(d.incomeFromTaxOffice) ? d.incomeFromTaxOffice : ""
    },
    {
      id: "payoutToCustomer",
      Header: t(VatDocumentsTableTrans + "PayoutToCustomerInEUR"),
      accessor: d => (!_.isEmpty(d.payoutToCustomer) ? d.payoutToCustomer : "")
    },
    {
      id: "applicationNo",
      Header: t(VatDocumentsTableTrans + "ApplicationNo")
      // accessor: d => (d.vatApplication ? d.vatApplication.applicationNo : "")
    },
    {
      Header: t(VatDocumentsTableTrans + "CreatedOn"),
      accessor: "createdOn"
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        isLoading={isLoading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        height={height}
        setTableHeight={handleTableHeightSet}
      />
      <TableResultData count={items.length || 0} />
    </React.Fragment>
  );
};

export default VatDocumentsTable;
