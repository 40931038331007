import React from "react";
import Table from "../../common/table";
import { useTranslation } from "react-i18next";
import useVatApplicationsLayoutState from "./hooks/layout/useVatApplicationsLayoutState";
import { TableResultData } from "../../common/tableFooter";
import useTableState from "../../common/redux/hooks/useTableState";
import { vatApplications } from "../../common/redux/types/selectorTypes";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { VAT_APPLICATIONS } from "../../common/redux/types/viewTypes";
import useDataState from "../../common/redux/hooks/useDataState";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";

const VatApplicationsSectionTable = () => {
  const { t } = useTranslation();
  const { isLoading } = useVatApplicationsLayoutState();
  const { items, selectedItem } = useDataState(vatApplications);
  const VatApplicationsTableTrans = "vat.applications.";
  const { height } = useTableState(vatApplications);
  const { handleRowSelect } = useTableActionDispatchers(VAT_APPLICATIONS);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    VAT_APPLICATIONS
  );
  const columns = [
    {
      id: "customer",
      Header: t(VatApplicationsTableTrans + "Customer"),
      accessor: d => d.customer[0].name
    },
    {
      Header: t(VatApplicationsTableTrans + "ApplicationNo"),
      accessor: "applicationNo"
    },
    {
      Header: t(VatApplicationsTableTrans + "ApplicationDate"),
      accessor: "applicationDate"
    },
    {
      Header: t(VatApplicationsTableTrans + "PeriodStart"),
      accessor: "periodStart"
    },
    {
      Header: t(VatApplicationsTableTrans + "PeriodEnd"),
      accessor: "periodEnd"
    },
    {
      id: "country",
      Header: t(VatApplicationsTableTrans + "Country"),
      accessor: d => t(`countries.${d.country[0].code}`)
    },
    {
      id: "serviceProvider",
      Header: t(VatApplicationsTableTrans + "ServiceProvider"),
      accessor: d => d.serviceProvider[0].name
    },
    {
      id: "applicationPayoutSum",
      Header: t(VatApplicationsTableTrans + "ApplicationSum"),
      accessor: d =>
        d.applicationPayoutSum ? d.applicationPayoutSum.payout_sum : ""
    },
    {
      id: "applicationUnconfirmedIncome",
      Header: t(VatApplicationsTableTrans + "Unconfirmed"),
      accessor: d =>
        d.applicationUnconfirmedIncome
          ? d.applicationUnconfirmedIncome.income_in_doc_currency_sum
          : ""
    },
    {
      Header: t(VatApplicationsTableTrans + "Unpaid"),
      accessor: "unpaidVatDoc"
    },
    {
      Header: t(VatApplicationsTableTrans + "LastIncomeDate"),
      accessor: "lastIncomeDate"
    }
  ];
  return (
    <React.Fragment>
      <Table
        classes="u-margin-bottom-small"
        height={height}
        isLoading={isLoading}
        setTableHeight={handleTableHeightSet}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        columns={columns}
        data={items || []}
      />
      <TableResultData
        count={items.length}
        enabled={false}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
      />
    </React.Fragment>
  );
};

export default VatApplicationsSectionTable;
