import React from "react";
import { FormFlexItem } from "./form/form";

const Modal = ({
  heading,
  confirmBtnHeading,
  cancelBtnHeading,
  confirmCb,
  cancelCb,
  isOpen,
  toggleCallback
}) => {
  if (isOpen) {
    return (
      <div className="modal">
        <div className="modal__container--wrapper">
          <div className="modal__container">
            <div className="modal__body">
              <ModalHeader>{heading}</ModalHeader>
              <ModalMain>
                <ModalBodySection>
                  <FormFlexItem classes="flex__justify-content--space-between">
                    <div className="btn" onClick={() => {}}>
                      {confirmBtnHeading}
                    </div>
                    <div className="btn btn--danger" onClick={() => {}}>
                      {cancelBtnHeading}
                    </div>
                  </FormFlexItem>
                </ModalBodySection>
              </ModalMain>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const ModalHeader = ({ children }) => {
  return <div className="modal__body-header">{children}</div>;
};

export const ModalMain = ({ children }) => {
  return <div className="modal__body-main">{children}</div>;
};

export const ModalBodySection = ({ children }) => {
  return <div className="modal__body-section">{children}</div>;
};

export const ModalCloseBtn = ({ handleToggle }) => {
  return (
    <div className="modal__close-btn">
      <div className="btn btn--close" onClick={() => handleToggle()}></div>
    </div>
  );
};

export default Modal;
