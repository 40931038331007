import { useSelector } from "react-redux";

const usePartnerLayoutState = () => {
  const tableVisible = useSelector(state => state.partner.layout.table.visible);
  const searchOpen = useSelector(state => state.partner.layout.search.isOpen);
  const expandersVisible = useSelector(
    state => state.partner.layout.expanders.visible
  );

  return { tableVisible, searchOpen, expandersVisible };
};

export default usePartnerLayoutState;
