import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../services/queries";

export const TOGGLE_CONTRACT_SEARCH = () => ({
  type: "TOGGLE_CONTRACT_SEARCH"
});

export const TOGGLE_CONTRACT_ROW = payload => ({
  type: "TOGGLE_CONTRACT_ROW",
  payload: payload
});

export const TOGGLE_CONTRACT_TABLE = () => ({
  type: "TOGGLE_CONTRACT_TABLE"
});

export const TOGGLE_CONTRACT_EXPANDERS = () => ({
  type: "TOGGLE_CONTRACT_EXPANDER_VIEW"
});

export const LOAD_CONTRACT_DATA_START = () => ({
  type: "LOAD_CONTRACT_DATA_START"
});

export const LOAD_CONTRACT_DATA_SUCCESS = payload => ({
  type: "LOAD_CONTRACT_DATA_SUCCESS",
  payload: payload
});

export const LOAD_CONTRACT_DATA_ERROR = payload => ({
  type: "LOAD_CONTRACT_DATA_ERROR",
  payload: payload
});

export const SUBMIT_CONTRACT_QUERY = payload => ({
  type: "SUBMIT_CONTRACT_QUERY",
  payload: payload
});

export const SELECT_CONTRACT_ROW = payload => ({
  type: "SELECT_CONTRACT_ROW",
  payload: payload
});

export const SET_CONTRACT_TABLE_SELECTED_ROWS_STATE = payload => ({
  type: "SET_CONTRACT_TABLE_SELECTED_ROWS_STATE",
  payload: payload
});

export const SET_CONTRACT_TABLE_HEIGHT = payload => ({
  type: "SET_CONTRACT_TABLE_HEIGHT",
  payload: payload
});

export const SET_CONTRACT_RESELLERS_DROPDOWN = payload => ({
  type: "SET_CONTRACT_RESELLERS_DROPDOWN",
  payload: payload
});

export const SET_CONTRACT_SERVICE_PROVIDERS_DROPDOWN = payload => ({
  type: "SET_CONTRACT_SERVICE_PROVIDERS_DROPDOWN",
  payload: payload
});

export const SET_CONTRACT_DROPDOWN_VALUES = payload => ({
  type: "SET_CONTRACT_DROPDOWN_VALUES",
  payload: payload
});

export const SET_CONTRACT_COUNTRIES_DROPDOWN = payload => ({
  type: "SET_CONTRACT_COUNTRIES_DROPDOWN",
  payload: payload
});

export const SELECT_CONTRACT_STATUS_HISTORY_ROW = payload => ({
  type: "SELECT_CONTRACT_STATUS_HISTORY_ROW",
  payload: payload
});

export const SELECT_CONTRACT_FACTORING_DEBTOR_ROW = payload => ({
  type: "SELECT_CONTRACT_FACTORING_DEBTOR_ROW",
  payload: payload
});

export const getContractDropdownData = () => {
  return dispatch => {
    getContractResellersDropdownData().then(d => {
      dispatch(SET_CONTRACT_DROPDOWN_VALUES({ resellers: d }));
      getContractServiceProvidersDropdownData().then(d =>
        dispatch(SET_CONTRACT_DROPDOWN_VALUES({ serviceProviders: d }))
      );
    });
  };
};

export const contractQuery = payload => {
  return dispatch => {
    dispatch(SUBMIT_CONTRACT_QUERY(payload));
    dispatch(LOAD_CONTRACT_DATA_START());
    loadContractData(payload)
      .then(data => dispatch(LOAD_CONTRACT_DATA_SUCCESS(data)))
      .catch(error => {
        toast.error("Error loading CONTRACT data");
        dispatch(LOAD_CONTRACT_DATA_ERROR(error.message));
      });
  };
};

export const getContractResellersDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      resellers{
        name
        id
      }
    }`
      },
      authorizationHeaders()
    );
    return data.data.data.resellers;
  } catch (err) {
    toast.error("Error loading RESELLERS");
    return [];
  }
};

export const getContractServiceProvidersDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
      serviceProviders{
        name
        id
      }
    }`
      },
      authorizationHeaders()
    );
    return data.data.data.serviceProviders;
  } catch (err) {
    toast.error("Error loading SERVICE PROVIDERS");
    return [];
  }
};

export const loadContractData = async payload => {
  const queryData = getContractQueryData(payload);
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
      query {
        contracts(${queryData}) {
          id
          contractTypeCl
          customer {name}
          isActiveCl
          contractTemplate {name}
          serviceProvider {name id}
          validFrom
          validThru
          contractNo
          reseller {name id}
          currentStatusCl
          currentStatusDate
          customerNo
          additionalInfo
          contractStatusHistory {id statusCl statusDate}
          productCostOverview {
         id
            code
            default_name
            sales_point_group_name
            default_name_2
            value_type_cl
            default_value
            level1_quantity_min
            level1_value
            level2_quantity_min
            level2_value
          }
          factoringBankAccount{
            bankAccountTypeCl
            bank{swift name}
            iban
          }
          factoringInvoices {
            factoringDebtor {name}
            invoiceNo
            invoiceDate
            dueDate
            total
            handlingFee
            contractFeePercent
          }
          
          
          factoringContractDebtors {
            id
            company {
              id
              name
              factoringDebtorServiceFees {
                createdOn
                invoiceFactoringServiceFeeTypeCl
                fixedValue
                dailyValue
              }
            }
            contract {
              handlingFees {
                createdOn
                invoiceFactoringHandlingFeeTypeCl
                value
              }
            }
          }
        }
        
      }`
      },
      authorizationHeaders()
    );
    return data.data.data.contracts;
  } catch (err) {
    toast.error("Error loading contracts");
    return [];
  }
};

const getContractQueryData = ({
  resultCount,
  customerName,
  contractType,
  contractStatus,
  contractNo,
  id,
  contractExpiresFrom,
  contractExpiresUntil,
  customerCountry,
  regno,
  vatNo,
  generalPhone,
  generalEmail,
  salesAgent
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : ""}${
    customerName ? `customer: "${customerName}"` : ""
  } ${id ? "id: " + id : ""} ${regno ? "reg_no: " + regno : ""} 
  ${contractType ? `contractType: "${contractType}"` : ""}
  ${contractStatus ? `contractStatus: "${contractStatus}"` : ""}
  ${vatNo ? "vat_no: " + vatNo : ""} 
  ${customerCountry ? `customerCountry: ${customerCountry}` : ""}
  ${contractExpiresFrom ? `expiresFrom: "${contractExpiresFrom}"` : ""}
  ${contractExpiresUntil ? `expiresUntil: "${contractExpiresUntil}"` : ""}
  ${contractNo ? `contractNo: "${contractNo}"` : ""}
  ${generalPhone ? `general_phone: "${generalPhone}"` : ""} ${
    generalEmail ? `email: "${generalEmail}"` : ""
  } ${salesAgent ? `sales_agent: "${salesAgent}"` : ""}`;
  return queryData;
};
