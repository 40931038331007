import {useCallback, useMemo} from "react";
import {useDispatch} from "react-redux";
import {SET_CUSTOMER_TABS_STATE} from "../../account/actions/customerAccountActions";
import {getCustomerInvoiceQuery} from "../../invoice/actions/customerInvoicesActions";
import {loadCustomerAccountData} from "../../account/actions/data/customerAccountDataQueries";
import {loadCustomers} from "../graphql/customersDataQueries";

const useCustomersActions = () => {
  const dispatch = useDispatch();

  const getCustomerAccountShortcutData = (itemName, dispatch, location) => {
    dispatch(loadCustomerAccountData({ client: itemName }));
  };

  const getCustomerInvoicesShortcutData = (itemName, dispatch) => {
    dispatch(getCustomerInvoiceQuery({ customer: itemName }));
  };

  const setSelectedRowsState = useCallback(payload => {}, []);

  const onSetCustomersTabsState = useMemo(
    () => payload => {
      dispatch(SET_CUSTOMER_TABS_STATE(payload));
    },
    [dispatch]
  );

  const onCustomersSearch = payload => {
    dispatch(loadCustomers(payload));
  };

  // const { selectedRowsState, selectedRows } = useCustomersState();

  // const removeRowToggle = path => {
  //   setSelectedRowsState([...selectedRowsState.filter(item => item !== path)]);
  //   handleRowToggle([...selectedRows.filter(item => item.path !== path)]);
  // };

  return {
    getCustomerAccountShortcutData,
    getCustomerInvoicesShortcutData,
    setSelectedRowsState,
    onCustomersSearch,
    onSetCustomersTabsState
  };
};

export default useCustomersActions;
