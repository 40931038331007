import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  getFactoringDebtorData,
  SELECT_FACTORING_DEBTOR_ROW
} from "../actions/factoringDebtorActions";

const useFactoringDebtorActions = () => {
  const dispatch = useDispatch();
  const handleSearch = payload => {
    dispatch(getFactoringDebtorData(payload));
  };

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_FACTORING_DEBTOR_ROW(payload));
    },
    [dispatch]
  );

  return {
    handleSearch,
    handleRowSelect
  };
};

export default useFactoringDebtorActions;
