import React, { useRef } from "react";
import {
  SectionMain,
  SectionHeader,
  SectionBody
} from "../../../common/section";
import useTransactionsLayoutActions from "../../hooks/layout/useTransactionsLayoutActions";
import useTransactionsLayoutState from "../../hooks/layout/useTransactionsLayoutState";
import useTransactionsState from "../../hooks/useTransactionsState";
import useTransactionsActions from "../../hooks/useTransactionsActions";
import useTransactionsConsts from "../../hooks/useTransactionsConsts";
import TransactionsTable from "../../transactionsTable";
import TransactionsButtons from "./transactionsButtons";
import { useTranslation } from "react-i18next";

const TransactionsMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useTransactionsLayoutActions();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useTransactionsLayoutState();
  const { searchQuery, selectedRow } = useTransactionsState();
  const { handleSearch, handleRowSelect } = useTransactionsActions();
  const { filterLabels, tabs } = useTransactionsConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("transactions.Header")}
        getFilters={() => {}}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={TransactionsTable}
        ButtonsComponent={TransactionsButtons}
        shortcutsEnabled={true}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default TransactionsMainSection;
