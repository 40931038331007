export const getCustomerAccountFilters = (payload,t) => {
  const filters = {};
  Object.assign(filters, payload);

  if(payload.customer_country){
    Object.assign(filters, {customer_country: t(`countriesIdx.${payload.customer_country}`)})
  }

  return filters;

};
