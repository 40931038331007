import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  LOAD_PARTNER_SEARCH_DATA_START,
  SELECT_PARTNER_ROW
} from "../actions/partnerActions";

const usePartnerActions = () => {
  const dispatch = useDispatch();
  const handleSearch = useCallback(
    payload => {
      dispatch(LOAD_PARTNER_SEARCH_DATA_START());
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_PARTNER_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default usePartnerActions;
