export const TOGGLE_VAT_APPLICATIONS_SEARCH = () => ({
  type: "TOGGLE_VAT_APPLICATIONS_SEARCH"
});

export const TOGGLE_VAT_APPLICATIONS_TABLE = () => ({
  type: "TOGGLE_VAT_APPLICATIONS_TABLE"
});

export const TOGGLE_VAT_APPLICATIONS_EXPANDER_VIEW = () => ({
  type: "TOGGLE_VAT_APPLICATIONS_EXPANDER_VIEW"
});

export const VAT_APPLICATIONS_SEARCH_START = () => ({
  type: "VAT_APPLICATIONS_SEARCH_START"
});

export const SELECT_VAT_APPLICATIONS_ROW = payload => ({
  type: "SELECT_VAT_APPLICATIONS_ROW",
  payload: payload
});

export const SUBMIT_VAT_APPLICATIONS_QUERY = payload => ({
  type: "SUBMIT_VAT_APPLICATIONS_QUERY",
  payload: payload
});
