import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import lang_en from "../assets/lang/lang_en.json";
import lang_et_EE from "../assets/lang/lang_et-EE.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: lang_en,
      et: lang_et_EE
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });
