import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import CardSectionDetailsForm from "../details/cardSectionDetailsForm";
import useDataState from "../../common/redux/hooks/useDataState";
import {card, details, limits, statusHistory} from "../../common/redux/types/selectorTypes";
import CardStatusHistoryTable from "../statusHistory/cardStatusHistoryTable";
import {CARD, DETAILS, LIMITS, STATUS_HISTORY} from "../../common/redux/types/viewTypes";

const useCardConsts = () => {
  const { t } = useTranslation();
  const {selectedItem} = useDataState(card);
  const filterLabels = {
    customer: t("customer.customerView.FilterCustomerName"),
    count: t("card.FilterRowCount"),
    id: t("customer.customerView.FilterCustomerId"),
    status: t("card.FilterCardCurrentStatus"),
    type: t("card.FilterCardType"),
    vatNo: t("customer.customerView.VatNo"),
    is_valid: t("card.FilterIsValid"),
    resultCount: t("customer.customerView.FilterResultCount"),
    is_active: t("card.FilterIsActive"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    valid_from: t("card.FilterValidFrom"),
    valid_thru: t("card.FilterValidThru"),
    card_no: t("card.FilterCardNumber"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    ),
    customer_country: t("customerDeposit.FilterCustomerCountry")
  };

  const tabs = useMemo(() => {
    const data = [
      {
        label: t("card.CardDetailData"),
        component: <CardSectionDetailsForm t={t} data={selectedItem}/>,
        viewSelector: card,
        viewType: CARD,
        tabSelector: details,
        tabType: DETAILS
      },
      {
        label: t("card.CardStatusHistory"),
        component: <CardStatusHistoryTable data={selectedItem} t={t}/>,
        viewSelector: card,
        viewType: CARD,
        tabSelector: statusHistory,
        tabType: STATUS_HISTORY
      },
      {
        label: t("card.CardLimit"),
        component: <React.Fragment>TODO</React.Fragment>,
        viewSelector: card,
        viewType: CARD,
        tabSelector: limits,
        tabType: LIMITS
      }
    ];
    return data;
  }, [t, selectedItem]);

  return { filterLabels, tabs };
};

export default useCardConsts;
