import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import CustomerVerticalSearchForm from "../../search/forms/customerVerticalSearchForm";
import useCustomersActions from "../../hooks/useCustomersActions";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";

const CustomerSearchSection = () => {
  const { t } = useTranslation();
  const { searchQuery } = useSearchState(customers);
  const { searchOpen } = useLayoutState(customers);
  const { isLoading } = useDataState(customers);
  const { handleSearchToggle } = useLayoutActionDispatchers(CUSTOMERS);
  const { onCustomersSearch } = useCustomersActions();
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <CustomerVerticalSearchForm
        t={t}
        handleSearch={onCustomersSearch}
        query={searchQuery}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default CustomerSearchSection;
