//CRUD

export const CLEAR_CUSTOMERS_SELECTED_ROW = () => ({
  type: "CLEAR_CUSTOMERS_SELECTED_ROW"
});

export const SELECTED_CUSTOMER_ROW_INDEX = payload => ({
  type: "SELECTED_CUSTOMER_ROW_INDEX",
  payload: payload
});

export const SELECT_CUSTOMERS_MISC_VAT_ROW = payload => ({
  type: "SELECT_CUSTOMERS_MISC_VAT_ROW",
  payload: payload
});

//LAYOUT

export const TOGGLE_CUSTOMERS_DELETE_PROMPT = () => ({
  type: "TOGGLE_CUSTOMERS_DELETE_PROMPT"
});

export const SET_CUSTOMERS_COMMENTS_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMERS_COMMENTS_TABLE_HEIGHT",
  payload: payload
});

export const SET_CUSTOMERS_INVOICE_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMERS_INVOICE_TABLE_HEIGHT",
  payload: payload
});

export const SET_CUSTOMER_MISC_EXCISE_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMER_MISC_EXCISE_TABLE_HEIGHT",
  payload: payload
});

export const SET_CUSTOMER_MISC_GUARANTOR_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMER_MISC_GUARANTOR_TABLE_HEIGHT",
  payload: payload
});

export const SET_CUSTOMER_MISC_VAT_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMER_MISC_VAT_TABLE_HEIGHT",
  payload: payload
});

/*--COMMENTS--*/
export const SELECT_CUSTOMERS_COMMENT_ROW = payload => ({
  type: "SELECT_CUSTOMERS_COMMENT_ROW",
  payload: payload
});
//--------------------------------------------------

/*----CONTACT INFORMATION ----*/
export const CUSTOMERSCONTACTROWSELECT = payload => ({
  type: "CUSTOMERSCONTACTROWSELECT",
  payload: payload
});
//--------------------------------------------------
