export const TOGGLE_DISCOUNT_TABLE = () => ({
  type: "TOGGLE_DISCOUNT_TABLE"
});

export const TOGGLE_DISCOUNT_EXPANDER_VIEW = () => ({
  type: "TOGGLE_DISCOUNT_EXPANDER_VIEW"
});

export const TOGGLE_DISCOUNT_SEARCH = () => ({
  type: "TOGGLE_DISCOUNT_SEARCH"
});

export const LOAD_DISCOUNT_SEARCH_DATA_START = () => ({
  type: "LOAD_DISCOUNT_SEARCH_DATA_START"
});
