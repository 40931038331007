import axios from "axios";
import { authorizationHeaders } from "../../../../services/queries";
import { toast } from "react-toastify";

export const TOGGLE_FACTORING_DEBTOR_ACC_SEARCH = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_ACCOUNT_SEARCH"
});

export const TOGGLE_FACTORING_DEBTOR_ACC_TABLE = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_ACCOUNT_TABLE"
});

export const TOGGLE_FACTORING_DEBTOR_ACC_EXPANDERS = () => ({
  type: "TOGGLE_FACTORING_DEBTOR_ACCOUNT_EXPANDER_VIEW"
});

export const SUBMIT_FACTORING_DEBTOR_ACC_QUERY = payload => ({
  type: "SUBMIT_FACTORING_DEBTOR_ACCOUNT_QUERY",
  payload: payload
});

export const SELECT_FACTORING_DEBTOR_ACC_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_ACCOUNT_ROW",
  payload: payload
});

export const SELECT_FACTORING_DEBTOR_ACC_CLEARING_ROW = payload => ({
  type: "SELECT_FACTORING_DEBTOR_ACC_CLEARING_ROW",
  payload: payload
});

export const SET_FACTORING_DEBTOR_ACC_TABLE_HEIGHT = payload => ({
  type: "SET_FACTORING_DEBTOR_ACC_TABLE_HEIGHT",
  payload: payload
});

export const LOAD_FACTORING_DEBTOR_ACC_DATA_START = () => ({
  type: "LOAD_FACTORING_DEBTOR_ACCOUNT_DATA_START"
});

export const LOAD_FACTORING_DEBTOR_ACC_DATA_SUCCESS = payload => ({
  type: "LOAD_FACTORING_DEBTOR_ACCOUNT_DATA_SUCCESS",
  payload: payload
});

export const LOAD_FACTORING_DEBTOR_ACC_DATA_ERROR = payload => ({
  type: "LOAD_FACTORING_DEBTOR_ACCOUNT_DATA_ERROR",
  payload: payload
});

export const loadFactoringDebtorAccData = payload => {
  return dispatch => {
    dispatch(SUBMIT_FACTORING_DEBTOR_ACC_QUERY(payload));
    dispatch(LOAD_FACTORING_DEBTOR_ACC_DATA_START());
    getFactoringDebtorAccData()
      .then(d => dispatch(LOAD_FACTORING_DEBTOR_ACC_DATA_SUCCESS(d)))
      .catch(err => {
        toast.error(err.message);
        dispatch(LOAD_FACTORING_DEBTOR_ACC_DATA_ERROR(err));
      });
  };
};

export const getFactoringDebtorAccData = async payload => {
  // const queryData = { resultCount: 100 };
  const data = await axios.post(
    "/graphql",
    {
      query: `query {
      customerAccounts(count: 1000 type:FACTORING_DEBTOR) {
        id
        entryBalance
        customer{name}
        entryNo
        
        entryTime
        sum
        entrySourceTypeCl
        entrySource
        additionalInfo
        relatedCustomerClearingDocumentItems {
          id
          description
          balance
          sum
          created_on
          customer_account_id
          customer_clearing_document_id
          entry_time
          additional_info
        }
      }
  }`
    },
    authorizationHeaders()
  );
  return data.data.data.customerAccounts;
};
