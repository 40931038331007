import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import VatApplicationsSectionDetailsForm from "../details/vatApplicationsSectionDetailsForm";
import {applicationPaymentInfo, details, vatApplications} from "../../../common/redux/types/selectorTypes";
import {APPLICATION_PAYMENT_INFO, DETAILS, VAT_APPLICATIONS} from "../../../common/redux/types/viewTypes";

const useVatApplicationsConsts = () => {
  const { t } = useTranslation();

  const filterLabels = useMemo(
    () => ({
      customer: t("vat.applications.FilterCustomer"),
      application_no: t("vat.applications.FilterApplicationNo"),
      application_date_to: t("vat.applications.FilterApplicationDateTo"),
      application_date_from: t("vat.applications.FilterApplicationDateFrom"),
      period_start: t("vat.applications.FilterPeriodStart"),
      period_end: t("vat.applications.FilterPeriodEnd"),
      reseller: t("vat.applications.FilterReseller"),
      service_provider: t("vat.applications.FilterServiceProvider"),
      country: t("vat.applications.FilterCountry"),
      salesAgent: t("vat.applications.FilterSalesAgent"),
      is_confirmed: t("vat.applications.FilterIsConfirmed"),
      is_active: t("vat.applications.FilterIsActive"),
      email_sent: t("vat.applications.FilterIsEmailSent"),
      netting: t("vat.applications.FilterIsNettingNeeded"),
      status: t("vat.applications.FilterStatus"),
      count: t("vat.applications.FilterRowCount")
    }),
    [t]
  );

  const tabs = useMemo(
    () => [
      {
        label: t("vat.applications.VatApplicationDetailData"),
        component: <VatApplicationsSectionDetailsForm t={t} />,
          viewSelector: vatApplications,
          viewType: VAT_APPLICATIONS,
          tabSelector: details,
          tabType: DETAILS
      },
      {
        label: t("vat.applications.VatApplicationPaymentInfo"),
        component: <React.Fragment>PAYMENT INFO //TODO</React.Fragment>,
          viewSelector: vatApplications,
          viewType: VAT_APPLICATIONS,
          tabSelector: applicationPaymentInfo,
          tabType: APPLICATION_PAYMENT_INFO
      }
    ],
    [t]
  );

  return { filterLabels, tabs };
};

export default useVatApplicationsConsts;
