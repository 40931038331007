import React, { useRef, useLayoutEffect } from "react";
import { InputFeedback, Label } from "../form/form";
import useCustomersAutocompleteData from "./useCustomersAutocompleteData";
import useSearchState from "../redux/hooks/useSearchState";

const CustomerAutocompleteInputField = ({
  type,
  id,
  label,
  classes,
  error,
  value,
  handleChange,
  className,
  autocomplete,
  actionType,
  selectorType,
  ...props
}) => {
  const { companyAutocompleteData: data } = useSearchState(selectorType);
  const {
    handleCompletionClick,
    handleAutocompleteClear,
    loadCompanyAutocomplete,
    clearCompanyAutocompleteSelectedItem
  } = useCustomersAutocompleteData(actionType);
  const dropdownRef = useRef();
  const selectedItem = useRef(0);

  useLayoutEffect(() => {
    const parentEl = dropdownRef.current;
    const inputEl = document.querySelector(`#${id}`);
    const childElements = parentEl.getElementsByTagName("li");

    inputEl.parentElement.addEventListener("keydown", e => {
      if (e.keyCode === 38 && childElements.length) {
        e.preventDefault();
        if (selectedItem.current - 1 > 0) {
          selectedItem.current = selectedItem.current - 1;
          childElements[selectedItem.current - 1].focus();
        } else if (selectedItem.current === 1) {
          inputEl.focus();
          selectedItem.current -= 1;
        }
      } else if (e.keyCode === 40 && childElements.length) {
        e.preventDefault();
        if (childElements.length >= selectedItem.current + 1) {
          childElements[selectedItem.current].focus();
          selectedItem.current += 1;
        } else if (selectedItem.current === 0 && childElements.length === 1) {
          childElements[selectedItem.current].focus();
        }
      } else if (e.keyCode === 13 && childElements.length) {
        const name = childElements[selectedItem.current + 1].innerHTML;
        handleCompletionClick(name);
        selectedItem.current = 0;
        inputEl.focus();
        e.preventDefault();
      } else {
        inputEl.focus();
        handleAutocompleteClear();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={"autocomplete " + classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        type={type}
        value={value}
        onChange={e => {
          handleChange(e);
          loadCompanyAutocomplete(e);
          clearCompanyAutocompleteSelectedItem();
        }}
        data-lpignore="true"
        {...props}
        autoComplete={autocomplete || "off"}
      />
      <InputFeedback error={error} />
      <ul className="autocomplete__dropdown" ref={dropdownRef}>
        {data.length
          ? data.map((item, i) => (
              <li
                className="autocomplete__dropdown-item"
                key={i}
                tabIndex="-1"
                onClick={() => handleCompletionClick(item.name)}
              >
                {item.name}
              </li>
            ))
          : []}
      </ul>
    </div>
  );
};

export default CustomerAutocompleteInputField;
