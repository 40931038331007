import { useDispatch } from "react-redux";
import {
  TOGGLE_SALESPOINT_TABLE,
  TOGGLE_SALESPOINT_SEARCH,
  TOGGLE_SALESPOINT_EXPANDER_VIEW
} from "../../actions/layout/salespointLayoutactions";

const useSalespointLayoutActions = () => {
  const dispatch = useDispatch();
  const handleTableToggle = () => {
    dispatch(TOGGLE_SALESPOINT_TABLE());
  };
  const handleExpandersToggle = () => {
    dispatch(TOGGLE_SALESPOINT_EXPANDER_VIEW());
  };
  const handleSearchToggle = () => {
    dispatch(TOGGLE_SALESPOINT_SEARCH());
  };

  return { handleExpandersToggle, handleSearchToggle, handleTableToggle };
};

export default useSalespointLayoutActions;
