export const SUBMIT_VAT_APPLICATIONS_QUERY = payload => ({
  type: "SUBMIT_VAT_APPLICATIONS_QUERY",
  payload: payload
});

export const LOAD_VAT_APPLICATIONS_DATA_START = () => ({
  type: "LOAD_VAT_APPLICATIONS_DATA_START"
});

export const LOAD_VAT_APPLICATIONS_DATA_SUCCESS = payload => ({
  type: "LOAD_VAT_APPLICATIONS_DATA_SUCCESS",
  payload: payload
});

export const LOAD_VAT_APPLICATIONS_DATA_ERROR = payload => ({
  type: "LOAD_VAT_APPLICATIONS_DATA_ERROR",
  payload: payload
});
