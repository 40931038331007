import React, { useRef } from "react";
import {
  SectionMain,
  SectionBody,
  SectionHeader
} from "../../../../common/section";
import _ from "lodash";
import CustomerAccountTable from "../../customerAccountTable";
import CustomerAccountBtnGroup from "../table/customerAccountBtnGroup";
import useCustomerAccountUtils from "../../hooks/useCustomerAccountUtils";
import useCustomerAccountConsts from "../../hooks/useCustomerAccountConsts";
import { useTranslation } from "react-i18next";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CUSTOMER_ACCOUNT } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";
import { loadCustomerAccountData } from "../../actions/data/customerAccountDataQueries";
import { useDispatch } from "react-redux";

const CustomerAccountMainSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleExpandersToggle,
    handleTableToggle
  } = useLayoutActionDispatchers(CUSTOMER_ACCOUNT);
  const { selectedItem } = useDataState(customerAccount);
  const { searchQuery } = useSearchState(customerAccount);
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    customerAccount
  );
  const { getCustomerAccountFilters } = useCustomerAccountUtils();

  const onLoadCustomerAccountData = payload => {
    dispatch(loadCustomerAccountData(payload));
  };

  const { filterLabels, customerAccountTabs } = useCustomerAccountConsts();
  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("customerAccount.Header")}
        getFilters={getCustomerAccountFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={onLoadCustomerAccountData}
        searchOpen={searchOpen}
      />
      <SectionBody
        TableComponent={CustomerAccountTable}
        ButtonsComponent={CustomerAccountBtnGroup}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        expandersEnabled={expandersVisible}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.customer[0].name : ""
        }
        tableRef={tableRef}
        tabs={customerAccountTabs}
      />
    </SectionMain>
  );
};

export default CustomerAccountMainSection;
