import React from "react";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customerInvoices } from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CUSTOMER_INVOICES,
  PAYMENT_HISTORY
} from "../../../common/redux/types/viewTypes";

const CustomerInvoicePaymentHistoryTable = ({ t, data }) => {
  const { table, selectedItem } = useTabState(
    customerInvoices,
    "paymentHistory"
  );
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CUSTOMER_INVOICES, PAYMENT_HISTORY);

  const CustomerInvoiceTableTrans = "customerInvoice.payment_history.";
  const columns = [
    {
      Header: t(CustomerInvoiceTableTrans + "PaymentDate"),
      accessor: "paymentTime"
    },

    {
      Header: t(CustomerInvoiceTableTrans + "Payment"),
      accessor: "payment"
    },
    {
      Header: t(CustomerInvoiceTableTrans + "InvoiceBalance"),
      accessor: "invoiceBalance"
    },
    {
      Header: t(CustomerInvoiceTableTrans + "PaymentBalance"),
      accessor: "paymentBalance"
    }
  ];

  return (
    <Table
      columns={columns}
      data={data || []}
      onRowSelect={handleRowSelect}
      setTableHeight={handleTableHeightSet}
      selectedRow={selectedItem}
      height={table.height}
    />
  );
};

export default CustomerInvoicePaymentHistoryTable;
