import {useEffect} from "react";
import {useDispatch} from "react-redux";

export const getFormChange = (e, values) => {
  return {...values, [e.target.name]: e.target.value};
};

export const handleFormChange = (e, values, formsState, dispatcher) => {
  dispatcher(Object.assign(formsState, getFormChange(e, values)));
};

export const handleFormRefChange = (reference, e, values) => {
  reference = getFormChange(e, values);
};

export const useFormChange = (e, values) => {
  const dispatch = useDispatch();

  useEffect(
    (e, values) => {
      dispatch(handleFormChange(e, values));
    },
    [e, values, dispatch]
  );
};
