import React, { Component } from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../common/form/form";

class PartnerSectionAddressesForm extends Component {
  render() {
    const { t } = this.props;
    const PartnerAddressesTrans = "partner.addresses.";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <h4 className="heading__quaternary">
                {t(PartnerAddressesTrans + "PartnerLegalAddress")}
              </h4>
              <SelectInputField
                label={t(PartnerAddressesTrans + "PartnerLegalAddressCountry")}
                id="partnerLegalAddressCountry"
                classes="form__group"
                options={["Estonia", "Poland", "Latvia"]}
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerAddressesTrans + "PartnerLegalAddressCityNameList"
                )}
                id="partnerLegalAddressCity"
                classes="form__group"
                options={["Tallinn", "Riga", "Warsaw"]}
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerAddressesTrans + "PartnerLegalAddressStreetNameList"
                )}
                id="partnerLegalAddressStreet"
                classes="form__group"
                options={["Roseni", "Veerenni", "Akadeemia"]}
                onChange={handleChange}
              />
              <InputField
                label={t(
                  PartnerAddressesTrans + "PartnerLegalAddressHouseOrApartment"
                )}
                id="partnerLegalAddressHouseOrApartment"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(PartnerAddressesTrans + "PartnerLegalAddressZipCode")}
                id="partnerLegalAddressZipCode"
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quaternary">
                {t(PartnerAddressesTrans + "PartnerPostalAddress")}
              </h4>
              <SelectInputField
                label={t(PartnerAddressesTrans + "PartnerPostalAddressCountry")}
                id="partnerPostalAddressCountry"
                classes="form__group"
                options={["Estonia", "Poland", "Latvia"]}
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerAddressesTrans + "PartnerPostalAddressCityNameList"
                )}
                id="partnerPostalAddressCity"
                classes="form__group"
                options={["Tallinn", "Riga", "Warsaw"]}
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerAddressesTrans + "PartnerPostalAddressStreetNameList"
                )}
                id="partnerPostalAddressStreet"
                classes="form__group"
                options={["Roseni", "Veerenni", "Akadeemia"]}
                onChange={handleChange}
              />
              <InputField
                label={t(
                  PartnerAddressesTrans + "PartnerPostalAddressHouseOrApartment"
                )}
                id="partnerPostalAddressHouseOrApartment"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                label={t(PartnerAddressesTrans + "PartnerPostalAddressZipCode")}
                id="partnerPostalAddressZipCode"
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    );
  }
}

export default PartnerSectionAddressesForm;
