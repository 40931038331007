import React, { useMemo, useCallback } from "react";
import Table from "../../common/table";
import { numberWithSpaces } from "../../../services/numberFunctions";
import { Loading } from "../../common/loading";
import { useTranslation } from "react-i18next";
import { TableResultData } from "../../common/tableFooter";
import useDataState from "../../common/redux/hooks/useDataState";
import { customerAccount } from "../../common/redux/types/selectorTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import { CUSTOMER_ACCOUNT } from "../../common/redux/types/viewTypes";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";

const CustomerAccountTable = props => {
  const { t } = useTranslation();
  const { isLoading, items: data } = useDataState(customerAccount);
  const { height } = useTableState(customerAccount);
  const { selectedItem } = useDataState(customerAccount);
  const { handleRowSelect } = useTableActionDispatchers(CUSTOMER_ACCOUNT);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    CUSTOMER_ACCOUNT
  );

  // useEffect(() => {
  //   dispatch(handleTableHeightSet(300));
  // }, []);

  const getColumns = useCallback(() => {
    const CustomerAccountTrans = "customerAccount.";
    const columns = [
      {
        Header: t(CustomerAccountTrans + "EntryNumber"),
        accessor: "entryNo",
        width: 80
      },

      {
        id: "client",
        Header: t(CustomerAccountTrans + "details.Client"),
        accessor: d => (d.customer ? d.customer[0].name : "")
      },
      {
        Header: t(CustomerAccountTrans + "details.Date"),
        accessor: "entryTime",
        width: 80
      },
      {
        id: "sum",
        cellAlign: "right",
        minWidth: 130,
        Header: t(CustomerAccountTrans + "details.Amount"),
        accessor: d => (d.sum ? numberWithSpaces(d.sum) : "")
      },

      {
        id: "entryBalance",
        Header: t(CustomerAccountTrans + "EntryBalance"),
        accessor: d => {
          if (d.entryBalance) {
            return d.entryBalance;
          } else {
            return "0";
          }
        }
      },
      {
        id: "entrySourceTypeCl",
        Header: t(CustomerAccountTrans + "EntrySourceType"),
        width: 100,
        accessor: d =>
          t(
            `classifications.ENTRY_SOURCE_TYPE.${
              d.entrySourceTypeCl.split(".")[1]
            }`
          )
      },
      {
        id: "entrySource",
        Header: t(CustomerAccountTrans + "EntrySource"),
        accessor: d => d.entrySource || ""
      },
      {
        id: "additionalInfo",
        Header: t(CustomerAccountTrans + "AdditionalInfo"),
        accessor: d => d.additionalInfo || "",
        minWidth: 300
      }
    ];

    return columns;
  }, [t]);

  const tableColumns = useMemo(() => getColumns(), [getColumns]);

  return (
    <React.Fragment>
      <Table
        columns={tableColumns}
        data={data}
        height={height}
        setTableHeight={handleTableHeightSet}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        setSelectedRowsState={() => {}}
        selectedRows={[]}
        isLoading={isLoading}
        onToggleRow={() => {}}
        loadingComponent={Loading}
        tableRef={props.tableRef}
      />
      <TableResultData
        count={data.length}
        selectedRows={[]}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};

export default CustomerAccountTable;
