import React from "react";
import View from "../common/view";
import TransactionsMainSection from "./layout/main/transactionsMainSection";
import TransactionsSearchSection from "./layout/search/transactionsSearchSection";

const TransactionsView = () => {
  return (
    <View
      MainSection={TransactionsMainSection}
      SearchSection={TransactionsSearchSection}
    />
  );
};

export default TransactionsView;
