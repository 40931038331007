export const TOGGLE_EXPANDERS = name => ({
  type: `TOGGLE_${name}_EXPANDER_VIEW`
});

export const TOGGLE_TABLE = name => ({
  type: `TOGGLE_${name}_TABLE`
});

export const TOGGLE_SEARCH = name => ({
  type: `TOGGLE_${name}_SEARCH`
});

export const SET_TABLE_HEIGHT = (name, payload) => ({
  type: `SET_${name}_TABLE_HEIGHT`,
  payload: payload
});

export const TOGGLE_TAB_EXPANDER = (viewType, tabType) => ({
  type: `TOGGLE_${viewType}_${tabType}_EXPANDER`
});
