import React from "react";
import View from "../../view";
import CustomerViewPlaceholderMainSection from "./customerViewPlaceholderMainSection";
import CustomerViewPlaceholderSearchSection from "./customerViewPlaceholderSearchSection";

const CustomerViewPlaceholder = () => {
  return (
    <View
      MainSection={CustomerViewPlaceholderMainSection}
      SearchSection={CustomerViewPlaceholderSearchSection}
    />
  );
};

export default CustomerViewPlaceholder;
