import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  CustomSelect,
  DateInputField,
  InputField,
  NewSelect,
  SelectInputField
} from "../../../../common/form/form";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";

const CustomerVerticalSearchForm = forwardRef(
  ({ t, handleSearch, query }, ref) => {
    const { selectedAutocompleteValue } = useSearchState(customers);

    // const CustomerSearchSchema = Yup.object().shape({
    //   resultCount: Yup.number()
    //     .typeError(t("validation.NoRequired"))
    //     .required(t("validation.Required"))
    // });

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        // validationSchema={CustomerSearchSchema}
        initialValues={{
          count: query.count || 1000,
          name: selectedAutocompleteValue,
          id: query.id || "",
          regNo: query.regNo || "",
          vatNo: query.vatNo || "",
          general_phone: query.general_phone || "",
          email: query.email || "",
          contactPerson: query.contactPerson || "",
          salesAgent: query.salesAgent || "",
          customerManager: query.customerManager || "",
          woCusManager: query.woCusManager ? query.woCusManager : "",
          is_active: query.is_active || "true",
          portOneContractsCount: query.portOneContractsCount || "",
          otherContractsCount: query.otherContractsCount || "",
          valid_from: query.valid_from || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="name"
              value={values.name}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
              data-lpignore="true"
              actionType={CUSTOMERS}
              selectorType={customers}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="id"
              value={values.id}
              onChange={handleChange}
              label={t("customer.customerView.FilterCustomerId")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="regNo"
              value={values.regNo}
              onChange={handleChange}
              label={t("customer.customerView.FilterRegNo")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="vatNo"
              value={values.vatNo}
              onChange={handleChange}
              label={t("customer.customerView.VatNo")}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="general_phone"
              value={values.general_phone}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralPhone")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="customersGeneralEmail"
              name="email"
              value={values.email}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralOrInvoiceEmail")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="contactPerson"
              value={values.contactPerson}
              onChange={handleChange}
              label={t("customer.customerView.FilterContactPerson")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              id="salesAgent"
              value={values.salesAgent}
              onChange={handleChange}
              label={t("customer.customerView.SalesAgent")}
            />

            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }]}
              id="customerManager"
              value={values.customerManager}
              onChange={handleChange}
              label={t("customer.customerView.CustomerManager")}
            />

            <SelectInputField
              label={t("customer.customerView.WithoutCustomerManager")}
              classes="form__group-stack"
              id="woCusManager"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                {
                  name: t("common.ReplyNo"),
                  value: "false"
                }
              ]}
              onChange={handleChange}
              value={values.woCusManager}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="customerSearchValidFrom"
              name="valid_from"
              placement="top"
              value={values.valid_from}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerValidFrom")}
            />
            <BoolSelectInputField
              classes="form__group-stack"
              name="is_active"
              id="customerSearchIsActive"
              value={values.is_active}
              onChange={handleChange}
              label={t("customer.customerView.FilterIsActive")}
            />
            <InputField
              type="number"
              classes="form__group-stack"
              id="resultCount"
              name="count"
              value={values.count}
              // error={errors.count}
              onChange={handleChange}
              label={t("customer.customerView.FilterResultCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default CustomerVerticalSearchForm;
