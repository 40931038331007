import React from "react";
import Table from "../../../common/table";
import { useTranslation } from "react-i18next";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customerInvoices } from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CUSTOMER_INVOICES,
  STATUS_HISTORY
} from "../../../common/redux/types/viewTypes";

const CustomerInvoiceStatusTable = ({ data }) => {
  const { t } = useTranslation();
  const { table, selectedItem } = useTabState(
    customerInvoices,
    "statusHistory"
  );
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CUSTOMER_INVOICES, STATUS_HISTORY);
  const columns = [
    {
      id: "statusCl",
      Header: t("customerInvoice.status.StatusDescription"),
      accessor: d =>
        t(`classifications.CONTRACT_STATUS.${d.statusCl.split(".")[1]}`)
    },
    {
      Header: t("customerInvoice.status.CreatedOn"),
      accessor: "createdOn"
    },
    {
      id: "createdByUser",
      Header: t("customerInvoice.status.CreatedBy"),
      accessor: d => d.createdByUser[0].firstName
    }
  ];

  const sortColumn = "status";
  return (
    <div className="customer__invoice-details__table">
      <Table
        columns={columns}
        data={data || []}
        height={table.height}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        sortColumn={sortColumn}
        selectedRow={selectedItem}
      />
    </div>
  );
};
export default CustomerInvoiceStatusTable;
