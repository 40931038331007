import React, { useEffect } from "react";
import _ from "lodash";
import Table from "../../common/table";
import { useTranslation } from "react-i18next";
import useTabState from "../../common/redux/hooks/useTabState";
import { contract, productCost } from "../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../common/redux/actions/table/useTabTableActionDispatchers";
import { CONTRACT, PRODUCT_COST } from "../../common/redux/types/viewTypes";
import useTabActionDispatchers from "../../common/redux/actions/tab/useTabActionDispatchers";

const ContractViewShowProductCostTable = ({ data }) => {
  const { t } = useTranslation();
  const { table, selectedItem } = useTabState(contract, productCost);
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CONTRACT, PRODUCT_COST);
  const ContractShowProductTrans = "contract.productCostTable.";
  const { handleTabSelectedItemClear } = useTabActionDispatchers(
    CONTRACT,
    PRODUCT_COST
  );

  useEffect(() => {
    if (!_.isEmpty(data)) {
      handleTabSelectedItemClear();
    }
  }, [data, handleTabSelectedItemClear]);

  const columns = [
    {
      id: "country",
      Header: t(ContractShowProductTrans + "CountryCode"),
      accessor: d => (d.code ? t(`countries.${d.code}`) : "")
    },
    {
      Header: t(ContractShowProductTrans + "ProductName"),
      accessor: d => d.default_name || ""
    },
    {
      Header: t(ContractShowProductTrans + "SalesPointGroupName"),
      accessor: d => d.sales_point_group_name || ""
    },
    {
      Header: t(ContractShowProductTrans + "CostValueType"),
      accessor: d =>
        d.value_type_cl ? t(`classifications.${d.value_type_cl}`) : ""
    },
    {
      Header: t(ContractShowProductTrans + "ValueFrom0QuantityStr"),
      accessor: d => d.default_value || ""
    },
    {
      Header: t(ContractShowProductTrans + "FromQuantity1"),
      accessor: d => d.level1_quantity_min || ""
    },
    {
      Header: t(ContractShowProductTrans + "ValueFrom1QuantityStr"),
      accessor: d => d.level1_value || ""
    },
    {
      Header: t(ContractShowProductTrans + "FromQuantity2"),
      accessor: d => d.level2_quantity_min || ""
    },
    {
      Header: t(ContractShowProductTrans + "ValueFrom2QuantityStr"),
      accessor: d => d.level2_value || ""
    }
  ];

  const idtoData = data => {
    if (!_.isEmpty(data)) {
      return data.map((item, idx) => {
        return Object.assign(item, { id: idx });
      });
    } else {
      return [];
    }
  };

  return (
    <Table
      columns={columns}
      onRowSelect={handleRowSelect}
      setTableHeight={handleTableHeightSet}
      selectedRow={selectedItem}
      data={idtoData(data)}
      height={table.height}
    />
  );
};

export default ContractViewShowProductCostTable;
