import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import useVatApplicationsLayoutState from "../../hooks/layout/useVatApplicationsLayoutState";
import VatApplicationsVerticalSearchForm from "../../search/forms/vatApplicationsVerticalSearchForm";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { VAT_APPLICATIONS } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { vatDocuments } from "../../../../common/redux/types/selectorTypes";

const VatApplicationsSearchSection = () => {
  const { searchOpen } = useVatApplicationsLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(VAT_APPLICATIONS);
  const { isLoading } = useDataState(vatDocuments);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      dataLoading={isLoading}
      onSearchToggle={handleSearchToggle}
    >
      <VatApplicationsVerticalSearchForm ref={formRef} />
    </SearchSection>
  );
};

export default VatApplicationsSearchSection;
