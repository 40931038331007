import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../../common/form/form";

const FactoringDebtorVerticalSearchForm = forwardRef(
  ({ t, selectedAutocompleteValue, handleSearch }, ref) => {
    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          resultCount: 1000,
          name: selectedAutocompleteValue || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="flex__flex-direction--column">
            <InputField
              type="text"
              classes="form__group-stack"
              id="name"
              value={values.name}
              onChange={handleChange}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="id"
              value={values.id}
              onChange={handleChange}
              label={t("customer.customerView.FilterCustomerId")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="regno"
              value={values.regno}
              onChange={handleChange}
              label={t("customer.customerView.FilterRegNo")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="vatNo"
              value={values.vatNo}
              onChange={handleChange}
              label={t("customer.customerView.VatNo")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="generalPhone"
              value={values.generalPhone}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralPhone")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="generalEmail"
              value={values.generalEmail}
              onChange={handleChange}
              label={t("customer.customerView.FilterGeneralOrInvoiceEmail")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="contactPerson"
              value={values.contactPerson}
              onChange={handleChange}
              label={t("customer.customerView.FilterContactPerson")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              id="customerManager"
              value={values.isActive}
              onChange={handleChange}
              label={t("customer.customerView.FilterIsActive")}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="valid_from"
              value={values.validFrom}
              handleChange={setFieldValue}
              label={t("customer.customerView.FilterCustomerValidFrom")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="resultCount"
              value={values.resultCount}
              onChange={handleChange}
              label={t("customer.customerView.FilterResultCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);
export default FactoringDebtorVerticalSearchForm;
