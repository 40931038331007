import React from "react";

export const Button = ({
  label,
  size,
  btnStyle,
  type = "button",
  handleClick,
  classes,
  disabled,
  style,
  ...rest
}) => {
  let btnClass;
  let disabledClass = disabled ? "btn--disabled" : "";
  btnClass = `btn btn--${size} btn--${btnStyle} ${disabledClass}`;

  return (
    <button
      type={type}
      className={btnClass + " " + classes}
      onClick={handleClick}
      style={style || {}}
      {...rest}
    >
      {label}
    </button>
  );
};

export const ButtonSubmit = ({ label, handleSubmit, classes, disabled }) => {
  return (
    <div className="form__group-stack search__btn-main">
      <button
        className={"btn btn--primary " + classes || ""}
        type="submit"
        onClick={handleSubmit}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
};

export const ButtonReset = ({ label, handleReset, classes }) => {
  return (
    <div className="form__group-stack search__btn-main">
      <button
        className={"btn btn--danger " + classes || ""}
        type="button"
        onClick={handleReset}
      >
        {label}
      </button>
    </div>
  );
};
