import React, { forwardRef } from "react";
import { Formik } from "formik";

import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../../common/form/form";

const FactoringInvoicesVerticalSearchForm = forwardRef(
  ({ t, handleSearch }, ref) => {
    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          resultCount: 1000
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("SUUB");
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form
            className="flex__flex-direction--column"
            onSubmit={handleSubmit}
          >
            <InputField
              type="text"
              id="customerName"
              classes="form__group-stack"
              value={values.customer}
              onChange={handleChange}
              label={t("factoring.invoices.FactoringInvoice")}
            />
            <SelectInputField
              id="factoringDebtor"
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: "FDebtor1", value: "f1" },
                { name: "FDebtor2", value: "f2" }
              ]}
              value={values.factoringDebtor}
              onChange={handleChange}
              label={t("factoring.invoices.FactoringDebtor")}
            />
            <InputField
              type="text"
              id="invoiceNo"
              classes="form__group-stack"
              value={values.invoiceNo}
              onChange={handleChange}
              label={t("factoring.invoices.InvoiceNo")}
            />
            <InputField
              type="text"
              id="contractAnnexNo"
              classes="form__group-stack"
              value={values.contractAnnexNo}
              onChange={handleChange}
              label={t("factoring.invoices.ContractAnnexNo")}
            />
            <SelectInputField
              id="isInContractAnnex"
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              value={values.isInContractAnnex}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsInContractAnnex")}
            />
            <SelectInputField
              id="isPaid"
              classes="form__group-stack"
              options={[
                {
                  name: "",
                  value: ""
                },
                {
                  name: t("common.ReplyYes"),
                  value: "true"
                },
                {
                  name: t("common.ReplyNo"),
                  value: "false"
                }
              ]}
              value={values.isPaid}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsPaid")}
            />
            <DateInputField
              type="date"
              id="dateFrom"
              classes="form__group-stack"
              value={values.dateFrom}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DateFrom")}
            />
            <DateInputField
              type="date"
              id="dateTo"
              classes="form__group-stack"
              value={values.dateTo}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DateTo")}
            />
            <DateInputField
              type="date"
              id="dueDateFrom"
              classes="form__group-stack"
              value={values.dueDateFrom}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DueDateFrom")}
            />
            <DateInputField
              type="date"
              id="dueDateTo"
              classes="form__group-stack"
              value={values.dueDateTo}
              handleChange={setFieldValue}
              label={t("factoring.invoices.DueDateTo")}
            />
            <SelectInputField
              id="isSummaryInvoice"
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              value={values.isSummaryInvoice}
              onChange={handleChange}
              label={t("factoring.invoices.FilterIsSummaryInvoice")}
            />
            <SelectInputField
              id="hasCancellation"
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              value={values.hasCancellation}
              onChange={handleChange}
              label={t("factoring.invoices.FilterHasCancellation")}
            />

            <InputField
              id="resultCount"
              name="resultCount"
              classes="form__group-stack"
              value={values.resultCount}
              onChange={handleChange}
              label={t("factoring.debtor.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default FactoringInvoicesVerticalSearchForm;
