import { useSelector } from "react-redux";

const useContractLayoutState = () => {
  const searchOpen = useSelector(state => state.contract.layout.search.isOpen);
  const tableVisible = useSelector(
    state => state.contract.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.contract.layout.expanders.visible
  );

  return { searchOpen, tableVisible, expandersVisible };
};

export default useContractLayoutState;
