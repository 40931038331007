import React, { useMemo, forwardRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../../common/form/form";
import { customerInvoices } from "../../../../common/redux/types/selectorTypes";
import { CUSTOMER_INVOICES } from "../../../../common/redux/types/viewTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";

const CustomerInvoiceSearchVerticalForm = forwardRef(
  ({ handleSearch, query, countryList, invoiceStatusTypes }, ref) => {
    const CustomerInvoiceSearchFormTrans = "customerInvoice.";
    const { t } = useTranslation();
    const { selectedAutocompleteValue } = useSearchState(customerInvoices);

    const invoiceStatusTypesData = invoiceStatusTypes
      ? invoiceStatusTypes
      : [{ name: "", value: "" }];

    const getInvoiceStatusTypesData = useMemo(
      () => [
        { code: t("countries.None"), id: "" },
        ...invoiceStatusTypesData.map(item => {
          return Object.assign(
            {},
            {
              name: t(
                `classifications.CONTRACT_STATUS.${item.value.split(".")[1]}`
              ),
              value: item.value.split("CUSTOMER_")[1]
            }
          );
        })
      ],
      [invoiceStatusTypesData, t]
    );

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          count: query.count || 1000,
          customer: selectedAutocompleteValue || "",
          invoice_no: query.invoice_no || "",
          invoice_status: query.invoice_status || "",
          issued_country: query.issued_country || "",
          date_from: query.date_from || "",
          date_to: query.date_to || "",
          is_it_paid: query.is_it_paid || "",
          is_virtual: query.is_virtual || "",
          reseller: query.reseller || "",
          dueDateUntil: query.dueDateUntil || "",
          customer_country: query.customerCountry || "",
          salesAgent: query.salesAgent || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="flex__direction--column" onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="customerInvoicesSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("customerAccount.details.Client")}
              data-lpignore="true"
              autocomplete="nope"
              actionType={CUSTOMER_INVOICES}
              selectorType={customerInvoices}
            />
            <InputField
              label={t(CustomerInvoiceSearchFormTrans + "InvoiceNo")}
              classes="form__group-stack"
              id="customerInvoicesSearchInvoiceNo"
              name="invoice_no"
              onChange={handleChange}
              value={values.invoice_no}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "InvoiceStatus")}
              options={getInvoiceStatusTypesData}
              classes="form__group-stack"
              id="customerInvoicesSearchInvoiceStatus"
              name="invoice_status"
              onChange={handleChange}
              value={values.invoice_status}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIssuedCountry")}
              type="number"
              options={
                countryList.length
                  ? [
                      { code: t("countries.None"), id: "" },
                      ...countryList.map(item => {
                        return Object.assign(
                          {},
                          {
                            code: t(`countries.${item.code}`),
                            id: parseInt(item.id, 10)
                          }
                        );
                      })
                    ]
                  : []
              }
              customNameKey="code"
              customValueKey="id"
              classes="form__group-stack"
              id="customerInvoicesSearchIssuedCountry"
              name="issued_country"
              onChange={handleChange}
              value={values.issued_country}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DateFrom")}
              classes="form__group-stack"
              id="customerInvoicesSearchDateFrom"
              name="dateFrom"
              handleChange={setFieldValue}
              value={values.dateFrom}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DateTo")}
              classes="form__group-stack"
              id="customerInvoicesSearchDateTo"
              name="dateTo"
              handleChange={setFieldValue}
              value={values.dateTo}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIsPaid")}
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              classes="form__group-stack"
              id="isPaid"
              onChange={handleChange}
              value={values.isPaid}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "FilterIsVirtual")}
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              classes="form__group-stack"
              id="customerInvoicesSearchIsVirtual"
              name="is_virtual"
              onChange={handleChange}
              value={values.is_virtual}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "ResellerName")}
              options={["", "yes", "no"]}
              classes="form__group-stack"
              id="reseller"
              onChange={handleChange}
              value={values.reseller}
            />
            <DateInputField
              type="date"
              label={t(CustomerInvoiceSearchFormTrans + "DueDateUntil")}
              classes="form__group-stack"
              id="dueDateUntil"
              placement="top"
              handleChange={setFieldValue}
              value={values.dueDateUntil}
            />
            <SelectInputField
              label={t(
                CustomerInvoiceSearchFormTrans + "FilterCustomerCountry"
              )}
              options={
                countryList.length
                  ? [
                      { code: t("countries.None"), id: "" },
                      ...countryList.map(item => {
                        return Object.assign(
                          {},
                          { code: t(`countries.${item.code}`), id: item.id }
                        );
                      })
                    ]
                  : []
              }
              customNameKey="code"
              customValueKey="id"
              classes="form__group-stack"
              name="customer_country"
              value={values.customer_country}
              id="customerInvoicesSearchCustomerCountry"
              onChange={handleChange}
            />
            <SelectInputField
              label={t(CustomerInvoiceSearchFormTrans + "SalesAgent")}
              options={["", "Polska", "Armands", "Raimonds"]}
              classes="form__group-stack"
              id="salesAgent"
              onChange={handleChange}
              value={values.salesAgent}
            />
            <InputField
              label={t(CustomerInvoiceSearchFormTrans + "ResultsNo")}
              classes="form__group-stack"
              id="customerInvoicesSearchCount"
              name="count"
              onChange={handleChange}
              value={values.count}
            />
          </form>
        )}
      </Formik>
    );
  }
);
export default CustomerInvoiceSearchVerticalForm;
