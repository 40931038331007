import {useSelector} from "react-redux";


const useCardClassifications = () => {
    const cardStatus = useSelector(state => state.layout.classifications.card_status);
    const cardTypes = useSelector(state => state.layout.classifications.card_types);

    return {cardStatus, cardTypes}

};

export default useCardClassifications;