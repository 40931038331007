import React from "react";
import Table from "../../common/table";
import {useTranslation} from "react-i18next";
import {TableResultData} from "../../common/tableFooter";
import useDataState from "../../common/redux/hooks/useDataState";
import {factoringInvoices} from "../../common/redux/types/selectorTypes";
import useTableState from "../../common/redux/hooks/useTableState";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import {FACTORING_INVOICES} from "../../common/redux/types/viewTypes";
import useTableLayoutActionDispatchers
  from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";

const FactoringInvoicesTable = props => {
  const { t } = useTranslation();
  const {items, selectedItem, isLoading} = useDataState(factoringInvoices);
  console.log(items);
  const {height} = useTableState(factoringInvoices);
  const {handleRowSelect} = useTableActionDispatchers(FACTORING_INVOICES);
  const {handleTableHeightSet} = useTableLayoutActionDispatchers(FACTORING_INVOICES);

      const FactoringInvoicesTableTrans = "factoring.invoices.";
      const columns = [

        {
          id: "customer",
          Header: t(FactoringInvoicesTableTrans + "Customer"),
          accessor: d => d.customer[0].name
        },
        {
          id: "factoringDebtor",
          Header: t(FactoringInvoicesTableTrans + "FactoringDebtor"),
          accessor: d => d.factoringDebtor[0].name
        },
        {
          Header: t(FactoringInvoicesTableTrans + "InvoiceNo"),
          accessor: "invoiceNo"
        },
        {
          Header: t(FactoringInvoicesTableTrans + "InvoiceDate"),
          accessor: "invoiceDate"
        },
        {
          Header: t(FactoringInvoicesTableTrans + "DueDate"),
          accessor: "dueDate"
        },
        {
          Header: t(FactoringInvoicesTableTrans + "Total"),
          accessor: "total"
        },
        {
          Header: t(FactoringInvoicesTableTrans + "Balance"),
          accessor: "balance",
          width: 120
        },
        {
          id: "annexNo",
          Header: t(FactoringInvoicesTableTrans + "AnnexNo"),
          accessor: d => (d.contract ? d.contract[0].contractNo : "")
        }
      ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        height={height}
        setTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRowsState={[]}
      />
      <TableResultData
        count={items.length}
        enabled={true}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
      />
    </React.Fragment>
  );
};

export default FactoringInvoicesTable;
