import { useSelector } from "react-redux";

const useTransactionsLayoutState = () => {
  const searchOpen = useSelector(
    state => state.transactions.layout.search.isOpen
  );
  const tableVisible = useSelector(
    state => state.transactions.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.transactions.layout.expanders.visible
  );

  return { searchOpen, tableVisible, expandersVisible };
};

export default useTransactionsLayoutState;
