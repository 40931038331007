import React, {useCallback, useMemo} from "react";
import {Loading} from "../../common/loading";
import Table from "../../common/table";
import {numberWithSpaces} from "../../../services/numberFunctions";
import {useTranslation} from "react-i18next";
import {TableResultData} from "../../common/tableFooter";
import useTableLayoutActionDispatchers
    from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import {CUSTOMERS} from "../../common/redux/types/viewTypes";
import useTableActionDispatchers from "../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useDataState from "../../common/redux/hooks/useDataState";
import {customers} from "../../common/redux/types/selectorTypes";
import useDataActionsDispatchers from "../../common/redux/actions/data/hooks/useDataActionsDispatchers";
import useTableState from "../../common/redux/hooks/useTableState";

const CustomerTable = ({
  removeItem,
  onRowSelect,
  onToggleRow,
  tableRef,
  tableVisible
}) => {
  const { t } = useTranslation();

  const { height } = useTableState(customers);
  const { items, selectedItem, selectedRows, isLoading } = useDataState(
    customers
  );

  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CUSTOMERS);
  const { handleRowSelect } = useTableActionDispatchers(CUSTOMERS);
    const {selectedRowsState} = useTableState(customers);
  const onSetSelectedRowsState = useCallback(payload => {}, []);

  const { handleSetSelectedRowsState } = useDataActionsDispatchers(CUSTOMERS);

  const SearchColumn = "customer.customerView.SearchResultColumn";

  const columns = useMemo(
    () => [

      {
        Header: t(SearchColumn + "RegNo"),
          width: 100,
        accessor: d => d.regNo || "",
      },

      {
        Header: t(SearchColumn + "Name"),
        accessor: "name"
      },
      {
        id: "country",
        Header: t(SearchColumn + "CountryName"),
          width: 85,
        accessor: d =>
          d.legalAddress
            ? t(`countries.${d.legalAddress[0].country[0].code}`)
            : []
      },
      {
        id: "isActive",
        Header: t(SearchColumn + "IsActive"),
          width: 80,
        accessor: d => t(d.isActiveCl),
        Cell: ({ cell: { value } }) => (
          <div className="table__align-center">{value}</div>
        )
      },

      {
        Header: t(SearchColumn + "NumberOfP1Contracts"),
          width: 80,
        accessor: "portOneContractsCount",
      },
      {
        id: "otherContractsCount",
        Header: t(SearchColumn + "NumberOfOtherContracts"),
          width: 80,
        accessor: d => (d.otherContractsCount ? d.otherContractsCount : 0),
      },
      {
        Header: t(SearchColumn + "ValidFrom"),
          width: 100,
        accessor: "createdOn"
      },

      {
        id: "balance",
        Header: t(SearchColumn + "Balance"),
          width: 120,
          cellAlign: "right",
        accessor: d => numberWithSpaces(d.balance.toFixed(2)),
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{value}</div>
        )
      },
      {
        id: "debt",
        Header: t(SearchColumn + "Debt"),
          width: 120,
          cellAlign: "right",
        accessor: d => numberWithSpaces(d.debt.toFixed(2)),
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{value}</div>
        )
      },
      {
        id: "creditLimit",
        Header: t(SearchColumn + "CreditLimit"),
          width: 100,
          cellAlign: "right",
        accessor: d => numberWithSpaces(d.creditLimit ? d.creditLimit : 0),
        Cell: ({ cell: { value } }) => (
          <div className="table__align-right">{value}</div>
        )
      },
      {
        id: "usedLimit",
        Header: t(SearchColumn + "CreditLimitUsedPercentage"),
          width: 80,
        accessor: d => {
          if (
            d.remainingCreditLimit !== null &&
            d.creditLimit !== null &&
            d.remainingCreditLimit > 0
          ) {
            return (
              ((d.remainingCreditLimit / d.creditLimit) * 100).toFixed(2) + "%"
            );
          } else {
            return "";
          }
        }
      },
      {
        id: "remainingCreditLimit",
        Header: t(SearchColumn + "CreditLimitFree"),
          width: 110,
          cellAlign: "right",
        accessor: d => {
          if (d.remainingCreditLimit !== null) {
            return numberWithSpaces(d.remainingCreditLimit.toFixed(2));
          } else {
            return 0;
          }
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onSetSelectedRowsState]
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={items}
        height={height}
        setTableHeight={handleTableHeightSet}
        setUserTableHeight={handleTableHeightSet}
        isLoading={isLoading}
        loadingComponent={Loading}
        onRowSelect={handleRowSelect}
        selectedRow={selectedItem}
        selectedRows={selectedRows}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={handleSetSelectedRowsState}
        onToggleRow={onToggleRow}
        tableRef={tableRef}
      />
      <TableResultData
        count={items.length}
        enabled={false}
        selectedRows={selectedRows}
        onRowSelect={onRowSelect}
        selectedRow={selectedItem}
        onRemoveItem={removeItem}
        tableRef={tableRef}
      />
    </React.Fragment>
  );
};

export default CustomerTable;
