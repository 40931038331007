import React, { useRef } from "react";
import {
  SectionMain,
  SectionHeader,
  SectionBody
} from "../../../common/section";
import _ from "lodash";
import useContractLayoutActions from "../../hooks/layout/useContractLayoutActions";
import useContractLayoutState from "../../hooks/layout/useContractLayoutState";
import { useTranslation } from "react-i18next";
import useContractState from "../../hooks/useContractState";
import useContractConsts from "../../hooks/useContractConsts";
import useContractActions from "../../hooks/useContractActions";
import ContractTable from "../../contractTable";
import ContractButtons from "./contractButtons";

const ContractMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    searchOpen,
    tableVisible,
    expandersVisible
  } = useContractLayoutState();
  const {
    handleTableToggle,
    handleExpandersToggle,
    handleSearchToggle
  } = useContractLayoutActions();
  const {
    handleSearch,
    handleRowSelect,
    handleRowToggle
  } = useContractActions();
  const { searchQuery, selectedRow } = useContractState();
  const { filterLabels, tabs } = useContractConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("contract.Contract")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={ContractTable}
        ButtonsComponent={ContractButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        onToggleRow={handleRowToggle}
        selectedItem={
          !_.isEmpty(selectedRow) ? selectedRow.customer[0].name : ""
        }
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default ContractMainSection;
