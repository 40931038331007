import {useSelector} from "react-redux";

const useVatDocumentsLayoutState = () => {
  const tableVisible = useSelector(
    state => state.vatDocuments.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.vatDocuments.layout.expanders.visible
  );
  const tableSpecs = useSelector(state => state.vatDocuments.table);
  const searchOpen = useSelector(
    state => state.vatDocuments.layout.search.isOpen
  );

  return { tableVisible, expandersVisible, searchOpen, tableSpecs };
};

export default useVatDocumentsLayoutState;
