import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  CLEAR_COMPANY_AUTOCOMPLETE_DATA,
  SELECT_COMPANY_AUTOCOMPLETE_VALUE,
  CLEAR_COMPANY_AUTOCOMPLETE_SELECTED_VALUE
} from "../redux/actions/search/searchActions";
import { companyAutocompleteQuery } from "./graphql/fetchCustomerAutocomplete";

const useCustomersAutocompleteData = actionType => {
  const dispatch = useDispatch();
  const onCompanyAutocompleteSearch = payload => {
    dispatch(companyAutocompleteQuery(actionType, payload));
  };
  const onCompanyAutocompleteClear = useCallback(() => {
    dispatch(CLEAR_COMPANY_AUTOCOMPLETE_DATA(actionType));
  }, [dispatch, actionType]);
  const onAutocompleteItemSelect = useCallback(
    payload => {
      dispatch(SELECT_COMPANY_AUTOCOMPLETE_VALUE(actionType, payload));
    },
    [dispatch, actionType]
  );

  const clearCompanyAutocompleteSelectedItem = useCallback(() => {
    dispatch(CLEAR_COMPANY_AUTOCOMPLETE_SELECTED_VALUE(actionType));
  }, [dispatch, actionType]);

  const handleAutocompleteClear = useCallback(() => {
    dispatch(CLEAR_COMPANY_AUTOCOMPLETE_DATA(actionType));
  }, [dispatch, actionType]);

  const loadCompanyAutocomplete = input => {
    const query = input.target.value;
    if (query.length > 2) {
      onCompanyAutocompleteSearch(query);
    }
  };

  const handleCompletionClick = data => {
    onAutocompleteItemSelect(data);
    onCompanyAutocompleteClear();
  };

  return {
    loadCompanyAutocomplete,
    handleCompletionClick,
    handleAutocompleteClear,
    clearCompanyAutocompleteSelectedItem
  };
};

export default useCustomersAutocompleteData;
