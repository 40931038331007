import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_FACTORING_INVOICE_SEARCH,
  TOGGLE_FACTORING_INVOICES_TABLE,
  TOGGLE_FACTORING_INVOICES_EXPANDERS
} from "../../actions/factoringInvoicesActions";

const useFactoringInvoicesLayoutActions = () => {
  const dispatch = useDispatch();

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_INVOICE_SEARCH());
  }, [dispatch]);

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_INVOICES_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_INVOICES_EXPANDERS());
  }, [dispatch]);

  return { handleSearchToggle, handleTableToggle, handleExpandersToggle };
};

export default useFactoringInvoicesLayoutActions;
