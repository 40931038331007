import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../common/table";
import useCustomerDepositState from "./hooks/useCustomerDepositState";
import useCustomerDepositActions from "./hooks/useCustomerDepositActions";
import { TableResultData } from "../../common/tableFooter";
import useTableLayoutActionDispatchers from "../../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { CUSTOMER_DEPOSIT } from "../../common/redux/types/viewTypes";
import useTableState from "../../common/redux/hooks/useTableState";
import { customerDeposit } from "../../common/redux/types/selectorTypes";
import { numberWithSpaces } from "../../../services/numberFunctions";

const CustomerDepositTable = () => {
  const { t } = useTranslation();
  const { data, isLoading, selectedRow } = useCustomerDepositState();
  const { handleRowSelect } = useCustomerDepositActions();
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    CUSTOMER_DEPOSIT
  );
  const { height } = useTableState(customerDeposit);
  const getColumns = () => {
    const CustomerDepositTableTrans = "customerDeposit.";
    const columns = [
      {
        Header: t(CustomerDepositTableTrans + "AddToInvoice"),
        width: 80,
        accessor: d =>
          d.addToInvoice ? t("common.ReplyYes") : t("common.ReplyNo")
      },

      {
        Header: t(CustomerDepositTableTrans + "CustomerName"),
        accessor: d => (d.customer ? d.customer[0].name : "")
      },
      {
        Header: t(CustomerDepositTableTrans + "EntryDate"),
        width: 120,
        accessor: "createdOn"
      },
      {
        id: "sum",
        Header: t(CustomerDepositTableTrans + "Amount"),
        width: 120,
        cellAlign: "right",
        accessor: d => (d.sum ? numberWithSpaces(d.sum) : "")
      },

      {
        Header: t(CustomerDepositTableTrans + "AdditionalInfo"),
        accessor: d => d.additionalInfo || "",
        width: 300
      }
    ];

    return columns;
  };

  return (
    <React.Fragment>
      <Table
        isLoading={isLoading}
        selectedRow={selectedRow}
        onRowSelect={handleRowSelect}
        setTableHeight={handleTableHeightSet}
        columns={getColumns()}
        data={data || []}
        height={height}
      />
      <TableResultData count={data.length} selectedRow={selectedRow} />
    </React.Fragment>
  );
};
export default CustomerDepositTable;
