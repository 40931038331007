import React, { useRef } from "react";
import Table from "../common/table";
import { useTranslation } from "react-i18next";
import { TableResultData } from "../common/tableFooter";
import useDataState from "../common/redux/hooks/useDataState";
import { partner } from "../common/redux/types/selectorTypes";
import { PARTNER } from "../common/redux/types/viewTypes";
import useTableState from "../common/redux/hooks/useTableState";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";

const PartnerTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { items, selectedItem } = useDataState(partner);
  const { height } = useTableState(partner);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(PARTNER);
  const PartnerTableTrans = "partner.";
  const columns = [
    {
      Header: t(PartnerTableTrans + "HeaderName"),
      accessor: "name"
    },
    {
      Header: t(PartnerTableTrans + "HeaderRegNo"),
      accessor: "regNo"
    },
    {
      Header: t(PartnerTableTrans + "HeaderIsActive"),
      accessor: "isActive"
    },
    {
      Header: t(PartnerTableTrans + "HeaderCountry"),
      accessor: "country"
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        height={height}
        setTableHeight={handleTableHeightSet}
        selectedRow={selectedItem}
        classes="u-margin-bottom-small"
        data={items || []}
      />
      <TableResultData count={0} selectedRow={{}} tableRef={tableRef} />
    </React.Fragment>
  );
};

export default PartnerTable;
