import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Shortcuts = ({ selectedItem, shortcuts }) => {
  const dispatch = useDispatch();
  return (
    <div className="shortcut">
      <span className="btn btn--pill">{selectedItem}</span>
      <div className="shortcut__items">
        {shortcuts.map(item => {
          return (
            <div
              className="shortcut__items-item u-margin-left-small"
              key={`shortcut-${item.name}`}
              onClick={() => item.callback(selectedItem, dispatch)}
            >
              <Link to={item.link}>{item.name}</Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TableFooterWShortcuts = ({
  selectedItem,
  children,
  shortcuts,
  dispatch
}) => {
  const { t } = useTranslation();
  return (
    <div className="table__footer ">
      {selectedItem ? (
        <div>
          <Shortcuts
            selectedItem={selectedItem}
            dispatch={dispatch}
            shortcuts={shortcuts}
          />
        </div>
      ) : (
        t("layout.NothingSelected")
      )}
      <div className="table__footer-buttons">{children}</div>
    </div>
  );
};

export const TableFooter = ({ selectedItem, children }) => {
  const { t } = useTranslation();
  return (
    <div className="table__footer ">
      {selectedItem ? (
        <div className="btn btn--pill u-margin-left-small btn--footer-itemName">
          <span
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden"
            }}
          >
            {selectedItem}
          </span>
        </div>
      ) : (
        t("layout.NothingSelected")
      )}
      <div className="table__footer-buttons">{children}</div>
    </div>
  );
};

export const TableResultData = ({
  count,
  selectedRows,
  selectedRow,
  onRowSelect,
  onRemoveItem,
  enabled,
  tableRef
}) => {
  const { t } = useTranslation();
  return (
    <div className="table__result-data">
      {count === 0 ? (
        ""
      ) : (
        <div className="table__result-data-resultno">
          <p>
            <span className="table__result-data-count">{count}</span>
            {count > 1 ? t("common.Results") : t("common.Result")}
          </p>
        </div>
      )}
      {selectedRows && enabled ? (
        <div className="table__result-data__container--wrapper">
          <div className="table__result-data__container">
            {selectedRows.map(item => {
              return (
                <div
                  className={
                    "table__result-data__container-tab " +
                    (selectedRow.id === item.id
                      ? "table__result-data__container-tab--selected"
                      : "")
                  }
                  key={item.id}
                  onClick={() => {
                    onRowSelect(item);
                    tableRef.current.scrollToItem(item.path, "center");
                    // tBodyElement.current.scrollToItem(item.index, "center");
                  }}
                >
                  <div
                    className="table__result-data__container-tab__remove-item"
                    onClick={e => {
                      e.stopPropagation();
                      onRemoveItem(item.path);
                    }}
                  >
                    <div className="table__result-data__container-tab--wrapper">
                      <div className="table__result-data__container-tab__remove-item-cross" />
                    </div>
                  </div>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const TableResultDataExpanders = ({
  selectedRows,
  selectedRow,
  onRowSelect,
  onRemoveItem,
  enabled
}) => {
  const tBodyElement = document.querySelector(".simplebar-wrapper");
  return (
    <div className="table__result-data">
      {selectedRows && enabled ? (
        <div className="table__result-data__container--wrapper">
          <div className="table__result-data__container">
            {selectedRows.map(item => {
              return (
                <div
                  className={
                    "table__result-data__container-tab " +
                    (selectedRow.id === item.id
                      ? "table__result-data__container-tab--selected"
                      : "")
                  }
                  key={item.id}
                  onClick={() => {
                    onRowSelect(item);
                    tBodyElement.scrollTo(0, item.scrollY);
                  }}
                >
                  <div
                    className="table__result-data__container-tab__remove-item"
                    onClick={e => {
                      e.stopPropagation();

                      onRemoveItem(item.id);
                    }}
                  >
                    <div className="table__result-data__container-tab--wrapper">
                      <div className="table__result-data__container-tab__remove-item-cross"></div>
                    </div>
                  </div>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
