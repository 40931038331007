import React, { Component } from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../common/form/form";

class PartnerSectionDetailsForm extends Component {
  render() {
    const { t } = this.props;
    const PartnerDetailsFormTrans = "partner.details.";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <div className="u-margin-bottom-small" />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerId")}
                id="partnerNo"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerName")}
                id="partnerName"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerRegNo")}
                id="partnerRegNo"
                classes="form__group"
                onChange={handleChange}
              />
              <SelectInputField
                label={t(PartnerDetailsFormTrans + "PartnerIsActive")}
                id="partnerIsActive"
                options={["yes", "no"]}
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <div className="u-margin-bottom-small" />

              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerGeneralPhone")}
                id="partnerGeneralPhone"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerGeneralFax")}
                id="partnerGeneralFax"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerGeneralEmail")}
                id="partnerGeneralEmail"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerHelpdeskPhone")}
                id="partnerHelpdeskPhone"
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quaternary">
                {t(PartnerDetailsFormTrans + "PartnerContactPerson")}
              </h4>
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerContactPersonName")}
                id="contactPersonName"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerContactPersonPhone")}
                id="contactPersonPhone"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(PartnerDetailsFormTrans + "PartnerContactPersonEmail")}
                id="contactPersonEmail"
                classes="form__group"
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerDetailsFormTrans + "PartnerContactPersonLanguage"
                )}
                id="contactPersonLanguage"
                options={["estonian", "english", "polish "]}
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <h4 className="heading__quaternary">
                {t(PartnerDetailsFormTrans + "PartnerAccountant")}
              </h4>
              <InputField
                type="text"
                label={t(
                  PartnerDetailsFormTrans + "PartnerAccountantPersonName"
                )}
                id="accountantName"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(
                  PartnerDetailsFormTrans + "PartnerAccountantPersonPhone"
                )}
                id="accountantPhone"
                classes="form__group"
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(
                  PartnerDetailsFormTrans + "PartnerAccountantPersonEmail"
                )}
                id="accountantEmail"
                classes="form__group"
                onChange={handleChange}
              />
              <SelectInputField
                label={t(
                  PartnerDetailsFormTrans + "PartnerAccountantPersonLanguage"
                )}
                id="accountantLanguage"
                options={["estonian", "english", "polish "]}
                classes="form__group"
                onChange={handleChange}
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    );
  }
}

export default PartnerSectionDetailsForm;
