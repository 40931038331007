import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useFactoringInvoicesLayoutState from "../../hooks/layout/useFactoringInvoicesLayoutState";
import useFactoringInvoicesActions from "../../hooks/useFactoringInvoicesActions";
import { SearchSection } from "../../../../common/section";
import FactoringInvoicesVerticalSearchForm from "../../search/forms/factoringInvoicesVerticalSearchForm";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { FACTORING_INVOICES } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { factoringInvoices } from "../../../../common/redux/types/selectorTypes";

const FactoringInvoicesSearchSection = () => {
  const { t } = useTranslation();
  const { searchOpen } = useFactoringInvoicesLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(FACTORING_INVOICES);
  const { isLoading } = useDataState(factoringInvoices);
  const { handleSearch } = useFactoringInvoicesActions();
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <FactoringInvoicesVerticalSearchForm
        t={t}
        handleSearch={handleSearch}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default FactoringInvoicesSearchSection;
