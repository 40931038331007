import React from "react";
import View from "../common/view";
import SalespointMainSection from "./layout/main/salespointMainSection";
import SalespointSearchSection from "./layout/search/salespointSearchSection";

const SalespointSection = () => {
  return (
    <View
      MainSection={SalespointMainSection}
      SearchSection={SalespointSearchSection}
    />
  );
};

export default SalespointSection;
