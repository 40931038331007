import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const SectionTabs = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const hash = props.location.hash;
    const hashMap = getHashMap();
    if (hash === "") {
      props.history.location.hash = getHashKey(hashMap, selectedIndex);
      handleHashChange();
    } else {
      props.history.location.hash = hash;
      setSelectedIndex(hashMap[hash]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleHashChange();
  });

  const handleHashChange = async () => {
    const hashMap = await getHashMap();
    setSelectedIndex(hashMap[props.history.location.hash]);
  };

  const getHashMap = () => {
    const hashMap = props.hashMap;
    return hashMap;
  };

  const getHashKey = (obj, val) => {
    return Object.keys(obj).find(key => obj[key] === val);
  };

  //const hashMap = getHashMap();
  return <Tabs>{props.children}</Tabs>;
};

export default withRouter(SectionTabs);

export class SectionTab extends Tab {}

export class SectionTabList extends TabList {}

export class SectionTabPanel extends TabPanel {}

// export const SectionTabPanel = <SimpleBar>{...TabPanel}</SimpleBar>;

// const tabPanelHOC = WrappedComponent => {
//   class TablePanelWrapper extends WrappedComponent {
//     render() {
//       console.log(this.props);
//       return (
//         <SimpleBar
//           autoHide={false}
//           forceVisible="y"
//           style={{
//             overflowX: "hidden"
//           }}
//         >
//           <div style={{ height: "100%", overflowY: "hidden" }}>
//             <WrappedComponent {...this.props} />
//           </div>
//         </SimpleBar>
//       );
//     }
//   }
//   return TablePanelWrapper;
// };

// export const SectionTabPanel = tabPanelHOC(TabPanel);
