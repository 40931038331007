import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {SELECT_CARD_ROW} from "../actions/cardActions";
import {loadCards} from "../graphql/cardQueries";

const useCardActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(payload => {
    dispatch(loadCards(payload));
  }, [dispatch]);

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_CARD_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useCardActions;
