import React from "react";
import _ from "lodash";
import { Button } from "../../../common/buttons/button";
import useContractState from "../../hooks/useContractState";
import { useTranslation } from "react-i18next";

const ContractButtons = () => {
  const { t } = useTranslation();

  const { selectedRow } = useContractState();

  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        classes="btn--footer"
        label={t("contract.BtnAddContract")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("contract.BtnDeleteContract")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("contract.BtnSaveContract")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("contract.BtnContractsToCsv")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("contract.BtnExportContract")}
      />
    </React.Fragment>
  );
};

export default ContractButtons;
