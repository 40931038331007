import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const useAppRedirect = (appLayout) => {
    const history = useHistory();
    useEffect(()=> {
        if(appLayout.length && history.location.pathname === "/"){
            history.push(appLayout[0].link);
        }
    }, [appLayout, history]);

};

export default useAppRedirect;

