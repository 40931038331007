export const TOGGLE_SALESPOINT_SEARCH = () => ({
  type: "TOGGLE_SALESPOINT_SEARCH"
});

export const TOGGLE_SALESPOINT_TABLE = () => ({
  type: "TOGGLE_SALESPOINT_TABLE"
});

export const TOGGLE_SALESPOINT_EXPANDER_VIEW = () => ({
  type: "TOGGLE_SALESPOINT_EXPANDER_VIEW"
});
