import React, {useMemo} from 'react';
import {
    addresses, clientAndInvoiceSettings,
    comments,
    contactInformation, creditLimitHistory,
    details,
    factoringDebtor, factoringDebtorOverdueInvoices, paymentHistory
} from "../../../common/redux/types/selectorTypes";
import {
    ADDRESSES, CLIENT_AND_INVOICE_SETTINGS,
    COMMENTS,
    CONTACT_INFORMATION, CREDIT_LIMIT_HISTORY,
    DETAILS,
    FACTORING_DEBTOR, FACTORING_DEBTOR_OVERDUE_INVOICES, PAYMENT_HISTORY
} from "../../../common/redux/types/viewTypes";
import FactoringDebtorCommentsTable from "../../debtor/comments/factoringDebtorCommentsTable";
import FactoringDebtorContactPersonsForm from "../../debtor/contact_persons/factoringDebtorContactPersonsForm";
import FactoringDebtorAddressesForm from "../../debtor/addresses/factoringDebtorAddressesForm";
import FactoringDebtorClientInvoiceSettingsTable
    from "../../debtor/client_invoice_settings/factoringDebtorClientInvoiceSettingsTable";
import FactoringDebtorCreditLimitHistoryTable
    from "../../debtor/credit_limit_history/factoringDebtorCreditLimitHistoryTable";
import {useTranslation} from "react-i18next";
import FactoringDebtorOverdueInvoicesDetailsForm from "../details/factoringDebtorOverdueInvoicesDetailsForm";

const useFactoringDebtorOverdueInvoicesConsts = () => {
    const {t} = useTranslation();
    const filterLabels = {
        name: t("customer.customerView.FilterCustomerName"),
        id: t("customer.customerView.FilterCustomerId"),
        woCusManager: t("customer.customerView.WithoutCustomerManager"),
        regNo: t("customer.customerView.FilterRegNo"),
        vatNo: t("customer.customerView.VatNo"),
        contactPerson: t("customer.customerView.FilterContactPerson"),
        resultCount: t("customer.customerView.FilterResultCount"),
        generalPhone: t("customer.customerView.FilterGeneralPhone"),
        generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
        salesAgent: t("customer.customerView.SalesAgent"),
        customerManager: t("customer.customerView.CustomerManager"),
        withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
        isActive: t("customer.customerView.FilterIsActive"),
        validFrom: t("customer.customerView.FilterCustomerValidFrom"),
        portOneContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfP1Contracts"
        ),
        otherContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfOtherContracts"
        )
    };

    const tabs = useMemo(
        () => [
            {
                label: t("factoring.debtor.FactoringDebtorDetails"),
                component: <FactoringDebtorOverdueInvoicesDetailsForm t={t} data={{}} />,
                viewSelector: factoringDebtorOverdueInvoices,
                viewType: FACTORING_DEBTOR_OVERDUE_INVOICES,
                tabSelector: details,
                tabType: DETAILS
            },
            {
                label: t("factoring.debtor.overdueInvoices.FactoringDebtorPenaltyInvoicesPaymentHistory"),
                component: () => {return ("TODO")},
                viewSelector: factoringDebtorOverdueInvoices,
                viewType: FACTORING_DEBTOR_OVERDUE_INVOICES,
                tabSelector: paymentHistory,
                tabType: PAYMENT_HISTORY
            },
            {
                label: t("factoring.debtor.overdueInvoices.PenaltyInvoicesGenerationResult"),
                component: () => {return ("TODO")},
                viewSelector: factoringDebtorOverdueInvoices,
                viewType: FACTORING_DEBTOR_OVERDUE_INVOICES,
                tabSelector: paymentHistory,
                tabType: PAYMENT_HISTORY
            },
            {
                label: t("factoring.debtor.overdueInvoices.PenaltyInvoicesDeleteResult"),
                component: () => {return ("TODO")},
                viewSelector: factoringDebtorOverdueInvoices,
                viewType: FACTORING_DEBTOR_OVERDUE_INVOICES,
                tabSelector: paymentHistory,
                tabType: PAYMENT_HISTORY
            }

        ],
        [t]
    );

    return { filterLabels, tabs };

}

export default useFactoringDebtorOverdueInvoicesConsts;