import React from "react";
import View from "../common/view";
import PartnerMainSection from "./layout/main/partnerMainSection";
import PartnerSearchSection from "./layout/search/partnerSearchSection";

const PartnerView = () => {
  return (
    <View
      MainSection={PartnerMainSection}
      SearchSection={PartnerSearchSection}
    />
  );
};
export default PartnerView;
