import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SectionBody, SectionHeader, SectionMain } from "../../section";
import CustomerTable from "../../../customer/customers/customerTable";
import CustomerButtons from "../../../customer/customers/layout/main/customerButtons";
import useCustomersConsts from "../../../customer/customers/hooks/useCustomersConsts";

const CustomerViewPlaceholderMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { customersTabs } = useCustomersConsts();

  return (
    <SectionMain toggleSearch={() => {}}>
      <SectionHeader
        sectionHeading={t("customer.customerView.Header")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={{}}
        filterLabels={[]}
        tableVisible={true}
        expandersVisible={false}
        tableEnabled={false}
        expandersEnabled={false}
        tabsEnabled={false}
        onToggleTable={() => {}}
        onToggleExpanders={() => {}}
        onToggleTabs={() => {}}
        onToggleSearch={() => {}}
        onSearch={() => {}}
        searchOpen={true}
      />

      <SectionBody
        TableComponent={CustomerTable}
        ButtonsComponent={CustomerButtons}
        shortcutsEnabled={false}
        selectedRow={{}}
        tableVisible={true}
        onRowSelect={() => {}}
        removeItem={() => {}}
        onToggleRow={() => {}}
        tableRef={tableRef}
        shortcuts={[]}
        expandersEnabled={false}
        tabs={customersTabs()}
      />
    </SectionMain>
  );
};

export default CustomerViewPlaceholderMainSection;
