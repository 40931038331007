import {toast} from "react-toastify";

const createDataReducer = (name = "") => {
  const initialState = {
    items: [],
    selectedItem: {},
    selectedRows: [],
    isLoading: false,
    error: {}
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `LOAD_${name}_DATA_START`:
        return {
          ...state,
          isLoading: true
        };
      case `LOAD_${name}_DATA_SUCCESS`:
        return {
          ...state,
          items: action.payload,
          isLoading: false
        };

      case `LOAD_${name}_DATA_ERROR`:
        toast.error(action.payload);
        return {
          ...state,
          error: action.payload,
          items: [],
          isLoading: false
        };
      case `SELECT_${name}_ROW`: {
        return {
          ...state,
          selectedItem: action.payload
        };
      }
      case `CLEAR_${name}_SELECTED_ROW`: {
        return {
          ...state,
          selectedItem: {}
        }
      }

      default:
        return state;
    }
  };
};

export default createDataReducer;
