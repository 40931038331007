import React from "react";

import View from "../common/view";
import ServiceMainSection from "./layout/main/serviceMainSection";
import ServiceSearchSection from "./layout/search/serviceSearchSection";

const ServiceView = () => {
  return (
    <View
      MainSection={ServiceMainSection}
      SearchSection={ServiceSearchSection}
    />
  );
};

export default ServiceView;
