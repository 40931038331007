import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../services/queries";

export const SELECT_SERVICE_ROW = payload => ({
  type: "SELECT_SERVICE_ROW",
  payload: payload
});

export const TOGGLE_SERVICE_SEARCH = () => ({
  type: "TOGGLE_SERVICE_SEARCH"
});

export const TOGGLE_SERVICE_TABLE = () => ({
  type: "TOGGLE_SERVICE_TABLE"
});

export const TOGGLE_SERVICE_EXPANDERS = () => ({
  type: "TOGGLE_SERVICE_EXPANDER_VIEW"
});

export const TOGGLE_SERVICE_ROW = payload => ({
  type: "TOGGLE_SERVICE_ROW",
  payload: payload
});

export const SUBMIT_SERVICE_QUERY = payload => ({
  type: "SUBMIT_SERVICE_QUERY",
  payload: payload
});

export const LOAD_SERVICE_DATA_START = () => ({
  type: "LOAD_SERVICE_DATA_START"
});

export const LOAD_SERVICE_DATA_SUCCESS = payload => ({
  type: "LOAD_SERVICE_DATA_SUCCESS",
  payload: payload
});

export const LOAD_SERVICE_DATA_ERROR = payload => ({
  type: "LOAD_SERVICE_DATA_ERROR",
  payload: payload
});

export const SERVICE_ROW_SELECT = payload => ({
  type: "SERVICE_ROW_SELECT",
  payload: payload
});

export const SET_SERVICE_TABLE_SELECTED_ROWS_STATE = payload => ({
  type: "SET_SERVICE_TABLE_SELECTED_ROWS_STATE",
  payload: payload
});

export const SET_SERVICE_TABLE_HEIGHT = payload => ({
  type: "SET_SERVICE_TABLE_HEIGHT",
  payload: payload
});

export const SET_SERVICE_SERVICE_TYPES_DROPDOWN = payload => ({
  type: "SET_SERVICE_SERVICE_TYPES_DROPDOWN",
  payload: payload
});

export const SET_SERVICE_COUNTRIES_DROPDOWN = payload => ({
  type: "SET_SERVICE_COUNTRIES_DROPDOWN",
  payload: payload
});

export const SET_SERVICE_DROPDOWN_VALUES = payload => ({
  type: "SET_SERVICE_DROPDOWN_VALUES",
  payload: payload
});

export const LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_START = () => ({
  type: "LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_START"
});

export const LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS = payload => ({
  type: "LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS",
  payload: payload
});

export const LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_ERROR = payload => ({
  type: "LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_ERROR",
  payload: payload
});

export const CLEAR_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA = () => ({
  type: "CLEAR_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA"
});

export const SELECT_SERVICE_CUSTOMER_AUTOCOMPLETE_VALUE = payload => ({
  type: "SELECT_SERVICE_CUSTOMER_AUTOCOMPLETE_VALUE",
  payload: payload
});

export const loadServiceData = payload => {
  return dispatch => {
    dispatch(SUBMIT_SERVICE_QUERY(payload));
    dispatch(LOAD_SERVICE_DATA_START());
    serviceQuery(payload)
      .then(d => {
        dispatch(LOAD_SERVICE_DATA_SUCCESS(d.services));
      })
      .catch(err => {
        toast.error("Error loading SERVICE data");
        dispatch(LOAD_SERVICE_DATA_ERROR(err));
      });
  };
};

const getServiceServiceTypesDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `query {
        serviceTypes{
         name
         id
       }
      }
         `
      },
      authorizationHeaders()
    );
    return data.data.data.serviceTypes;
  } catch (err) {
    toast.error("Error loading SERVICE_TYPES");
    return [];
  }
};

export const getServiceDropdownData = () => {
  return dispatch => {
    getServiceServiceTypesDropdownData().then(d =>
      dispatch(SET_SERVICE_DROPDOWN_VALUES({ serviceTypes: d }))
    );
  };
};

export const serviceCustomerAutocompleteQuery = payload => {
  return dispatch => {
    dispatch(LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_START(payload));
    loadServiceCustomerAutocompleteData(payload)
      .then(data =>
        dispatch(LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS(data))
      )
      .catch(error =>
        dispatch(LOAD_SERVICE_CUSTOMER_AUTOCOMPLETE_DATA_ERROR(error))
      );
  };
};

export const loadServiceCustomerAutocompleteData = async payload => {
  const data = await axios.post(
    "/graphql",
    {
      query: `
    query {
      companies(name:"${payload}") {
        name
      }
     }
  `
    },
    authorizationHeaders()
  );
  return data.data.data.companies;
};

const serviceQuery = async payload => {
  const queryData = getServiceQueryData(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `query{
      services(${queryData}){
        id,
        quantity
        customer{name}
        serviceType{name}
        addToInvoice
        additionalInfo
        total
        serviceDate
        serviceTypeId
        country{code}
        serviceType{isPrivate}
        unitPrice
        partnerId
        partner{name}
      }
          }
         `
    },
    authorizationHeaders()
  );
  return data.data.data;
};

const getServiceQueryData = ({
  resultCount,
  customer,
  id,
  regno,
  addToInvoice,
  serviceType,
  servicesTo,
  servicesFrom,
  vatNo,
  generalPhone,
  generalEmail,
  salesAgent
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : ""}${
    customer ? `customer: "${customer}"` : ""
  } ${id ? "id: " + id : ""} ${regno ? "reg_no: " + regno : ""} ${
    vatNo ? "vat_no: " + vatNo : ""
  } ${generalPhone ? `general_phone: "${generalPhone}"` : ""} 
  ${servicesFrom ? `services_from: "${servicesFrom}"` : ""}
  ${servicesTo ? `services_to: "${servicesTo}"` : ""}
  ${serviceType ? `service_type: "${serviceType}"` : ""}
  ${addToInvoice ? `add_to_invoice: ${addToInvoice}` : ""}
  ${generalEmail ? `email: "${generalEmail}"` : ""} ${
    salesAgent ? `sales_agent: "${salesAgent}"` : ""
  }`;
  return queryData;
};
