import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DiscountDetailsForm from "../details/discountDetailsForm";
import useDataState from "../../common/redux/hooks/useDataState";
import {details, discount} from "../../common/redux/types/selectorTypes";
import {DETAILS, DISCOUNT} from "../../common/redux/types/viewTypes";

const useDiscountConsts = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(discount);
  console.log(selectedItem);
  const filterLabels = {
    name: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    woCusManager: t("customer.customerView.WithoutCustomerManager"),
    regNo: t("customer.customerView.FilterRegNo"),
    vatNo: t("customer.customerView.VatNo"),
    contactPerson: t("customer.customerView.FilterContactPerson"),
    resultCount: t("customer.customerView.FilterResultCount"),
    generalPhone: t("customer.customerView.FilterGeneralPhone"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    validFrom: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  const tabs = useMemo(() => (
    [
      {
        label: t("discount.DiscountDetailData"),
        component: <DiscountDetailsForm t={t} data={selectedItem} />,
        viewSelector: discount,
        viewType: DISCOUNT,
        tabSelector: details,
        tabType: DETAILS
      }
    ]
), [t, selectedItem]);

  return { tabs, filterLabels };
};

export default useDiscountConsts;
