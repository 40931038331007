import axios from "axios";
import Cookie from 'js-cookie';

export const ContractQuery = async () => {
  const data = await axios.post("/graphql", {
    query: `
    query {
        contracts(count: 100){
      customerNo
      contractTypeCl
    }
    }
  `
  });
  return data;
};

export const authorizationHeaders = () => {
  return {
    headers: { Authorization: Cookie.get("_session") }
  };
};
