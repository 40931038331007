import React, { Component } from "react";
import SectionTabs, {
  SectionTab,
  SectionTabList,
  SectionTabPanel
} from "../../common/tabs/sectionTabs";
import BrokerInvoicesDetailsForm from "./details/forms/brokerInvoicesDetailsForm";

export default class BrokerInvoicesTabs extends Component {
  state = {
    hashMap: {
      "#details": 0
    }
  };
  render() {
    const { t } = this.props;
    return (
      <SectionTabs hashMap={this.state.hashMap}>
        <SectionTabList>
          <SectionTab>
            {t("broker.invoices.BrokerInvoicesDetailData")}
          </SectionTab>
        </SectionTabList>
        <SectionTabPanel>
          <BrokerInvoicesDetailsForm t={t} />
        </SectionTabPanel>
      </SectionTabs>
    );
  }
}
