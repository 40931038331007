import React from "react";
import Table from "../../common/table";
import { SectionHeadBtnGroup } from "../../common/section";
import { TableFooter } from "../../common/tableFooter";
import { Button } from "../../common/buttons/button";
import useTabState from "../../common/redux/hooks/useTabState";
import {
  bankAccountTypes,
  contract
} from "../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  BANK_ACCOUNT_TYPES,
  CONTRACT
} from "../../common/redux/types/viewTypes";

const ContractFactoringBankAccountTypesTable = ({ t }) => {
  const { table, selectedItem } = useTabState(contract, bankAccountTypes);
  const { handleTableHeightSet } = useTabTableActionDispatchers(
    CONTRACT,
    BANK_ACCOUNT_TYPES
  );
  const columns = [
    {
      Header: t("vat.applications.details.BankAccountType"),
      accessor: "bankAccountType"
    },
    {
      Header: t("vat.applications.details.Bank"),
      accessor: "bank"
    },
    {
      Header: t("vat.applications.details.IBAN"),
      accessor: "iban"
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={[]}
        height={table.height}
        setTableHeight={handleTableHeightSet}
        selectedRow={selectedItem}
      />
      <TableFooter selectedItem="">
        <SectionHeadBtnGroup>
          <Button
            btnStyle="lime"
            size="small"
            classes="btn--footer"
            label={"Kirjuta üle"}
          />
          <Button
            btnStyle="primary"
            size="small"
            classes="btn--footer"
            label={"Lisa konto"}
          />
          <Button
            btnStyle="lime"
            size="small"
            classes="btn--footer"
            label={"Muuda kontot"}
          />
          <Button
            btnStyle="danger"
            size="small"
            classes="btn--footer"
            label={"Kustuta konto"}
          />
        </SectionHeadBtnGroup>
      </TableFooter>
    </React.Fragment>
  );
};
export default ContractFactoringBankAccountTypesTable;
