import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { CLEAR_AUTH_ERROR, userLogin } from "./actions/authActions";
import { Button, ButtonSubmit } from "../common/buttons/button";

const Login = () => {
  const dispatch = useDispatch();
  const modalOpen = useSelector(state => state.auth.login_modal.isOpen);
  const authLoading = useSelector(state => state.auth.isLoading);
  const authError = useSelector(state => state.auth.error);
  const { t } = useTranslation();

  const onLogUserIn = useCallback(
    payload => {
      dispatch(userLogin(payload));
    },
    [dispatch]
  );

  const clearAuthError = useCallback(() => {
    dispatch(CLEAR_AUTH_ERROR());
  }, [dispatch]);

  const getInstallationFromUrl = useCallback(() => {
    let installation;
    if (window.location.hostname.includes("port1")) {
      installation = "Port1";
    } else if (window.location.hostname.includes("polska")) {
      installation = "Polska";
    } else if (window.location.hostname.includes("redcredit")) {
      installation = "Red Credit";
    } else {
      installation = "DEV";
    }

    return installation;
  }, []);

  if (modalOpen) {
    return (
      <div className="login">
        <div className="login__modal">
          <h2 className="heading__secondary" style={{ color: "white" }}>
            {getInstallationFromUrl()} Login
          </h2>
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              clearAuthError();
              onLogUserIn(values);
            }}
          >
            {({ values, isSubmitting, handleSubmit, handleChange }) => (
              <form className="form-col" onSubmit={handleSubmit}>
                <div className="form__group">
                  <label htmlFor="email" className="form__group-label--login">
                    {t("layout.Username")}
                  </label>
                  <Field
                    type="text"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    className="input__login"
                    placeholder="Username"
                  />
                </div>
                <div className="form__group" style={{ margin: "0" }}>
                  <label
                    htmlFor="password"
                    className="form__group-label--login"
                  >
                    {t("layout.Password")}
                  </label>
                  <Field
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    className="input__login"
                    placeholder="Password"
                  />
                  <Button
                    label={
                      !authLoading ? t("layout.SubmitLogin") : "Logging in..."
                    }
                    disabled={authLoading}
                    classes="u-margin-top-small"
                    btnStyle="primary"
                    type="submit"
                  />
                </div>
                <div
                  className="login__modal--error"
                  style={{
                    opacity: authError ? "1" : "0",
                    transform: authError
                      ? "translateY(30px)"
                      : "translateY(40px)"
                  }}
                >
                  {authError}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withRouter(Login);
