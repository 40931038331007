import _ from "lodash";

const useCustomerDepositUtils = () => {
  const getFilters = payload => {
    if (!_.isEmpty(payload)) {
      // if (payload.isActive !== "") {
      //   return Object.assign(
      //     {},
      //     {
      //       ...payload,
      //       isActive:
      //         payload.isActive === "true"
      //           ? t("common.ReplyYes")
      //           : t("common.ReplyNo")
      //     }
      //   );
      // }
    }
    return payload;
  };

  return { getFilters };
};

export default useCustomerDepositUtils;
