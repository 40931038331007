import {useSelector} from "react-redux";

const useSearchState = name => {
  const searchQuery = useSelector(state => state[name].search.query);
  const companyAutocompleteData = useSelector(
    state => state[name].search.companyAutocomplete.data
  );
  const selectedAutocompleteValue = useSelector(
    state => state[name].search.companyAutocomplete.selectedItem
  );

  return { searchQuery, companyAutocompleteData, selectedAutocompleteValue };
};

export default useSearchState;
