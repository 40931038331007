import { useCallback } from "react";
import useNavigationActions from "./useNavigationActions";

const useNavigationUtils = () => {
  const { onNavMainItemSelect } = useNavigationActions();
  const getMainItemPath = useCallback(
    pathname => {
      let mainPath = pathname;
      mainPath.indexOf(1);

      mainPath.toLowerCase();

      mainPath = mainPath.split("/")[1];
      onNavMainItemSelect(mainPath);
    },
    [onNavMainItemSelect]
  );

  return { getMainItemPath };
};

export default useNavigationUtils;
