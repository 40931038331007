import React, { useRef } from "react";
import { SearchSection } from "../../../common/section";
import useDiscountLayoutState from "../../hooks/layout/useDiscountLayoutState";
import DiscountVerticalSearchForm from "../../search/forms/discountVerticalSearchForm";
import { useDispatch } from "react-redux";
import { getDiscountData } from "../../graphql/queries/getDiscountData";
import useLayoutActionDispatchers from "../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { DISCOUNT } from "../../../common/redux/types/viewTypes";
import useDataState from "../../../common/redux/hooks/useDataState";
import { discount } from "../../../common/redux/types/selectorTypes";

const DiscountSearchSection = () => {
  const { searchOpen } = useDiscountLayoutState();
  const formRef = useRef();
  const { handleSearchToggle } = useLayoutActionDispatchers(DISCOUNT);
  const { isLoading } = useDataState(discount);
  const dispatch = useDispatch();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <DiscountVerticalSearchForm
        ref={formRef}
        handleSearch={payload => dispatch(getDiscountData(payload))}
      />
    </SearchSection>
  );
};

export default DiscountSearchSection;
