import React from "react";
import useAppLayout from "../../layout/hooks/useAppLayout";
import { useDispatch } from "react-redux";
import { SET_NAV_ITEMS_STATE } from "../../layout/actions/layoutActions";

const NavigationPreferences = () => {
  const dispatch = useDispatch();
  const { appLayoutConfig } = useAppLayout();
  return (
    <div>
      <p>Navigation preferences</p>
      <div>
        {appLayoutConfig.map((item, idx) => {
          return (
            <div
              onClick={() =>
                dispatch(SET_NAV_ITEMS_STATE({ item: !item.hidden }))
              }
              style={{
                backgroundColor: "gray",
                borderRadius: "8px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem"
              }}
            >
              {item.translation} - {item.hidden ? "hidden" : "shown"}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationPreferences;
