import React from "react";
import { FormBody, FormCol, DisplayField } from "../../../../common/form/form";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { Formik } from "formik";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";

const CustomerAccountDetailsForm = ({ t }) => {
  const CustomerAccountDetailsTrans = "customerAccount.details.";
  const { selectedItem: data } = useDataState(customerAccount);
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          additionalInfo: data.additionalInfo || "-",
          client: data.customer ? data.customer[0].name : "-",
          date: data.entryTime || "-",
          sourceReference: data.entrySource || "-",
          amount: data.sum || "-",
          total: data.total || "-"
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <DisplayField
                classes="form__group form__group--display"
                id="client"
                label={t(CustomerAccountDetailsTrans + "Client")}
                value={values.client}
              />
              <DisplayField
                classes="form__group form__group--display"
                id="sourceReference"
                label={t(CustomerAccountDetailsTrans + "SourceReference")}
                value={values.sourceReference}
              />
            </FormCol>
            <FormCol>
              <DisplayField
                classes="form__group form__group--display"
                id="date"
                label={t(CustomerAccountDetailsTrans + "Date")}
                value={values.date}
              />
              <DisplayField
                classes="form__group form__group--display"
                id="amount"
                label={t(CustomerAccountDetailsTrans + "Amount")}
                value={values.amount}
              />
            </FormCol>
            <FormCol>
              <DisplayField
                classes="form__group form__group--display"
                id="total"
                label={t(CustomerAccountDetailsTrans + "Total")}
                value={values.total}
              />

              <div className="form__group form__group--display">
                <label>
                  {t(CustomerAccountDetailsTrans + "EntrySourceType")}
                </label>
                <select>
                  <option>SWED EE</option>
                </select>
              </div>
            </FormCol>
            <div className="form-col u-margin-left-small">
              <div className="form__block form__group form__block--multiline form__group--display">
                <label>
                  {t(CustomerAccountDetailsTrans + "AdditionalInformation")}
                </label>
                <textarea
                  type="text"
                  name="additionalInfo"
                  value={values.additionalInfo}
                  onChange={handleChange}
                />
              </div>
            </div>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default CustomerAccountDetailsForm;
