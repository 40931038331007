import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import CustomerAutocompleteInputField from "../../../common/autocomplete/customerAutocompleteField";
import { CUSTOMERS } from "../../../common/redux/types/viewTypes";
import { customers } from "../../../common/redux/types/selectorTypes";
import {
  BoolSelectInputField,
  DateInputField,
  InputField
} from "../../../common/form/form";

const TasksSearchForm = forwardRef(({ handleSearch }, ref) => {
  const { t } = useTranslation();
  const TranslationNS = "tasks.";

  return (
    <div className="section__search-form">
      <div style={{ height: "100%", overflow: "visible" }}>
        <Formik
          ref={ref}
          enableReinitialize={true}
          // validationSchema={CustomerSearchSchema}
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            handleSearch(values);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            validateField,
            validateOnChange,
            errors,
            touched,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <DateInputField
                type="date"
                classes="form__group-stack"
                id="factoringDebtorOverdueInvoicesInvoiceDateFrom"
                name="validFrom"
                placement="top"
                value={values.validFrom}
                handleChange={setFieldValue}
                label={t(TranslationNS + "FilterTasksFrom")}
              />

              <DateInputField
                type="date"
                classes="form__group-stack"
                id="factoringDebtorOverdueInvoicesInvoiceDateTo"
                name="validTo"
                placement="top"
                value={values.validFrom}
                handleChange={setFieldValue}
                label={t(TranslationNS + "FilterTasksTo")}
              />

              <BoolSelectInputField
                classes="form__group-stack"
                name="is_active"
                id="factoringDebtorOverdueInvoicesIsPaid"
                value={values.is_active}
                onChange={handleChange}
                label={t(TranslationNS + "FilterTaskType")}
              />

              <BoolSelectInputField
                classes="form__group-stack"
                name="is_active"
                id="factoringDebtorOverdueInvoicesInvoiceNotIssued"
                value={values.is_active}
                onChange={handleChange}
                label={t(TranslationNS + "FilterResult")}
              />

              <InputField
                type="number"
                classes="form__group-stack"
                id="factoringDebtorOverdueInvoicesIssuedForInvoiceNo"
                name="count"
                value={values.count}
                // error={errors.count}
                onChange={handleChange}
                label={t(TranslationNS + "FilterStatus")}
              />
              <InputField
                type="number"
                classes="form__group-stack"
                id="factoringDebtorOverdueInvoicesResultCount"
                name="count"
                value={values.count}
                onChange={handleChange}
                label={t(TranslationNS + "FilterUser")}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
});

export default TasksSearchForm;
