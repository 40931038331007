import React from "react";
import View from "../../common/view";
import FactoringDebtorMainSection from "./layout/main/factoringDebtorMainSection";
import FactoringDebtorSearchSection from "./layout/search/factoringDebtorSearchSection";

const FactoringDebtorView = () => {
  return (
    <View
      MainSection={FactoringDebtorMainSection}
      SearchSection={FactoringDebtorSearchSection}
    />
  );
};

export default FactoringDebtorView;
