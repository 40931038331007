import {useSelector} from "react-redux";

const useLayoutState = selectorName => {
  const tableVisible = useSelector(
    state => state[selectorName].layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state[selectorName].layout.expanders.visible
  );
  const searchOpen = useSelector(
    state => state[selectorName].layout.search.isOpen
  );

  return { tableVisible, expandersVisible, searchOpen };
};

export default useLayoutState;
