import React, { useState } from "react";
import { Section, SectionHead, SectionHeading } from "../common/section";
import SectionTabs, {
  SectionTabList,
  SectionTab,
  SectionTabPanel
} from "../common/tabs/sectionTabs";
import PreferencesGeneralForm from "./forms/general/preferencesGeneralForm";
import PreferencesAccountForm from "./forms/account/preferencesAccountForm";
import NavigationPreferences from "./navigation/navigationPreferences";

const PreferencesSection = () => {
  const [hashMap] = useState({
    "#general": 0,
    "#account": 1
  });
  return (
    <Section>
      <SectionTabs hashMap={hashMap}>
        <SectionHead>
          <SectionHeading heading="Preferences" />
        </SectionHead>
        <div className="preferences">
          <SectionTabList>
            <div className="preferences__sidebar">
              <SectionTab>General</SectionTab>
              <SectionTab>Account</SectionTab>
              <SectionTab>Navigation</SectionTab>
            </div>
          </SectionTabList>

          <div className="preferences__body u-margin-left-medium">
            <SectionTabPanel>
              <PreferencesGeneralForm />
            </SectionTabPanel>
            <SectionTabPanel>
              <PreferencesAccountForm />
            </SectionTabPanel>
            <SectionTabPanel>
              <NavigationPreferences />
            </SectionTabPanel>
          </div>
        </div>
      </SectionTabs>
    </Section>
  );
};
export default PreferencesSection;
