import React, { useRef } from "react";
import {
  SectionMain,
  SectionHeader,
  SectionBody
} from "../../../../common/section";
import _ from "lodash";
import useFactoringInvoicesLayoutActions from "../../hooks/layout/useFactoringInvoicesLayoutActions";
import { useTranslation } from "react-i18next";
import useFactoringInvoicesState from "../../hooks/useFactoringInvoicesState";
import useFactoringInvoicesConsts from "../../hooks/useFactoringInvoicesConsts";
import useFactoringInvoicesLayoutState from "../../hooks/layout/useFactoringInvoicesLayoutState";
import useFactoringInvoicesActions from "../../hooks/useFactoringInvoicesActions";
import FactoringInvoicesTable from "../../factoringInvoicesTable";
import FactoringInvoicesButtons from "./factoringInvoicesButtons";
import useFactoringInvoicesUtils from "../../hooks/useFactoringInvoicesUtils";

const FactoringInvoicesMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useFactoringInvoicesLayoutActions();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useFactoringInvoicesLayoutState();
  const { handleSearch, handleRowSelect } = useFactoringInvoicesActions();
  const { searchQuery, selectedRow } = useFactoringInvoicesState();
  const { filterLabels } = useFactoringInvoicesConsts();
  const { tabs } = useFactoringInvoicesConsts();
  const { getFilters } = useFactoringInvoicesUtils();
  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("factoring.invoices.FactoringInvoice")}
        getFilters={getFilters}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />
      <SectionBody
        TableComponent={FactoringInvoicesTable}
        ButtonsComponent={FactoringInvoicesButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        selectedItem={
          !_.isEmpty(selectedRow) ? selectedRow.customer[0].name : ""
        }
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default FactoringInvoicesMainSection;
