import { useSelector } from "react-redux";

const useFactoringDebtorLayoutState = () => {
  const tableVisible = useSelector(
    state => state.factoringDebtor.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.factoringDebtor.layout.expanders.visible
  );
  const searchOpen = useSelector(
    state => state.factoringDebtor.layout.search.isOpen
  );

  return { tableVisible, expandersVisible, searchOpen };
};

export default useFactoringDebtorLayoutState;
