import React from "react";
import { FormBody } from "../../../../common/form/form";
import { Formik } from "formik";
import ClientNoEUtable from "../clientNoEUtable";

const ClientNoEUform = ({ t, data }) => {
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, handleChange }) => (
          <FormBody>
            <ClientNoEUtable></ClientNoEUtable>
            <button
              className="btn btn--primary btn--small u-margin-top-tiny u-margin-right-small"
              type="submit"
              onClick={() => console.log("click")}
            >
              {t("customer.clientNoEU.BtnAddTaxOfficeNo")}
            </button>
            <button
              className="btn btn--danger btn--small u-margin-top-tiny"
              type="submit"
              onClick={() => console.log("click")}
            >
              {t("customer.clientNoEU.BtnDeleteTaxOfficeNo")}
            </button>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default ClientNoEUform;
