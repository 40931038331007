import React, { useEffect } from "react";
import View from "../../common/view";
import FactoringInvoicesMainSection from "./layout/main/factoringInvoicesMainSection";
import FactoringInvoicesSearchSection from "./layout/search/factoringInvoicesSearchSection";

const FactoringInvoicesView = () => {
  useEffect(() => {});

  return (
    <View
      MainSection={FactoringInvoicesMainSection}
      SearchSection={FactoringInvoicesSearchSection}
    />
  );
};

export default FactoringInvoicesView;
