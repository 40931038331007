import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FactoringInvoicesSectionDetailsForm from "../details/factoringInvoicesSectionDetailsForm";
import useFactoringInvoicesState from "./useFactoringInvoicesState";
import {details, factoringInvoices} from "../../../common/redux/types/selectorTypes";
import {DETAILS, FACTORING_INVOICES} from "../../../common/redux/types/viewTypes";

const useFactoringInvoicesConsts = () => {
  const { t } = useTranslation();
  const { selectedRow } = useFactoringInvoicesState();
  const filterLabels = {
    customerName: t("factoring.invoices.FactoringInvoice"),
    factoringDebtor: t("factoring.invoices.FactoringDebtor"),
    invoiceNo: t("factoring.invoices.InvoiceNo"),
    contractAnnexNo: t("factoring.invoices.ContractAnnexNo"),
    isInContractAnnex: t("factoring.invoices.FilterIsInContractAnnex"),
    isPaid: t("factoring.invoices.FilterIsPaid"),
    dateFrom: t("factoring.invoices.DateFrom"),
    dateTo: t("factoring.invoices.DateTo"),
    dueDateFrom: t("factoring.invoices.DueDateFrom"),
    dueDateTo: t("factoring.invoices.DueDateTo"),
    isSummaryInvoice: t("factoring.invoices.FilterIsSummaryInvoice"),
    hasCancellation: t("factoring.invoices.FilterHasCancellation"),
    resultCount: "Result Count"
  };

  const tabs = useMemo(
    () => [
      {
        label: t("factoring.invoices.FactoringInvoiceDetails"),
        component: (
          <FactoringInvoicesSectionDetailsForm t={t} data={selectedRow} />
        ),
        viewSelector: factoringInvoices,
        viewType: FACTORING_INVOICES,
        tabSelector: details,
        tabType: DETAILS
      }
    ],
    [t, selectedRow]
  );

  return { filterLabels, tabs };
};

export default useFactoringInvoicesConsts;
