import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  TOGGLE_CUSTOMER_ACCOUNT_SEARCH,
  TOGGLE_CUSTOMER_ACCOUNT_TABLE,
  SET_CUSTOMER_ACCOUNT_TABLE_HEIGHT,
  TOGGLE_CUSTOMER_ACCOUNT_EXPANDER_VIEW
} from "../actions/layout/customerAccountLayoutActions";

const useCustomerAccountUtils = () => {
  const dispatch = useDispatch();
  const getCustomerAccountFilters = (payload, t) => {
    if (!_.isEmpty(payload)) {
      if (payload.transactionEntry) {
        return Object.assign(
          {},
          {
            ...payload,
            transactionEntry: t(
              `classifications.ENTRY_SOURCE_TYPE.${
                payload.transactionEntry.split(".")[1]
              }`
            )
          }
        );
      }

      if (payload.hasEntryBalance) {
        return Object.assign(
          {},
          {
            ...payload,
            hasEntryBalance: payload.hasEntryBalance === "true" ? "yes" : "no"
          }
        );
      }
    }
    return payload;
  };

  const onToggleSearch = () => {
    dispatch(TOGGLE_CUSTOMER_ACCOUNT_SEARCH());
  };

  const onToggleTable = useCallback(() => {
    dispatch(TOGGLE_CUSTOMER_ACCOUNT_TABLE());
  }, [dispatch]);

  const onSetTableHeight = useMemo(
    () => payload => {
      dispatch(SET_CUSTOMER_ACCOUNT_TABLE_HEIGHT(payload));
    },
    [dispatch]
  );

  const onToggleExpanders = useCallback(() => {
    dispatch(TOGGLE_CUSTOMER_ACCOUNT_EXPANDER_VIEW());
  }, [dispatch]);

  return {
    getCustomerAccountFilters,
    onToggleExpanders,
    onToggleTable,
    onSetTableHeight,
    onToggleSearch
  };
};

export default useCustomerAccountUtils;
