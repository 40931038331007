import React from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import CustomerInvoiceSettingsTable from "../customerInvoiceSettingsTable";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { getFormChange } from "../../../../../services/formService";
import { CUSTOMER_FORM_INVOICE_SETTINGS_DATA_CHANGE } from "../../actions/forms/customersFormActions";
import { useTranslation } from "react-i18next";

const CustomerInvoiceSettingsForm = ({ selectedRow: data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const languages = useSelector(
    state => state.layout.classifications.languages
  );
  const handleFormChange = (e, values) => {
    dispatch(
      CUSTOMER_FORM_INVOICE_SETTINGS_DATA_CHANGE(getFormChange(e, values))
    );
  };

  const CustomerInvoiceSettingsTrans = "customer.customerInvoiceSettings.";

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        paymentRef: data.paymentRef ? data.paymentRef : "",
        paymentTermInDays: data.paymentTermInDays || "",
        invoiceEmail: data.invoiceEmail || "",
        invoiceLanguageCl: data.invoiceLanguageCl || "",
        invoiceCurrency: data.invoiceCurrencyCl ? data.invoiceCurrencyCl : "",
        penaltyPercentage: data.penaltyPercent ? data.penaltyPercent : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,

        handleChange
      }) => (
        <FormBody flex="true">
          <FormCol className="u-margin-right-small">
            <InputField
              id="paymentTermInDays"
              name="paymentTermInDays"
              classes="form__group"
              label={t(CustomerInvoiceSettingsTrans + "PaymentTermInDays")}
              value={values.paymentTermInDays}
              onChange={e => {
                handleChange(e);
                handleFormChange(e, values);
              }}
            />
            <InputField
              id="paymentRef"
              name={"paymentRef"}
              type="text"
              classes="form__group"
              label={t(CustomerInvoiceSettingsTrans + "PaymentRef")}
              value={values.paymentRef}
              onChange={e => {
                handleChange(e);
                handleFormChange(e, values);
              }}
            />

            <InputField
              id="invoice_email"
              name="invoiceEmail"
              classes="form__group"
              label={t(CustomerInvoiceSettingsTrans + "InvoiceEmail")}
              value={values.invoiceEmail}
              onChange={e => {
                handleChange(e);
                handleFormChange(e, values);
              }}
            />
          </FormCol>
          <FormCol>
            <SelectInputField
              id="invoice_lang"
              name="invoiceLanguageCl"
              label={t(CustomerInvoiceSettingsTrans + "InvoiceLanguage")}
              classes="form__group"
              options={[
                { name: "", value: "" },
                ...languages.map(lang => {
                  return {
                    name: t(`classifications.${lang.name}`),
                    value: lang.value
                  };
                })
              ]}
              value={values.invoiceLanguageCl}
              onChange={e => {
                handleChange(e);
              }}
            />

            <SelectInputField
              id="invoice_lang"
              label={t(CustomerInvoiceSettingsTrans + "InvoiceCurrency")}
              classes="form__group"
              options={[
                { name: "EUR", value: "eur" },
                { name: "USD", value: "usd" }
              ]}
              value={values.invoiceLanguage}
              onChange={e => {
                handleChange(e);
              }}
            />
            <InputField
              id="penaltyPercentageDay"
              name="penaltyPercentage"
              type="text"
              classes="form__group"
              label={t(CustomerInvoiceSettingsTrans + "Penalty")}
              value={values.penaltyPercentage}
              onChange={e => {
                handleChange(e);
                handleFormChange(e, values);
              }}
            />
          </FormCol>
          <div className="form-col u-margin-left-small">
            <CustomerInvoiceSettingsTable t={t} />
          </div>
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerInvoiceSettingsForm;
