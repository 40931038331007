import {useSelector} from "react-redux";

const useAuthState = () => {
  const userAuthenticated = useSelector(state => state.auth.userAuthenticated);
  const permissionsLoaded = useSelector(state => state.auth.permissionsLoaded);
  const assignedPermissions = useSelector(state => state.auth.user.assignedPermissions);
  const reduxToken = useSelector(state => state.auth.token);
  const loginModalOpen = useSelector(state => state.auth.login_modal.isOpen);
  const isLoading = useSelector(state => state.auth.isLoading);

  return { userAuthenticated, reduxToken, loginModalOpen, isLoading, permissionsLoaded, assignedPermissions };
};

export default useAuthState;
