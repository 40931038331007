import { useSelector } from "react-redux";

const useFactoringInvoicesLayoutState = () => {
  const tableVisible = useSelector(
    state => state.factoringInvoices.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.factoringInvoices.layout.expanders.visible
  );

  const searchOpen = useSelector(
    state => state.factoringInvoices.layout.search.isOpen
  );

  return { tableVisible, expandersVisible, searchOpen };
};

export default useFactoringInvoicesLayoutState;
