import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {CLEAR_FORMS_STATE, SET_FORMS_STATE} from "../formActions";

const useFormsActionDispatchers = name => {
  const dispatch = useDispatch();

  const handleFormsChange = useCallback(
    payload => {
      dispatch(SET_FORMS_STATE(name, payload));
    },
    [dispatch, name]
  );

  const handleFormsStateClear = useCallback(() => {
    dispatch(CLEAR_FORMS_STATE(name))
  }, [dispatch, name]);

  return { handleFormsChange, handleFormsStateClear };
};

export default useFormsActionDispatchers;
