import React from "react";
import { Button } from "../../../common/buttons/button";

const DiscountButtons = () => {
  return (
    <React.Fragment>
      <Button btnStyle="primary" label="TODO" size="medium" />
    </React.Fragment>
  );
};

export default DiscountButtons;
