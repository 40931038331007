export const customers = "customers";
export const customerAccount = "customerAccount";
export const customerInvoices = "customerInvoices";
export const customerDeposit = "customerDeposit";
export const contract = "contract";
export const transactions = "transactions";
export const service = "service";
export const discount = "discount";
export const vatDocuments = "vatDocuments";
export const vatApplications = "vatApplications";
export const card = "card";
export const partner = "partner";
export const salespoint = "salespoint";
export const factoringDebtor = "factoringDebtor";
export const factoringDebtorAccount = "factoringDebtorAccount";
export const factoringInvoices = "factoringInvoices";
export const factoringDebtorOverdueInvoices = "factoringDebtorOverdueInvoices";
export const tasks = "tasks";

export const receivedMail = "receivedMail";
export const clearing = "clearing";
export const statusHistory = "statusHistory";
export const productCost = "productCost";
export const bankAccountTypes = "bankAccountTypes";
export const details = "details";
export const addresses = "addresses";
export const comments = "comments";
export const contactInformation = "contactInformation";
export const clientNoEuTaxOffice = "clientNoEuTaxOffice";
export const contractOverview = "contractOverview";
export const invoiceSettings = "invoiceSettings";
export const vatInfo = "vatInfo";
export const paymentHistory = "paymentHistory";
export const factoringBankAccount = 'factoringBankAccount';
export const factoringDebtors = "factoringDebtors";
export const services = 'services';
export const applicationPaymentInfo = "applicationPaymentInfo";
export const paymentRegistration = "paymentRegistration";
export const limits = "limits";
export const productPriceConditions = "productPriceConditions";
export const partnerAndInvoiceSettings = "partnerAndInvoiceSettings";
export const contractData = "contractData";
export const clientAndInvoiceSettings = "clientAndInvoiceSettings";
export const creditLimitHistory = "creditLimitHistory";
export const query = "query";
export const taskLog = "taskLog";
