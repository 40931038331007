import { useSelector } from "react-redux";

const useServiceLayoutState = () => {
  const tableVisible = useSelector(state => state.service.layout.table.visible);
  const searchOpen = useSelector(state => state.service.layout.search.isOpen);
  const expandersVisible = useSelector(
    state => state.service.layout.expanders.visible
  );

  return { tableVisible, expandersVisible, searchOpen };
};

export default useServiceLayoutState;
