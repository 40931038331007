import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  loadFactoringDebtorAccData,
  SELECT_FACTORING_DEBTOR_ACC_ROW
} from "../actions/factoringDebtorAccountActions";

const useFactoringDebtorAccountActions = () => {
  const dispatch = useDispatch();
  const handleSearch = useCallback(
    payload => {
      dispatch(loadFactoringDebtorAccData(payload));
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_FACTORING_DEBTOR_ACC_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useFactoringDebtorAccountActions;
