import { useSelector } from "react-redux";

const useSalespointState = () => {
  const searchQuery = useSelector(state => state.salespoint.search.query);
  const selectedRow = useSelector(state => state.salespoint.data.selectedItem);

  return { searchQuery, selectedRow };
};

export default useSalespointState;
