import { useDispatch } from "react-redux";
import {
  SELECT_CUSTOMER_DEPOSIT_ROW,
  TOGGLE_CUSTOMER_DEPOSIT_ROW
} from "../actions/customerDepositActions";
import { getCustomerDepoData } from "../actions/data/customerDepositDataQueries";

const useCustomerDepositActions = () => {
  const dispatch = useDispatch();

  const handleRowSelect = payload => {
    dispatch(SELECT_CUSTOMER_DEPOSIT_ROW(payload));
  };

  const handleRowToggle = payload => {
    dispatch(TOGGLE_CUSTOMER_DEPOSIT_ROW(payload));
  };

  const handleSearch = payload => {
    dispatch(getCustomerDepoData(payload));
  };

  return { handleRowSelect, handleRowToggle, handleSearch };
};

export default useCustomerDepositActions;
