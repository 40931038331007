import React from "react";
import CustomerMainSection from "./layout/main/customerMainSection";
import View from "../../common/view";
import CustomerSearchSection from "./layout/search/customerSearchSection";

const CustomerView = () => {
  return (
    <View
      MainSection={CustomerMainSection}
      SearchSection={CustomerSearchSection}
    />
  );
};

export default CustomerView;
