
const useCardFilters = (payload, t) => {
    const newFilters = {};
    Object.assign(newFilters, payload);

    // Object.keys(newFilters).forEach(item => {
    //     Object.assign(newFilters, {item: t(`classifications.${newFilters[item]}`)})
    // });
            if(payload.type){
                Object.assign(newFilters, {type: t(`classifications.${payload.type}`)});
            }
            if(payload.status){
                Object.assign(newFilters, {status: t(`classifications.${payload.status}`)})
            }
            if(payload.is_active){
                Object.assign(newFilters, {is_active: t(`classifications.${payload.is_active}`)})
            }
            if(payload.customer_country){
                Object.assign(newFilters, {customer_country: t(`countries.${payload.customer_country}`)})
            }
            if(payload.is_valid){
                Object.assign(newFilters, {is_valid: t(`classifications.${payload.is_valid}`)})
            }



    return newFilters;

};

export default useCardFilters;