const createSearchReducer = (name = "") => {
  const initialState = {
    isOpen: true,
    lastHeight: "",
    query: {},
    companyAutocomplete: {
      data: [],
      selectedItem: "",
      query: {},
      isLoading: false,
      error: {}
    },
    dropdowns: {}
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `SUBMIT_${name}_QUERY`: {
        return {
          ...state,
          query: action.payload
        };
      }

      case `LOAD_${name}_COMPANY_AUTOCOMPLETE_START`:
        return {
          ...state,
          companyAutocomplete: { ...state.companyAutocomplete, isLoading: true }
        };

      case `LOAD_${name}_COMPANY_AUTOCOMPLETE_SUCCESS`:
        return {
          ...state,
          companyAutocomplete: {
            ...state.companyAutocomplete,
            isLoading: false,
            data: action.payload
          }
        };

      case `LOAD_${name}_COMPANY_AUTOCOMPLETE_ERROR`:
        return {
          ...state,
          companyAutocomplete: {
            ...state.companyAutocomplete,
            isLoading: false,
            error: action.payload
          }
        };

      case `CLEAR_${name}_COMPANY_AUTOCOMPLETE_DATA`:
        return {
          ...state,
          companyAutocomplete: {
            ...state.companyAutocomplete,
            data: []
          }
        };

      case `CLEAR_${name}_COMPANY_AUTOCOMPLETE_SELECTED_VALUE`: {
        return {
          ...state,
          companyAutocomplete: {
            ...state.companyAutocomplete,
            selectedItem: ""
          }
        };
      }

      case `SELECT_${name}_COMPANY_AUTOCOMPLETE_VALUE`:
        return {
          ...state,
          companyAutocomplete: {
            ...state.companyAutocomplete,
            selectedItem: action.payload
          }
        };

      case `SET_${name}_DROPDOWN_VALUES`:
        return {
          ...state,
          dropdowns: { ...state.dropdowns, ...action.payload }
        };

      default:
        return state;
    }
  };
};

export default createSearchReducer;
