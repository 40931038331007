import React from "react";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import {
  clearing,
  customerAccount
} from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {
  CLEARING,
  CUSTOMER_ACCOUNT
} from "../../../common/redux/types/viewTypes";

const CustomerAccountClearingTable = ({ data, t }) => {
  const {
    handleTableHeightSet,
    handleRowSelect
  } = useTabTableActionDispatchers(CUSTOMER_ACCOUNT, CLEARING);
  const { table, selectedItem } = useTabState(customerAccount, clearing);
  const CustomerAccountClearingTrans = "customerAccount.clearing.";
  const columns = [
    {
      Header: t(CustomerAccountClearingTrans + "ClearingDocumentNo"),
      accessor: "id"
    },

    {
      Header: t(CustomerAccountClearingTrans + "Info"),
      accessor: "description"
    },
    {
      Header: t(CustomerAccountClearingTrans + "Time"),
      accessor: "created_on"
    },
    {
      Header: t(CustomerAccountClearingTrans + "Amount"),
      accessor: "balance"
    },

    {
      Header: t(CustomerAccountClearingTrans + "EntryOriginalAmount"),
      accessor: "sum"
    },

    {
      Header: t("customerAccount.AdditionalInfo"),
      accessor: "additional_info",
      minWidth: 350
    }
  ];

  return (
    <Table
      columns={columns}
      data={data || []}
      onRowSelect={handleRowSelect}
      setTableHeight={handleTableHeightSet}
      selectedRow={selectedItem}
      height={table.height}
    />
  );
};

export default CustomerAccountClearingTable;
