import React, {useMemo} from "react";
import VatDocumentsDetails from "../vatDocumentsDetails";
import {useTranslation} from "react-i18next";
import VatDocumentsServicesForm from "../services/form/vatDocumentsServicesForm";
import VatDocumentsApplicationPaymentDataForm
    from "../applicationPaymentData/forms/vatDocumentsapplicationPaymentDataForm";
import VatDocumentsReceivedMailTable from "../receivedMail/vatDocumentsReceivedMailTable";
import {
    applicationPaymentInfo,
    details,
    paymentRegistration,
    receivedMail,
    services,
    vatDocuments
} from "../../../common/redux/types/selectorTypes";
import {
    APPLICATION_PAYMENT_INFO,
    DETAILS,
    PAYMENT_REGISTRATION,
    RECEIVED_MAIL,
    SERVICES,
    VAT_DOCUMENTS
} from "../../../common/redux/types/viewTypes";

const useVatDocumentsConsts = () => {
  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        label: t("vat.documents.VatDocumentDetailData"),
        component: <VatDocumentsDetails data={[]} />,
          viewSelector: vatDocuments,
          viewType: VAT_DOCUMENTS,
          tabSelector: details,
          tabType: DETAILS
      },
      {
        label: t("vat.documents.VatDocumentServices"),
        component: <VatDocumentsServicesForm />,
          viewSelector: vatDocuments,
          viewType: VAT_DOCUMENTS,
          tabSelector: services,
          tabType: SERVICES
      },
      {
        label: t("vat.documents.VatDocumentApplicationPaymentData"),
        component: <VatDocumentsApplicationPaymentDataForm />,
          viewSelector: vatDocuments,
          viewType: VAT_DOCUMENTS,
          tabSelector:applicationPaymentInfo,
          tabType: APPLICATION_PAYMENT_INFO
      },
      {
        label: t("vat.documents.VatDocumentReceivedMail"),
        component: <VatDocumentsReceivedMailTable />,
          viewSelector: vatDocuments,
          viewType: VAT_DOCUMENTS,
          tabSelector: receivedMail,
          tabType: RECEIVED_MAIL
      },
      {
        label: t("vat.documents.VatDocumentPaymentRegisteringData"),
        component: <h2>PAYMENT REGISTERING DATA</h2>,
          viewSelector: vatDocuments,
          viewType: VAT_DOCUMENTS,
          tabSelector:paymentRegistration,
          tabType: PAYMENT_REGISTRATION
      }
    ],
    [t]
  );

  const filterLabels = {
    customer: t("customer.customerView.FilterCustomerName"),
    id: t("customer.customerView.FilterCustomerId"),
    woCusManager: t("customer.customerView.WithoutCustomerManager"),
    regNo: t("customer.customerView.FilterRegNo"),
    vatNo: t("customer.customerView.VatNo"),
    contactPerson: t("customer.customerView.FilterContactPerson"),
    count: t("customer.customerView.FilterResultCount"),
    generalPhone: t("customer.customerView.FilterGeneralPhone"),
    generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
    salesAgent: t("customer.customerView.SalesAgent"),
    customerManager: t("customer.customerView.CustomerManager"),
    withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
    isActive: t("customer.customerView.FilterIsActive"),
    validFrom: t("customer.customerView.FilterCustomerValidFrom"),
    portOneContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfP1Contracts"
    ),
    otherContractsCount: t(
      "customer.customerView.SearchResultColumnNumberOfOtherContracts"
    )
  };

  return { tabs, filterLabels };
};

export default useVatDocumentsConsts;
