import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { loadCards } from "../graphql/cardQueries";
import useSearchState from "../../common/redux/hooks/useSearchState";
import { card } from "../../common/redux/types/selectorTypes";
import CustomerAutocompleteInputField from "../../common/autocomplete/customerAutocompleteField";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../common/form/form";
import { CARD } from "../../common/redux/types/viewTypes";
import useAppClassifications from "../../layout/hooks/useAppClassifications";
import useCardClassifications from "../hooks/useCardClassifications";

const CardVerticalSearchForm = forwardRef(({ submitRef }, ref) => {
  const dispatch = useDispatch();
  const { selectedAutocompleteValue } = useSearchState(card);

  const { cardStatus, cardTypes } = useCardClassifications();
  const { countries } = useAppClassifications();

  const handleSubmit = values => {
    dispatch(loadCards(values));
  };

  const { t } = useTranslation();

  return (
    <Formik
      ref={ref}
      enableReinitialize={true}
      initialValues={{
        count: 1000,
        customer: selectedAutocompleteValue || "",
        type: "",
        car_reg_no: "",
        card_no: "",
        status: "",
        valid_from: "",
        valid_thru: "",
        is_valid: "",
        customer_country: "",
        sales_agent: "",
        is_active: ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        validateField,
        validateOnChange,
        errors,
        touched,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomerAutocompleteInputField
            type="text"
            classes="form__group-stack"
            id="vatDocumentsSearchCustomer"
            name="customer"
            value={values.customer}
            handleChange={e => {
              handleChange(e);
            }}
            label={t("vat.documents.FilterCustomer")}
            autocomplete="password"
            data-lpignore="true"
            actionType={CARD}
            selectorType={card}
          />
          <SelectInputField
            id="vatDocumentsSearchCardType"
            name="type"
            label={t("card.FilterCardType")}
            value={values.type}
            options={[
              { name: "", value: "" },
              ...cardTypes.map(item => {
                return {
                  name: t(`classifications.${item.name}`),
                  value: item.value
                };
              })
            ]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="vatDocumentsSearchCarRegNo"
            name="car_reg_no"
            label={t("card.CarRegNo")}
            value={values.car_reg_no}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="cardSearchCardNo"
            name="card_no"
            label={t("card.FilterCardNumber")}
            value={values.card_no}
            onChange={handleChange}
            autocomplete={"nope"}
            classes="form__group-stack"
          />

          <SelectInputField
            id="vatDocumentsSearchStatus"
            name="status"
            label={t("card.FilterCardCurrentStatus")}
            value={values.status}
            options={[
              { name: "", value: "" },
              ...cardStatus.map(item => {
                return {
                  name: t(`classifications.${item.name}`),
                  value: item.value
                };
              })
            ]}
            classes="form__group-stack"
            onChange={handleChange}
          />

          <DateInputField
            id="cardSearchValidFrom"
            name="valid_from"
            label={t("card.FilterValidFrom")}
            value={values.valid_from}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <DateInputField
            id="cardSearchValidThru"
            name="valid_thru"
            label={t("card.FilterValidThru")}
            value={values.valid_thru}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="cardSearchIsValid"
            type="bool"
            name="is_valid"
            label={t("card.FilterIsValid")}
            value={values.is_valid}
            onChange={handleChange}
            classes="form__group-stack"
          />

          <SelectInputField
            id="cardSearchCustomerCountry"
            name="customer_country"
            label={t("card.FilterCustomerCountry")}
            value={values.customer_country}
            options={[
              { name: "", value: "" },
              ...countries.map(item => {
                return {
                  name: t(`countries.${item.code}`),
                  value: item.code
                };
              })
            ]}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <SelectInputField
            id="cardSearchSalesAgent"
            name="sales_agent"
            label={t("card.SalesAgent")}
            value={values.sales_agent}
            options={[{ name: "", value: "" }]}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <BoolSelectInputField
            id="cardSearchIsActive"
            name="is_active"
            label={t("card.FilterIsActive")}
            value={values.is_active}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="cardSearchCount"
            name="count"
            type="number"
            label={t("customer.customerView.FilterResultCount")}
            value={values.count}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
        </form>
      )}
    </Formik>
  );
});

export default CardVerticalSearchForm;
