import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import LayoutReducer from "../components/layout/reducers/layoutReducer";
import AuthReducer from "../components/auth/reducers/authReducer";
import createLayoutReducer from "../components/common/redux/reducers/createLayoutReducer";
import createTableReducer from "../components/common/redux/reducers/createTableReducer";
import createFormsReducer from "../components/common/redux/reducers/createFormsReducer";
import createSearchReducer from "../components/common/redux/reducers/createSearchReducer";
import createDataReducer from "../components/common/redux/reducers/createDataReducer";
import createTabReducer from "../components/common/redux/reducers/tabs/createTabReducer";
import {
  ADDRESSES,
  APPLICATION_PAYMENT_INFO,
  BANK_ACCOUNT_TYPES,
  CARD,
  CLEARING,
  CLIENT_AND_INVOICE_SETTINGS,
  CLIENT_NO_EU,
  COMMENTS,
  CONTACT_INFORMATION,
  CONTRACT,
  CONTRACT_DATA,
  CONTRACT_OVERVIEW,
  CREDIT_LIMIT_HISTORY,
  CUSTOMER_ACCOUNT,
  CUSTOMER_DEPOSIT,
  CUSTOMER_INVOICES,
  CUSTOMERS,
  DETAILS,
  DISCOUNT,
  FACTORING_BANK_ACCOUNT,
  FACTORING_DEBTOR,
  FACTORING_DEBTOR_ACCOUNT, FACTORING_DEBTOR_OVERDUE_INVOICES,
  FACTORING_DEBTORS,
  FACTORING_INVOICES,
  INVOICE_SETTINGS,
  LIMITS,
  PARTNER,
  PARTNER_AND_INVOICE_SETTINGS,
  PAYMENT_HISTORY,
  PAYMENT_REGISTRATION,
  PRODUCT_COST,
  PRODUCT_PRICE_CONDITIONS, QUERY,
  RECEIVED_MAIL,
  SALESPOINT,
  SERVICE,
  SERVICES,
  STATUS_HISTORY, TASK_LOG, TASKS,
  TRANSACTIONS,
  VAT_APPLICATIONS,
  VAT_DOCUMENTS,
  VAT_INFO
} from "../components/common/redux/types/viewTypes";

const enhancers = [];
const isDevelopment = process.env.NODE_ENV === "development";
if (
  // isDevelopment &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
enhancers.push(applyMiddleware(thunk));
const rootReducer = combineReducers({
  customers: combineReducers({
    data: createDataReducer(CUSTOMERS),
    layout: createLayoutReducer(CUSTOMERS),
    table: createTableReducer(CUSTOMERS),
    search: createSearchReducer(CUSTOMERS),
    forms: createFormsReducer(CUSTOMERS),
    tabs: combineReducers({
      details: createTabReducer(CUSTOMERS, DETAILS),
      addresses: createTabReducer(CUSTOMERS, ADDRESSES),
      comments: createTabReducer(CUSTOMERS, COMMENTS),
      contactInformation: createTabReducer(CUSTOMERS, CONTACT_INFORMATION),
      clientNoEuTaxOffice: createTabReducer(CUSTOMERS, CLIENT_NO_EU),
      contractOverview: createTabReducer(CUSTOMERS, CONTRACT_OVERVIEW),
      invoiceSettings: createTabReducer(CUSTOMERS, INVOICE_SETTINGS),
      vatInfo: createTabReducer(CUSTOMERS, VAT_INFO)
    })
  }),
  customerAccount: combineReducers({
    data: createDataReducer(CUSTOMER_ACCOUNT),
    layout: createLayoutReducer(CUSTOMER_ACCOUNT),
    table: createTableReducer(CUSTOMER_ACCOUNT),
    search: createSearchReducer(CUSTOMER_ACCOUNT),
    forms: createFormsReducer(CUSTOMER_ACCOUNT),
    tabs: combineReducers({
      details: createTabReducer(CUSTOMER_ACCOUNT, DETAILS),
      clearing: createTabReducer(CUSTOMER_ACCOUNT, CLEARING)
    })
  }),
  customerInvoices: combineReducers({
    data: createDataReducer(CUSTOMER_INVOICES),
    layout: createLayoutReducer(CUSTOMER_INVOICES),
    table: createTableReducer(CUSTOMER_INVOICES),
    search: createSearchReducer(CUSTOMER_INVOICES),
    forms: createFormsReducer(CUSTOMER_INVOICES),
    tabs: combineReducers({
      details: createTabReducer(CUSTOMER_INVOICES, DETAILS),
      statusHistory: createTabReducer(CUSTOMER_INVOICES, STATUS_HISTORY),
      paymentHistory: createTabReducer(CUSTOMER_INVOICES, PAYMENT_HISTORY)
    })
  }),
  customerDeposit: combineReducers({
    data: createDataReducer(CUSTOMER_DEPOSIT),
    layout: createLayoutReducer(CUSTOMER_DEPOSIT),
    table: createTableReducer(CUSTOMER_DEPOSIT),
    search: createSearchReducer(CUSTOMER_DEPOSIT),
    forms: createFormsReducer(CUSTOMER_DEPOSIT),
    tabs: combineReducers({
      details: createTabReducer(CUSTOMER_DEPOSIT, DETAILS)
    })
  }),
  contract: combineReducers({
    data: createDataReducer(CONTRACT),
    layout: createLayoutReducer(CONTRACT),
    table: createTableReducer(CONTRACT),
    search: createSearchReducer(CONTRACT),
    forms: createFormsReducer(CONTRACT),
    tabs: combineReducers({
      details: createTabReducer(CONTRACT, DETAILS),
      statusHistory: createTabReducer(CONTRACT, STATUS_HISTORY),
      productCost: createTabReducer(CONTRACT, PRODUCT_COST),
      bankAccountTypes: createTabReducer(CONTRACT, BANK_ACCOUNT_TYPES),
      factoringBankAccount: createTabReducer(CONTRACT, FACTORING_BANK_ACCOUNT),
      factoringDebtors: createTabReducer(CONTRACT, FACTORING_DEBTORS)
    })
  }),
  service: combineReducers({
    data: createDataReducer(SERVICE),
    layout: createLayoutReducer(SERVICE),
    table: createTableReducer(SERVICE),
    search: createSearchReducer(SERVICE),
    forms: createFormsReducer(SERVICE),
    tabs: combineReducers({
      details: createTabReducer(SERVICE, DETAILS)
    })
  }),
  factoringInvoices: combineReducers({
    data: createDataReducer(FACTORING_INVOICES),
    layout: createLayoutReducer(FACTORING_INVOICES),
    table: createTableReducer(FACTORING_INVOICES),
    search: createSearchReducer(FACTORING_INVOICES),
    forms: createFormsReducer(FACTORING_INVOICES),
    tabs: combineReducers({
      details: createTabReducer(FACTORING_INVOICES, DETAILS)
    })
  }),
  factoringDebtor: combineReducers({
    data: createDataReducer(FACTORING_DEBTOR),
    layout: createLayoutReducer(FACTORING_DEBTOR),
    table: createTableReducer(FACTORING_DEBTOR),
    search: createSearchReducer(FACTORING_DEBTOR),
    forms: createFormsReducer(FACTORING_DEBTOR),
    tabs: combineReducers({
      details: createTabReducer(FACTORING_DEBTOR, DETAILS),
      comments: createTabReducer(FACTORING_DEBTOR, COMMENTS),
      contactInformation: createTabReducer(
        FACTORING_DEBTOR,
        CONTACT_INFORMATION
      ),
      addresses: createTabReducer(FACTORING_DEBTOR, ADDRESSES),
      clientAndInvoiceSettings: createTabReducer(
        FACTORING_DEBTOR,
        CLIENT_AND_INVOICE_SETTINGS
      ),
      creditLimitHistory: createTabReducer(
        FACTORING_DEBTOR,
        CREDIT_LIMIT_HISTORY
      )
    })
  }),
  factoringDebtorAccount: combineReducers({
    data: createDataReducer(FACTORING_DEBTOR_ACCOUNT),
    layout: createLayoutReducer(FACTORING_DEBTOR_ACCOUNT),
    table: createTableReducer(FACTORING_DEBTOR_ACCOUNT),
    search: createSearchReducer(FACTORING_DEBTOR_ACCOUNT),
    forms: createFormsReducer(FACTORING_DEBTOR_ACCOUNT),
    tabs: combineReducers({
      details: createTabReducer(FACTORING_DEBTOR_ACCOUNT, DETAILS),
      clearing: createTabReducer(FACTORING_DEBTOR_ACCOUNT, CLEARING)
    })
  }),
  factoringDebtorOverdueInvoices: combineReducers({
    data: createDataReducer(FACTORING_DEBTOR_OVERDUE_INVOICES),
    layout: createLayoutReducer(FACTORING_DEBTOR_OVERDUE_INVOICES),
    table: createTableReducer(FACTORING_DEBTOR_OVERDUE_INVOICES),
    search: createSearchReducer(FACTORING_DEBTOR_OVERDUE_INVOICES),
    forms: createSearchReducer(FACTORING_DEBTOR_OVERDUE_INVOICES),
    tabs: combineReducers({
      details: createTabReducer(FACTORING_DEBTOR_OVERDUE_INVOICES, DETAILS)
    })
  }),

  vatDocuments: combineReducers({
    data: createDataReducer(VAT_DOCUMENTS),
    layout: createLayoutReducer(VAT_DOCUMENTS),
    table: createTableReducer(VAT_DOCUMENTS),
    search: createSearchReducer(VAT_DOCUMENTS),
    forms: createFormsReducer(VAT_DOCUMENTS),
    tabs: combineReducers({
      details: createTabReducer(VAT_DOCUMENTS, DETAILS),
      services: createTabReducer(VAT_DOCUMENTS, SERVICES),
      applicationPaymentInfo: createTabReducer(
        VAT_DOCUMENTS,
        APPLICATION_PAYMENT_INFO
      ),
      receivedMail: createTabReducer(VAT_DOCUMENTS, RECEIVED_MAIL),
      paymentRegistration: createTabReducer(VAT_DOCUMENTS, PAYMENT_REGISTRATION)
    })
  }),
  vatApplications: combineReducers({
    data: createDataReducer(VAT_APPLICATIONS),
    layout: createLayoutReducer(VAT_APPLICATIONS),
    table: createTableReducer(VAT_APPLICATIONS),
    search: createSearchReducer(VAT_APPLICATIONS),
    forms: createFormsReducer(VAT_APPLICATIONS),
    tabs: combineReducers({
      details: createTabReducer(VAT_APPLICATIONS, DETAILS),
      applicationPaymentInfo: createTabReducer(VAT_APPLICATIONS, APPLICATION_PAYMENT_INFO)
    })
  }),
  card: combineReducers({
    data: createDataReducer(CARD),
    layout: createLayoutReducer(CARD),
    table: createTableReducer(CARD),
    search: createSearchReducer(CARD),
    forms: createFormsReducer(CARD),
    tabs: combineReducers({
      details: createTabReducer(CARD, DETAILS),
      statusHistory: createTabReducer(CARD, STATUS_HISTORY),
      limits: createTabReducer(CARD, LIMITS)
    })
  }),
  partner: combineReducers({
    data: createDataReducer(PARTNER),
    layout: createLayoutReducer(PARTNER),
    table: createTableReducer(PARTNER),
    search: createSearchReducer(PARTNER),
    forms: createFormsReducer(PARTNER),
    tabs: combineReducers({
      details: createTabReducer(PARTNER, DETAILS),
      addresses: createTabReducer(PARTNER, ADDRESSES),
      partnerAndInvoiceSettings: createTabReducer(PARTNER, PARTNER_AND_INVOICE_SETTINGS),
      contractData: createTabReducer(PARTNER, CONTRACT_DATA),
      productPriceConditions: createTabReducer(PARTNER, PRODUCT_PRICE_CONDITIONS)
    })
  }),
  salespoint: combineReducers({
    data: createDataReducer(SALESPOINT),
    layout: createLayoutReducer(SALESPOINT),
    table: createTableReducer(SALESPOINT),
    search: createSearchReducer(SALESPOINT),
    forms: createFormsReducer(SALESPOINT),
    tabs: combineReducers({
      details: createTabReducer(SALESPOINT, DETAILS)
    })
  }),
  transactions: combineReducers({
    data: createDataReducer(TRANSACTIONS),
    layout: createLayoutReducer(TRANSACTIONS),
    table: createTableReducer(TRANSACTIONS),
    search: createSearchReducer(TRANSACTIONS),
    forms: createFormsReducer(TRANSACTIONS),
    tabs: combineReducers({
      details: createTabReducer(TRANSACTIONS, DETAILS)
    })
  }),
  discount: combineReducers({
    data: createDataReducer(DISCOUNT),
    layout: createLayoutReducer(DISCOUNT),
    table: createTableReducer(DISCOUNT),
    search: createSearchReducer(DISCOUNT),
    forms: createFormsReducer(DISCOUNT),
    tabs: combineReducers({
      details: createTabReducer(DISCOUNT, DETAILS)
    })
  }),
  tasks: combineReducers({
    data: createDataReducer(TASKS),
    layout: createLayoutReducer(TASKS),
    table: createTableReducer(TASKS),
    search: createSearchReducer(TASKS),
    forms: createFormsReducer(TASKS),
    tabs: combineReducers({
      query: createTabReducer(TASKS, QUERY),
      taskLog: createTabReducer(TASKS, TASK_LOG)
    })
  }),
  layout: LayoutReducer,
  auth: AuthReducer
});

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), ...enhancers)
);

export default store;
