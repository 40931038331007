import { useSelector } from "react-redux";

const useFactoringInvoicesState = () => {
  const searchQuery = useSelector(
    state => state.factoringInvoices.search.query
  );
  const selectedRow = useSelector(
    state => state.factoringInvoices.data.selectedItem
  );

  return {
    searchQuery,
    selectedRow
  };
};

export default useFactoringInvoicesState;
