import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import VatApplicationsButtons from "./vatApplicationsButtons";
import VatApplicationsSectionTable from "../../vatApplicationsSectionTable";
import useVatApplicationsState from "../../hooks/useVatApplicationsState";
import useVatApplicationsLayoutActions from "../../hooks/layout/useVatApplicationsLayoutActions";
import useVatApplicationsLayoutState from "../../hooks/layout/useVatApplicationsLayoutState";
import useVatApplicationsConsts from "../../hooks/useVatApplicationsConsts";
import useVatApplicationsActions from "../../hooks/useVatApplicationsActions";

const VatApplicationsMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { selectedRow, searchQuery } = useVatApplicationsState();
  const { handleSearch, handleRowSelect } = useVatApplicationsActions();
  const {
    handleSearchToggle,
    handleTableToggle,
    handleExpandersToggle
  } = useVatApplicationsLayoutActions();
  const {
    searchOpen,
    tableVisible,
    expandersVisible
  } = useVatApplicationsLayoutState();

  const { filterLabels, tabs } = useVatApplicationsConsts();
  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("vat.applications.Header")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        onSearch={handleSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={VatApplicationsSectionTable}
        ButtonsComponent={VatApplicationsButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        selectedItem={
          !_.isEmpty(selectedRow)
            ? selectedRow.customer
              ? selectedRow.customer[0].name
              : ""
            : ""
        }
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default VatApplicationsMainSection;
