import {useSelector} from "react-redux";

const useUserData = () => {
  const userPermissions = useSelector(
    state => state.auth.user.assignedPermissions
  );

  const userLanguage = useSelector(state => state.auth.user.languageCl);

  const userObj = useSelector(state => state.auth.user);

  return { userPermissions, userObj, userLanguage };
};

export default useUserData;
