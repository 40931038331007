const createFormsReducer = (name = "") => {
  const initialState = {
    forms_state: {},
    dataChanged: false
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `SET_${name}_FORMS_STATE`: {
        return {
          ...state,
          forms_state: Object.assign(state.forms_state, action.payload)
        };
      }
      case `CLEAR_${name}_FORMS_STATE`: {
        return {
          ...state,
          forms_state: {}
        }
      }
      default:
        return state;
    }
  };
};

export default createFormsReducer;
