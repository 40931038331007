import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import FactoringDebtorOverdueInvoicesDetailsForm
    from "../../factoring/debtor_overdue_invoices/details/factoringDebtorOverdueInvoicesDetailsForm";
import {
    details,
    factoringDebtorOverdueInvoices,
    paymentHistory,
    query, taskLog,
    tasks
} from "../../common/redux/types/selectorTypes";
import {
    DETAILS,
    FACTORING_DEBTOR_OVERDUE_INVOICES,
    PAYMENT_HISTORY,
    QUERY, TASK_LOG,
    TASKS
} from "../../common/redux/types/viewTypes";
import TasksQueryForm from "../query/tasksQueryForm";
import TaskLogForm from "../taskLog/taskLogForm";

const useTasksConsts = () => {

    const {t} = useTranslation();
    const filterLabels = {
        name: t("customer.customerView.FilterCustomerName"),
        id: t("customer.customerView.FilterCustomerId"),
        woCusManager: t("customer.customerView.WithoutCustomerManager"),
        regNo: t("customer.customerView.FilterRegNo"),
        vatNo: t("customer.customerView.VatNo"),
        contactPerson: t("customer.customerView.FilterContactPerson"),
        resultCount: t("customer.customerView.FilterResultCount"),
        generalPhone: t("customer.customerView.FilterGeneralPhone"),
        generalEmail: t("customer.customerView.FilterGeneralOrInvoiceEmail"),
        salesAgent: t("customer.customerView.SalesAgent"),
        customerManager: t("customer.customerView.CustomerManager"),
        withoutCustomerManager: t("customer.customerView.WithoutCustomerManager"),
        isActive: t("customer.customerView.FilterIsActive"),
        validFrom: t("customer.customerView.FilterCustomerValidFrom"),
        portOneContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfP1Contracts"
        ),
        otherContractsCount: t(
            "customer.customerView.SearchResultColumnNumberOfOtherContracts"
        )
    };

    const tabs = useMemo(
        () => [
            {
                label: t("tasks.Query"),
                component: <TasksQueryForm/>,
                viewSelector: tasks,
                viewType: TASKS,
                tabSelector: query,
                tabType: QUERY
            },
            {
                label: t("tasks.Log"),
                component: <TaskLogForm/>,
                viewSelector: tasks,
                viewType: TASKS,
                tabSelector: taskLog,
                tabType: TASK_LOG
            },

        ],
        [t]
    );

    return {filterLabels, tabs}
}

export default useTasksConsts;