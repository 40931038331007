import {
  SUBMIT_QUERY,
  LOAD_DATA_START,
  LOAD_DATA_SUCCESS,
  LOAD_DATA_ERROR
} from "../../../common/redux/actions/search/searchActions";
import { fetchDiscountData } from "./fetchDiscountData";
import { DISCOUNT } from "../../../common/redux/types/viewTypes";

export const getDiscountData = payload => {
  return dispatch => {
    dispatch(SUBMIT_QUERY(DISCOUNT, payload));
    dispatch(LOAD_DATA_START(DISCOUNT));
    fetchDiscountData(payload)
      .then(data =>
        dispatch(LOAD_DATA_SUCCESS(DISCOUNT, data.data.data.customerDiscounts))
      )
      .catch(error => dispatch(LOAD_DATA_ERROR(DISCOUNT, error.message)));
  };
};
