import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  FormFlexWrapper,
  FormCol,
  FormFlexItem,
  DisplayField,
  SelectInputField
} from "../../../common/form/form";
import { Button } from "../../../common/buttons/button";
import useAppClassifications from "../../../layout/hooks/useAppClassifications";

const CustomerInvoiceDetails = ({ data, t }) => {
  const CustomerInvoiceTransNs = "customerInvoice.";
  const { customerInvoicesStatusHistoryTypes } = useAppClassifications();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        companyName: data.company ? data.company[0].name : "-",
        netto: data.netto || "-",
        exchangeRateToEur: data.exchangeRateToEur || "-",
        invoiceNo: data.invoiceNo || "-",
        vat: data.vat || "-",
        dueDate: data.dueDate || "-",
        invoiceDate: data.invoiceDate || "-",
        invoiceStatus: data.currentStatusCl || "-",
        balance: data.balance || "-",
        totalAmount: data.totalInEur || "-"
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log("SUBMIT");
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol className="u-margin-right-small">
              <DisplayField
                id="customerName"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "CustomerName")}
                value={values.companyName}
              />
              <DisplayField
                id="netAmount"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "NetAmountStr")}
                value={values.netto}
              />
              <DisplayField
                id="exchangeRateToEur"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "ExchangeRate")}
                value={values.exchangeRateToEur}
              />

              <Button
                btnStyle="primary"
                size="small"
                style={{ width: "30%" }}
                classes="button__flex__noGrow"
                label={t("customerInvoice.details.OpenPDF")}
              />
            </FormCol>
            <FormCol>
              <DisplayField
                id="invoiceNo"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "InvoiceNo")}
                value={values.invoiceNo}
              />
              <DisplayField
                id="vat"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "VatAmountStr")}
                value={values.vat}
              />
              <DisplayField
                id="dueDate"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "DueDate")}
                value={values.dueDate}
              />
              <DisplayField
                id="invoiceDate"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "InvoiceDate")}
                value={values.invoiceDate}
              />
            </FormCol>

            <FormCol>
              <SelectInputField
                label={t(CustomerInvoiceTransNs + "details.CurrentStatus")}
                id="currentStatus"
                classes="form__group"
                onChange={handleChange}
                value={values.invoiceStatus}
                options={
                  customerInvoicesStatusHistoryTypes.length
                    ? [
                        { code: t("countries.None"), id: "" },
                        ...customerInvoicesStatusHistoryTypes.map(item => {
                          return Object.assign(
                            {},
                            {
                              name: t(
                                `classifications.CONTRACT_STATUS.${
                                  item.name.split(".")[1]
                                }`
                              ),
                              value: item.value
                            }
                          );
                        })
                      ]
                    : []
                }
              />
              <DisplayField
                id="balance"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "details.BalanceStr")}
                value={values.balance}
              />

              <DisplayField
                id="totalAmountInEur"
                classes="form__group form__group--display"
                label={t(CustomerInvoiceTransNs + "TotalAmountEurStr")}
                value={values.totalAmount}
              />
            </FormCol>
          </FormFlexWrapper>
        </FormBody>
      )}
    </Formik>
  );
};

export default CustomerInvoiceDetails;
