import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {USER_LOGOUT_START, USER_LOGOUT_SUCCESS} from "./actions/authActions";
import Cookie from 'js-cookie';

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(USER_LOGOUT_START());
    Cookie.remove("_session");
    dispatch(USER_LOGOUT_SUCCESS());
    window.location = "/";
  });
  return "";
};
