import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_FACTORING_DEBTOR_TABLE,
  TOGGLE_FACTORING_DEBTOR_EXPANDERS,
  TOGGLE_FACTORING_DEBTOR_SEARCH
} from "../../actions/factoringDebtorActions";

const useFactoringDebtorLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_DEBTOR_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_DEBTOR_EXPANDERS());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_FACTORING_DEBTOR_SEARCH());
  }, [dispatch]);

  return { handleTableToggle, handleExpandersToggle, handleSearchToggle };
};

export default useFactoringDebtorLayoutActions;
