import React from "react";
import View from "../common/view";
import DiscountMainSection from "./layout/main/discountMainSection";
import DiscountSearchSection from "./layout/search/discountSearchSection";

const DiscountView = () => {
  return (
    <View
      MainSection={DiscountMainSection}
      SearchSection={DiscountSearchSection}
    />
  );
};

export default DiscountView;
