import React from "react";
import BrokerClaimsTable from "./brokerClaimsTable";
import SectionTabs, {
  SectionTab,
  SectionTabList,
  SectionTabPanel
} from "../../common/tabs/sectionTabs";
import { Section, SectionBody, SectionHead } from "../../common/section";
import BrokerClaimsDetailsForm from "./details/forms/brokerClaimsDetailsForm";
import { useTranslation } from "react-i18next";

const BrokerClaimsSection = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <SectionHead>
        <h1 className="heading__primary">{t("broker.claims.Header")}</h1>
      </SectionHead>
      <BrokerClaimsTable t={t} />
      <SectionTabs>
        <SectionTabList>
          <SectionTab>{t("broker.claims.BrokerClaimDetailData")}</SectionTab>
        </SectionTabList>
        <SectionTabPanel>
          <BrokerClaimsDetailsForm t={t} />
        </SectionTabPanel>
      </SectionTabs>
    </Section>
  );
};

export default BrokerClaimsSection;
