const createTableReducer = (name = "") => {
  const initialState = {
    height: 275,
    selectedRowsState: []
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `SET_${name}_TABLE_HEIGHT`: {
        return {
          ...state,
          height: action.payload
        };
      }
      case `SET_${name}_SELECTED_ROWS_STATE`: {
        return {
          ...state,
          selectedRowsState: action.payload
        };
      }
      default:
        return state;
    }
  };
};

export default createTableReducer;
