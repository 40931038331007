export const TOGGLE_CARD_SEARCH = () => ({
  type: "TOGGLE_CARD_SEARCH"
});

export const TOGGLE_CARD_TABLE = () => ({
  type: "TOGGLE_CARD_TABLE"
});

export const TOGGLE_CARD_EXPANDER_VIEW = () => ({
  type: "TOGGLE_CARD_EXPANDER_VIEW"
});

export const CARD_SEARCH_START = () => ({
  type: "CARD_SEARCH_START"
});

export const SELECT_CARD_ROW = payload => ({
  type: "SELECT_CARD_ROW",
  payload: payload
});
