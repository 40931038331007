import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../../../common/buttons/button";
import { toast } from "react-toastify";
import {
  deleteCustomer,
  addNewCustomer,
  updateCustomer
} from "../../graphql/crud/customersCrudQueries";
import useFormsState from "../../../../common/redux/hooks/useFormsState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useDataActionsDispatchers from "../../../../common/redux/actions/data/hooks/useDataActionsDispatchers";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useFormsActionDispatchers from "../../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";

const CustomerButtons = formRef => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedItem } = useDataState(customers);
  const { searchQuery } = useSearchState(customers);
  const { formsState } = useFormsState(customers);
  const { handleFormsStateClear } = useFormsActionDispatchers(CUSTOMERS);
  const { handleSelectedRowClear } = useDataActionsDispatchers(CUSTOMERS);
  return (
    <React.Fragment>
      <div className="u-margin-right-small" style={{ display: "flex" }}>
        <Button
          btnStyle="primary"
          size="small"
          handleClick={() => {
            handleSelectedRowClear();
          }}
          disabled={_.isEmpty(selectedItem) ? true : false}
          label={t("customer.customerView.BtnAddCustomer")}
          classes="btn--footer"
        />
        <Button
          btnStyle="danger"
          size="small"
          label={t("customer.customerView.BtnDeleteCustomer")}
          handleClick={() => {
            dispatch(deleteCustomer(selectedItem.id, searchQuery));
            handleSelectedRowClear();
            toast.success("Entity deleted");
          }}
          disabled={_.isEmpty(selectedItem)}
          classes={"btn--footer"}
        />
        <Button
          btnStyle="green"
          size="small"
          label={t("customer.customerView.BtnSaveCustomer")}
          handleClick={() => {
            selectedItem.id
              ? dispatch(updateCustomer(formsState))
              : dispatch(addNewCustomer(formsState));

            handleFormsStateClear();
          }}
          disabled={false}
          classes="btn--footer"
        />
      </div>

      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnCustomersToCsv")}
        handleClick={() => alert("CSV")}
        disabled={_.isEmpty(selectedItem)}
        classes={"btn--footer"}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnSendToPrinter")}
        handleClick={() => alert("PRINT ENVELOPE")}
        disabled={_.isEmpty(selectedItem)}
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default CustomerButtons;
