import React, { useRef } from "react";
import {
  SectionBody,
  SectionHeader,
  SectionMain
} from "../../../../common/section";
import { useTranslation } from "react-i18next";
import useVatDocumentsState from "../../hooks/useVatDocumentsState";
import useVatDocumentsConsts from "../../hooks/useVatDocumentsConsts";
import useVatDocumentsLayoutState from "../../hooks/layout/useVatDocumentsLayoutState";
import VatDocumentsTable from "../../vatDocumentsTable";
import VatDocumentsButtons from "./vatDocumentsButtons";
import useVatDocumentsLayoutActions from "../../hooks/layout/useVatDocumentsLayoutActions";

const VatDocumentsMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const { searchQuery } = useVatDocumentsState();
  const { filterLabels, tabs } = useVatDocumentsConsts();
  const { selectedRow } = useVatDocumentsState();
  const {
    tableVisible,
    expandersVisible,
    searchOpen
  } = useVatDocumentsLayoutState();

  const {
    handleTableToggle,
    handleSearchToggle,
    handleExpandersToggle
  } = useVatDocumentsLayoutActions();
  return (
    <SectionMain toggleSearch={() => {}}>
      <SectionHeader
        sectionHeading={t("navigation.vatDocuments")}
        getFilters={payload => {
          return payload;
        }}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleTabs={() => {}}
        onToggleSearch={handleSearchToggle}
        onSearch={() => {}}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={VatDocumentsTable}
        ButtonsComponent={VatDocumentsButtons}
        shortcutsEnabled={false}
        selectedRow={selectedRow}
        selectedItem={selectedRow.customer ? selectedRow.customer[0].name : ""}
        tableVisible={tableVisible}
        onRowSelect={() => {}}
        onToggleRow={() => {}}
        tableRef={tableRef}
        expandersEnabled={expandersVisible}
        tabs={tabs}
      />
    </SectionMain>
  );
};

export default VatDocumentsMainSection;
