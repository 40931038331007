import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import useCustomerDepositLayoutState from "../../hooks/layout/useCustomerDepositLayoutState";
import CustomerDepositVerticalSearchFrom from "../../search/forms/customerDepositVerticalSearchForm";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CUSTOMER_DEPOSIT } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import { customerDeposit } from "../../../../common/redux/types/selectorTypes";

const CustomerDepositSearchSection = () => {
  const { searchOpen } = useCustomerDepositLayoutState();
  const { handleSearchToggle } = useLayoutActionDispatchers(CUSTOMER_DEPOSIT);
  const { isLoading } = useDataState(customerDeposit);
  const formRef = useRef();
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      onSearchToggle={handleSearchToggle}
      dataLoading={isLoading}
    >
      <CustomerDepositVerticalSearchFrom ref={formRef} />
    </SearchSection>
  );
};

export default CustomerDepositSearchSection;
