import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { SET_FACTORING_DEBTOR_TABLE_HEIGHT } from "../actions/factoringDebtorActions";

const useFactoringDebtorUtils = () => {
  const dispatch = useDispatch();
  const onSetTableHeight = useMemo(
    () => payload => {
      dispatch(SET_FACTORING_DEBTOR_TABLE_HEIGHT(payload));
    },
    [dispatch]
  );

  const getFilters = payload => {
    return payload;
  };

  return {
    onSetTableHeight,
    getFilters
  };
};

export default useFactoringDebtorUtils;
