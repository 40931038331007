import React from "react";
import Table from "../../common/table";
import useTabState from "../../common/redux/hooks/useTabState";
import {
  contract,
  statusHistory
} from "../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../common/redux/actions/table/useTabTableActionDispatchers";
import { CONTRACT, STATUS_HISTORY } from "../../common/redux/types/viewTypes";

const ContractViewStatusHistoryTable = ({ t, data }) => {
  const {
    handleRowSelect,
    handleTableHeightSet
  } = useTabTableActionDispatchers(CONTRACT, STATUS_HISTORY);
  const { table, selectedItem } = useTabState(contract, statusHistory);
  const ContractViewTrans = "contract.status.";
  const columns = [
    {
      id: "statusCl",
      Header: t(ContractViewTrans + "StatusDescription"),
      accessor: d => {
        switch (d.statusCl) {
          case "CONTRACT_STATUS.DRAFT": {
            return t("classifications.CONTRACT_STATUS.DRAFT");
          }
          case "CONTRACT_STATUS.SIGNED": {
            return t("classifications.CONTRACT_STATUS.SIGNED");
          }
          case "CONTRACT_STATUS.DIGITALLY_SIGNED": {
            return t("classifications.CONTRACT_STATUS.DIGITALLY_SIGNED");
          }
          default: {
            return d.statusCl;
          }
        }
      }
    },
    {
      Header: t(ContractViewTrans + "StatusDate"),
      accessor: "statusDate"
    }
  ];

  return (
    <Table
      columns={columns}
      data={data}
      onRowSelect={handleRowSelect}
      setTableHeight={handleTableHeightSet}
      selectedRow={selectedItem}
      classes="u-margin-bottom-small"
      height={table.height}
    />
  );
};

export default ContractViewStatusHistoryTable;
