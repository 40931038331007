import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { getYear } from "date-fns";
import { Formik } from "formik";
import { format } from "date-fns";

export const CustomForm = ({ children, initialValues }) => {
  const handleSearch = values => {
    return values;
  };

  return (
    <div className="section__search-form">
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initialValues }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            {children(
              values,
              handleChange,
              handleSubmit,
              validateField,
              validateOnChange,
              errors,
              touched,
              setFieldValue
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export const FormBody = props => {
  return (
    <form className={props.flex ? "form form--flex" : "form"} {...props}>
      {props.children}
    </form>
  );
};

export const FormFlexWrapper = ({ children, classes, direction }) => {
  return (
    <div
      className={"form--flex " + (classes ? classes : "")}
      style={{ flexDirection: direction ? direction : "" }}
    >
      {children}
    </div>
  );
};

export const FormFlexItem = ({
  children,
  classes,
  flexBasis,
  direction,
  flexGrow,
  flexShrink
}) => {
  return (
    <div
      className={"form--flex " + (classes || "")}
      style={{
        flexBasis: flexBasis ? flexBasis : "",
        flexDirection: direction ? direction : "",
        flexGrow: flexGrow ? flexGrow : "",
        flexShrink: flexShrink ? flexShrink : ""
      }}
    >
      {children}
    </div>
  );
};

export const FormNonFlexWrapper = props => {
  return <div className={props.classes}>{props.children}</div>;
};

export const FormCol = ({ className, children, flexBasis }) => {
  return (
    <div
      className={"form-col " + (className ? className : "")}
      style={{ flexBasis: flexBasis ? flexBasis : "" }}
    >
      {children}
    </div>
  );
};

export const FormGroup = props => {
  return <div className="form__group">{props.children}</div>;
};

export const FormGroupMultiCol = props => {
  return <div className="form__group--multiCol">{props.children}</div>;
};

export const FormGroupSmall = props => {
  return <div className="form__group form__group--small">{props.children}</div>;
};

export const FormGroupBlockMultiline = props => {
  return (
    <div className="form__block form__group form__block--multiline">
      {props.children}
    </div>
  );
};

export const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback">{error}</div> : null;

export const Label = ({ error, className, children, ...props }) => {
  return (
    <label className="label" {...props}>
      {children}
    </label>
  );
};

export const MultilineInputField = ({
  label,
  value,
  onChange,
  id,
  classes
}) => {
  return (
    <div className="form__block form__group form__block--multiline">
      <label>{label}</label>
      <textarea
        id={id}
        type="text"
        name="additionalInfo"
        value={value}
        className={classes || ""}
        onChange={onChange}
      />
    </div>
  );
};

export const DisplayField = ({
  id,
  label,
  classes,
  error,
  value,
  className,
  ...props
}) => {
  return (
    <div className={classes}>
      <Label htmlFor={id}>{label}</Label>
      <p style={{ fontSize: "1.4rem" }}>{value}</p>
    </div>
  );
};

export const InputField = ({
  type,
  id,
  label,
  classes,
  disabled,
  error,
  value,
  style,
  onChange,
  className,
  autocomplete,
  ...props
}) => {
  return (
    <div className={classes} style={style || {}}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
        disabled={disabled}
        autoComplete="off"
        data-lpignore="true"
      />
      <InputFeedback error={error} />
    </div>
  );
};

export const TextareaInputField = ({
  type,
  id,
  label,
  classes,
  error,
  value,
  onChange,
  style,
  textareaClasses,
  ...props
}) => {
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <textarea
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        className={textareaClasses}
        style={style}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export const CheckboxInputField = ({
  label,
  classes,
  error,
  checked,
  checkboxClass,
  id,
  onChange,
  value,
  props
}) => {
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        type="checkbox"
        className={checkboxClass}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export const SelectInputField = ({
  options,
  id,
  label,
  classes,
  error,
  value,
  onChange,
  className,
  style,
  customNameKey,
  customValueKey,
  ...props
}) => {
  // return (
  //   <div className={classes} style={style || {}}>
  //     <Label htmlFor={id} error={error}>
  //       {label}
  //     </Label>
  //     <select
  //       className={props.selectClass}
  //       id={id}
  //       value={value}
  //       onChange={onChange}
  //       {...props}
  //     >
  //       {options.map((option, idx) => (
  //         <option
  //           key={option.name ? option.name : idx}
  //           value={option.value ? option.value : option[customValueKey]}
  //         >
  //           {option.name ? option.name : option[customNameKey]}
  //         </option>
  //       ))}
  //     </select>
  //   </div>
  // );
  return (
    <div className={"pakk-select " + classes}>
      <Label htmlFor={id}>{label}</Label>
      <select
        className="pakk-select__input"
        id={id}
        value={value}
        onChange={onChange}
        {...props}
      >
        {options.map((option, idx) => (
          <option
            key={option.name ? option.name : idx}
            value={option.value ? option.value : option[customValueKey]}
          >
            {option.name ? option.name : option[customNameKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const BoolSelectInputField = ({
  options,
  id,
  name,
  label,
  classes,
  style,
  error,
  value,
  onChange,
  className,
  customNameKey,
  customValueKey,
  isCl,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <SelectInputField
      id={id}
      classes={classes}
      onChange={onChange}
      label={label}
      name={name}
      value={value}
      style={style || {}}
      options={
        isCl
          ? [
              { name: "", value: "" },
              { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
              { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
            ]
          : [
              { name: "", value: "" },
              { name: t("common.ReplyYes"), value: "true" },
              { name: t("common.ReplyNo"), value: "false" }
            ]
      }
    />
  );
};

export const DateInputField = ({
  handleChange,
  classes,
  id,
  value,
  label,
  name,
  placement
}) => {
  const [startDate, setStartDate] = useState(null);
  const { t } = useTranslation();
  const getIsoDate = useCallback(date => {
    if (date !== null) {
      return format(date, "yyyy-MM-dd").split("T")[0];
    } else {
      return "";
    }
  }, []);

  const ref = useRef();

  const getYearsRange = useCallback(() => {
    let years = [];

    for (var i = 2000; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }, []);

  const years = useMemo(() => getYearsRange(), [getYearsRange]);

  const getMonth = date => {};

  const months = useMemo(
    () => [
      t(`classifications.Months.January`),
      t(`classifications.Months.February`),
      t(`classifications.Months.March`),
      t(`classifications.Months.April`),
      t(`classifications.Months.May`),
      t(`classifications.Months.June`),
      t(`classifications.Months.July`),
      t(`classifications.Months.August`),
      t(`classifications.Months.September`),
      t(`classifications.Months.October`),
      t(`classifications.Months.November`),
      t(`classifications.Months.December`)
    ],
    [t]
  );

  return (
    <div className={classes}>
      <Label htmlFor={id}>{label}</Label>
      <DatePicker
        id={id}
        name={name}
        autoComplete="off"
        utcOffset={0}
        dateFormat="yyyy/MM/dd HH:mm"
        timeFormat="HH:mm"
        isClearable
        value={value}
        customInput={<DateInput ref={ref} />}
        selected={startDate}
        popperPlacement={placement || "bottom"}
        onChange={value => {
          setStartDate(value);
          handleChange(name, getIsoDate(value));
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
              zIndex: 100000
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
      />
    </div>
  );
};

const DateInput = forwardRef(
  ({ onChange, placeholder, value, id, onClick, classes }, ref) => {
    return (
      <input
        ref={ref}
        onChange={onChange}
        style={{
          width: "100%",
          fontSize: "1.5rem",
          borderRadius: "3px",
          border: "1px solid #3b3b3b",
          padding: "0.3rem"
        }}
        placeholder={getLocaleDateString()}
        value={value}
        autoComplete="off"
        id={id}
        onClick={onClick}
      />
    );
  }
);

function getLocaleDateString() {
  var formats = {
    "ar-SA": "dd/MM/yy",
    "bg-BG": "dd.M.yyyy",
    "ca-ES": "dd/MM/yyyy",
    "zh-TW": "yyyy/M/d",
    "cs-CZ": "d.M.yyyy",
    "da-DK": "dd-MM-yyyy",
    "de-DE": "dd.MM.yyyy",
    "el-GR": "d/M/yyyy",
    "en-US": "M/d/yyyy",
    "fi-FI": "d.M.yyyy",
    "fr-FR": "dd/MM/yyyy",
    "he-IL": "dd/MM/yyyy",
    "hu-HU": "yyyy. MM. dd.",
    "is-IS": "d.M.yyyy",
    "it-IT": "dd/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "ko-KR": "yyyy-MM-dd",
    "nl-NL": "d-M-yyyy",
    "nb-NO": "dd.MM.yyyy",
    "pl-PL": "yyyy-MM-dd",
    "pt-BR": "d/M/yyyy",
    "ro-RO": "dd.MM.yyyy",
    "ru-RU": "dd.MM.yyyy",
    "hr-HR": "d.M.yyyy",
    "sk-SK": "d. M. yyyy",
    "sq-AL": "yyyy-MM-dd",
    "sv-SE": "yyyy-MM-dd",
    "th-TH": "d/M/yyyy",
    "tr-TR": "dd.MM.yyyy",
    "ur-PK": "dd/MM/yyyy",
    "id-ID": "dd/MM/yyyy",
    "uk-UA": "dd.MM.yyyy",
    "be-BY": "dd.MM.yyyy",
    "sl-SI": "d.M.yyyy",
    "et-EE": "dd.MM.yyyy",
    "lv-LV": "yyyy.MM.dd.",
    "lt-LT": "yyyy.MM.dd",
    "fa-IR": "MM/dd/yyyy",
    "vi-VN": "dd/MM/yyyy",
    "hy-AM": "dd.MM.yyyy",
    "az-Latn-AZ": "dd.MM.yyyy",
    "eu-ES": "yyyy/MM/dd",
    "mk-MK": "dd.MM.yyyy",
    "af-ZA": "yyyy/MM/dd",
    "ka-GE": "dd.MM.yyyy",
    "fo-FO": "dd-MM-yyyy",
    "hi-IN": "dd-MM-yyyy",
    "ms-MY": "dd/MM/yyyy",
    "kk-KZ": "dd.MM.yyyy",
    "ky-KG": "dd.MM.yy",
    "sw-KE": "M/d/yyyy",
    "uz-Latn-UZ": "dd/MM yyyy",
    "tt-RU": "dd.MM.yyyy",
    "pa-IN": "dd-MM-yy",
    "gu-IN": "dd-MM-yy",
    "ta-IN": "dd-MM-yyyy",
    "te-IN": "dd-MM-yy",
    "kn-IN": "dd-MM-yy",
    "mr-IN": "dd-MM-yyyy",
    "sa-IN": "dd-MM-yyyy",
    "mn-MN": "yy.MM.dd",
    "gl-ES": "dd/MM/yy",
    "kok-IN": "dd-MM-yyyy",
    "syr-SY": "dd/MM/yyyy",
    "dv-MV": "dd/MM/yy",
    "ar-IQ": "dd/MM/yyyy",
    "zh-CN": "yyyy/M/d",
    "de-CH": "dd.MM.yyyy",
    "en-GB": "dd/MM/yyyy",
    "es-MX": "dd/MM/yyyy",
    "fr-BE": "d/MM/yyyy",
    "it-CH": "dd.MM.yyyy",
    "nl-BE": "d/MM/yyyy",
    "nn-NO": "dd.MM.yyyy",
    "pt-PT": "dd-MM-yyyy",
    "sr-Latn-CS": "d.M.yyyy",
    "sv-FI": "d.M.yyyy",
    "az-Cyrl-AZ": "dd.MM.yyyy",
    "ms-BN": "dd/MM/yyyy",
    "uz-Cyrl-UZ": "dd.MM.yyyy",
    "ar-EG": "dd/MM/yyyy",
    "zh-HK": "d/M/yyyy",
    "de-AT": "dd.MM.yyyy",
    "en-AU": "d/MM/yyyy",
    "es-ES": "dd/MM/yyyy",
    "fr-CA": "yyyy-MM-dd",
    "sr-Cyrl-CS": "d.M.yyyy",
    "ar-LY": "dd/MM/yyyy",
    "zh-SG": "d/M/yyyy",
    "de-LU": "dd.MM.yyyy",
    "en-CA": "dd/MM/yyyy",
    "es-GT": "dd/MM/yyyy",
    "fr-CH": "dd.MM.yyyy",
    "ar-DZ": "dd-MM-yyyy",
    "zh-MO": "d/M/yyyy",
    "de-LI": "dd.MM.yyyy",
    "en-NZ": "d/MM/yyyy",
    "es-CR": "dd/MM/yyyy",
    "fr-LU": "dd/MM/yyyy",
    "ar-MA": "dd-MM-yyyy",
    "en-IE": "dd/MM/yyyy",
    "es-PA": "MM/dd/yyyy",
    "fr-MC": "dd/MM/yyyy",
    "ar-TN": "dd-MM-yyyy",
    "en-ZA": "yyyy/MM/dd",
    "es-DO": "dd/MM/yyyy",
    "ar-OM": "dd/MM/yyyy",
    "en-JM": "dd/MM/yyyy",
    "es-VE": "dd/MM/yyyy",
    "ar-YE": "dd/MM/yyyy",
    "en-029": "MM/dd/yyyy",
    "es-CO": "dd/MM/yyyy",
    "ar-SY": "dd/MM/yyyy",
    "en-BZ": "dd/MM/yyyy",
    "es-PE": "dd/MM/yyyy",
    "ar-JO": "dd/MM/yyyy",
    "en-TT": "dd/MM/yyyy",
    "es-AR": "dd/MM/yyyy",
    "ar-LB": "dd/MM/yyyy",
    "en-ZW": "M/d/yyyy",
    "es-EC": "dd/MM/yyyy",
    "ar-KW": "dd/MM/yyyy",
    "en-PH": "M/d/yyyy",
    "es-CL": "dd-MM-yyyy",
    "ar-AE": "dd/MM/yyyy",
    "es-UY": "dd/MM/yyyy",
    "ar-BH": "dd/MM/yyyy",
    "es-PY": "dd/MM/yyyy",
    "ar-QA": "dd/MM/yyyy",
    "es-BO": "dd/MM/yyyy",
    "es-SV": "dd/MM/yyyy",
    "es-HN": "dd/MM/yyyy",
    "es-NI": "dd/MM/yyyy",
    "es-PR": "dd/MM/yyyy",
    "am-ET": "d/M/yyyy",
    "tzm-Latn-DZ": "dd-MM-yyyy",
    "iu-Latn-CA": "d/MM/yyyy",
    "sma-NO": "dd.MM.yyyy",
    "mn-Mong-CN": "yyyy/M/d",
    "gd-GB": "dd/MM/yyyy",
    "en-MY": "d/M/yyyy",
    "prs-AF": "dd/MM/yy",
    "bn-BD": "dd-MM-yy",
    "wo-SN": "dd/MM/yyyy",
    "rw-RW": "M/d/yyyy",
    "qut-GT": "dd/MM/yyyy",
    "sah-RU": "MM.dd.yyyy",
    "gsw-FR": "dd/MM/yyyy",
    "co-FR": "dd/MM/yyyy",
    "oc-FR": "dd/MM/yyyy",
    "mi-NZ": "dd/MM/yyyy",
    "ga-IE": "dd/MM/yyyy",
    "se-SE": "yyyy-MM-dd",
    "br-FR": "dd/MM/yyyy",
    "smn-FI": "d.M.yyyy",
    "moh-CA": "M/d/yyyy",
    "arn-CL": "dd-MM-yyyy",
    "ii-CN": "yyyy/M/d",
    "dsb-DE": "d. M. yyyy",
    "ig-NG": "d/M/yyyy",
    "kl-GL": "dd-MM-yyyy",
    "lb-LU": "dd/MM/yyyy",
    "ba-RU": "dd.MM.yy",
    "nso-ZA": "yyyy/MM/dd",
    "quz-BO": "dd/MM/yyyy",
    "yo-NG": "d/M/yyyy",
    "ha-Latn-NG": "d/M/yyyy",
    "fil-PH": "M/d/yyyy",
    "ps-AF": "dd/MM/yy",
    "fy-NL": "d-M-yyyy",
    "ne-NP": "M/d/yyyy",
    "se-NO": "dd.MM.yyyy",
    "iu-Cans-CA": "d/M/yyyy",
    "sr-Latn-RS": "d.M.yyyy",
    "si-LK": "yyyy-MM-dd",
    "sr-Cyrl-RS": "d.M.yyyy",
    "lo-LA": "dd/MM/yyyy",
    "km-KH": "yyyy-MM-dd",
    "cy-GB": "dd/MM/yyyy",
    "bo-CN": "yyyy/M/d",
    "sms-FI": "d.M.yyyy",
    "as-IN": "dd-MM-yyyy",
    "ml-IN": "dd-MM-yy",
    "en-IN": "dd-MM-yyyy",
    "or-IN": "dd-MM-yy",
    "bn-IN": "dd-MM-yy",
    "tk-TM": "dd.MM.yy",
    "bs-Latn-BA": "d.M.yyyy",
    "mt-MT": "dd/MM/yyyy",
    "sr-Cyrl-ME": "d.M.yyyy",
    "se-FI": "d.M.yyyy",
    "zu-ZA": "yyyy/MM/dd",
    "xh-ZA": "yyyy/MM/dd",
    "tn-ZA": "yyyy/MM/dd",
    "hsb-DE": "d. M. yyyy",
    "bs-Cyrl-BA": "d.M.yyyy",
    "tg-Cyrl-TJ": "dd.MM.yy",
    "sr-Latn-BA": "d.M.yyyy",
    "smj-NO": "dd.MM.yyyy",
    "rm-CH": "dd/MM/yyyy",
    "smj-SE": "yyyy-MM-dd",
    "quz-EC": "dd/MM/yyyy",
    "quz-PE": "dd/MM/yyyy",
    "hr-BA": "d.M.yyyy.",
    "sr-Latn-ME": "d.M.yyyy",
    "sma-SE": "yyyy-MM-dd",
    "en-SG": "d/M/yyyy",
    "ug-CN": "yyyy-M-d",
    "sr-Cyrl-BA": "d.M.yyyy",
    "es-US": "M/d/yyyy"
  };

  return formats[navigator.language] || "dd/MM/yyyy";
}

export const CustomSelect = ({
  options,
  id,
  label,
  classes,
  error,
  value,
  onChange,
  className,
  style,
  customNameKey,
  customValueKey,
  ...props
}) => {
  return (
    <div className={"pakk-select " + classes}>
      <Label htmlFor={id}>{label}</Label>
      <select
        className="pakk-select__input"
        id={id}
        value={value}
        onChange={onChange}
        {...props}
      >
        {options.map((option, idx) => (
          <option
            key={option.name ? option.name : idx}
            value={option.value ? option.value : option[customValueKey]}
          >
            {option.name ? option.name : option[customNameKey]}
          </option>
        ))}
      </select>
    </div>
  );
};
