import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { numberWithSpaces } from "../../../../../services/numberFunctions";
import {
  FormBody,
  FormCol,
  InputField,
  FormFlexItem,
  SelectInputField
} from "../../../../common/form/form";

const FactoringDebtorDetailsForm = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customerNo: data.id ? data.id : "",
          customerName: data.name ? data.name : "",
          regNo: data.regNo ? data.regNo : "",
          isActive: data.isActiveCl ? data.isActiveCl : "",
          validFrom: data.createdOn ? data.createdOn : "",
          creditLimit: data.creditLimit ? data.creditLimit : "",
          generalFax: data.generalFax ? data.generalFax : "",
          generalEmail: data.generalEmail ? data.generalEmail : "",
          generalPhone: data.generalPhone ? data.generalPhone : "",
          balance: data.balance ? data.balance : 0,
          debt: data.debt ? data.debt : 0
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <FormFlexItem direction="row">
                <FormCol className="u-margin-right-tiny">
                  <InputField
                    type="text"
                    classes="form__group"
                    id="customerNo"
                    onChange={handleChange}
                    value={values.customerNo}
                    label={t("factoring.debtor.CustomerNo")}
                  />
                </FormCol>
                <FormCol className="u-margin-right-tiny">
                  <InputField
                    type="text"
                    classes="form__group"
                    id="customerName"
                    onChange={handleChange}
                    value={values.regNo}
                    label={t("factoring.debtor.RegNo")}
                  />
                </FormCol>
                <FormCol>
                  <SelectInputField
                    classes="form__group"
                    id="isActive"
                    options={[
                      { name: "", value: "" },
                      { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                      { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
                    ]}
                    onChange={handleChange}
                    value={values.isActive}
                    label={t("factoring.debtor.IsActive")}
                  />
                </FormCol>
              </FormFlexItem>

              <InputField
                type="text"
                classes="form__group"
                id="customerName"
                onChange={handleChange}
                value={values.customerName}
                label={t("factoring.debtor.CustomerName")}
              />
              <FormFlexItem direction="row">
                <InputField
                  type="text"
                  classes="form__group"
                  id="customerValidFrom"
                  onChange={handleChange}
                  value={values.validFrom}
                  label={t("factoring.debtor.CustomerValidFrom")}
                />
                <InputField
                  type="text"
                  classes="form__group u-margin-left-tiny"
                  id="creditLimit"
                  onChange={handleChange}
                  value={values.creditLimit}
                  label={t("factoring.debtor.CreditLimit")}
                />
              </FormFlexItem>
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                classes="form__group"
                id="generalFax"
                onChange={handleChange}
                value={values.generalFax}
                label={t("factoring.debtor.GeneralFax")}
              />
              <InputField
                type="text"
                classes="form__group"
                id="generalPhone"
                onChange={handleChange}
                value={values.generalPhone}
                label={t("factoring.debtor.GeneralPhone")}
              />
              <InputField
                type="text"
                classes="form__group"
                id="generalEmail"
                onChange={handleChange}
                value={values.generalEmail}
                label={t("factoring.debtor.GeneralEmail")}
              />
            </FormCol>
            <FormCol flexBasis={"30%"} className="u-margin-left-small">
              <div className="form__group">
                <label>{t("factoring.debtor.CurrentBalance")}</label>
                <p>{numberWithSpaces(values.balance.toFixed(2))}</p>
              </div>
              <div className="form__group">
                <label>{t("factoring.debtor.CurrentDebt")}</label>
                <p>{values.debt}</p>
              </div>
              <div className="form__group">
                <label>{t("factoring.debtor.PaymentAccuracy")}</label>
                <p>0</p>
              </div>
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorDetailsForm;
