import React, { useRef } from "react";
import {
  SectionMain,
  SectionBody,
  SectionHeader
} from "../../../../common/section";
import _ from "lodash";
import CustomerInvoiceTable from "../../customerInvoiceTable";
import CustomerInvoiceButtons from "../table/customerInvoiceButtons";
import useCustomerInvoiceActions from "../../hooks/useCustomerInvoiceActions";
import useCustomerInvoiceConsts from "../../hooks/useCustomerInvoiceConsts";
import { getCustomerAccountFilters } from "../../customerInvoiceUtils";
import { useTranslation } from "react-i18next";
import { CUSTOMER_INVOICES } from "../../../../common/redux/types/viewTypes";
import { customerInvoices } from "../../../../common/redux/types/selectorTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";

const CustomerInvoiceMainSection = () => {
  const { t } = useTranslation();

  const { filterLabels } = useCustomerInvoiceConsts();
  const { selectedItem, isLoading } = useDataState(customerInvoices);
  const { searchQuery } = useSearchState(customerInvoices);
  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    customerInvoices
  );

  const {
    handleTableToggle,
    handleExpandersToggle,
    handleSearchToggle
  } = useLayoutActionDispatchers(CUSTOMER_INVOICES);

  const { onRowSelect } = useCustomerInvoiceActions();

  const { customerInvoiceTabs } = useCustomerInvoiceConsts();

  const tableRef = useRef();

  return (
    <SectionMain>
      <SectionHeader
        sectionHeading={t("customerInvoice.Header")}
        getFilters={getCustomerAccountFilters}
        dataLoading={isLoading}
        searchQuery={searchQuery}
        filterLabels={filterLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleSearch={handleSearchToggle}
        searchOpen={searchOpen}
      />
      <SectionBody
        toggleSearch={handleSearchToggle}
        TableComponent={CustomerInvoiceTable}
        ButtonsComponent={CustomerInvoiceButtons}
        shortcutsEnabled={false}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        onRowSelect={onRowSelect}
        expandersEnabled={expandersVisible}
        selectedItem={
          !_.isEmpty(selectedItem) ? selectedItem.company[0].name : ""
        }
        tableRef={tableRef}
        tabs={customerInvoiceTabs}
      />
    </SectionMain>
  );
};

export default CustomerInvoiceMainSection;
