import { useEffect } from "react";
import useAuthState from "../../auth/hooks/useAuthState";
import useAppActions from "./useAppActions";

const useLoadAppDataEffect = () => {
  const { loadClassifications, loadInstallation } = useAppActions();
  const { userAuthenticated } = useAuthState();
  useEffect(() => {
    if (userAuthenticated) {
      loadClassifications();
      loadInstallation();
    }
  }, [loadClassifications, loadInstallation, userAuthenticated]);
};

export default useLoadAppDataEffect;
