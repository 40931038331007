import {useSelector} from "react-redux";

const useCardLayoutState = () => {
  const tableVisible = useSelector(state => state.card.layout.table.visible);
  const expandersVisible = useSelector(
    state => state.card.layout.expanders.visible
  );
  const searchOpen = useSelector(state => state.card.layout.search.isOpen);

  return { tableVisible, expandersVisible, searchOpen };
};

export default useCardLayoutState;
