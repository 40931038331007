export const CUSTOMER_FORMS_DATA_CHANGE = payload => ({
  type: "CUSTOMER_FORMS_DATA_CHANGE",
  payload: payload
});

export const SET_CUSTOMERS_FORMS_STATE = payload => ({
  type: "SET_CUSTOMERS_FORMS_STATE",
  payload: payload
});

export const CUSTOMER_FORM_GENERAL_DATA_CHANGE = payload => ({
  type: "CUSTOMER_FORM_GENERAL_DATA_CHANGE",
  payload: payload
});

export const CUSTOMER_FORM_ADDRESSES_DATA_CHANGE = payload => ({
  type: "CUSTOMER_FORM_ADDRESSES_DATA_CHANGE",
  payload: payload
});

export const CUSTOMER_FORM_ADD_CUSTOMER_DATA_CHANGE = payload => ({
  type: "CUSTOMER_FORM_ADD_CUSTOMER_DATA_CHANGE",
  payload: payload
});

export const CUSTOMER_FORM_INVOICE_SETTINGS_DATA_CHANGE = payload => ({
  type: "CUSTOMER_FORM_INVOICE_SETTINGS_DATA_CHANGE",
  payload: payload
});
