import React from "react";
import _ from "lodash";
import { Button } from "../../../../common/buttons/button";
import useCustomerInvoiceState from "../../hooks/useCustomerInvoiceState";
import { useTranslation } from "react-i18next";

const CustomerInvoiceButtons = () => {
  const { selectedRow } = useCustomerInvoiceState();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        btnStyle="lime"
        size="small"
        classes="btn--footer"
        disabled={_.isEmpty(selectedRow)}
        label={t("customerInvoice.BtnMergePDF")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("customerInvoice.BtnSend")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("customerInvoice.BtnGenerateInvoice")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("customerInvoice.BtnToExcel")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("customerInvoice.BtnToCSV")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("customerInvoice.BtnSave")}
      />
    </React.Fragment>
  );
};

export default CustomerInvoiceButtons;
