import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../common/form/form";
import { useTranslation } from "react-i18next";
import CustomerAutocompleteInputField from "../../../common/autocomplete/customerAutocompleteField";
import { TRANSACTIONS } from "../../../common/redux/types/viewTypes";
import { transactions } from "../../../common/redux/types/selectorTypes";

const TransactionsVerticalSearchFrom = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <Formik
      ref={ref}
      enableReinitialize={true}
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {}}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        validateField,
        validateOnChange,
        errors,
        touched,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomerAutocompleteInputField
            type="text"
            classes="form__group-stack"
            id="transactionsSearchCustomer"
            name="customerName"
            value={values.customer}
            handleChange={e => {
              handleChange(e);
            }}
            label={t("transactions.FilterCustomer")}
            autocomplete="password"
            data-lpignore="true"
            actionType={TRANSACTIONS}
            selectorType={transactions}
          />
          <SelectInputField
            id="transactionsSearchAddToInvoice"
            classes="form__group-stack"
            label={t("transactions.FilterAddToInvoice")}
            options={[
              { name: "", value: "" },
              { name: t("common.ReplyYes"), value: "true" },
              { name: t("common.ReplyNo"), value: "false" }
            ]}
          />
          <InputField
            id="transactionsSearchCardNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterCardNo")}
            value={""}
          />
          <InputField
            id="transactionsSearchTransactionNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterTransactionNo")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchProduct"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterProduct")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchParentProduct"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterParentProduct")}
            value={""}
          />
          <BoolSelectInputField
            id="transactionsSearchNotAddedToExcise"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterNotAddedToExcise")}
          />
          <BoolSelectInputField
            id="transactionsSearchWithoutCustomerInvoice"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterWithoutCustomerInvoice")}
          />
          <InputField
            id="transactionsSearchInvoiceNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterInvoiceNo")}
            value={""}
          />
          <BoolSelectInputField
            id="transactionsSearchWithoutPartnerInvoice"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterWithoutPartnerInvoice")}
          />
          <InputField
            id="transactionsSearchPartnerInvoiceNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterPartnerInvoiceNo")}
            value={""}
          />
          <BoolSelectInputField
            id="transactionsSearchWithoutBrokerInvoice"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterWithoutBrokerInvoice")}
          />
          <InputField
            id="transactionsSearchBrokerInvoiceNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterBrokerInvoiceNo")}
            value={""}
          />
          <DateInputField
            id="transactionsSearchTransactionsFrom"
            classes="form__group-stack"
            label={t("transactions.FilterTransactionsFrom")}
            value={""}
            onChange={handleChange}
          />
          <DateInputField
            id="transactionsSearchTransactionsTo"
            classes="form__group-stack"
            label={t("transactions.FilterTransactionsTo")}
            value={""}
            onChange={handleChange}
          />
          <SelectInputField
            id="transactionsSearchCountry"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterCountry")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchServiceProvider"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterServiceProvider")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchPartner"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterPartner")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchSalespoint"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterSalesPoint")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchReseller"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterReseller")}
            value={""}
          />
          <DateInputField
            id="transactionsSearchCreatedFrom"
            classes="form__group-stack"
            label={t("transactions.FilterCreatedFrom")}
            value={""}
            onChange={handleChange}
          />
          <SelectInputField
            id="transactionsSearchCustomerCountry"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterCustomerCountry")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchSalesAgent"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.SalesAgent")}
            value={""}
          />
          <InputField
            id="transactionsSearchCarRegNo"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.TransactionCarRegNo")}
            value={""}
          />
          <SelectInputField
            id="transactionsSearchCustomerManager"
            classes="form__group-stack"
            options={[]}
            onChange={handleChange}
            label={t("transactions.FilterCustomerManager")}
            value={""}
          />
          <InputField
            id="transactionsSearchResultCount"
            classes="form__group-stack"
            onChange={handleChange}
            label={t("transactions.FilterRowCount")}
            value={""}
          />
        </form>
      )}
    </Formik>
  );
});

export default TransactionsVerticalSearchFrom;
