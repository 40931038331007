import React from "react";
import { Button } from "../../../../common/buttons/button";

const VatApplicationsButtons = () => {
  return (
    <React.Fragment>
      <Button btnStyle="primary" label="ADD" size="small" />
    </React.Fragment>
  );
};

export default VatApplicationsButtons;
