import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getServiceDropdownData } from "../actions/serviceActions";
import useAuthState from "../../auth/hooks/useAuthState";

const useServiceDropdownData = () => {
  const dispatch = useDispatch();
  const { userAuthenticated } = useAuthState();
  useEffect(() => {
    if (userAuthenticated) {
      dispatch(getServiceDropdownData());
    }
  }, [dispatch, userAuthenticated]);
};

export default useServiceDropdownData;
