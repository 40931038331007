import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../common/table";
import useTableState from "../common/redux/hooks/useTableState";
import { transactions } from "../common/redux/types/selectorTypes";
import useDataState from "../common/redux/hooks/useDataState";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { TRANSACTIONS } from "../common/redux/types/viewTypes";

const TransactionsTable = () => {
  const { t } = useTranslation();
  const { height } = useTableState(transactions);
  const { items, selectedItem } = useDataState(transactions);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(
    TRANSACTIONS
  );
  const TransactionsTableTrans = "transactions.";
  const columns = [
    {
      Header: t(TransactionsTableTrans + "TransactionAddToInvoice"),
      accessor: "addToInvoice"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionCardNo"),
      accessor: "cardNo"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionDate"),
      accessor: "date"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionCustomerName"),
      accessor: "customer"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionCarRegNo"),
      accessor: "carRegNo"
    },
    {
      Header: t(TransactionsTableTrans + "InvoiceNo"),
      accessor: "invoiceNo"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionPartnerInvoiceNo"),
      accessor: "partnerInvoiceNo"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionSalesPointName"),
      accessor: "salespoint"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionProductDefaultName"),
      accessor: "product"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionQuantity"),
      accessor: "quantity"
    },
    {
      Header: t(TransactionsTableTrans + "TransactionTotal"),
      accessor: "total"
    }
  ];
  return (
    <Table
      columns={columns}
      classes="u-margin-bottom-small"
      data={items || []}
      selectedRow={selectedItem}
      setTableHeight={handleTableHeightSet}
      height={height}
    />
  );
};

export default TransactionsTable;
