import React from "react";
import { useDispatch } from "react-redux";
import Table from "../../../common/table";
import { SET_CUSTOMERS_INVOICE_TABLE_HEIGHT } from "../actions/customersActions";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";

const CustomerInvoiceSettingsTable = ({ t, selectedRow }) => {
  const dispatch = useDispatch();
  const { table } = useTabState(customers, "invoiceSettings");

  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMERS_INVOICE_TABLE_HEIGHT(payload));
  };
  const CustomerInvoiceSettingsTrans = "customer.customerInvoiceSettings.";

  const columns = [
    {
      Header: t(CustomerInvoiceSettingsTrans + "BankAccount"),
      accessor: "bankAccount"
    },
    {
      id: "isPrimary",
      Header: t(CustomerInvoiceSettingsTrans + "Primary"),
      accessor: d => (d.isPrimary ? t("common.ReplyYes") : t("common.ReplyNo"))
    }
  ];

  const sortColumn = "name";
  return (
    <div className="customer__comments__table">
      <Table
        columns={columns}
        height={table.height}
        setTableHeight={onSetTableHeight}
        data={[]}
        sortColumn={sortColumn}
        selectedRow={{}}
      />
    </div>
  );
};

export default CustomerInvoiceSettingsTable;
