import {toast} from "react-toastify";

const initialState = {
  token: "",
  user: {
    assignedPermissions: []
  },
  error: null,
  login_modal: {
    isOpen: false,
    data: {}
  },
  userMutation: {
    isLoading: false,
    error: {}
  },
  isLoading: false,
  permissionsLoading: true,
  permissionsLoaded: false,
  permissions: {},
  userAuthenticated: false
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN_START":
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false
      };

    case "USER_LOGIN_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case "GET_USER_DATA_START":
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case "GET_USER_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        userAuthenticated: true
      };
    case "GET_USER_DATA_ERROR":
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case "GET_USER_PERMISSIONS_START": {
      return {
        ...state,
        permissionsLoading: true
      };
    }
    case "GET_USER_PERMISSIONS_SUCCESS": {
      return {
        ...state,
        permissionsLoading: false,
        permissionsLoaded: true,
        user: { ...state.user, assignedPermissions: action.payload }
      };
    }
    case "GET_USER_PERMISSIONS_ERROR": {
      return {
        ...state,
        permissionsLoading: false
      };
    }
    case "USER_LOGIN_SET_TOKEN":
      return {
        ...state,
        token: action.payload
      };

    case "USER_LOGOUT_SUCCESS": {
      return {
        ...state,
        token: "",
        user: ""
      };
    }
    case "TOGGLE_LOGIN_MODAL_OPEN":
      return {
        ...state,
        login_modal: {
          ...state.login_modal,
          isOpen: true
        }
      };
    case "TOGGLE_LOGIN_MODAL_CLOSE":
      return {
        ...state,
        login_modal: {
          ...state.login_modal,
          isOpen: false
        }
      };
    case "MUTATE_USER_START":
      return {
        ...state,
        userMutation: {
          ...state.userMutation,
          isLoading: true
        }
      };
    case "MUTATE_USER_SUCCESS":
      return {
        ...state,
        userMutation: {
          ...state.userMutation,
          isLoading: false
        }
      };
    case "MUTATE_USER_ERROR":
      return {
        ...state,
        userMutation: {
          ...state.userMutation,
          isLoading: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};

export default AuthReducer;
