import { useEffect } from "react";
import useUserData from "../../auth/hooks/useUserData";
import { useTranslation } from "react-i18next";

const useLanguageSwitchEffect = () => {
  const { userLanguage } = useUserData();
  const { i18n } = useTranslation();

  useEffect(() => {
    switch (userLanguage) {
      case "LANGUAGE.EN-GB":
        i18n.changeLanguage("en");
        break;
      case "LANGUAGE.ET-EE":
        i18n.changeLanguage("et");
        break;
      default: {
        i18n.changeLanguage("en");
      }
    }
  }, [i18n, userLanguage]);
};

export default useLanguageSwitchEffect;
