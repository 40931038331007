import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {CLEAR_SELECTED_ROW, SET_SELECTED_ROWS_STATE} from "../dataActions";

const useDataActionsDispatchers = name => {
  const dispatch = useDispatch();

  const handleSelectedRowClear = useCallback(() => {
    dispatch(CLEAR_SELECTED_ROW(name));
  }, [dispatch, name]);

  const handleSetSelectedRowsState = useCallback(
    payload => {
      dispatch(SET_SELECTED_ROWS_STATE(name, payload));
    },
    [dispatch, name]
  );

  return { handleSelectedRowClear, handleSetSelectedRowsState };
};

export default useDataActionsDispatchers;
