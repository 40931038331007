import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { useTranslation } from "react-i18next";
import useVatApplicationsActions from "../../hooks/useVatApplicationsActions";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import { VAT_APPLICATIONS } from "../../../../common/redux/types/viewTypes";
import { vatApplications } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";

const VatApplicationsVerticalSearchForm = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { selectedAutocompleteValue } = useSearchState(vatApplications);
  const { handleSearch } = useVatApplicationsActions();
  return (
    <div className="section__search-form">
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          customer: selectedAutocompleteValue || "",
          application_no: "",
          application_date_from: "",
          application_date_to: "",
          period_start: "",
          period_end: "",
          service_provider: "",
          reseller: "",
          country: "",
          is_confirmed: "",
          is_active: "",
          email_sent: "",
          netting: "",
          status: "",
          count: 1000
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          validateField,
          validateOnChange,
          errors,
          touched,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="vatApplicationsSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("vat.applications.FilterCustomer")}
              autocomplete="password"
              data-lpignore="true"
              actionType={VAT_APPLICATIONS}
              selectorType={vatApplications}
            />
            <InputField
              id="vatApplicationsSearchApplicationNo"
              name="application_no"
              label={t("vat.applications.FilterApplicationNo")}
              value={values.application_no}
              onChange={handleChange}
              classes="form__group-stack"
            />
            <DateInputField
              type="date"
              label={t("vat.applications.FilterApplicationDateFrom")}
              classes="form__group-stack date1"
              id="vatApplicationsSearchApplicationDateFrom"
              name="application_date_from"
              handleChange={setFieldValue}
              value={values.application_date_from}
            />
            <DateInputField
              type="date"
              label={t("vat.applications.FilterApplicationDateTo")}
              classes="form__group-stack date1"
              id="vatApplicationsSearchApplicationDateTo"
              name="application_date_to"
              handleChange={setFieldValue}
              value={values.application_date_to}
            />
            <DateInputField
              type="date"
              label={t("vat.applications.FilterPeriodStart")}
              classes="form__group-stack"
              id="vatApplicationsSearchPeriodStart"
              name="period_start"
              handleChange={setFieldValue}
              value={values.period_end}
            />
            <DateInputField
              type="date"
              label={t("vat.applications.FilterPeriodEnd")}
              classes="form__group-stack"
              id="vatApplicationsSearchApplicationDateTo"
              name="period_end"
              handleChange={setFieldValue}
              value={values.period_end}
            />
            <SelectInputField
              id="vatApplicationsSearchServiceProvider"
              name="service_provider"
              label={t("vat.applications.FilterServiceProvider")}
              options={[]}
              onChange={handleChange}
              classes="form__group-stack"
              value={values.service_provider}
            />
            <SelectInputField
              id="vatApplicationsSearchReseller"
              name="reseller"
              label={t("vat.applications.FilterReseller")}
              options={[]}
              onChange={handleChange}
              classes="form__group-stack"
              value={values.reseller}
            />
            <SelectInputField
              id="vatApplicationsSearchCountry"
              name="country"
              label={t("vat.applications.FilterCountry")}
              options={[]}
              onChange={handleChange}
              classes="form__group-stack"
              value={values.country}
            />
            <BoolSelectInputField
              id="vatApplicationsSearchIsConfirmed"
              name="is_confirmed"
              label={t("vat.applications.FilterIsConfirmed")}
              classes="form__group-stack"
              onChange={handleChange}
              value={values.is_confirmed}
            />
            <BoolSelectInputField
              id="vatApplicationsSearchIsActive"
              name="is_active"
              label={t("vat.applications.FilterIsActive")}
              classes="form__group-stack"
              onChange={handleChange}
              value={values.is_active}
            />
            <BoolSelectInputField
              id="vatApplicationsSearchEmailSent"
              name="email_sent"
              label={t("vat.applications.FilterIsEmailSent")}
              classes="form__group-stack"
              onChange={handleChange}
              value={values.email_sent}
            />
            <BoolSelectInputField
              id="vatApplicationsSearchIsNettingNeeded"
              name="netting"
              label={t("vat.applications.FilterIsNettingNeeded")}
              classes="form__group-stack"
              onChange={handleChange}
              value={values.netting}
            />
            <SelectInputField
              id="vatApplicationsSearchStatus"
              name="status"
              label={t("vat.applications.FilterStatus")}
              options={[]}
              onChange={handleChange}
              classes="form__group-stack"
              value={values.status}
            />
            <InputField
              id="vatApplicationsSearchResultCount"
              name="count"
              label={t("vat.applications.FilterRowCount")}
              value={values.count}
              onChange={handleChange}
              classes="form__group-stack"
            />
          </form>
        )}
      </Formik>
    </div>
  );
});

export default VatApplicationsVerticalSearchForm;
