import React from "react";
import View from "../../common/view";
import CustomerAccountMainSection from "./layout/mainSection/customerAccountMainSection";
import CustomerAccountSearchSection from "./layout/search/customerAccountSearchSection";

const CustomerAccountView = () => {
  return (
    <View
      MainSection={CustomerAccountMainSection}
      SearchSection={CustomerAccountSearchSection}
    />
  );
};

export default CustomerAccountView;
