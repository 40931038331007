import React, { forwardRef } from "react";
import SimpleBar from "simplebar-react";

export const TableBodyComponent = ({ children, style, myRef, scrollY }) => {
  return (
    <div className="rt-tbody" style={style}>
      <SimpleBar
        forceVisible="y"
        style={{ maxHeight: "100%" }}
        scrollableNodeProps={{ ref: myRef }}
      >
        {children}
      </SimpleBar>
    </div>
  );
};

export const headerProps = (props, { column }) =>
  getHeaderStyles(props, column.headerAlign);

export const cellProps = (props, { cell }) =>
  getTdStyles(props, cell.column.cellAlign);

export const getHeaderStyles = (
  props,
  headerAlign = "center",
  flexGrow = "auto"
) => [
  props,
  {
    style: {
      justifyContent: getCellAlignment(headerAlign),
      alignItems: "flex-start",
      display: "flex"
    }
  }
];

export const getTdStyles = (props, cellAlign = "left") => [
  props,
  {
    style: {
      justifyContent: getCellAlignment(cellAlign),
      alignItems: "flex-start",
      display: "flex"
    }
  }
];

const getCellAlignment = align => {
  switch (align) {
    case "right": {
      return "flex-end";
    }
    case "left": {
      return "flex-start";
    }
    case "center": {
      return "center";
    }
    default: {
      return "flex-start";
    }
  }
};

export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
