import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  FormCol,
  FormFlexItem,
  SelectInputField,
  FormBody,
  InputField
} from "../../common/form/form";
import { Button } from "../../common/buttons/button";
import ContractFactoringDebtorsTable from "./contractFactoringDebtorsTable";
import useContractState from "../hooks/useContractState";

const FactoringDebtorsForm = () => {
  const { t } = useTranslation();
  const { contractData: data } = useContractState();
  const factoringDebtor = data.factoringContractDebtors;
  const selectedFactoringDebtor = {};

  const contract = selectedFactoringDebtor.contract;
  const company = selectedFactoringDebtor.company;

  const invoiceFactoringHandlingFee =
    contract !== undefined && contract.length
      ? contract[0].handlingFees[0]
      : "";

  const invoiceFactoringContractFee =
    company !== undefined && company.length
      ? company[0].factoringDebtorServiceFees[0]
      : "";
  console.log(invoiceFactoringContractFee);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          invoiceFactoringHandlingFeeTypeCl:
            invoiceFactoringHandlingFee.invoiceFactoringHandlingFeeTypeCl || "",
          invoiceFactoringHandlingFeeValue:
            invoiceFactoringHandlingFee.value || "",
          invoiceFactoringHandlingFeePeriod:
            invoiceFactoringHandlingFee.createdOn || "",
          invoiceFactoringContractFeeTypeCl:
            invoiceFactoringContractFee.invoiceFactoringServiceFeeTypeCl || "",
          invoiceFactoringContractFeePeriod:
            invoiceFactoringContractFee.createdOn || "",
          invoiceFactoringContractFeeValue:
            invoiceFactoringContractFee.fixedValue || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol className="u-margin-right-small">
              <ContractFactoringDebtorsTable t={t} data={factoringDebtor} />
              <FormFlexItem
                classes="form--flex-center flex__justify-content--space-between u-margin-top-small"
                direction="row"
              >
                <FormFlexItem
                  direction="row"
                  flexGrow="1"
                  classes="u-margin-right-medium flex__justify-content--space-between"
                >
                  <FormFlexItem flexGrow="1" classes="u-margin-right-small">
                    <SelectInputField
                      id="period"
                      classes="form__group u-margin-none"
                      options={[]}
                      onChange={handleChange}
                      value={values.period}
                    />
                  </FormFlexItem>
                  <FormFlexItem classes="u-margin-right-medium">
                    <Button btnStyle="primary" size="medium" label="Add" />
                  </FormFlexItem>
                </FormFlexItem>
                <FormFlexItem>
                  <Button btnStyle="danger" size="medium" label="Delete" />
                </FormFlexItem>
              </FormFlexItem>
            </FormCol>
            <FormCol>
              <FormFlexItem direction="column">
                <h3 className="heading__tertiary">
                  {t("contract.FactoringDebtors.InvoiceHandlingFee")}
                </h3>
                <FormFlexItem
                  direction="row"
                  classes="form--flex-center flex__justify-content--space-between"
                >
                  <FormFlexItem flexGrow={"1"}>
                    <SelectInputField
                      id="period"
                      label={t("contract.FactoringDebtors.Period")}
                      classes="form__group"
                      options={[
                        values.invoiceFactoringHandlingFeePeriod !== ""
                          ? {
                              name: `${values.invoiceFactoringHandlingFeePeriod} - ...`,
                              value: ""
                            }
                          : { name: "", value: "" }
                      ]}
                      onChange={handleChange}
                      value={values.period}
                    />
                  </FormFlexItem>
                  <FormFlexItem classes="u-margin-left-small">
                    <Button
                      btnStyle="primary"
                      size="small"
                      label={t("contract.FactoringDebtors.BtnAddPeriod")}
                      classes="u-margin-right-small"
                    />
                    <Button
                      btnStyle="danger"
                      size="small"
                      label={t("contract.FactoringDebtors.BtnDeletePeriod")}
                    />
                  </FormFlexItem>
                </FormFlexItem>
                <FormFlexItem direction="row">
                  <InputField
                    type="text"
                    id="paymentType"
                    classes="form__group"
                    onChange={handleChange}
                    value={
                      values.invoiceFactoringHandlingFeeTypeCl
                        ? t(
                            `classifications.${values.invoiceFactoringHandlingFeeTypeCl}`
                          )
                        : ""
                    }
                    label={t("contract.FactoringDebtors.PaymentType")}
                  />
                  <InputField
                    type="text"
                    id="value"
                    classes="form__group u-margin-left-small"
                    onChange={handleChange}
                    value={values.invoiceFactoringHandlingFeeValue}
                    label={t("contract.FactoringDebtors.Value")}
                  />
                </FormFlexItem>

                <h3 className="heading__tertiary">
                  {t("contract.FactoringDebtors.FactoringContractFee")}
                </h3>
                <FormFlexItem
                  direction="row"
                  classes="form--flex-center flex__justify-content--space-between"
                >
                  <FormFlexItem flexGrow={"1"}>
                    <SelectInputField
                      id="period"
                      label={t("contract.FactoringDebtors.Period")}
                      classes="form__group"
                      options={[
                        values.invoiceFactoringContractFeePeriod
                          ? {
                              name: `${values.invoiceFactoringContractFeePeriod} - ...`,
                              value: ""
                            }
                          : { name: "", value: "" }
                      ]}
                      onChange={handleChange}
                      value={values.period}
                    />
                  </FormFlexItem>
                  <FormFlexItem classes="u-margin-left-small">
                    <Button
                      btnStyle="primary"
                      size="small"
                      label={t("contract.FactoringDebtors.BtnAddPeriod")}
                      classes="u-margin-right-small"
                    />
                    <Button
                      btnStyle="danger"
                      size="small"
                      label={t("contract.FactoringDebtors.BtnDeletePeriod")}
                    />
                  </FormFlexItem>
                </FormFlexItem>
                <FormFlexItem direction="row">
                  <InputField
                    type="text"
                    id="invoiceFactoringContractFeeTypeCl"
                    classes="form__group"
                    onChange={handleChange}
                    value={
                      values.invoiceFactoringContractFeeTypeCl
                        ? t(
                            `classifications.${values.invoiceFactoringContractFeeTypeCl}`
                          )
                        : ""
                    }
                    label={t("contract.FactoringDebtors.PaymentType")}
                  />
                  <InputField
                    type="text"
                    id="invoiceFactoringContractFeeValue"
                    classes="form__group u-margin-left-small"
                    onChange={handleChange}
                    value={values.invoiceFactoringContractFeeValue}
                    label={t(
                      "contract.FactoringDebtors.DefaultValuePercentage"
                    )}
                  />
                </FormFlexItem>
              </FormFlexItem>
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorsForm;
