import React, { useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import customerView from "../../customer/customers/customerView";
import customerAccountView from "../../customer/account/customerAccountView";
import customerInvoiceView from "../../customer/invoice/customerInvoiceView";
import CustomerDepositView from "../../customer/deposit/customerDepositView";
import ContractView from "../../contract/contractView";
import ServiceView from "../../service/serviceView";
import FactoringInvoicesView from "../../factoring/invoices/factoringInvoicesView";
import FactoringDebtorView from "../../factoring/debtor/factoringDebtorView";
import FactoringDebtorAccountView from "../../factoring/debtor_account/factoringDebtorAccountView";
import vatDocumentsView from "../../vat/documents/vatDocumentsView";
import VatApplicationsSection from "../../vat/applications/vatApplicationsSection";
import CardView from "../../card/cardView";
import BrokerInvoicesSection from "../../broker/invoices/brokerInvoicesSection";
import BrokerClaimsSection from "../../broker/claims/brokerClaimsSection";
import PartnerView from "../../partner/partnerView";
import SalespointSection from "../../salespoint/salespointSection";
import transactionsView from "../../transactions/transactionsView";
import DiscountView from "../../discount/discountView";
import ReportSection from "../../report/reportSection";
import { useTranslation } from "react-i18next";

export const NavigationBody = props => {
  return (
    <nav className={props.collapsed ? "navigation__collapsed" : "navigation"}>
      {props.children}
    </nav>
  );
};

export const NavBrand = ({ user, img }) => {
  const name = !_.isEmpty(user) ? `${user.firstName} ${user.lastName}` : "";

  return (
    <div className="navigation__profile">
      <img
        className="navigation__profile-img"
        src={process.env.PUBLIC_URL + img}
        alt="user_icon"
      />
      <p className="navigation__profile-name">
        {name || (
          <span className="navigation__profile-name--loading">First Last</span>
        )}
      </p>
    </div>
  );
};

export const NavItemList = props => {
  return <div className="navigation__items">{props.children}</div>;
};

export const NavItem = props => {
  const item = props.data;

  return (
    <div className={"navigation__items-item "} key={item.index}>
      <Link to={item.link}>
        <div
          className={
            "nav-item__main" +
            (item.name === props.selectedMainItem
              ? " nav-item__title--active"
              : "")
          }
        >
          <img
            src={process.env.PUBLIC_URL + item.iconSrc}
            className={
              "nav-item__img" +
              (item.name === props.selectedMainItem
                ? " nav-item__img--active "
                : "")
            }
            alt={item.name + "_icon"}
          />

          {/*<div className="nav-item__main--label">*/}
          {/*  <span className="nav-item__main--label-text">{props.name}</span>*/}
          {/*</div>*/}
          <div className={"nav-item__title"}>{props.name}</div>
        </div>
      </Link>
    </div>
  );
};

export const NavItemGroup = ({
  children,
  isOpen,
  heading,
  toggleOpen,
  classes
}) => {
  return (
    <div className={"nav-item-group " + (classes || "")}>
      <span className="nav-item-group__main" onClick={() => toggleOpen()}>
        <p>{heading}</p>

        <img
          src={process.env.PUBLIC_URL + "/assets/icons/triangle.svg"}
          style={{ transform: isOpen ? "rotate(0deg)" : "rotate(180deg)" }}
          className="nav-item-group__arrow"
          alt="arrow_down"
        />
      </span>
      <SlideDown transitionOnAppear={false}>
        {isOpen ? <ul>{children}</ul> : ""}
      </SlideDown>
    </div>
  );
};

export const NavItemGroupItems = ({
  data,
  isHidden,
  collapsed,
  selectedMainItem,
  section,
  t
}) => {
  const scrollElement = document.querySelector(".navigation__items");
  const [tooltip, setTooltip] = useState({});
  if (!data.length) {
    return <div />;
  } else {
    return (
      <React.Fragment>
        {data.map(item => {
          if (item.hidden === isHidden && item.section === section) {
            return (
              <li key={item.name}>
                <div
                  style={{}}
                  onMouseOver={() =>
                    setTooltip(
                      Object.assign({ ...tooltip }, { [item.name]: true })
                    )
                  }
                  onMouseLeave={() =>
                    setTooltip(
                      Object.assign({ ...tooltip }, { [item.name]: false })
                    )
                  }
                >
                  {tooltip[item.name] && collapsed ? (
                    <span
                      className="nav-item__span-label"
                      style={{
                        transform: `translateY(-${scrollElement.scrollTop}px)`
                      }}
                    >
                      {item.translation}
                    </span>
                  ) : (
                    ""
                  )}

                  <NavItem
                    name={item.translation}
                    data={item}
                    selectedMainItem={selectedMainItem}
                    collapsed={collapsed}
                  />
                </div>
              </li>
            );
          } else {
            return "";
          }
        })}
      </React.Fragment>
    );
  }
};

export const NavGroup = props => {
  return <div className="nav__group">{props.children}</div>;
};

export const NavPlaceholders = ({ userAuth, permissionsLoaded, data }) => {
  const { t } = useTranslation();
  const placeholders = [
    {
      name: "customers",
      translation: t("navigation.Customers"),
      iconSrc: "/assets/icons/customer_new.svg",
      link: "/customers",
      index: 0,
      hidden: false,
      permission: "View.Customer",
      type: "view",
      mainComponent: customerView
    },
    {
      name: "customeraccount",
      translation: t("navigation.CustomerAccount"),
      iconSrc: "/assets/icons/customer_new.svg",
      link: "/customeraccount",
      index: 1,
      hidden: false,
      permission: "View.CustomerAccount",
      type: "view",
      mainComponent: customerAccountView
    },
    {
      name: "customerinvoice",
      translation: t("navigation.CustomerInvoice"),
      iconSrc: "/assets/icons/customer_new.svg",
      link: "/customerinvoice",
      index: 2,
      hidden: false,
      permission: "View.CustomerInvoice",
      type: "view",
      mainComponent: customerInvoiceView
    },
    {
      name: "customerdeposit",
      translation: t("navigation.CustomerDeposit"),
      iconSrc: "/assets/icons/customer_new.svg",
      link: "/customerdeposit",
      index: 3,
      hidden: false,
      permission: "View.CustomerDeposit",
      type: "view",
      mainComponent: CustomerDepositView
    },

    {
      name: "contract",
      translation: t("navigation.contract"),
      iconSrc: "/assets/icons/contract.svg",
      link: "/contract",
      index: 4,
      hidden: false,
      permission: "View.Contract",
      type: "view",
      mainComponent: ContractView
    },

    {
      name: "service",
      translation: t("navigation.service"),
      iconSrc: "/assets/icons/service_new.svg",
      link: "/service",
      index: 5,
      hidden: false,
      permission: "View.Service",
      type: "view",
      mainComponent: ServiceView
    },

    {
      name: "factoringinvoices",
      translation: t("navigation.FactoringInvoices"),
      iconSrc: "/assets/icons/briefcase.svg",
      link: "/factoringinvoices",
      index: 6,
      hidden: false,
      permission: "View.FactoringInvoice",
      type: "view",
      mainComponent: FactoringInvoicesView
    },
    {
      name: "factoringdebtor",
      translation: t("navigation.FactoringDebtor"),
      iconSrc: "/assets/icons/briefcase.svg",
      link: "/factoringdebtor",
      index: 7,
      hidden: false,
      permission: "View.FactoringDebtor",
      type: "view",
      mainComponent: FactoringDebtorView
    },
    {
      name: "factoringdebtoraccount",
      translation: t("navigation.FactoringDebtorAccount"),
      iconSrc: "/assets/icons/briefcase.svg",
      link: "/factoringdebtoraccount",
      index: 8,
      hidden: false,
      permission: "View.FactoringDebtorAccount",
      type: "view",
      mainComponent: FactoringDebtorAccountView
    },
    {
      name: "vatdocuments",
      translation: t("navigation.vatDocuments"),
      iconSrc: "/assets/icons/tax.svg",
      link: "/vatdocuments",
      index: 9,
      hidden: false,
      permission: "View.VatDocument",
      type: "view",
      mainComponent: vatDocumentsView
    },
    {
      name: "vatapplications",
      translation: t("navigation.vatApplications"),
      iconSrc: "/assets/icons/tax.svg",
      link: "/vatapplications",
      index: 10,
      hidden: false,
      permission: "View.VatApplication",
      type: "view",
      mainComponent: VatApplicationsSection
    },
    {
      name: "card",
      translation: t("navigation.card"),
      iconSrc: "/assets/icons/credit-card.svg",
      link: "/card",
      index: 11,
      hidden: false,
      permission: "View.Card",
      type: "view",
      mainComponent: CardView
    },
    {
      name: "brokerInvoices",
      translation: t("navigation.brokerInvoices"),
      iconSrc: "/assets/icons/receipt.svg",
      link: "/brokerinvoices",
      index: 12,
      hidden: false,
      permission: "View.BrokerInvoice",
      type: "view",
      mainComponent: BrokerInvoicesSection
    },
    {
      name: "brokerClaims",
      translation: t("navigation.brokerClaims"),
      iconSrc: "/assets/icons/receipt.svg",
      link: "/brokerclaims",
      index: 13,
      hidden: false,
      permission: "View.BrokerClaim",
      type: "view",
      mainComponent: BrokerClaimsSection
    },
    {
      name: "partner",
      translation: t("navigation.partner"),
      iconSrc: "/assets/icons/partner.svg",
      link: "/partner",
      index: 14,
      hidden: false,
      permission: "View.Partner",
      type: "view",
      mainComponent: PartnerView
    },
    {
      name: "salespoint",
      translation: t("navigation.salespoint"),
      iconSrc: "/assets/icons/salespoint.svg",
      link: "/salespoint",
      index: 15,
      hidden: false,
      permission: "View.SalesPoint",
      type: "view",
      mainComponent: SalespointSection
    },
    {
      name: "transactions",
      translation: t("navigation.transactions"),
      iconSrc: "/assets/icons/briefcase.svg",
      link: "/transactions",
      index: 16,
      hidden: false,
      permission: "View.Transaction",
      type: "view",
      mainComponent: transactionsView
    },
    {
      name: "discount",
      translation: t("navigation.discount"),
      iconSrc: "/assets/icons/discount.svg",
      link: "/discount",
      index: 17,
      hidden: false,
      permission: "View.Discount",
      type: "view",
      mainComponent: DiscountView
    },
    {
      name: "report",
      translation: t("navigation.report"),
      iconSrc: "/assets/icons/statistics.svg",
      link: "/report",
      index: 18,
      hidden: true,
      permission: "View.Report",
      type: "view",
      mainComponent: ReportSection
    },
    {
      name: "preferences",
      translation: t("navigation.preferences"),
      iconSrc: "/assets/icons/preferences.svg",
      link: "/preferences",
      type: "view",
      index: 19,
      hidden: false
    }
  ];

  if (userAuth) {
    if (permissionsLoaded && data.length === 0) {
      return (
        <div className="navigation__no-permissions">
          Error loading permissions
        </div>
      );
    } else {
      return placeholders.map(item => {
        return <div className="nav-item__placeholder" key={item.index} />;
      });
    }
  } else {
    return placeholders.map(item => {
      return (
        <NavItem
          key={item.index}
          name={item.translation}
          data={item}
          selectedMainItem={false}
          collapsed={false}
        />
      );
    });
  }
};
