import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {SELECT_TABLE_ROW, TOGGLE_TABLE_ROW} from "../commonTableActions";
import {SET_SELECTED_ROWS_STATE} from "../../data/dataActions";

const useTableActionDispatchers = name => {
  const dispatch = useDispatch();

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_TABLE_ROW(name, payload));
    },
    [dispatch, name]
  );

  const handleRowToggle = useCallback(
    payload => {
      dispatch(TOGGLE_TABLE_ROW(name, payload));
    },
    [dispatch, name]
  );

  const handleSetSelectedRowsState = useCallback(
    payload => {
      dispatch(SET_SELECTED_ROWS_STATE(name, payload));
    },
    [dispatch, name]
  );

  return { handleRowSelect, handleRowToggle, handleSetSelectedRowsState };
};

export default useTableActionDispatchers;
