import React from "react";
import { Formik } from "formik";
import {
  FormBody,
  InputField,
  FormCol,
  BoolSelectInputField
} from "../../common/form/form";

const DiscountDetailsForm = ({ t, data }) => {
  const DiscountDetailsTrans = "discount.details.";
  console.log(data);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody flex={"true"}>
          <FormCol>
            <InputField
              id="discountDetailsCustomer"
              name="customer"
              classes="form__group"
              type="text"
              value={values.customer ? values.customer[0].name : ""}
              label={t(DiscountDetailsTrans + "CustomerDiscountCustomerName")}
              onChange={handleChange}
            />
            <InputField
              classes="form__group"
              type="text"
              label={t(
                DiscountDetailsTrans + "CustomerDiscountProductDefaultName"
              )}
              id="discountDetailsProduct"
              name="product"
              value={values.product ? values.product[0].defaultName : ""}
              onChange={handleChange}
            />
            <InputField
              id="discountDetailsQuantity"
              name="quantity"
              value={values.quantity}
              classes="form__group"
              type="text"
              label={t(DiscountDetailsTrans + "CustomerDiscountQuantity")}
              onChange={handleChange}
            />
          </FormCol>
          <FormCol className="u-margin-left-medium">
            <InputField
              classes="form__group"
              type="text"
              label={t(
                DiscountDetailsTrans + "CustomerDiscountServiceProviderName"
              )}
              id="serviceProvider"
              onChange={handleChange}
            />
            <InputField
              classes="form__group"
              type="text"
              label={t(DiscountDetailsTrans + "CustomerDiscountUnitDiscount")}
              id="discountDetailsUnitDiscount"
              name="unitDiscount"
              value={values.unitDiscount}
              onChange={handleChange}
            />
            <InputField
              id="discountDetailsNetto"
              name="netto"
              classes="form__group"
              type="text"
              value={values.netto}
              label={t(DiscountDetailsTrans + "CustomerDiscountNetto")}
              onChange={handleChange}
            />
          </FormCol>
          <FormCol className="u-margin-left-medium">
            <InputField
              id="discountDetailsCreatedOn"
              name="createdOn"
              classes="form__group"
              type="text"
              value={values.createdOn}
              label={t(DiscountDetailsTrans + "CustomerDiscountCreatedOn")}
              onChange={handleChange}
            />
            <InputField
              id="discountDetailsSalesPoint"
              name="partnerSalesPoint"
              classes="form__group"
              type="text"
              value={
                values.partnerSalesPoint
                  ? values.partnerSalesPoint[0].defaultName
                  : ""
              }
              label={t(DiscountDetailsTrans + "CustomerDiscountSalesPointName")}
              onChange={handleChange}
            />
            <InputField
              id="discountDetailsInvoiceNo"
              name="invoiceNo"
              classes="form__group"
              type="text"
              value={
                values.issuedForInvoice
                  ? values.issuedForInvoice[0].invoiceNo
                  : ""
              }
              label={t(DiscountDetailsTrans + "CustomerDiscountInvoiceNo")}
              onChange={handleChange}
            />
          </FormCol>
          <FormCol className="u-margin-left-medium">
            <InputField
              id="discountDetailsVat"
              name="vat"
              classes="form__group"
              type="text"
              value={values.vat}
              label={t(DiscountDetailsTrans + "CustomerDiscountVat")}
              onChange={handleChange}
            />
            <InputField
              classes="form__group"
              type="text"
              label={t(DiscountDetailsTrans + "CustomerDiscountCountryName")}
              id="country"
              onChange={handleChange}
            />
            <BoolSelectInputField
              id="discountDetailsIsActive"
              name="isActiveCl"
              isCl={true}
              value={values.isActiveCl}
              classes="form__group"
              label={t(DiscountDetailsTrans + "IsActive")}
              onChange={handleChange}
            />
          </FormCol>
        </FormBody>
      )}
    </Formik>
  );
};

export default DiscountDetailsForm;
