import React from "react";
import View from "../../common/view";
import FactoringDebtorAccountMainSection from "./layout/main/factoringDebtorAccountMainSection";
import FactoringDebtorAccountSearchSection from "./layout/search/factoringDebtorAccountSearchSection";

const FactoringDebtorAccountView = () => {
  return (
    <View
      MainSection={FactoringDebtorAccountMainSection}
      SearchSection={FactoringDebtorAccountSearchSection}
    />
  );
};

export default FactoringDebtorAccountView;
