import React from "react";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  FormBody,
  FormCol,
  FormGroupMultiCol,
  FormGroupSmall,
  InputField,
  TextareaInputField
} from "../../../common/form/form";
import { handleFormChange } from "../../../../services/formService";
import { numberWithSpaces } from "../../../../services/numberFunctions";
import { useTranslation } from "react-i18next";
import useFormsState from "../../../common/redux/hooks/useFormsState";
import { factoringDebtorOverdueInvoices } from "../../../common/redux/types/selectorTypes";
import useFormsActionDispatchers from "../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";
import { FACTORING_DEBTOR_OVERDUE_INVOICES } from "../../../common/redux/types/viewTypes";

const FactoringDebtorOverdueInvoicesDetailsForm = () => {
  const { t } = useTranslation();
  const { formsState } = useFormsState(factoringDebtorOverdueInvoices);
  const { handleFormsChange } = useFormsActionDispatchers(
    FACTORING_DEBTOR_OVERDUE_INVOICES
  );
  const TranslationNS = "factoring.debtor.overdueInvoices.";
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          state,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm
        }) => (
          <FormBody>
            <div className="form--flex">
              <FormCol>
                <InputField
                  label={t(TranslationNS + "FilterFactoringDebtor")}
                  type="text"
                  classes="form__group"
                  name="id"
                  id="customerDetailsFormNo"
                  value={values.id}
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />

                <InputField
                  label={t(TranslationNS + "TotalPenalties")}
                  name="name"
                  type="text"
                  id="customerDetailsFormName"
                  classes="form__group"
                  value={values.name}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <InputField
                  label={t(TranslationNS + "FilterIsEmailSent")}
                  type="text"
                  name="regNo"
                  id="customerDetailsFormRegNo"
                  value={values.regNo}
                  classes="form__group"
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>

              <FormCol className="u-margin-left-small">
                <InputField
                  label={t(TranslationNS + "FilterInvoiceNo")}
                  type="text"
                  classes="form__group"
                  name="generalFax"
                  id="customerDetailsFormGeneralFax"
                  value={values.generalFax}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <InputField
                  label={t(TranslationNS + "FilterIssuedForInvoiceNo")}
                  type="text"
                  classes="form__group"
                  name="generalPhone"
                  value={values.generalPhone}
                  id="customerDetailsFormGeneralPhone"
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>
              <FormCol className="u-margin-left-small">
                <DateInputField
                  label={t(TranslationNS + "PenaltyInvoiceDate")}
                  type="text"
                  classes="form__group"
                  name="generalFax"
                  id="customerDetailsFormGeneralFax"
                  value={values.generalFax}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
                <DateInputField
                  label={t(TranslationNS + "DueDate")}
                  type="text"
                  classes="form__group"
                  name="generalPhone"
                  value={values.generalPhone}
                  id="customerDetailsFormGeneralPhone"
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState, handleFormsChange);
                  }}
                />
              </FormCol>
            </div>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorOverdueInvoicesDetailsForm;
