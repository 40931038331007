import React from "react";
import View from "../common/view";
import ContractMainSection from "./layout/main/contractMainSection";
import ContractSearchSection from "./layout/search/contractSearchSection";

const ContractView = () => {
  return (
    <View
      MainSection={ContractMainSection}
      SearchSection={ContractSearchSection}
    />
  );
};

export default ContractView;
