import React from "react";
import { Button } from "../../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const CustomerDepositButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="u-margin-right-small" style={{ display: "flex" }}>
        <Button
          btnStyle="primary"
          size="small"
          handleClick={() => {}}
          disabled={false}
          label={t("customer.customerView.BtnAddCustomer")}
          classes="btn--footer"
        />
        <Button
          btnStyle="danger"
          size="small"
          label={t("customer.customerView.BtnDeleteCustomer")}
          handleClick={() => {}}
          disabled={false}
          classes={"btn--footer"}
        />
        <Button
          btnStyle="green"
          size="small"
          label={t("customer.customerView.BtnSaveCustomer")}
          handleClick={() => {}}
          disabled={false}
          classes="btn--footer"
        />
      </div>

      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnCustomersToCsv")}
        handleClick={() => alert("CSV")}
        disabled={false}
        classes={"btn--footer"}
      />
      <Button
        btnStyle="lime"
        size="small"
        label={t("customer.customerView.BtnSendToPrinter")}
        handleClick={() => alert("PRINT ENVELOPE")}
        disabled={false}
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default CustomerDepositButtons;
