import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getCustomerInvoiceQuery } from "../actions/customerInvoicesActions";

const useCustomerInvoiceActions = () => {
  const dispatch = useDispatch();
  const onSubmitCustomerInvoiceQuery = useMemo(
    () => payload => {
      dispatch(getCustomerInvoiceQuery(payload));
    },
    [dispatch]
  );

  return {
    onSubmitCustomerInvoiceQuery
  };
};

export default useCustomerInvoiceActions;
