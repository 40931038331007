import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FormBody, InputField, FormCol } from "../../../common/form/form";

const FactoringDebtorAccDetailsForm = () => {
  const { t } = useTranslation();
  const data = useSelector(
    state => state.factoringDebtorAccount.data.selectedItem
  );

  const getEntrySourceTypeCl = entrySourceTypeCl => {
    switch (entrySourceTypeCl) {
      case "ENTRY_SOURCE_TYPE.FACTORING_INVOICE": {
        return t("factoring.invoices.FactoringInvoice");
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          factoringDebtor: data.customer ? data.customer[0].name : "",
          entryTime: data.entryTime || "",
          entrySource: data.entrySource || "",
          transactionEntry: data.entrySourceTypeCl ? data.entrySourceTypeCl : ""
        }}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <FormCol>
              <InputField
                type="text"
                id="factoringDebtor"
                value={values.factoringDebtor}
                classes="form__group"
                label={t("customerAccount.FactoringDebtor")}
                onChange={handleChange}
              />
              <InputField
                type="text"
                classes="form__group"
                id="entryTime"
                value={values.entryTime}
                label={t("customerAccount.EntryDate")}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                id="transactionEntry"
                value={getEntrySourceTypeCl(values.transactionEntry)}
                classes="form__group"
                label={t("customerAccount.EntrySourceType")}
                onChange={handleChange}
              />
              <InputField
                type="text"
                classes="form__group"
                id="entrySource"
                value={values.entrySource}
                label={t("customerAccount.EntrySource")}
                onChange={handleChange}
              />
            </FormCol>
          </FormBody>
        )}
      </Formik>
    </div>
  );
};

export default FactoringDebtorAccDetailsForm;
