import React from "react";
import useTabState from "./redux/hooks/useTabState";
import useTabLayoutActionDispatchers from "./redux/actions/layout/tab/useTabLayoutActionDispatchers";

const Expander = ({
  toggle,
  isOpen,
  name,
  children,
  heading,
  classes,
  viewType,
  tabType,
  viewSelector,
  tabSelector
}) => {
  // const [open, setOpen] = useState(false);
  //
  // useLayoutEffect(() => {
  //   if (isOpen) {
  //     setOpen(!open);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { expanded: open } = useTabState(viewSelector, tabSelector);
  const { handleTabExpanderToggle } = useTabLayoutActionDispatchers(
    viewType,
    tabType
  );

  return (
    <React.Fragment>
      <div
        key={name}
        className={
          (open ? "expander expander__expanded " : "expander ") +
          (classes || "")
        }
        onClick={handleTabExpanderToggle}
      >
        <p>{heading}</p>
      </div>
      <div
        className={
          open
            ? "expander__content expander__content-active"
            : "expander__content"
        }
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default Expander;
