import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { LOAD_DISCOUNT_SEARCH_DATA_START } from "../actions/discountActions";

const useDiscountActions = () => {
  const dispatch = useDispatch();
  const handleSearch = useCallback(payload => {
    dispatch(LOAD_DISCOUNT_SEARCH_DATA_START(payload));
  }, [dispatch]);

  return { handleSearch };
};

export default useDiscountActions;
