const createLayoutReducer = (name = "") => {
  const initialState = {
    table: {
      visible: true
    },
    expanders: {
      visible: false
    },
    search: {
      isOpen: true
    }
  };
  return function(state = initialState, action) {
    switch (action.type) {
      case `TOGGLE_${name}_SEARCH`: {
        return {
          ...state,
          search: {
            ...state.search,
            isOpen: !state.search.isOpen
          }
        };
      }

      case `TOGGLE_${name}_EXPANDER_VIEW`: {
        return {
          ...state,
          expanders: { ...state.expanders, visible: !state.expanders.visible }
        };
      }

      case `TOGGLE_${name}_TABLE`: {
        return {
          ...state,
          table: {
            ...state.table,
            visible: !state.table.visible
          }
        };
      }
      default:
        return state;
    }
  };
};

export default createLayoutReducer;
