import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  TOGGLE_CONTRACT_TABLE,
  TOGGLE_CONTRACT_EXPANDERS,
  TOGGLE_CONTRACT_SEARCH
} from "../../actions/contractActions";

const useContractLayoutActions = () => {
  const dispatch = useDispatch();
  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_CONTRACT_TABLE());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_CONTRACT_EXPANDERS());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_CONTRACT_SEARCH());
  }, [dispatch]);

  return { handleTableToggle, handleExpandersToggle, handleSearchToggle };
};

export default useContractLayoutActions;
