import React from "react";
import { Formik } from "formik";
import _ from "lodash";
import {
  FormBody,
  FormCol,
  InputField,
  SelectInputField,
  TextareaInputField,
  FormFlexWrapper,
  FormFlexItem
} from "../../../common/form/form";
import { Button } from "../../../common/buttons/button";

const ContractViewDetailsForm = ({
  t,
  data,
  resellers,
  contractTypes,
  contractStatus,
  serviceProviders
}) => {
  const ContractDetailsTrans = "contract.details.";

  const resellersData = resellers || [{ name: "", value: "" }];

  const serviceProvidersData = serviceProviders || [{ name: "", value: "" }];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        contractType: data ? data.contractTypeCl : "",
        contractStatus: data ? data.currentStatusCl : "",
        customerNo: data ? data.customerNo : "",
        validFrom: data ? data.validFrom : "",
        contractNo: data ? data.contractNo : "",
        contractTemplate:
          !_.isEmpty(data) && data.contractTemplate
            ? data.contractTemplate[0].name
            : "",
        customerName: !_.isEmpty(data) ? data.customer[0].name : "",
        serviceProvider: !_.isEmpty(data) ? data.serviceProvider[0].id : "",
        isActiveCl: data ? data.isActiveCl : "",
        currentStatusDate: data ? data.currentStatusDate : "",
        additionalInfo: data.additionalInfo || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper classes="flex__align-items--center">
            <FormCol>
              <SelectInputField
                options={[
                  { name: "", value: "" },
                  ...(contractTypes !== undefined
                    ? contractTypes.map(item =>
                        Object.assign(
                          {},
                          {
                            name: t(`classifications.${item.name}`),
                            value: item.value
                          }
                        )
                      )
                    : [])
                ]}
                label={t(ContractDetailsTrans + "ContractType")}
                id="contractType"
                classes="form__group"
                onChange={handleChange}
                value={values.contractType}
              />
              <FormFlexWrapper classes="form--space-between form--flex-center">
                <FormFlexItem flexGrow={1} classes="u-margin-right-tiny">
                  <SelectInputField
                    options={[
                      { name: "", value: "" },
                      { name: "Faktooring tava", value: "Faktooring tava" }
                    ]}
                    label={t(ContractDetailsTrans + "ContractTemplate")}
                    id="contractTemplate"
                    classes="form__group"
                    value={values.contractTemplate}
                    onChange={handleChange}
                  />
                </FormFlexItem>
                <FormFlexItem>
                  <Button
                    size="small"
                    btnStyle="primary"
                    label="Open template"
                  />
                </FormFlexItem>
              </FormFlexWrapper>
              <FormFlexWrapper classes="form--space-between form--flex-center">
                <FormFlexItem flexGrow={1} classes="u-margin-right-tiny">
                  <InputField
                    type="text"
                    label={t(ContractDetailsTrans + "ContractNo")}
                    id="contractNo"
                    name="contractNo"
                    classes="form__group"
                    onChange={handleChange}
                    value={values.contractNo || ""}
                  />
                </FormFlexItem>
                <FormFlexItem>
                  <Button
                    size="small"
                    btnStyle="primary"
                    label="Generate number"
                  />
                </FormFlexItem>
              </FormFlexWrapper>

              <SelectInputField
                options={[
                  { name: "", value: "" },
                  ...(contractStatus !== undefined
                    ? contractStatus.map(item =>
                        Object.assign(
                          {},
                          {
                            name: t(`classifications.${item.name}`),
                            value: item.value
                          }
                        )
                      )
                    : [])
                ]}
                label={t(ContractDetailsTrans + "ContractStatus")}
                id="contractStatus"
                classes="form__group"
                value={values.contractStatus}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                label={t(ContractDetailsTrans + "ContractCustomer")}
                id="customer"
                value={values.customerName}
                onChange={handleChange}
                classes="form__group"
              />
              <SelectInputField
                options={[{ name: "", id: "" }, ...serviceProvidersData]}
                customValueKey="id"
                label={t(ContractDetailsTrans + "ContractServiceProvider")}
                value={values.serviceProvider}
                onChange={handleChange}
                id="serviceProvider"
                classes="form__group"
              />
              <SelectInputField
                options={[{ name: "", id: "" }, ...resellersData]}
                customValueKey="id"
                label={t(ContractDetailsTrans + "ContractReseller")}
                value={values.reseller}
                onChange={handleChange}
                id="reseller"
                classes="form__group"
              />
              <InputField
                type="date"
                label={t(ContractDetailsTrans + "StatusDate")}
                id="statusDate"
                value={values.currentStatusDate}
                onChange={handleChange}
                classes="form__group"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <SelectInputField
                options={[
                  { name: "", value: "" },
                  { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                  { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
                ]}
                value={values.isActiveCl}
                onChange={handleChange}
                label={t(ContractDetailsTrans + "ContractIsActive")}
                id="isActive"
                classes="form__group"
              />
              <InputField
                type="date"
                label={t(ContractDetailsTrans + "ContractValidFrom")}
                id="validFrom"
                onChange={handleChange}
                value={values.validFrom}
                classes="form__group"
              />
              <InputField
                type="date"
                label={t(ContractDetailsTrans + "ContractValidThru")}
                id="validThru"
                onChange={handleChange}
                classes="form__group"
              />
              <InputField
                type="text"
                label={t(ContractDetailsTrans + "ContractCustomerNo")}
                id="customerNo"
                value={values.customerNo}
                onChange={handleChange}
                classes="form__group"
              />
            </FormCol>
          </FormFlexWrapper>
          <TextareaInputField
            type="text"
            classes="form__block form__group form__block--multiline"
            label={t(ContractDetailsTrans + "ContractAdditionalInfo")}
            id="additionalInfo"
            value={values.additionalInfo}
            onChange={handleChange}
          />
        </FormBody>
      )}
    </Formik>
  );
};

export default ContractViewDetailsForm;
