export const CLEAR_CUSTOMERS_FORMS = () => ({
  type: "CLEAR_CUSTOMERS_FORMS"
});

export const SUBMIT_ADD_NEW_CUSTOMER_QUERY_START = payload => ({
  type: "SUBMIT_ADD_NEW_CUSTOMER_QUERY_START"
});

export const SUBMIT_ADD_NEW_CUSTOMER_QUERY_SUCCESS = payload => ({
  type: "SUBMIT_ADD_NEW_CUSTOMER_QUERY_SUCCESS",
  payload: payload
});

export const SUBMIT_ADD_NEW_CUSTOMER_QUERY_ERROR = payload => ({
  type: "SUBMIT_ADD_NEW_CUSTOMER_QUERY_ERROR",
  payload: payload
});

export const SUBMIT_UPDATE_CUSTOMER_QUERY_START = () => ({
  type: "SUBMIT_UPDATE_CUSTOMER_QUERY_START"
});

export const SUBMIT_UPDATE_CUSTOMER_QUERY_SUCCESS = payload => ({
  type: "SUBMIT_UPDATE_CUSTOMER_QUERY_SUCCESS",
  payload: payload
});

export const SUBMIT_UPDATE_CUSTOMER_QUERY_ERROR = payload => ({
  type: "SUBMIT_UPDATE_CUSTOMER_QUERY_ERROR",
  payload: payload
});

export const SUBMIT_DELETE_CUSTOMER_QUERY_START = () => ({
  type: "SUBMIT_DELETE_CUSTOMER_QUERY_START"
});

export const SUBMIT_DELETE_CUSTOMER_QUERY_SUCCESS = payload => ({
  type: "SUBMIT_DELETE_CUSTOMER_QUERY_SUCCESS",
  payload: payload
});

export const SUBMIT_DELETE_CUSTOMER_QUERY_ERROR = payload => ({
  type: "SUBMIT_DELETE_CUSTOMER_QUERY_ERROR",
  payload: payload
});

export const CLEAR_DELETE_CUSTOMER_QUERY = () => ({
  type: "CLEAR_DELETE_CUSTOMER_QUERY"
});

export const CLEAR_ADD_NEW_CUSTOMER_QUERY = () => ({
  type: "CLEAR_ADD_NEW_CUSTOMER_QUERY"
});
