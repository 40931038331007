import React from "react";

const UserRole = ({ role }) => {
  if (role) {
    return (
      <div className="notification-bar__role u-margin-left-tiny">{role}</div>
    );
  } else {
    return null;
  }
};

export const UserRoleFallback = () => {
  return (
    <div className="notification-bar__role u-margin-left-tiny">LOADING...</div>
  );
};

export default UserRole;
