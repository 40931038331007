import {useCallback} from "react";
import {SET_TABLE_HEIGHT, TOGGLE_EXPANDERS, TOGGLE_SEARCH, TOGGLE_TABLE} from "../layoutActions";
import {useDispatch} from "react-redux";

const useLayoutActionDispatchers = name => {
  const dispatch = useDispatch();

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_EXPANDERS(name));
  }, [dispatch, name]);

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_TABLE(name));
  }, [dispatch, name]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_SEARCH(name));
  }, [dispatch, name]);

  const handleTableHeightSet = useCallback(
    payload => {
      dispatch(SET_TABLE_HEIGHT(name, payload));
    },
    [dispatch, name]
  );

  return {
    handleExpandersToggle,
    handleTableToggle,
    handleSearchToggle,
    handleTableHeightSet
  };
};

export default useLayoutActionDispatchers;
