import  { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  getFactoringInvoiceData,
  SELECT_FACTORING_INVOICE_ROW
} from "../actions/factoringInvoicesActions";

const useFactoringInvoicesActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(
    payload => {
      dispatch(getFactoringInvoiceData(payload));
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_FACTORING_INVOICE_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useFactoringInvoicesActions;
