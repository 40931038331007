export const LOAD_CUSTOMER_ACCOUNT_DATA_START = () => ({
  type: "LOAD_CUSTOMER_ACCOUNT_DATA_START"
});

export const LOAD_CUSTOMER_ACCOUNT_DATA_SUCCESS = payload => ({
  type: "LOAD_CUSTOMER_ACCOUNT_DATA_SUCCESS",
  payload: payload
});

export const LOAD_CUSTOMER_ACCOUNT_DATA_ERROR = payload => ({
  type: "LOAD_CUSTOMER_ACCOUNT_DATA_ERROR",
  payload: payload
});

export const SUBMIT_CUSTOMER_ACCOUNT_QUERY = payload => ({
  type: "SUBMIT_CUSTOMER_ACCOUNT_QUERY",
  payload: payload
});
