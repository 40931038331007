import React, { Component } from "react";
import { Formik } from "formik";
import { FormBody, CheckboxInputField } from "../../common/form/form";

class PartnerSectionInvoiceSettingsForm extends Component {
  render() {
    const PartnerInvoiceSettingsTrans = "partner.invoiceSettings.";
    const { t } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <FormBody flex={"true"}>
            <CheckboxInputField
              label={t(PartnerInvoiceSettingsTrans + "IsPrepaid")}
              classes="form__group"
              id="partnerInvoiceSettingsIsPrepaid"
              onChange={handleChange}
            />
          </FormBody>
        )}
      </Formik>
    );
  }
}

export default PartnerSectionInvoiceSettingsForm;
