import { useSelector } from "react-redux";

const useFactoringDebtorAccountLayoutState = () => {
  const tableVisible = useSelector(
    state => state.factoringDebtorAccount.layout.table.visible
  );
  const expandersVisible = useSelector(
    state => state.factoringDebtorAccount.layout.expander_view
  );

  const searchOpen = useSelector(
    state => state.factoringDebtorAccount.layout.search.isOpen
  );

  return { tableVisible, expandersVisible, searchOpen };
};

export default useFactoringDebtorAccountLayoutState;
