export const addEmptyFirstSelectOption = arr => {
  if (arr[0] !== { name: "", value: "" }) arr.unshift({ name: "", value: "" });
  return arr;
};

export const mutateClassifications = arr => {
  const newArr = arr.map(item => {
    return Object.assign({}, { name: item.attribute, value: item.attribute });
  });
  return newArr;
};

export const getNestedItem = (src, item, idx = 0) => {
  if (src === null || src === undefined) {
    return "";
  } else {
    if (src.length) {
      return src[idx][item];
    } else {
      return "";
    }
  }
};

export const checkNestedObject = (obj, level, ...rest) => {
  if (obj === undefined) return false;
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
  return checkNestedObject(obj[level], ...rest);
};
