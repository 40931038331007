export const TOGGLE_VAT_DOCUMENTS_TABLE = () => ({
  type: "TOGGLE_VAT_DOCUMENTS_TABLE"
});

export const TOGGLE_VAT_DOCUMENTS_SEARCH = () => ({
  type: "TOGGLE_VAT_DOCUMENTS_SEARCH"
});

export const TOGGLE_VAT_DOCUMENTS_EXPANDER_VIEW = () => ({
  type: "TOGGLE_VAT_DOCUMENTS_EXPANDER_VIEW"
});

export const SET_VAT_DOCUMENTS_TABLE_HEIGHT = payload => ({
  type: "SET_VAT_DOCUMENTS_TABLE_HEIGHT",
  payload: payload
});
