import React from "react";
import { Button } from "../../../common/buttons/button";
import { useTranslation } from "react-i18next";

const CardButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Button
        btnStyle="lime"
        label={t("card.BtnPost")}
        classes={"btn--footer"}
        disabled={true}
        size="medium"
      />
      <Button
        btnStyle="primary"
        disabled={true}
        classes={"btn--footer"}
        label={t("card.BtnOpen")}
        size="medium"
      />
      <Button
        btnStyle="danger"
        classes={"btn--footer"}
        disabled={true}
        label={t("card.BtnClose")}
        size="medium"
      />
      <Button
        btnStyle="primary"
        classes={"btn--footer"}
        disabled={true}
        label={t("card.BtnShowPin")}
        size="medium"
      />
      <Button
        btnStyle="danger"
        classes={"btn--footer"}
        disabled={true}
        label={t("card.BtnDelete")}
        size="medium"
      />
      <Button
        btnStyle="green"
        classes={"btn--footer"}
        disabled={true}
        label={t("card.BtnSave")}
        size="medium"
      />
    </React.Fragment>
  );
};

export default CardButtons;
