import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../common/table";
import { SET_CUSTOMERS_CONTACT_INFO_TABLE_HEIGHT } from "./actions/customerContactInformationActions";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";

const CustomerContactInformationTable = ({
  t,
  handleRowClick,
  selectedRow
}) => {
  const dispatch = useDispatch();

  const { table } = useTabState(customers, "contactInformation");
  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMERS_CONTACT_INFO_TABLE_HEIGHT(payload));
  };

  const data = useSelector(
    state => state.customers.data.selectedItem.customerContactPerson
  );

  useEffect(() => {
    // if (_.isEmpty(selectedRow)) {
    //   var index = selectedRow.index;
    //   if (index < data.length) {
    //     handleRowClick(Object.assign({ index: selectedRow.index }, data[0]));
    //   } else {
    //     handleRowClick(Object.assign({ index: 0 }, data[0]));
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getContactPersonType = type => {
    switch (type) {
      case "CONTACT_PERSON_TYPE.BOARD_MEMBER": {
        return "board member";
      }
      case "CONTACT_PERSON_TYPE.CONTACT_PERSON": {
        return "contact person";
      }
      case "CONTACT_PERSON_TYPE.ACCOUNTANT": {
        return "accountant";
      }
      default: {
        return "NONE";
      }
    }
  };
  //√
  const CustomerContactTrans = "customer.customerContactData.";
  const columns = [
    {
      Header: t(CustomerContactTrans + "PersonName"),
      accessor: "name"
    },
    {
      id: "contactPersonTypeCl",
      Header: t(CustomerContactTrans + "Position"),
      accessor: data => getContactPersonType(data.contactPersonTypeCl)
    },
    {
      id: "hasSignatureRight",
      Header: t(CustomerContactTrans + "SignatureVerified"),
      accessor: data => (data.hasSignatureRight ? "√" : "")
    },
    {
      id: "isPrimary",
      Header: t(CustomerContactTrans + "Primary"),
      accessor: data => (data.isPrimary ? "√" : "")
    }
  ];

  console.log(data, "CONTACT INFO TABLE");
  const sortColumn = "name";
  return (
    <div className="customer__comments__table">
      <Table
        classes="u-margin-right-small"
        height={table.height}
        setTableHeight={onSetTableHeight}
        columns={columns}
        onRowSelect={handleRowClick}
        selectedRow={selectedRow}
        data={data || []}
        sortColumn={sortColumn}
      />
    </div>
  );
};

export default CustomerContactInformationTable;
