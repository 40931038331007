import React, { useRef } from "react";
import useCustomersActions from "../../hooks/useCustomersActions";
import {
  SectionMain,
  SectionBody,
  SectionHeader
} from "../../../../common/section";
import CustomerTable from "../../customerTable";
import useCustomersConsts from "../../hooks/useCustomersConsts";
import CustomerButtons from "../main/customerButtons";
import { getCustomerFilters } from "../../hooks/customersUtils";
import { useTranslation } from "react-i18next";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import useTableActionDispatchers from "../../../../common/redux/actions/table/hooks/useTableActionDispatchers";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";

const CustomerMainSection = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const formRef = useRef({
    customerNo: "",
    name: "",
    generalPhone: "",
    generalEmail: "",
    generalFax: "",
    regNo: "",
    creditLimit: "",
    isActive: "",
    debt: 0,
    additionalInfo: "",
    validFrom: "",
    customerManager: "",
    portOneContractsCount: 0,
    otherContractsCount: 0,
    salesAgentName: "",
    balance: 0,
    legalAddressCountry: "",
    legalAddressCity: "",
    legalAddressStreet: "",
    legalAddressHouse: "",
    legalAddressZipCode: "",
    postalAddressCountry: "",
    postalAddressCity: "",
    postalAddressStreet: "",
    postalAddressHouse: "",
    postalAddressZipCode: "",
    boardMemberAddressCountry: "",
    boardMemberAddressCity: "",
    boardMemberAddressStreet: "",
    boardMemberAddressHouse: "",
    boardMemberAddressZipCode: ""
  });
  const { removeRowToggle } = useCustomersActions();

  const { handleRowSelect, handleRowToggle } = useTableActionDispatchers(
    CUSTOMERS
  );

  const {
    handleExpandersToggle,
    handleTableToggle,
    handleSearchToggle
  } = useLayoutActionDispatchers(CUSTOMERS);

  const { tableVisible, expandersVisible, searchOpen } = useLayoutState(
    customers
  );

  const { searchQuery } = useSearchState(customers);
  const { selectedItem } = useDataState(customers);
  const { onSetCustomersTabsState, onCustomersSearch } = useCustomersActions();
  const { customersLabels } = useCustomersConsts();

  const { customersShortcuts, customersTabs } = useCustomersConsts();

  return (
    <SectionMain toggleSearch={handleSearchToggle}>
      <SectionHeader
        sectionHeading={t("customer.customerView.Header")}
        getFilters={getCustomerFilters}
        searchQuery={searchQuery}
        filterLabels={customersLabels}
        tableVisible={tableVisible}
        expandersVisible={expandersVisible}
        tableEnabled={true}
        expandersEnabled={true}
        tabsEnabled={false}
        onToggleTable={handleTableToggle}
        onToggleExpanders={handleExpandersToggle}
        onToggleTabs={onSetCustomersTabsState}
        onToggleSearch={handleSearchToggle}
        onSearch={onCustomersSearch}
        searchOpen={searchOpen}
      />

      <SectionBody
        TableComponent={CustomerTable}
        ButtonsComponent={() => CustomerButtons(formRef)}
        shortcutsEnabled={true}
        selectedRow={selectedItem}
        tableVisible={tableVisible}
        onRowSelect={handleRowSelect}
        removeItem={removeRowToggle}
        onToggleRow={handleRowToggle}
        tableRef={tableRef}
        shortcuts={customersShortcuts}
        expandersEnabled={expandersVisible}
        tabs={customersTabs(formRef)}
      />
    </SectionMain>
  );
};

export default CustomerMainSection;
