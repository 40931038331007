import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {
    SET_VAT_DOCUMENTS_TABLE_HEIGHT,
    TOGGLE_VAT_DOCUMENTS_EXPANDER_VIEW,
    TOGGLE_VAT_DOCUMENTS_SEARCH,
    TOGGLE_VAT_DOCUMENTS_TABLE
} from "../../actions/layout/vatDocumentsLayoutActions";

const useVatDocumentsLayoutActions = () => {
  const dispatch = useDispatch();

  const handleTableToggle = useCallback(() => {
    dispatch(TOGGLE_VAT_DOCUMENTS_TABLE());
  }, [dispatch]);

  const handleSearchToggle = useCallback(() => {
    dispatch(TOGGLE_VAT_DOCUMENTS_SEARCH());
  }, [dispatch]);

  const handleExpandersToggle = useCallback(() => {
    dispatch(TOGGLE_VAT_DOCUMENTS_EXPANDER_VIEW());
  }, [dispatch]);

  const handleTableHeightChange = useCallback(
    payload => {
      dispatch(SET_VAT_DOCUMENTS_TABLE_HEIGHT(payload));
    },
    [dispatch]
  );

  return {
    handleTableToggle,
    handleSearchToggle,
    handleExpandersToggle,
    handleTableHeightChange
  };
};

export default useVatDocumentsLayoutActions;
