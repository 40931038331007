import {useSelector} from "react-redux";

const useVatDocumentsState = () => {
  const searchQuery = useSelector(state => state.vatDocuments.search.query);
  const data = useSelector(state => state.vatDocuments.data.items);
  const selectedRow = useSelector(
    state => state.vatDocuments.data.selectedItem
  );
  const isLoading = useSelector(state => state.vatDocuments.data.isLoading);

  return { searchQuery, data, selectedRow, isLoading };
};

export default useVatDocumentsState;
