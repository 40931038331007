import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  FormBody,
  InputField,
  SelectInputField,
  FormCol
} from "../../../../common/form/form";
import { Loading } from "../../../../common/loading";
import useFormsActionDispatchers from "../../../../common/redux/actions/forms/hooks/useFormsActionDispatchers";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import useFormsState from "../../../../common/redux/hooks/useFormsState";
import { customers } from "../../../../common/redux/types/selectorTypes";
import { handleFormChange } from "../../../../../services/formService";
import _ from "lodash";

const CustomerFormAddresses = ({ data, t }) => {
  const AddressTranslation = "customer.customerAddresses.";

  const { handleFormsChange } = useFormsActionDispatchers(CUSTOMERS);
  const { formsState } = useFormsState(customers);

  useEffect(() => {
    if (!_.isEmpty(formsState)) {
      handleFormsChange(Object.assign(formsState, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (data) {
    const dataLegal = data.legalAddress ? data.legalAddress[0] : {};
    const dataPostal = data.postalAddress ? data.postalAddress[0] : {};
    const dataBoardMember = data.boardMemberAddress
      ? data.boardMemberAddress[0]
      : {};

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            legalAddressCountry: dataLegal.country
              ? dataLegal.country[0].code
              : "",
            legalAddressCity: dataLegal.city ? dataLegal.city[0].name : "",
            legalAddressStreet: dataLegal.street
              ? dataLegal.street[0].name
              : "",
            legalAddressHouse: dataLegal.houseOrApartment
              ? dataLegal.houseOrApartment
              : "",
            legalAddressZipCode: dataLegal.zipCode || "",
            postalAddressCountry: dataPostal.country
              ? dataPostal.country[0].code
              : "",
            postalAddressCity: dataPostal.city ? dataPostal.city[0].name : "",
            postalAddressStreet: dataPostal.street
              ? dataPostal.street[0].name
              : "",
            postalAddressHouse: dataPostal.houseOrApartment || "",
            postalAddressZipCode: dataPostal.zipCode || "",
            boardMemberAddressCountry: dataBoardMember.country
              ? dataBoardMember.country[0].code
              : "",
            boardMemberAddressCity: dataBoardMember.city || "",
            boardMemberAddressStreet: dataBoardMember.street || "",
            boardMemberAddressHouse: dataBoardMember.houseOrApartment || "",
            boardMemberAddressZipCode: dataBoardMember.zipCode || ""
          }}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({ isSubmitting, handleChange, values }) => (
            <FormBody flex={"true"}>
              <FormCol>
                <h4 className="heading__quaternary u-margin-bottom-small">
                  {t(AddressTranslation + "HeaderLegalAddress")}
                </h4>
                <SelectInputField
                  id="legalAddressCountry"
                  label={t(AddressTranslation + "Country")}
                  options={[
                    { name: "", value: "" },
                    { name: "Estonia", value: "EE" }
                  ]}
                  classes="form__group"
                  value={values.legalAddressCountry}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                />
                <InputField
                  label={t(AddressTranslation + "City")}
                  name="legalAddressCity"
                  classes="form__group"
                  value={values.legalAddressCity}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  id="legalAddressCity"
                />
                <InputField
                  label={t(AddressTranslation + "Street")}
                  id="legalAddressStreet"
                  value={values.legalAddressStreet}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "House")}
                  id="legalAddressHouse"
                  value={values.legalAddressHouse}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "ZipCode")}
                  id="legalAddressZipCode"
                  value={values.legalAddressZipCode}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  classes="form__group"
                />
              </FormCol>
              <FormCol className="u-margin-left-small">
                <h4 className="heading__quaternary u-margin-bottom-small">
                  {t(AddressTranslation + "HeaderPostalAddress")}
                </h4>
                <SelectInputField
                  label={t(AddressTranslation + "Country")}
                  options={[
                    { name: "", value: "" },
                    { name: "Estonia", value: "EE" }
                  ]}
                  classes="form__group"
                  value={values.postalAddressCountry}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  id="postalAddressCountry"
                />
                <InputField
                  label={t(AddressTranslation + "City")}
                  classes="form__group"
                  value={values.postalAddressCity}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e, values, formsState);
                  }}
                  id="postalAddressCity"
                />
                <InputField
                  label={t(AddressTranslation + "Street")}
                  id="postalAddressStreet"
                  value={values.postalAddressStreet}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "House")}
                  id="postalAddressHouse"
                  value={values.postalAddressHouse}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
                <InputField
                  label={t(AddressTranslation + "ZipCode")}
                  id="postalAddressZipCode"
                  value={values.postalAddressZipCode}
                  onChange={e => {
                    handleChange(e);
                  }}
                  classes="form__group"
                />
              </FormCol>
              {/*<FormCol className="u-margin-left-small">*/}
              {/*  <h4 className="heading__quaternary u-margin-bottom-small">*/}
              {/*    {t(AddressTranslation + "HeaderBoardMemberAddress")}*/}
              {/*  </h4>*/}
              {/*  <SelectInputField*/}
              {/*    label={t(AddressTranslation + "Country")}*/}
              {/*    options={[*/}
              {/*      { name: "", value: "" },*/}
              {/*      { name: "Estonia", value: "EE" }*/}
              {/*    ]}*/}
              {/*    classes="form__group"*/}
              {/*    value={values.boardMemberAddressCountry}*/}
              {/*    onChange={e => {*/}
              {/*      handleChange(e);*/}
              {/*    }}*/}
              {/*    id="boardMemberAddressCountry"*/}
              {/*  />*/}
              {/*  <InputField*/}
              {/*    label={t(AddressTranslation + "City")}*/}
              {/*    classes="form__group"*/}
              {/*    value={values.boardMemberAddressStreet}*/}
              {/*    onChange={e => {*/}
              {/*      handleChange(e);*/}
              {/*    }}*/}
              {/*    id="boardMemberAddressCity"*/}
              {/*  />*/}
              {/*  <InputField*/}
              {/*    label={t(AddressTranslation + "Street")}*/}
              {/*    id="boardMemberAddressStreet"*/}
              {/*    value={values.boardMemberAddressStreet}*/}
              {/*    onChange={e => {*/}
              {/*      handleChange(e);*/}
              {/*    }}*/}
              {/*    classes="form__group"*/}
              {/*  />*/}
              {/*  <InputField*/}
              {/*    label={t(AddressTranslation + "House")}*/}
              {/*    id="boardMemberAddressHouse"*/}
              {/*    value={values.boardMemberAddressStreet}*/}
              {/*    onChange={e => {*/}
              {/*      handleChange(e);*/}
              {/*    }}*/}
              {/*    classes="form__group"*/}
              {/*  />*/}
              {/*  <InputField*/}
              {/*    label={t(AddressTranslation + "ZipCode")}*/}
              {/*    id="boardMemberAddressZipCode"*/}
              {/*    value={values.boardMemberAddressStreet}*/}
              {/*    onChange={e => {*/}
              {/*      handleChange(e);*/}
              {/*    }}*/}
              {/*    classes="form__group"*/}
              {/*  />*/}
              {/*</FormCol>*/}
            </FormBody>
          )}
        </Formik>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default CustomerFormAddresses;
