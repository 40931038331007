import React from "react";
import _ from "lodash";

const getActiveFilters = (filters, labels) => {
  let activeFilters = [];
  for (var propName in filters) {
    activeFilters.push({
      label: labels[propName],
      value: filters[propName].name ? filters[propName].name : filters[propName]
    });
  }
  return activeFilters;
};

export const AppliedFilters = ({ items, labels }) => {
  if (!_.isEmpty(items)) {
    const processedItems = getActiveFilters(items, labels);
    return (
      <div className="search__applied-filters">
        <div className="appliedFilters__container">
          <img
            className="appliedFilters__icon"
            src={process.env.PUBLIC_URL + "/assets/icons/filter.svg"}
            alt="filterIco"
          />
          {processedItems.map(item => {
            if (item.value !== "") {
              return (
                <React.Fragment key={item.value}>
                  <div className="appliedFilters__heading" />
                  <FilterItem
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                </React.Fragment>
              );
            } else return "";
          })}
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const FilterItem = ({ label, value }) => {
  return (
    <div className="filter-item">
      <p className="search__applied-filters--value">{value}</p>

      <label className="search__applied-filters--label">{label}</label>
    </div>
  );
};
