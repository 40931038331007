import React from "react";
import { Formik } from "formik";
import _ from "lodash";
import {
  BoolSelectInputField,
  FormBody,
  FormCol,
  FormFlexWrapper,
  InputField,
  SelectInputField,
  TextareaInputField
} from "../../../common/form/form";
import useVatApplicationsState from "../hooks/useVatApplicationsState";
import { useTranslation } from "react-i18next";

const VatApplicationsSectionDetailsForm = () => {
  const { t } = useTranslation();
  const { selectedRow: data } = useVatApplicationsState();
  const VatApplicationsDetailsTrans = "vat.applications.details.";
  console.log(data);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        isActive: data.isActiveCl || "",
        customer: data.customer ? data.customer[0].name : "",
        applicationNo: data.applicationNo || "",
        periodStart: data.periodStart || "",
        country: data.country ? t(`countries.${data.country[0].code}`) : "",
        bankAccountType: data.bankAccountTypeCl || "",
        commonFeeInPercent: data.commonFeeInPercent || "",
        commonFeeExternalInPercent: data.commonFeeExternalInPercent || "",
        additionalInfo: data.additionalInfo || "",
        status: data.statusCl || "",
        applicationDate: data.applicationDate || "",
        serviceProvider: data.serviceProvider
          ? data.serviceProvider[0].name
          : "",
        periodEnd: data.periodEnd || "",
        bankId: data.bankId || "",
        isAutomaticClearingAllowed: !_.isEmpty(data)
          ? data.isAutomaticClearingAllowed
            ? "true"
            : "false"
          : "",
        iban: data.iban || "",
        isEmailSent: data.isSentCl || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <BoolSelectInputField
                isCl={true}
                id="vatApplicationsDetailsIsActive"
                name="isActive"
                label={t(VatApplicationsDetailsTrans + "IsActive")}
                classes="form__group"
                onChange={handleChange}
                value={values.isActive}
              />
              <InputField
                type="text"
                id="vatApplicationsDetailsCustomer"
                name="customer"
                label={t(VatApplicationsDetailsTrans + "Customer")}
                classes="form__group"
                value={values.customer}
                onChange={handleChange}
              />
              <InputField
                type="text"
                id="vatApplicationsDetailsApplicationNo"
                name="applicationNo"
                label={t(VatApplicationsDetailsTrans + "ApplicationNo")}
                classes="form__group"
                value={values.applicationNo}
                onChange={handleChange}
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "PeriodStart")}
                classes="form__group"
                onChange={handleChange}
                value={values.periodStart}
                id="periodStart"
              />

              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "Country")}
                classes="form__group"
                onChange={handleChange}
                value={values.country}
                id="country"
              />
              <SelectInputField
                label={t(VatApplicationsDetailsTrans + "BankAccountType")}
                options={["personal", "P1 Swedbank LT"]}
                onChange={handleChange}
                classes="form__group"
                value={values.bankAccountType}
                id="bankAccountType"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "ServiceFeePercent")}
                onChange={handleChange}
                classes="form__group"
                value={values.commonFeeInPercent}
                id="serviceFeePercent"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <SelectInputField
                label={t(VatApplicationsDetailsTrans + "Status")}
                onChange={handleChange}
                options={[
                  { name: "", value: "" },
                  { name: "DRAFT", value: "VAT_APPLICATION_STATUS.DRAFT" }
                ]}
                classes="form__group"
                value={values.status}
                id="status"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "ApplicationDate")}
                onChange={handleChange}
                classes="form__group"
                value={values.applicationDate}
                id="applicationDate"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "ServiceProvider")}
                classes="form__group"
                onChange={handleChange}
                value={values.serviceProvider}
                id="serviceProvider"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "PeriodEnd")}
                classes="form__group"
                onChange={handleChange}
                value={values.periodEnd}
                id="periodEnd"
              />
              <SelectInputField
                label={t(VatApplicationsDetailsTrans + "Bank")}
                onChange={handleChange}
                options={["mBank"]}
                classes="form__group"
                value={values.bankId}
                id="bank"
              />
              <InputField
                type="text"
                label={t(
                  VatApplicationsDetailsTrans + "ServiceFeeExternalPercent"
                )}
                classes="form__group"
                onChange={handleChange}
                value={values.commonFeeExternalInPercent}
                id="serviceFeeExternalPercent"
              />
              <BoolSelectInputField
                label={t(VatApplicationsDetailsTrans + "AutomaticClearing")}
                classes="form__group"
                onChange={handleChange}
                value={values.isAutomaticClearingAllowed}
                id="automaticClearing"
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "PayoutToCustomer")}
                classes="form__group"
                onChange={handleChange}
                id="payoutToCustomer"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "PayoutToCustomerEur")}
                classes="form__group"
                onChange={handleChange}
                id="payoutToCustomerEUR"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "IncomeFromTaxOffice")}
                classes="form__group"
                onChange={handleChange}
                id="incomeFromTaxOffice"
              />
              <InputField
                type="text"
                label={t(
                  VatApplicationsDetailsTrans + "IncomeFromTaxOfficeEur"
                )}
                classes="form__group"
                onChange={handleChange}
                id="incomeFromTaxOfficeEur"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "ApplicationAmount")}
                classes="form__group"
                onChange={handleChange}
                id="applicationsAmount"
              />
              <InputField
                type="text"
                label={t(VatApplicationsDetailsTrans + "IBAN")}
                classes="form__group"
                onChange={handleChange}
                value={values.iban}
                id="iban"
              />
              <BoolSelectInputField
                label={t(VatApplicationsDetailsTrans + "IsEmailSent")}
                onChange={handleChange}
                isCl={true}
                name="isEmailSent"
                classes="form__group"
                value={values.isEmailSent}
                id="emailSent"
              />
            </FormCol>
          </FormFlexWrapper>
          <TextareaInputField
            type="text"
            classes="form__block form__group form__block--multiline"
            label={t(VatApplicationsDetailsTrans + "AdditionalInfo")}
            id="additionalInfo"
            value={values.additionalInfo}
            onChange={handleChange}
            textareaClasses="u-border-radius-3px"
          />
        </FormBody>
      )}
    </Formik>
  );
};

export default VatApplicationsSectionDetailsForm;
