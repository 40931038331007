import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  LOAD_TRANSACTIONS_SEARCH_DATA_START,
  SELECT_TRANSACTIONS_ROW
} from "../actions/transactionsActions";

const useTransactionsActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(
    payload => {
      dispatch(LOAD_TRANSACTIONS_SEARCH_DATA_START());
    },
    [dispatch]
  );

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_TRANSACTIONS_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useTransactionsActions;
