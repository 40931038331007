import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  BoolSelectInputField,
  DateInputField,
  InputField,
  SelectInputField
} from "../../../../common/form/form";
import { useTranslation } from "react-i18next";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import { getVatDocumentsData } from "../../actions/queries/vatDocumentsQueries";
import { VAT_DOCUMENTS } from "../../../../common/redux/types/viewTypes";
import { vatDocuments } from "../../../../common/redux/types/selectorTypes";
import useSearchState from "../../../../common/redux/hooks/useSearchState";

const VatDocumentsVerticalSearchForm = forwardRef(({ submitRef }, ref) => {
  const dispatch = useDispatch();
  const { selectedAutocompleteValue } = useSearchState(vatDocuments);
  const handleSubmit = values => {
    dispatch(getVatDocumentsData(values));
  };

  const { t } = useTranslation();

  return (
    <Formik
      ref={ref}
      enableReinitialize={true}
      initialValues={{
        count: 1000,
        customer: selectedAutocompleteValue || ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        validateField,
        validateOnChange,
        errors,
        touched,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomerAutocompleteInputField
            type="text"
            classes="form__group-stack"
            id="vatDocumentsSearchCustomer"
            name="customer"
            value={values.customer}
            handleChange={e => {
              handleChange(e);
            }}
            label={t("vat.documents.FilterCustomer")}
            autocomplete="password"
            data-lpignore="true"
            actionType={VAT_DOCUMENTS}
            selectorType={vatDocuments}
          />
          <SelectInputField
            id="vatDocumentsSearchCountry"
            name="country"
            label={t("vat.documents.FilterCountry")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <SelectInputField
            id="vatDocumentsSearchSeller"
            name="seller"
            label={t("vat.documents.FilterSeller")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="vatDocumentsSearchVatNo"
            name="vatNo"
            label={t("vat.documents.FilterVatNumber")}
            value={""}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <SelectInputField
            id="vatDocumentsSearchService1"
            name="service1"
            label={t("vat.documents.FilterService1")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <SelectInputField
            id="vatDocumentsSearchService2"
            name="service2"
            label={t("vat.documents.FilterService2")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="vatDocumentsSearchNetAmount"
            name="netAmount"
            label={t("vat.documents.FilterNetAmount")}
            value={""}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <DateInputField
            id="vatDocumentsSearchDocDateFrom"
            name="docDateFrom"
            label={t("vat.documents.FilterDocumentDateFrom")}
            value={""}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <DateInputField
            id="vatDocumentsSearchDocDateTo"
            name="docDateTo"
            label={t("vat.documents.FilterDocumentDateTo")}
            value={""}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <DateInputField
            id="vatDocumentsSearchCreatedFrom"
            name="created_from"
            label={t("vat.documents.FilterCreatedFrom")}
            value={""}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <DateInputField
            id="vatDocumentsSearchCreatedTo"
            name="created_to"
            label={t("vat.documents.FilterCreatedTo")}
            value={""}
            handleChange={setFieldValue}
            classes="form__group-stack"
          />
          <InputField
            id="vatDocumentsSearchDocNo"
            name="document_no"
            label={t("vat.documents.FilterDocumentNo")}
            value={""}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <InputField
            id="vatDocumentsSearchApplicationNo"
            name="application_no"
            label={t("vat.documents.FilterVatApplicationNo")}
            value={""}
            classes="form__group-stack"
            onChange={handleChange}
          />
          <BoolSelectInputField
            id="vatDocumentsSearchWoVatApplication"
            name="wo_vat_application"
            label={t("vat.documents.FilterWithoutVatApplication")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <SelectInputField
            id="vatDocumentsSearchCustomerCountry"
            name="customer_country"
            label={t("vat.documents.FilterCustomerCountry")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <SelectInputField
            id="vatDocumentsSearchSalesAgent"
            name="salesAgent"
            label={t("vat.documents.SalesAgent")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="vatDocumentsSearchIsSuspicious"
            name="is_suspicious"
            label={t("vat.documents.FilterIsSuspicious")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="vatDocumentsSearchIsActive"
            name="is_active"
            label={t("vat.documents.FilterIsActive")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="vatDocumentsSearchWoServices"
            name="wo_services"
            label={t("vat.documents.FilterWithoutServices")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="vatDocumentsSearchWoServiceProvider"
            name="wo_service_provider"
            label={t("vat.documents.FilterWithoutServiceProvider")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <BoolSelectInputField
            id="vatDocumentsSearchHasPdf"
            name="has_pdf"
            label={t("vat.documents.FilterHasPdf")}
            value={""}
            options={[]}
            onChange={handleChange}
            classes="form__group-stack"
          />
          <InputField
            id="vatDocumentsSearchResultCount"
            name="count"
            type="number"
            label={t("vat.documents.FilterRowCount")}
            value={values.count}
            classes="form__group-stack"
            onChange={handleChange}
          />
        </form>
      )}
    </Formik>
  );
});

export default VatDocumentsVerticalSearchForm;
