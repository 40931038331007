import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import { CLIENT_NO_EU, CUSTOMERS } from "../../../common/redux/types/viewTypes";

const ClientNoEUtable = ({ handleRowSelect, selectedRow }) => {
  const { t } = useTranslation();
  const { table } = useTabState(customers, "clientNoEuTaxOffice");
  const { handleTableHeightSet } = useTabTableActionDispatchers(
    CUSTOMERS,
    CLIENT_NO_EU
  );
  const ClientNoEUtrans = "customer.clientNoEU.";
  const columns = [
    {
      Header: t(ClientNoEUtrans + "CountryFilter"),
      accessor: "country"
    },
    {
      Header: t(ClientNoEUtrans + "ClientNoFilter"),
      accessor: "clientNo"
    }
  ];

  return (
    <Table
      classes="u-margin-top-small"
      height={table.height}
      setTableHeight={handleTableHeightSet}
      columns={columns}
      data={[]}
      selectedRow={selectedRow}
      onRowSelect={handleRowSelect}
    />
  );
};

export default ClientNoEUtable;
