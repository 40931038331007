import React from "react";
import { Button } from "../../../common/buttons/button";

const TransactionsButtons = () => {
  return (
    <React.Fragment>
      <Button btnStyle="primary" label="TODO" size="medium" />
    </React.Fragment>
  );
};

export default TransactionsButtons;
