import React, { useRef } from "react";
import { SearchSection } from "../../../../common/section";
import CustomerAccountVerticalSearchForm from "../../search/forms/customerAccountVerticalSearchForm";
import { useTranslation } from "react-i18next";
import useSearchState from "../../../../common/redux/hooks/useSearchState";
import { customerAccount } from "../../../../common/redux/types/selectorTypes";
import useLayoutState from "../../../../common/redux/hooks/useLayoutState";
import { loadCustomerAccountData } from "../../actions/data/customerAccountDataQueries";
import { useDispatch } from "react-redux";
import useLayoutActionDispatchers from "../../../../common/redux/actions/layout/hooks/useLayoutActionDispatchers";
import { CUSTOMER_ACCOUNT } from "../../../../common/redux/types/viewTypes";
import useDataState from "../../../../common/redux/hooks/useDataState";

const CustomerAccountSearchSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchQuery } = useSearchState(customerAccount);
  const { searchOpen } = useLayoutState(customerAccount);
  const { isLoading } = useDataState(customerAccount);
  const formRef = useRef();
  const onLoadCustomerAccountData = payload => {
    dispatch(loadCustomerAccountData(payload));
  };
  const { handleSearchToggle } = useLayoutActionDispatchers(CUSTOMER_ACCOUNT);
  return (
    <SearchSection
      isOpen={searchOpen}
      formRef={formRef}
      dataLoading={isLoading}
      onSearchToggle={handleSearchToggle}
    >
      <CustomerAccountVerticalSearchForm
        t={t}
        handleSearch={onLoadCustomerAccountData}
        query={searchQuery}
        ref={formRef}
      />
    </SearchSection>
  );
};

export default CustomerAccountSearchSection;
