import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  LOAD_SALESPOINT_DATA_START,
  SELECT_SALESPOINT_ROW
} from "../actions/main/salespointMainActions";

const useSalespointActions = () => {
  const dispatch = useDispatch();

  const handleSearch = useCallback(() => {
    dispatch(LOAD_SALESPOINT_DATA_START());
  }, [dispatch]);

  const handleRowSelect = useCallback(
    payload => {
      dispatch(SELECT_SALESPOINT_ROW(payload));
    },
    [dispatch]
  );

  return { handleSearch, handleRowSelect };
};

export default useSalespointActions;
