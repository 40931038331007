import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getClassifications, getInstallation } from "../actions/layoutActions";

const useAppActions = () => {
  const dispatch = useDispatch();
  const loadClassifications = useCallback(
    () => dispatch(getClassifications()),
    [dispatch]
  );

  const loadInstallation = useCallback(() => dispatch(getInstallation()), [
    dispatch
  ]);

  return { loadClassifications, loadInstallation };
};

export default useAppActions;
