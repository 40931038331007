import React from "react";
import _ from "lodash";
import { Button } from "../../../common/buttons/button";
import useServiceState from "../../hooks/useServiceState";
import { useTranslation } from "react-i18next";

const ServiceButtons = () => {
  const { t } = useTranslation();
  const { selectedRow } = useServiceState();
  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        classes="btn--footer"
        label={t("service.BtnAdd")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("service.BtnCancel")}
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("service.BtnDelete")}
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("service.BtnSave")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("service.BtnAddToInvoice")}
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        classes="btn--footer"
        label={t("service.ServicesToCsv")}
      />
    </React.Fragment>
  );
};

export default ServiceButtons;
