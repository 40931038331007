export const SELECT_CUSTOMER_ACCOUNT_ROW = payload => ({
  type: "SELECT_CUSTOMER_ACCOUNT_ROW",
  payload: payload
});

export const SELECT_CUSTOMER_ACCOUNT_CLEARING_ROW = payload => ({
  type: "SELECT_CUSTOMER_ACCOUNT_CLEARING_ROW",
  payload: payload
});

export const TOGGLE_CUSTOMER_ACCOUNT_ROW = payload => ({
  type: "TOGGLE_CUSTOMER_ACCOUNT_ROW",
  payload: payload
});

export const SET_CUSTOMER_ACCOUNT_TABLE_SELECTED_ROWS_STATE = payload => ({
  type: "SET_CUSTOMER_ACCOUNT_TABLE_SELECTED_ROWS_STATE",
  payload: payload
});

export const SET_CUSTOMER_TABS_STATE = payload => ({
  type: "SET_CUSTOMER_TABS_STATE",
  payload: payload
});

export const LOAD_CUSTOMER_ACCOUNT_ENTRY_SOURCE_TYPES_START = () => ({
  type: "LOAD_CUSTOMER_ACCOUNT_ENTRY_SOURCE_TYPES_START"
});

export const LOAD_CUSTOMER_ACCOUNT_ENTRY_SOURCE_TYPES_SUCCESS = payload => ({
  type: "LOAD_CUSTOMER_ACCOUNT_ENTRY_SOURCE_TYPES_SUCCESS",
  payload: payload
});
