import axios from 'axios';
import {extractQueryParameters} from "../../../services/query";
import {authorizationHeaders} from "../../../services/queries";
import {
    LOAD_DATA_ERROR,
    LOAD_DATA_START,
    LOAD_DATA_SUCCESS,
    SUBMIT_QUERY
} from "../../common/redux/actions/search/searchActions";
import {CARD} from "../../common/redux/types/viewTypes";


export const loadCards = payload => {

    return dispatch => {
        dispatch(SUBMIT_QUERY(CARD, payload));
        dispatch(LOAD_DATA_START(CARD));
        fetchCards(payload)
            .then(data =>
                dispatch(LOAD_DATA_SUCCESS(CARD, data.data.data.cards))
            )
            .catch(error => dispatch(LOAD_DATA_ERROR(CARD, error.message)));
    };
};

const fetchCards = async payload => {
    try{
    const queryData = extractQueryParameters(payload);
    const data = await axios.post(
        "/graphql",
        {
            query: `query {
  cards(${queryData}){
   id
    customer{name}
    chipCurrentStatusCl
    chipNo
    chipLastUsedDate
    magneticStripeNo
    magneticStripeCurrentStatusCl
    isActiveCl
    vehicleRegistrationNo
    vehicleRegistrationNoForInvoice
    chipLastUsedDate
    validFrom
    validThru
    cardType{cardTypeCl}
    cardChipStatusHistory{id statusCl cardId createdOn }
    additionalInfo
  }
}
        `
        },
        authorizationHeaders()
    );
    return data;
    }
    catch(err){
        throw new Error("Error loading data")
    }
};