import { useSelector } from "react-redux";

const useFactoringDebtorState = () => {
  const selectedRow = useSelector(
    state => state.factoringDebtor.data.selectedItem
  );
  const searchQuery = useSelector(state => state.factoringDebtor.search.query);

  return {
    selectedRow,
    searchQuery
  };
};

export default useFactoringDebtorState;
