import React from "react";
import Navigation from "../layout/navigation/navigation";
import NotificationBar from "./notificationBar/notificationBar";

const Layout = ({ children, classes = "" }) => {
  return (
    <div className={`layout ${classes}`}>
      <Navigation />
      <div className="content">
        <NotificationBar />

        <div className="section__wrapper">{children}</div>
      </div>
    </div>
  );
};
export default Layout;
