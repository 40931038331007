import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ServiceSectionDetailsForm from "../details/serviceSectionDetailsForm";
import useDataState from "../../common/redux/hooks/useDataState";
import {details, service} from "../../common/redux/types/selectorTypes";
import {DETAILS, SERVICE} from "../../common/redux/types/viewTypes";

const useServiceConsts = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(service);
  const filterLabels = {
    customer: t("service.CustomerName"),
    addToInvoice: t("service.FilterAddToInvoice"),
    serviceType: t("service.FilterAddToInvoice"),
    invoiceNo: t("service.FilterInvoiceNo"),
    servicesFrom: t("service.FilterServicesFrom"),
    servicesTo: t("service.FilterServicesTo"),
    isPrivate: t("service.FilterIsPrivate"),
    createdFrom: t("service.FilterCreatedFrom"),
    country: t("service.FilterCountry"),
    partner: t("service.FilterPartner"),
    withoutCustomerInvoice: t("service.FilterWithoutCustomerInvoice"),
    customerCountry: t("service.FilterCustomerCountry"),
    salesagent: t("service.SalesAgent"),
    resultCount: t("service.FilterRowCount")
  };

  const tabs = useMemo(
    () => [
      {
        label: t("service.ServiceDetailData"),
        component: <ServiceSectionDetailsForm t={t} data={selectedItem || {}} />,
        viewSelector: service,
        tabSelector: details,
        viewType: SERVICE,
        tabType: DETAILS
      }
    ],
    [selectedItem, t]
  );

  return { filterLabels, tabs };
};

export default useServiceConsts;
