import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const LogoutBtn = () => {
  const { t } = useTranslation();
  return (
    <div className="logout">
      <Link to="/logout">{t("layout.Logout")}</Link>
    </div>
  );
};
