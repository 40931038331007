import React from "react";
import _ from "lodash";
import { Button } from "../../../../common/buttons/button";
import useFactoringDebtorState from "../../hooks/useFactoringDebtorState";
import { useTranslation } from "react-i18next";

const FactoringDebtorButtons = () => {
  const { t } = useTranslation();
  const { selectedRow } = useFactoringDebtorState();
  return (
    <React.Fragment>
      <Button
        btnStyle="primary"
        size="small"
        label={t("factoring.debtor.BtnAdd")}
        classes="btn--footer"
      />
      <Button
        btnStyle="danger"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        label={t("factoring.debtor.BtnDelete")}
        classes="btn--footer"
      />
      <Button
        btnStyle="green"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        label={t("factoring.debtor.BtnSave")}
        classes="btn--footer"
      />
      <Button
        btnStyle="lime"
        size="small"
        disabled={_.isEmpty(selectedRow)}
        label={t("factoring.debtor.BtnToCsv")}
        classes="btn--footer"
      />
    </React.Fragment>
  );
};

export default FactoringDebtorButtons;
