import axios from "axios";
import {
  SUBMIT_CUSTOMER_ACCOUNT_QUERY,
  LOAD_CUSTOMER_ACCOUNT_DATA_START,
  LOAD_CUSTOMER_ACCOUNT_DATA_SUCCESS,
  LOAD_CUSTOMER_ACCOUNT_DATA_ERROR
} from "./customerAccountDataActions";
import { extractQueryParameters } from "../../../../../services/query";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../../services/queries";

export const loadCustomerAccountData = payload => {
  console.log(payload, "CUSTOMERACC PAYLOAD");
  return dispatch => {
    dispatch(SUBMIT_CUSTOMER_ACCOUNT_QUERY(payload));
    dispatch(LOAD_CUSTOMER_ACCOUNT_DATA_START());
    customerAccountQuery(payload)
      .then(data =>
        dispatch(
          LOAD_CUSTOMER_ACCOUNT_DATA_SUCCESS(data.data.data.customerAccounts)
        )
      )
      .catch(error => {
        toast.error(error.message);
        dispatch(LOAD_CUSTOMER_ACCOUNT_DATA_ERROR(error));
      });
  };
};

const customerAccountQuery = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
      query {
        customerAccounts(${queryData}) {
          entryBalance
          id
          entryNo
          customer {
            name
          }
          entryTime
          sum
          entrySourceTypeCl
          entrySource
          additionalInfo
          relatedCustomerClearingDocumentItems {
            id
            description
            balance
            sum
            created_on
            customer_account_id
            customer_clearing_document_id
            entry_time
            additional_info
          }
        }
    }
  
    `
    },
    authorizationHeaders()
  );
  return data;
};
