import {useSelector} from "react-redux";

const useDataState = selectorName => {
  const items = useSelector(state => state[selectorName].data.items);
  const selectedItem = useSelector(
    state => state[selectorName].data.selectedItem
  );
  const selectedRows = useSelector(
    state => state[selectorName].data.selectedRows
  );
  const isLoading = useSelector(state => state[selectorName].data.isLoading);

  return { items, selectedItem, selectedRows, isLoading };
};

export default useDataState;
