import React from "react";

const View = ({ MainSection, SearchSection }) => {
  return (
    <React.Fragment>
      <MainSection />
      <SearchSection />
    </React.Fragment>
  );
};

export default View;
