import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Table from "../common/table";
import {
  SELECT_CONTRACT_ROW,
  SET_CONTRACT_TABLE_SELECTED_ROWS_STATE,
  TOGGLE_CONTRACT_ROW
} from "./actions/contractActions";
import { TableResultData } from "../common/tableFooter";
import { useTranslation } from "react-i18next";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import useTableState from "../common/redux/hooks/useTableState";
import { contract } from "../common/redux/types/selectorTypes";
import { CONTRACT } from "../common/redux/types/viewTypes";
import useContractState from "./hooks/useContractState";

const ContractTable = ({ tableRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { height, selectedRowsState } = useTableState(contract);
  const { isLoading, contractData, selectedRow } = useContractState();
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(CONTRACT);

  const onSetSelectedRowsState = useCallback(
    payload => {
      dispatch(SET_CONTRACT_TABLE_SELECTED_ROWS_STATE(payload));
    },
    [dispatch]
  );

  const onContractRowSelect = useCallback(
    payload => {
      dispatch(SELECT_CONTRACT_ROW(payload));
    },
    [dispatch]
  );

  const onToggleRow = useCallback(
    payload => {
      dispatch(TOGGLE_CONTRACT_ROW(payload));
    },
    [dispatch]
  );

  const getColumns = useCallback(() => {
    const ContractViewTrans = "contract.";
    const columns = [
      {
        id: "contractTypeCl",
        Header: t(ContractViewTrans + "ContractType"),
        accessor: d =>
          t(`classifications.CONTRACT_TYPE.${d.contractTypeCl.split(".")[1]}`)
      },
      {
        Header: t(ContractViewTrans + "ContractNo"),
        accessor: "contractNo"
      },
      {
        Header: t(ContractViewTrans + "ValidFrom"),
        accessor: "validFrom",
        width: 100
      },
      {
        id: "validThru",
        Header: t(ContractViewTrans + "ValidThru"),
        accessor: d => d.validThru || ""
      },
      {
        id: "customer",
        Header: t(ContractViewTrans + "CustomerName"),
        accessor: d => d.customer[0].name
      },
      {
        id: "currentStatusCl",
        Header: t(ContractViewTrans + "ContractStatus"),
        accessor: d =>
          t(
            `classifications.CONTRACT_STATUS.${d.currentStatusCl.split(".")[1]}`
          )
      },
      {
        id: "reseller",
        Header: t(ContractViewTrans + "ResellerName"),
        accessor: d =>
          d.reseller ? (d.reseller.length ? d.reseller[0].name : "") : ""
      },
      {
        id: "serviceProvider",
        Header: t(ContractViewTrans + "ServiceProviderName"),
        accessor: d => d.serviceProvider[0].name
      }
    ];

    return columns;
  }, [t]);

  const tableColumns = useMemo(() => getColumns(), [getColumns]);

  return (
    <React.Fragment>
      <Table
        columns={tableColumns}
        height={height}
        setTableHeight={handleTableHeightSet}
        selectedRowsState={selectedRowsState}
        setSelectedRowsState={onSetSelectedRowsState}
        isLoading={isLoading}
        data={contractData}
        onRowSelect={onContractRowSelect}
        selectedRow={selectedRow}
        onToggleRow={onToggleRow}
        tableRef={tableRef}
      />
      <TableResultData
        count={contractData.length}
        selectedRows={[]}
        onRowSelect={onContractRowSelect}
        selectedRow={selectedRow}
        onRemoveItem={[]}
      />
    </React.Fragment>
  );
};

export default ContractTable;
