export const SELECT_TAB_ROW = (viewName, tabName, payload) => ({
  type: `SELECT_${viewName}_${tabName}_ROW`,
  payload: payload
});

export const SET_TAB_TABLE_HEIGHT = (viewName, tabName, payload) => ({
  type: `SET_${viewName}_${tabName}_TABLE_HEIGHT`,
  payload: payload
});

export const SELECT_TABLE_ROW = (name, payload) => ({
  type: `SELECT_${name}_ROW`,
  payload: payload
});

export const SELECT_TAB_TABLE_ROW = (viewName, tabName, payload) => ({
  type: `SELECT_${viewName}_${tabName}_ROW`,
  payload: payload
});

export const TOGGLE_TABLE_ROW = (name, payload) => ({
  type: `TOGGLE_${name}_ROW`,
  payload: payload
});
