import React from "react";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";
import { COMMENTS, CUSTOMERS } from "../../../common/redux/types/viewTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";

const CustomerCommentsTable = ({ data, t }) => {
  const { table } = useTabState(customers, "comments");

  const { selectedItem } = useTabState(customers, "comments");
  const { handleTableHeightSet } = useTabTableActionDispatchers(
    CUSTOMERS,
    COMMENTS
  );
  const CustomerComments = "customer.customerComments.";
  const columns = [
    {
      Header: t(CustomerComments + "HeaderModifiedOn"),
      accessor: "createdOn",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderModifiedByUser"),
      accessor: "createdByUserId",
      width: 80
    },
    {
      Header: t(CustomerComments + "HeaderComment"),
      accessor: "comment",
      width: 900
    }
  ];

  return (
    <Table
      classes="u-margin-top-small"
      height={table.height}
      setTableHeight={handleTableHeightSet}
      columns={columns}
      data={data.customerComments || []}
      selectedRow={selectedItem}
      onRowSelect={() => {}}
    />
  );
};

export default CustomerCommentsTable;
