import React, { forwardRef } from "react";
import { Formik } from "formik";
import CustomerAutocompleteInputField from "../../../../common/autocomplete/customerAutocompleteField";
import { CUSTOMERS } from "../../../../common/redux/types/viewTypes";
import { customers } from "../../../../common/redux/types/selectorTypes";
import {
  BoolSelectInputField,
  DateInputField,
  InputField
} from "../../../../common/form/form";
import { useTranslation } from "react-i18next";

const FactoringDebtorOverdueInvoicesSearchForm = forwardRef(
  ({ handleSearch }, ref) => {
    const { t } = useTranslation();
    const TranslationNS = "factoring.debtor.overdueInvoices.";

    return (
      <div className="section__search-form">
        <div style={{ height: "100%", overflow: "visible" }}>
          <Formik
            ref={ref}
            enableReinitialize={true}
            // validationSchema={CustomerSearchSchema}
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              handleSearch(values);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              validateField,
              validateOnChange,
              errors,
              touched,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <CustomerAutocompleteInputField
                  type="text"
                  classes="form__group-stack"
                  id="name"
                  value={values.name}
                  handleChange={e => {
                    handleChange(e);
                  }}
                  label={t(TranslationNS + "FilterFactoringDebtor")}
                  autocomplete="password"
                  data-lpignore="true"
                  actionType={CUSTOMERS}
                  selectorType={customers}
                />

                <InputField
                  id="factoringDebtorOverdueInvoicesInvoiceNo"
                  type="text"
                  classes="form__group-stack"
                  value={values.id}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterInvoiceNo")}
                />
                <InputField
                  type="text"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesEmailSent"
                  value={values.regNo}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterEmailSent")}
                />
                <DateInputField
                  type="date"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesInvoiceDateFrom"
                  name="validFrom"
                  placement="top"
                  value={values.validFrom}
                  handleChange={setFieldValue}
                  label={t(TranslationNS + "FilterInvoiceDateFrom")}
                />

                <DateInputField
                  type="date"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesInvoiceDateTo"
                  name="validTo"
                  placement="top"
                  value={values.validFrom}
                  handleChange={setFieldValue}
                  label={t(TranslationNS + "FilterInvoiceDateTo")}
                />

                <BoolSelectInputField
                  classes="form__group-stack"
                  name="is_active"
                  id="factoringDebtorOverdueInvoicesIsPaid"
                  value={values.is_active}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterIsPaid")}
                />

                <BoolSelectInputField
                  classes="form__group-stack"
                  name="is_active"
                  id="factoringDebtorOverdueInvoicesInvoiceNotIssued"
                  value={values.is_active}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterInvoiceNotIssued")}
                />

                <InputField
                  type="number"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesIssuedForInvoiceNo"
                  name="count"
                  value={values.count}
                  // error={errors.count}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterIssuedForInvoiceNo")}
                />
                <InputField
                  type="number"
                  classes="form__group-stack"
                  id="factoringDebtorOverdueInvoicesResultCount"
                  name="count"
                  value={values.count}
                  onChange={handleChange}
                  label={t(TranslationNS + "FilterRowCount")}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
);

export default FactoringDebtorOverdueInvoicesSearchForm;
