import {useCallback} from 'react';
import {useDispatch} from "react-redux";
import {SET_TABLE_HEIGHT} from "../../layoutActions";

const useTableLayoutActionDispatchers = name => {
  const dispatch = useDispatch();

  const handleTableHeightSet = useCallback(payload => {
    dispatch(SET_TABLE_HEIGHT(name, payload));
  }, [dispatch, name]);

  return { handleTableHeightSet };
};

export default useTableLayoutActionDispatchers;
