import React from "react";
import { Formik } from "formik";
import { FormBody } from "../../../common/form/form";
import { ToggleSwitchWLabel } from "../../../common/buttons/toggleSwitch";

const PreferencesGeneralForm = () => {
  const changeFunc = value => {
    console.log(value);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <ToggleSwitchWLabel
            label="Navbar default state?"
            handleOnChange={changeFunc}
          />
        </FormBody>
      )}
    </Formik>
  );
};

export default PreferencesGeneralForm;
