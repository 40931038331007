import axios from "axios";
import { toast } from "react-toastify";
import { authorizationHeaders } from "../../../../services/queries";
import {extractQueryParameters} from "../../../../services/query";
export const SELECT_CUSTOMER_INVOICES_ROW = payload => ({
  type: "SELECT_CUSTOMER_INVOICES_ROW",
  payload: payload
});
export const TOGGLE_CUSTOMER_INVOICES_SEARCH = () => ({
  type: "TOGGLE_CUSTOMER_INVOICES_SEARCH"
});

export const TOGGLE_CUSTOMER_INVOICES_ROW = payload => ({
  type: "TOGGLE_CUSTOMER_INVOICES_ROW",
  payload: payload
});

export const TOGGLE_CUSTOMER_INVOICES_TABLE = () => ({
  type: "TOGGLE_CUSTOMER_INVOICES_TABLE"
});

export const TOGGLE_CUSTOMER_INVOICES_EXPANDERS = () => ({
  type: "TOGGLE_CUSTOMER_INVOICES_EXPANDERS"
});

export const SUBMIT_CUSTOMER_INVOICES_QUERY = payload => ({
  type: "SUBMIT_CUSTOMER_INVOICES_QUERY",
  payload: payload
});

export const LOAD_CUSTOMER_INVOICES_DATA_START = () => ({
  type: "LOAD_CUSTOMER_INVOICES_DATA_START"
});

export const LOAD_CUSTOMER_INVOICES_DATA_SUCCESS = payload => ({
  type: "LOAD_CUSTOMER_INVOICES_DATA_SUCCESS",
  payload: payload
});

export const LOAD_CUSTOMER_INVOICES_DATA_ERROR = error => ({
  type: "LOAD_CUSTOMER_INVOICES_DATA_ERROR",
  payload: error
});

export const SELECT_CUSTOMER_INVOICES_STATUS_HISTORY_ROW = payload => ({
  type: "SELECT_CUSTOMER_INVOICES_STATUS_HISTORY_ROW",
  payload: payload
});

export const SELECT_CUSTOMER_INVOICES_PAYMENT_HISTORY_ROW = payload => ({
  type: "SELECT_CUSTOMER_INVOICES_PAYMENT_HISTORY_ROW",
  payload: payload
});

export const SET_CUSTOMER_INVOICES_TABLE_HEIGHT = payload => ({
  type: "SET_CUSTOMER_INVOICES_TABLE_HEIGHT",
  payload: payload
});

export const SET_CUSTOMER_INVOICES_TABLE_SELECTED_ROWS_STATE = payload => ({
  type: "SET_CUSTOMER_INVOICES_TABLE_SELECTED_ROWS_STATE",
  payload: payload
});

export const SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN = payload => ({
  type: "SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN",
  payload: payload
});

export const getCustomerInvoiceQuery = payload => {
  return dispatch => {
    dispatch(SUBMIT_CUSTOMER_INVOICES_QUERY(payload));
    dispatch(LOAD_CUSTOMER_INVOICES_DATA_START());
    CustomerInvoiceQuery(payload)
      .then(data => dispatch(LOAD_CUSTOMER_INVOICES_DATA_SUCCESS(data)))
      .catch(error => {
        dispatch(LOAD_CUSTOMER_INVOICES_DATA_ERROR(error));
        toast.error(error.message);
      });
  };
};

export const getCustomerInvoicesDropdownData = () => {
  return dispatch => {
    getCustomerInvoiceCountriesDropdownData().then(d =>
      dispatch(SET_CUSTOMER_INVOICES_COUNTRIES_DROPDOWN(d))
    );
  };
};

//QUERIES

export const getCustomerInvoiceCountriesDropdownData = async () => {
  try {
    const data = await axios.post(
      "/graphql",
      {
        query: `
    query {
      countries {
        id
        code
      }
     }
`
      },
      authorizationHeaders()
    );
    return data.data.data.countries;
  } catch (err) {
    toast.error("Error loading countries");
    return [];
  }
};

export const CustomerInvoiceQuery = async payload => {
  const queryData = extractQueryParameters(payload);
  const data = await axios.post(
    "/graphql",
    {
      query: `
    query {
      customerInvoices(${queryData}) {
        id
        company{name}
        customerId
        invoiceNo
        totalInEur
        invoiceDate
        dueDate
        netto
        currentStatusCl
        vat
        reseller{name}
        exchangeRateToEur
        issuedCountryId
        currencyCl
        totalInSecondCurrency
        customerInvoicePaymentHistory {
          id,
          paymentTime,
          payment,
          invoiceBalance,
          paymentBalance
        }
        customerInvoiceStatusHistory {
          id
          statusCl
          createdOn
          createdByUser {
            id
            firstName
          }
        }
      }
  }
  `
    },
    authorizationHeaders()
  );
  return data.data.data.customerInvoices;
};

const getCustomerInvoiceQueryData = ({
  resultCount,
  customer,
  invoiceNo,
  isPaid,
  isVirtual,
  issuedCountry,
  invoiceStatus,
  customer_country,
  reseller,
  dateFrom,
  dueDateUntil,
  dateTo
}) => {
  const queryData = `${resultCount ? `count: ${resultCount}` : ""} ${
    customer ? `customer: "${customer}"` : ""
  } ${invoiceNo ? `invoice: "${invoiceNo}"` : ""} 
  ${issuedCountry ? `issued_country: ${issuedCountry}` : ""}
  ${isPaid ? `is_it_paid: "${isPaid}"` : ""} 
  ${invoiceStatus ? `invoice_status: "${invoiceStatus}"` : ""}
  ${reseller ? `reseller: ${reseller}` : ""}
  ${customer_country ? `customer_country: ${customer_country}` : ""}
  ${dueDateUntil ? `due_date_until: "${dueDateUntil}"` : ""}
  ${isVirtual ? `is_it_virtual: "${isVirtual}"` : ""} ${
    dateFrom ? `date_from: "${dateFrom}"` : ""
  } ${dateTo ? `date_to: "${dateTo}"` : ""}`;
  return queryData;
};
