import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomerAccountDetails from "../details/customerAccountDetails";
import CustomerAccountClearingTable from "../clearing/customerAccountClearingTable";
import useDataState from "../../../common/redux/hooks/useDataState";
import {clearing, customerAccount, details} from "../../../common/redux/types/selectorTypes";
import {CLEARING, CUSTOMER_ACCOUNT, DETAILS} from "../../../common/redux/types/viewTypes";

const useCustomerAccountConsts = () => {
  const { t } = useTranslation();
  const { selectedItem } = useDataState(customerAccount);
  const filterLabels = {
    client: t("customerAccount.CustomerName"),
    count: t("customerAccount.ResultsNumber"),
    period_start: t("customerAccount.PeriodStart"),
    period_end: t("customerAccount.PeriodEnd"),
    transaction_entry: t("customerAccount.EntrySourceType"),
    has_entry_balance: t("customerAccount.FilterHasEntryBalance"),
    money_received: t("customerAccount.IsMoneyReceived")
  };

  const customerAccountTabs = useMemo(
    () => [
      {
        label: t("customerAccount.CustomerAccountDetails"),
        component: <CustomerAccountDetails t={t} />,
          viewSelector: customerAccount,
          tabSelector: details,
          viewType: CUSTOMER_ACCOUNT,
          tabType: DETAILS
      },
      {
        label: t("customerAccount.CustomerAccountClearing"),
        component: (
          <CustomerAccountClearingTable
            t={t}
            data={
              selectedItem
                ? selectedItem.relatedCustomerClearingDocumentItems
                : []
            }
          />
        ),
          viewSelector: customerAccount,
          tabSelector: clearing,
          viewType: CUSTOMER_ACCOUNT,
          tabType: CLEARING
      }
    ],
    [selectedItem, t]
  );

  return { filterLabels, customerAccountTabs };
};

export default useCustomerAccountConsts;
