import React, { Component } from "react";
import { Formik } from "formik";
import { FormBody } from "../../common/form/form";

class PartnerSectionProductsAndPriceConditionsForm extends Component {
  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => <FormBody flex={"true"}>TODO</FormBody>}
      </Formik>
    );
  }
}

export default PartnerSectionProductsAndPriceConditionsForm;
