export const SELECT_CUSTOMER_DEPOSIT_ROW = payload => ({
  type: "SELECT_CUSTOMER_DEPOSIT_ROW",
  payload: payload
});

export const TOGGLE_CUSTOMER_DEPOSIT_ROW = () => ({
  type: "TOGGLE_CUSTOMER_DEPOSIT_ROW"
});

export const LOAD_CUSTOMER_DEPOSIT_DATA_START = () => ({
  type: "LOAD_CUSTOMER_DEPOSIT_DATA_START"
});

export const LOAD_CUSTOMER_DEPOSIT_DATA_SUCCESS = payload => ({
  type: "LOAD_CUSTOMER_DEPOSIT_DATA_SUCCESS",
  payload: payload
});

export const LOAD_CUSTOMER_DEPOSIT_DATA_ERROR = payload => ({
  type: "LOAD_CUSTOMER_DEPOSIT_DATA_ERROR",
  payload: payload
});

export const SUBMIT_CUSTOMER_DEPOSIT_QUERY = payload => ({
  type: "SUBMIT_CUSTOMER_DEPOSIT_QUERY",
  payload: payload
});
