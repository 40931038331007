import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../common/form/form";
import useContractSearchDropdowns from "../hooks/useContractSearchDropdowns";
import CustomerAutocompleteInputField from "../../../common/autocomplete/customerAutocompleteField";
import { CONTRACT } from "../../../common/redux/types/viewTypes";
import { contract } from "../../../common/redux/types/selectorTypes";
import useSearchState from "../../../common/redux/hooks/useSearchState";

const ContractVerticalSearchForm = forwardRef(
  ({ handleSearch, t, query, resellers, serviceProviders }, ref) => {
    const {
      getContractStatusesData,
      getContractTypesData,
      getCountriesData
    } = useContractSearchDropdowns();

    const { selectedAutocompleteValue } = useSearchState(contract);

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          resultCount: query.resultCount || 1000,
          customerName: selectedAutocompleteValue || "",
          customerNo: query.customerNo || "",
          contractType: query.contractType || "",
          contractNo: query.contractNo || "",
          contractStatus: query.contractStatus || "",
          contractExpiresFrom: query.contractExpiresFrom || "",
          contractExpiresUntil: query.contractExpiresUntil || "",
          contractReseller: query.contractReseller || "",
          contractServiceProvider: query.contractServiceProvider || "",
          customerCountry: query.customerCountry || "",
          salesagent: query.salesagent || "",
          contractIsActive: query.contractIsActive ? query.contractIsActive : ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="flex__direction--column" onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="contractSearchCustomer"
              name="customerName"
              value={values.customerName}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("contract.FilterContractCustomer")}
              autocomplete="password"
              data-lpignore="true"
              actionType={CONTRACT}
              selectorType={contract}
            />

            <InputField
              type="text"
              classes="form__group-stack"
              id="customerNo"
              value={values.customerNo}
              onChange={handleChange}
              label={t("contract.FilterCustomerNo")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              id="contractType"
              options={getContractTypesData}
              value={values.contractType}
              onChange={handleChange}
              label={t("contract.FilterContractType")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="contractNo"
              value={values.contractNo}
              onChange={handleChange}
              label={t("contract.FilterContractNo")}
            />
            <SelectInputField
              type="text"
              classes="form__group-stack"
              id="contractStatus"
              options={getContractStatusesData}
              value={values.contractStatus}
              onChange={handleChange}
              label={t("contract.FilterContractStatus")}
            />

            <DateInputField
              type="date"
              classes="form__group-stack"
              id="contractExpiresFrom"
              value={values.contractExpiresFrom}
              handleChange={setFieldValue}
              label={t("contract.FilterContractExpiresFrom")}
            />
            <DateInputField
              type="date"
              classes="form__group-stack"
              id="contractExpiresUntil"
              value={values.contractExpiresUntil}
              handleChange={setFieldValue}
              label={t("contract.FilterContractExpiresUntil")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...resellers]}
              id="contractReseller"
              value={values.contractReseller}
              onChange={handleChange}
              label={t("contract.FilterContractReseller")}
            />

            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...serviceProviders]}
              id="contractServiceProvider"
              value={values.contractServiceProvider}
              onChange={handleChange}
              label={t("contract.FilterContractServiceProvider")}
            />
            <SelectInputField
              classes="form__group-stack"
              customNameKey="code"
              customValueKey="id"
              id="customerCountry"
              options={getCountriesData}
              value={values.customerCountry}
              onChange={handleChange}
              label={t("contract.FilterCustomerCountry")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={["", "yes", "no"]}
              id="contractSalesagent"
              value={values.salesagent}
              onChange={handleChange}
              label={t("contract.FilterSalesAgent")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "BOOLEAN.YES" },
                { name: t("common.ReplyNo"), value: "BOOLEAN.NO" }
              ]}
              id="contractIsActive"
              value={values.contractIsActive}
              onChange={handleChange}
              label={t("contract.FilterIsActive")}
            />
            <InputField
              type="text"
              classes="form__group-stack"
              id="resultCount"
              value={values.resultCount}
              onChange={handleChange}
              label={t("contract.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);

export default ContractVerticalSearchForm;
