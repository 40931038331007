import React from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import {
  InputField,
  FormCol,
  FormBody,
  FormFlexWrapper,
  FormGroupBlockMultiline,
  SelectInputField
} from "../../common/form/form";
import { getNestedItem } from "../../../services/arrayFunctions";
import useServiceState from "../hooks/useServiceState";

const ServiceSectionDetailsForm = ({ t, data }) => {
  const ServiceDetailsTrans = "service.details.";
  const countries = useSelector(
    state => state.layout.classifications.countries
  );

  const { serviceTypeNames: serviceTypes } = useServiceState();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        addToInvoice: data.addToInvoice || "",
        customer: data.customer ? data.customer[0].name : "",
        serviceDate: data.serviceDate || "",
        unitPrice: data.unitPrice || "",
        quantity: data.quantity || "",
        country: getNestedItem(data.country, "code") || "None",
        serviceType: getNestedItem(data.serviceType, "name"),
        total: data.total || "",
        additionalInfo: data.additionalInfo || "",
        partner: getNestedItem(data.partner, "name")
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <FormBody>
          <FormFlexWrapper>
            <FormCol>
              <SelectInputField
                label={t(ServiceDetailsTrans + "AddToInvoice")}
                classes="form__group"
                id="serviceAddToInvoice"
                options={[
                  { name: "", value: "" },
                  { name: t("common.ReplyYes"), value: "true" },
                  { name: t("common.ReplyNo"), value: "false" }
                ]}
                onChange={handleChange}
                value={values.addToInvoice}
              ></SelectInputField>
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "CustomerName")}
                value={values.customer}
                id="customer"
                onChange={handleChange}
              />
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "ServiceQuantity")}
                id="serviceDetailsQuantity"
                onChange={handleChange}
                value={values.quantity}
              />
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "Partner")}
                id="serviceDetailsPartner"
                value={values.partner}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "CustomerInvoiceNo")}
                id="serviceDetailsInvoiceNo"
                onChange={handleChange}
              />
              <SelectInputField
                type="text"
                classes="form__group"
                options={[
                  { name: "", value: "" },
                  ...serviceTypes.map(item => {
                    return Object.assign(
                      {},
                      { name: item.name, value: item.name }
                    );
                  })
                ]}
                label={t(ServiceDetailsTrans + "ServiceType")}
                id="serviceDetailsServiceType"
                value={values.serviceType}
                onChange={handleChange}
              />
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "ServiceUnitPrice")}
                id="serviceDetailsUnitPrice"
                onChange={handleChange}
                value={values.unitPrice}
              />
              <SelectInputField
                type="text"
                customNameKey="code"
                options={[
                  { code: "", id: "" },
                  ...countries.map(item => {
                    return Object.assign(
                      {},
                      { code: t(`countries.${item.code}`), value: item.code }
                    );
                  })
                ]}
                classes="form__group"
                label={t(ServiceDetailsTrans + "FilterCountry")}
                id="country"
                value={values.country}
                onChange={handleChange}
              />
            </FormCol>
            <FormCol className="u-margin-left-small">
              <InputField
                type="date"
                classes="form__group"
                label={t(ServiceDetailsTrans + "ServiceDate")}
                id="serviceDetailsServiceDate"
                onChange={handleChange}
                value={values.serviceDate}
              />
              <InputField
                type="text"
                classes="form__group"
                label={t(ServiceDetailsTrans + "ServiceSum")}
                id="total"
                value={values.total}
                onChange={handleChange}
              />
              <h4 className="heading__quaternary">
                {t(ServiceDetailsTrans + "ForEstonianCustomersAddedVat")}
              </h4>
            </FormCol>
          </FormFlexWrapper>
          <FormGroupBlockMultiline>
            <label>Additional info</label>
            <textarea
              type="text"
              name="brokerAdditionalInfo"
              onChange={handleChange}
              value={values.additionalInfo}
              id="serviceDetailsAdditionalInfo"
            />
          </FormGroupBlockMultiline>
        </FormBody>
      )}
    </Formik>
  );
};

export default ServiceSectionDetailsForm;
