import React, { forwardRef } from "react";
import { Formik } from "formik";
import {
  InputField,
  SelectInputField,
  DateInputField
} from "../../../common/form/form";
import useServiceState from "../../hooks/useServiceState";
import CustomerAutocompleteInputField from "../../../common/autocomplete/customerAutocompleteField";
import { SERVICE } from "../../../common/redux/types/viewTypes";
import { service } from "../../../common/redux/types/selectorTypes";
import useSearchState from "../../../common/redux/hooks/useSearchState";

const ServiceSearchVerticalForm = forwardRef(
  ({ t, query, handleSearch }, ref) => {
    const { countriesDropdownData, serviceTypeNames } = useServiceState();

    const { selectedAutocompleteValue } = useSearchState(service);

    const serviceTypeNamesData = serviceTypeNames.map(item => {
      return Object.assign({}, { name: item.name, value: item.serviceTypeId });
    });

    return (
      <Formik
        ref={ref}
        enableReinitialize={true}
        initialValues={{
          resultCount: query.resultCount || 1000,
          customer: selectedAutocompleteValue || "",
          addToInvoice: query.addToInvoice ? query.addToInvoice : "",
          serviceType: query.serviceType || "",
          invoiceNo: query.invoiceNo || "",
          servicesFrom: query.servicesFrom || "",
          servicesTo: query.servicesTo || "",
          isPrivate: query.isPrivate ? query.isPrivate : "",
          createdFrom: query.createdFrom || "",
          country: query.country || "",
          partner: query.partner || "",
          withoutCustomerInvoice: query.withoutCustomerInvoice
            ? query.withoutCustomerInvoice
            : "",
          customerCountry: query.customerCountry || "",
          salesagent: query.salesagent || ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSearch(values);
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomerAutocompleteInputField
              type="text"
              classes="form__group-stack"
              id="serviceSearchCustomer"
              name="customer"
              value={values.customer}
              handleChange={e => {
                handleChange(e);
              }}
              label={t("customer.customerView.FilterCustomerName")}
              autocomplete="password"
              data-lpignore="true"
              actionType={SERVICE}
              selectorType={service}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                { name: t("common.ReplyNo"), value: "false" }
              ]}
              id="addToInvoice"
              label={t("service.FilterAddToInvoice")}
              value={values.addToInvoice}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[{ name: "", value: "" }, ...serviceTypeNamesData]}
              id="serviceType"
              label={t("service.FilterServiceType")}
              value={values.serviceType}
              onChange={handleChange}
            />
            <InputField
              type="text"
              id="invoiceNo"
              onChange={handleChange}
              value={values.invoiceNo}
              classes="form__group-stack"
              label={t("service.FilterInvoiceNo")}
            />
            <DateInputField
              type="date"
              id="servicesFrom"
              value={values.servicesFrom}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterServicesFrom")}
            />
            <DateInputField
              type="date"
              id="servicesTo"
              value={values.servicesTo}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterServicesTo")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: t("BOOLEAN.YES"), value: "BOOLEAN.YES" },
                { name: t("BOOLEAN.NO"), value: "BOOLEAN.NO" }
              ]}
              id="isPrivate"
              label={t("service.FilterIsPrivate")}
              value={values.isPrivate}
              onChange={handleChange}
            />
            <DateInputField
              type="date"
              id="createdFrom"
              value={values.createdFrom}
              handleChange={setFieldValue}
              classes="form__group-stack"
              label={t("service.FilterCreatedFrom")}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                ...countriesDropdownData.map(item => {
                  return Object.assign(
                    {},
                    {
                      name: t(`countries.${item.code}`),
                      value: item.code
                    }
                  );
                })
              ]}
              id="country"
              label={t("service.FilterCountry")}
              value={values.country}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: "Port One", value: "p1" }
              ]}
              id="partner"
              label={t("service.FilterPartner")}
              value={values.partner}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              label={t("service.FilterWithoutCustomerInvoice")}
              id="withoutCustomerInvoice"
              options={[
                { name: "", value: "" },
                { name: t("common.ReplyYes"), value: "true" },
                { name: t("common.ReplyNo"), value: "false" }
              ]}
              onChange={handleChange}
              value={values.withoutCustomerInvoice}
            />
            <SelectInputField
              classes="form__group-stack"
              customNameKey="code"
              customValueKey="id"
              options={
                countriesDropdownData.length
                  ? [
                      { code: "", id: "" },
                      ...countriesDropdownData.map(item => {
                        return Object.assign(
                          {},
                          { code: t(`countries.${item.code}`), id: item.id }
                        );
                      })
                    ]
                  : [{ code: "", id: "" }]
              }
              id="customerCountry"
              label={t("service.FilterCustomerCountry")}
              value={values.customerCountry}
              onChange={handleChange}
            />
            <SelectInputField
              classes="form__group-stack"
              options={[
                { name: "", value: "" },
                { name: "Armands", value: "armands" }
              ]}
              id="salesagent"
              label={t("service.SalesAgent")}
              value={values.salesagent}
              onChange={handleChange}
            />
            <InputField
              type="text"
              id="resultCount"
              value={values.resultCount}
              onChange={handleChange}
              classes="form__group-stack"
              label={t("service.FilterRowCount")}
            />
          </form>
        )}
      </Formik>
    );
  }
);
export default ServiceSearchVerticalForm;
