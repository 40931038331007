import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import { SELECT_FACTORING_DEBTOR_CONTACT_PERSON_ROW } from "../actions/factoringDebtorActions";

const FactoringDebtorContactPersonsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedRow = useSelector(
    state => state.factoringDebtor.tabs.contactInformation.selectedItem
  );
  const data = useSelector(
    state => state.factoringDebtor.data.selectedItem.customerContactPerson
  );

  const handleRowSelect = payload => {
    dispatch(SELECT_FACTORING_DEBTOR_CONTACT_PERSON_ROW(payload));
  };

  const getColumns = () => {
    const columns = [
      {
        Header: t("customer.customerContactData.PersonName"),
        accessor: "name"
      },
      {
        id: "contactPersonTypeCl",
        Header: t("customer.customerContactData.Position"),
        accessor: d => {
          return t(
            `classifications.CONTACT_PERSON_TYPE.${
              d.contactPersonTypeCl.split(".")[1]
            }`
          );
        }
      },
      {
        Header: t("customer.customerContactData.PersonSignatory"),
        accessor: "canSign"
      },
      {
        Header: t("customer.customerContactData.Primary"),
        accessor: "primary"
      }
    ];
    return columns;
  };

  return (
    <Table
      columns={getColumns()}
      selectedRow={selectedRow}
      onRowSelect={handleRowSelect}
      height={200}
      data={data || []}
    />
  );
};
export default FactoringDebtorContactPersonsTable;
