import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table";
import { SET_CUSTOMERS_COMMENTS_TABLE_HEIGHT } from "../actions/customersActions";
import useTabState from "../../../common/redux/hooks/useTabState";
import { customers } from "../../../common/redux/types/selectorTypes";

const CustomerContractOverviewTable = ({ handleRowSelect, selectedRow }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { table } = useTabState(customers, "contractOverview");
  const onSetTableHeight = payload => {
    dispatch(SET_CUSTOMERS_COMMENTS_TABLE_HEIGHT(payload));
  };
  const getColumns = () => {
    const CustomerContractOverviewTrans = "customer.contractOverview.";
    const columns = [
      {
        Header: t(CustomerContractOverviewTrans + "ContractNoFilter"),
        accessor: "contractNo"
      },
      {
        Header: t(CustomerContractOverviewTrans + "DateFilter"),
        accessor: "date"
      },
      {
        Header: t(CustomerContractOverviewTrans + "TypeFilter"),
        accessor: "type"
      },
      {
        Header: t(CustomerContractOverviewTrans + "ConditionsFilter"),
        accessor: "conditions"
      }
    ];
    return columns;
  };

  return (
    <Table
      classes="u-margin-top-small"
      height={table.height}
      setTableHeight={onSetTableHeight}
      columns={getColumns()}
      data={[]}
      selectedRow={selectedRow}
      onRowSelect={handleRowSelect}
    />
  );
};

export default CustomerContractOverviewTable;
