import React from "react";
import Table from "../common/table";
import { TableResultData } from "../common/tableFooter";
import { useTranslation } from "react-i18next";
import useTableState from "../common/redux/hooks/useTableState";
import { salespoint } from "../common/redux/types/selectorTypes";
import useTableLayoutActionDispatchers from "../common/redux/actions/layout/table/hooks/useTableLayoutActionDispatchers";
import { SALESPOINT } from "../common/redux/types/viewTypes";
import useDataState from "../common/redux/hooks/useDataState";

const SalespointTable = () => {
  const { t } = useTranslation();
  const { items, selectedItem } = useDataState(salespoint);
  const { height } = useTableState(salespoint);
  const { handleTableHeightSet } = useTableLayoutActionDispatchers(SALESPOINT);
  const SalespointTableTrans = "salespoint.";
  const columns = [
    {
      Header: t(SalespointTableTrans + "HeaderName"),
      accessor: "salespointName"
    },
    {
      Header: t(SalespointTableTrans + "HeaderPartner"),
      accessor: "salespointPartner"
    },
    {
      Header: t(SalespointTableTrans + "HeaderSalesPointGroup"),
      accessor: "salespointGroup"
    },
    {
      Header: t(SalespointTableTrans + "HeaderCode"),
      accessor: "salespointCode"
    }
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        classes="u-margin-bottom-small"
        setTableHeight={handleTableHeightSet}
        data={items || []}
        selectedRow={selectedItem}
        height={height}
      />
      <TableResultData count={0} />
    </React.Fragment>
  );
};

export default SalespointTable;
