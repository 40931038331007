import React from "react";
import {useTranslation} from "react-i18next";
import Table from "../../../common/table";
import useTabState from "../../../common/redux/hooks/useTabState";
import {receivedMail, vatDocuments} from "../../../common/redux/types/selectorTypes";
import useTabTableActionDispatchers from "../../../common/redux/actions/table/useTabTableActionDispatchers";
import {RECEIVED_MAIL, VAT_DOCUMENTS} from "../../../common/redux/types/viewTypes";

const VatDocumentsReceivedMailTable = () => {
  const { t } = useTranslation();
  const {table} = useTabState(vatDocuments, receivedMail);
  const {handleTableHeightSet} = useTabTableActionDispatchers(VAT_DOCUMENTS, RECEIVED_MAIL);
    const VatDocumentsTableTrans = "vat.documents.";
    const columns = [
      {
        id: "customer",
        Header: t(VatDocumentsTableTrans + "Customer"),
        accessor: d => (d.customer ? d.customer[0].name : "")
      },

      {
        id: "country",
        Header: t(VatDocumentsTableTrans + "Country"),
        accessor: d => (d.country ? t(`countries.${d.country[0].code}`) : "")
      },
      {
        Header: t(VatDocumentsTableTrans + "DocumentDate"),
        accessor: "documentDate"
      },
      {
        Header: t(VatDocumentsTableTrans + "DocumentNo"),
        accessor: "documentNo"
      },

      {
        Header: t(VatDocumentsTableTrans + "FilterSeller"),
        accessor: "sellerName"
      },
      {
        Header: t(VatDocumentsTableTrans + "DocumentNetto"),
        accessor: "documentNetto"
      },
      {
        Header: t(VatDocumentsTableTrans + "DocumentVat"),
        accessor: "documentVat"
      },

      {
        Header: t(VatDocumentsTableTrans + "DocumentCurrency"),
        accessor: "documentCurrencyCl"
      },
      {
        Header: t(VatDocumentsTableTrans + "IncomeFromTaxOffice"),
        accessor: "incomeFromTaxOffice"
      },
      {
        Header: t(VatDocumentsTableTrans + "PayoutToCustomerInEUR"),
        accessor: "payoutToCustomer"
      },
      {
        id: "applicationNo",
        Header: t(VatDocumentsTableTrans + "ApplicationNo"),
        accessor: d => (d.vatApplication ? d.vatApplication.applicationNo : "")
      },
      {
        Header: t(VatDocumentsTableTrans + "CreatedOn"),
        accessor: "createdOn"
      }
    ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={[]}
        isLoading={false}
        onRowSelect={() => {}}
        selectedRow={{}}
        height={table.height}
        setTableHeight={handleTableHeightSet}
      />
    </React.Fragment>
  );
};

export default VatDocumentsReceivedMailTable;
