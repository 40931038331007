import React from "react";

const NotFound = () => {
  return (
    <div className="routes-msg-container">
      <div className="routes-text">PAGE NOT FOUND</div>
    </div>
  );
};

export default NotFound;
