import {useCallback} from "react";
import {TOGGLE_TAB_EXPANDER,} from "../layoutActions";
import {useDispatch} from "react-redux";

const useTabLayoutActionDispatchers = (viewType, tabType) => {
    console.log("LAYOUT HOOK RENDER");
    const dispatch = useDispatch();

    const handleTabExpanderToggle = useCallback(() => {
        dispatch(TOGGLE_TAB_EXPANDER(viewType, tabType));
    }, [dispatch, viewType, tabType]);


    return {
        handleTabExpanderToggle
    };
};

export default useTabLayoutActionDispatchers;
