import axios from 'axios';
import {DATABASE_CONNECTION_FAIL, DATABASE_CONNECTION_OK} from "../actions/layoutActions";
import {authorizationHeaders} from "../../../services/queries";

export const checkIfDatabaseIsUp = () => {
    return dispatch => {
        axios.post("/graphql", {
            query: `query {
             classifications(count:1 type:LANGUAGE){
             attribute}
            }`
        }, authorizationHeaders()).then(d => dispatch(DATABASE_CONNECTION_OK(d.data.data.classifications)))
            .catch(err => {dispatch(DATABASE_CONNECTION_FAIL(err.message));})

    }
};